import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import MenuActionItem from '../../components/header/MenuActionItem';
import { gameRoutesFull } from '../../routing/gameRoutes';


const MenuGotoGame = React.forwardRef((props, ref) => {
  const { t } = useTranslation('site');

  const TEXT = t('Go to Game');

  function handleClick() {
    Globals.dispatchNavigate(gameRoutesFull.landing, { replace: false });
    Globals.dispatchCloseAppMenu();
  }

  return (
    <MenuActionItem onClick={handleClick} text={TEXT} />
  );
});
export default MenuGotoGame;
