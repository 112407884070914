import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { supportEmail } from '../../appSupport/GlobalConstants';

export default function Contact(props) {
  const { ...rest } = props;
  const { t } = useTranslation('site');
  const contactUs = t('contact: {{contactEmail}}', { contactEmail: supportEmail })
  return (
    <Typography {...rest}>
      {contactUs}
    </Typography >
  );
}