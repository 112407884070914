import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import BoxC from '../../components/box/BoxC';
import BoxCG from '../../components/box/BoxCG';
import BoxR from '../../components/box/BoxR';
import CardDecksHelp from '../../components/help/CardDecksHelp';
import DeckTableList from '../../components/list/DeckTableList';
import DeckStore from '../../db/DeckStore';
import DeckPrompt from '../../prompt/DeckPrompt';
import ProgressPrompt from '../../prompt/ProgressPrompt';

const classes = {
  root: 'DecksContent-root',
  containerBox: 'DecksContent-containerBox',
  listBox: 'DecksContent-listBox',
  headerBox: 'DecksContent-headerBox'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`& .${classes.containerBox}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.listBox}`]: {
    margin: theme.spacing(2),
    flexGrow: 1,
    flexShrink: 1
  },
  [`& .${classes.headerBox}`]: {
    justifyContent: 'space-between',
  }
}));

export default function DecksContent(props) {
  const { onFinished, decks, isDecksLoading, ...rest } = props;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedNames, setSelectedNames] = React.useState([]);
  const [deckIdToEdit, setDeckIdToEdit] = React.useState(null);
  const [pendingDeckIdToEdit, setPendingDeckIdToEdit] = React.useState(null);
  const [deckPromptOpen, setDeckPromptOpen] = React.useState(false);
  const [deleteProgressPrompt, setDeleteProgressPrompt] = React.useState({ open: false });

  const { t } = useTranslation('controller');

  function checkPendingEdit() {
    if (pendingDeckIdToEdit) {
      // See if it is now present in decks.
      const find = decks.find(f => f.id === pendingDeckIdToEdit);
      if (find) {
        setDeckPromptOpen(true);
        setPendingDeckIdToEdit(null);
        setDeckIdToEdit(find.id);
      }
    }
  }
  function handleFinished() {
    onFinished();
  }

  function handleRowSelection(selected) {
    setSelectedRows(selected);
    const names = decks.filter(f => selected.includes(f.id)).map(m => m.name);
    setSelectedNames(names);
  }
  function handleOnAddTool() {
    setDeckPromptOpen(true);
  }
  function handleOnDeleteTool() {
    const deletePromptText = Globals.listToCommaSeparatedString(selectedNames);
    Globals.showConfirmation({
      title: t('delete-deck'),
      text: t("are-you-sure-you-want-to-delet", { deckNames: deletePromptText }),
      continueButtonText: t('delete'),
      onSave: handleDeleteDeckConfirmed,
    });
  }
  function handleOnEditTool() {
    setDeckIdToEdit(decks.find(f => f.id === selectedRows[0]).id);
    setDeckPromptOpen(true);
  }

  async function handleDeleteDeckConfirmed() {
    const all = selectedRows.map(async e => {
      const deck = DeckStore.deckFor(e);
      setDeleteProgressPrompt({ open: true, statusText: t('deleting-deck-deckname', { deckName: deck.name }) });
      return await DeckStore.deleteDeck(e);
    });
    Promise.all(all).then(() => {
      setDeleteProgressPrompt({ open: false })
      setSelectedRows([]);
      setSelectedNames([]);
    });
  }
  function handleDeckPromptClose(addedId) {
    if (addedId) { // If a Deck was just created, open the edit prompt on that deck.
      setPendingDeckIdToEdit(addedId);
    }
    setDeckPromptOpen(false);
    setDeckIdToEdit(null);
  }

  checkPendingEdit();
  return (
    <StyledBoxCG className={classes.root} {...rest}>
      <DeckPrompt open={deckPromptOpen} onClose={handleDeckPromptClose} deckIdToEdit={deckIdToEdit} />
      <ProgressPrompt open={deleteProgressPrompt.open} statusText={deleteProgressPrompt.statusText} title={t('delete-deck-s')} />
      <BoxCG className={classes.containerBox}>
        <BoxR className={classes.headerBox}>
          <CardDecksHelp visible={false} />
          <Button onClick={handleFinished} variant="contained" color="primary" >
            {t('finished')}
          </Button>
          <CardDecksHelp />
        </BoxR>
        <BoxC className={classes.listBox}>
          <DeckTableList rows={decks} isDecksLoading={isDecksLoading} selectedRows={selectedRows}
            onRowSelection={handleRowSelection} onAdd={handleOnAddTool} onDelete={handleOnDeleteTool} onEdit={handleOnEditTool} />
        </BoxC>
      </BoxCG>
    </StyledBoxCG>
  );
}
