import { styled } from '@mui/material/styles';
import React from 'react';
import Globals from '../../appSupport/Globals';
import BoxC from '../../components/box/BoxC';


const classes = {
  publicBox: 'LandingBoxPublicBox'
};

const localOnly = ['isDefaultUser'];
const localOnlyTest = prop => !localOnly.includes(prop);

const StyledBoxC = styled(BoxC, { shouldForwardProp: localOnlyTest })(({ theme, isDefaultUser }) => ({
  [`&.${classes.publicBox}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    backgroundColor: isDefaultUser ? theme.palette.landingBackground.loggedOut : theme.palette.landingBackground.loggedIn,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
  }
}));

export default function LandingBox(props) {
  const isDefaultUser = Globals.isDefaultUser();

  return (
    <StyledBoxC className={classes.publicBox} isDefaultUser={isDefaultUser} {...props} >
      {props.children}
    </StyledBoxC>
  );
}
