import React from 'react';
import { useTranslation } from 'react-i18next';
import { gamePrefix } from '../../routing/gameRoutes';
import Asset from '../siteComponents/Asset';
import SequenceTitle from '../siteComponents/SequenceTitle';
import SiteNav from '../siteComponents/SiteNav';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';

const gameLanding = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameLanding.png';

export default function FlowStartGame(props) {
  const { mobile = false } = props;
  const { t } = useTranslation('site');
  const gameTarget = 'https://buenoFun.com/app';

  return (
    <SiteSection>
      <div>
        <SequenceTitle sequence='1' title={t('The Game Controller creates a game')} />
        <div>
          <Asset src={gameLanding} mobile={mobile} />
          <StyleParagraph>{t('The game controller goes to the game launch site and starts a game by pressing the New Game button.')}</StyleParagraph>
          <StyleParagraph>{t("It's a good idea for the game controller to spend some time with two devices to figure things out before hosting a game. A computer, tablet and phone all work well.")}</StyleParagraph>
          <SiteNav target={gamePrefix}>{gameTarget}</SiteNav>
        </div>
      </div>
    </SiteSection>
  );
}
