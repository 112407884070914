// NLS-NLS(player)
import { IconButton, Tooltip } from '@mui/material';
import IconImage from '@mui/icons-material/Refresh';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UserAssistance from '../../appSupport/UserAssistance';
import UserAssistancePopover from '../UserAssistancePopover';

export default function RefreshTool(props) {
  const { onClick } = props;
  const { t } = useTranslation('player');

  return (
    <UserAssistancePopover userAssistance={[UserAssistance.refreshToolTip()]}>
      <Tooltip title={t('refresh')}>
        <IconButton
          style={{ edge: 'start', color: 'inherit' }}
          onClick={onClick}
          size="large">
          <IconImage />
        </IconButton>
      </Tooltip>
    </UserAssistancePopover>
  );
}
