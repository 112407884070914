import { Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react';
import BoxC from '../../components/box/BoxC';
import BoxR from '../../components/box/BoxR';
import AppLogo from './AppLogo';
import Contact from './Contact';
import GotoGameBox from './GotoGameBox';

const localOnly = ['isMobile'];
const localOnlyTest = prop => !localOnly.includes(prop);
const HeaderBox = styled(BoxC, { shouldForwardProp: localOnlyTest })
  (({ theme, isMobile }) => ({
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    borderWidth: 1,
    borderColor: 'grey',
    borderStyle: 'solid',
    backgroundColor: theme.palette.landingBackground.loggedOut,
    justifyContent: (isMobile ? 'center' : undefined),
    alignItems: (isMobile ? 'center' : undefined),
  }));

const StyledBoxR = styled(BoxR)({
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const sxContactLarge = {
  alignSelf: 'flex-end',
  pr: 1,
};
const sxContactSmall = {
  pr: 1,
};

export default function SiteHeader(props) {
  const { ...rest } = props;
  return (
    <Fragment>
      <Hidden smDown>
        <HeaderBox {...rest}>
          <StyledBoxR>
            <AppLogo />
            <GotoGameBox />
          </StyledBoxR>
          <Contact sx={sxContactLarge} />
        </HeaderBox>
      </Hidden>
      <Hidden smUp>
        <HeaderBox isMobile={true} {...rest}>
          <AppLogo />
          <GotoGameBox />
          <Contact sx={sxContactSmall} />
        </HeaderBox>
      </Hidden>
    </Fragment>
  );
}
