import { Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import BoxR from '../box/BoxR';

export default function MenuActionItem(props) {
  const { onClick, text, IconClass, separatorBefore = false, separatorAfter = false } = props;

  const result = [];
  if (separatorBefore) result.push(<Divider key={`${text}-dividerB`} />);
  result.push(
    <MenuItem key={text} onClick={() => { onClick() }}>
      <BoxR>
        {IconClass ? <IconClass color='primary' style={{ marginRight: 5 }} /> : null}
        {text}
      </BoxR>
    </MenuItem>);
  if (separatorAfter) result.push(<Divider key={`${text}-dividerA`} />);
  return result;
}