import React from 'react';
import { useTranslation } from 'react-i18next';
import GameContent from '../game/GameContent';
import MenuFaq from '../menu/MenuFaq';
import MenuGotoGame from '../menu/MenuGotoGame';
import MenuInstructions from '../menu/MenuInstructions';
import MenuScanningTips from '../menu/MenuScanningTips';
import MenuSetupTips from '../menu/MenuSetupTips';
import SitePage from '../siteComponents/SitePage';


export default function Games(props) {
  const { t } = useTranslation('site');
  const title = t("Bueno Fun - Games");
  const tabTitle = t("Bueno Fun - Games");
  const component = <GameContent />
  const mobileComponent = <GameContent mobile />

  const menuActionItems = [
    <MenuGotoGame key='gotoGame' />,
    <MenuFaq key='faq' />,
    <MenuInstructions key='instructions' />,
    <MenuScanningTips key='scanningTips' />,
    <MenuSetupTips key='setupTips' />
  ];

  return (
    <SitePage title={title} tabTitle={tabTitle} component={component} mobileComponent={mobileComponent} menuActionItems={menuActionItems} />
  );
}
