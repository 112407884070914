let INSTANCE;
const MAX_LOG_SIZE = 100;

// https://unicode.org/emoji/charts/full-emoji-list.html
// 👁 🐞 🍒 🥚 🍥 🥮 🍩 🍪 🧊 🌎 🌐 🧭 🛑 ☀ 💧 🔍 🔦 ⭕
// 🔴 🟠 🟡 🟢 🔵 🟣 🟤 ⚫ ⚪  
// 🟥 🟧 🟨 🟩 🟦 🟪 🟫 ⬛ ⬜

const localDateString = () => new Date().toISOString(); // Device date
const externalDateString = (dateString) => `(${dateString})`; // Server date
const stringMax = (a, b) => { return a > b ? a : b }

export default class TraceLog {

  static instance() {
    if (!INSTANCE) {
      INSTANCE = new TraceLogInst();
    }
    return INSTANCE;
  }
  static add(entry) {
    TraceLog.instance().addEntry(entry);
  }
  static addDbEvent(subType, data = {}) {
    const entry = { type: ('🟦 ' + subType), date: localDateString(), data };
    TraceLog.instance().addEntry(entry);
  }
  static addSubscriptionEvent(subType, object = {}) {
    const date = stringMax(object.createdAt || '', object.updatedAt || '');
    const entry = { type: ('🔵 ' + subType), date: externalDateString(date), data: JSON.stringify(object) };
    TraceLog.instance().addEntry(entry);
  }

  static addTrace(message) {
    const entry = { type: '⚪ TRACE', date: localDateString(), data: message };
    TraceLog.instance().addEntry(entry);
  }
  static addEvent(message, subType) {
    const entry = { type: `⚫ EVENT${subType ? subType : ''}`, date: localDateString(), data: message };
    TraceLog.instance().addEntry(entry);
  }
  static addError(message) {
    const entry = { type: '🔴 ERROR', date: localDateString(), data: message };
    TraceLog.instance().addEntry(entry);
  }
  static getEntries() {
    return TraceLog.instance().getEntries();
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

}

class TraceLogInst {
  constructor() {
    this.init();
  }
  init() {
    this.entries = [];
  }
  getEntries() {
    return [...this.entries];
  }
  addEntry(entry) {
    this.entries.unshift(entry); // Add to the front
    while (this.entries.length > MAX_LOG_SIZE) {
      this.entries.pop(); // Remove from the end
    }
  }
  release() {
    this.init();
  }
}

