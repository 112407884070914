export const gamePrefix = '/app';
export const gamePrefixGeneric = '/app/*';
function fullPath(path) {
  return (gamePrefix + '/' + path);
}
export const gameRoutes = {
  landing: 'landing/',
  loading: 'loading/',
  addPlayers: 'addPlayers/',
  play: 'play/',
  playWait: 'playWait/',
  results: 'results/',
  newRound: 'newRound/',
  score: 'score/',
  decks: 'decks/',
  supportRequests: 'supportRequests/',
}
export const gameRoutesFull2 = {
  landing: fullPath(gameRoutes.landing),
  loading: fullPath(gameRoutes.loading),
  addPlayers: fullPath(gameRoutes.addPlayers),
  play: fullPath(gameRoutes.play),
  playWait: fullPath(gameRoutes.playWait),
  results: fullPath(gameRoutes.results),
  newRound: fullPath(gameRoutes.newRound),
  score: fullPath(gameRoutes.score),
  decks: fullPath(gameRoutes.decks),
  supportRequests: fullPath(gameRoutes.supportRequests),
}

export const gameRoutesFull =
  Object.assign({}, ...Object.entries(gameRoutes).map(([key, value]) => {
    return { [key]: fullPath(value) }
  }));