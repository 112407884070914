import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupMultipleEntry(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Set the "Number of answer fields" to the number of entry fields you want"')}>
        {t('Multiple entry fields')}
      </SetupTip>
    </p>
  );
}
