export default class AutoScoreReader {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;

    this.firstAnswerScoring = input ? input.firstAnswerScoring : false;
    this.firstAnswerCorrect = input ? input.firstAnswerCorrect : 2;
    this.firstAnswerIncorrect = input ? input.firstAnswerIncorrect : -1;

    this.answerCorrect = input ? input.answerCorrect : 1;
    this.answerIncorrect = input ? input.answerIncorrect : 0;

    this.lastAnswerScoring = input ? input.lastAnswerScoring : false;
    this.lastAnswerCorrect = input ? input.lastAnswerCorrect : undefined;
    this.lastAnswerIncorrect = input ? input.lastAnswerIncorrect : undefined;

    this.readerPoints = input ? input.readerPoints : 0;
    this.noAnswerSubmittedPoints = input ? input.noAnswerSubmittedPoints : 0;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

