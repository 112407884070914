// NLS-NLS(controller)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import GameStore from '../../db/GameStore';
import GameOptions from '../../fields/GameOptions';

const classes = {
  root: 'NewGameButton-root',
  gameButton: 'NewGameButton-gameButton',
  button: 'NewGameButton-button'
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.gameButton}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    // color: theme.palette.primary.contrastText,
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  }
}));

export default function NewGameButton(props) {
  const { open, sx: sxIn } = props;

  const game = GameStore.getGame();
  const isScoreKeeper = Globals.isScoreKeeper(game);
  const gameOptions = new GameOptions(game.gameOptions);
  const { t } = useTranslation('controller');
  const relativeCurrentHand = Globals.getRelativeCurrentHand();

  const handleNewGameConfirmNow = () => {
    Globals.showConfirmation({
      title: t('Resetting scores'), text: t("Would you like to start a new game beginning with the next round (scores to 0) or would you like this round to be the start of the next game (keep this round's scores)?"),
      onSave: handleNewGame, continueButtonText: [t('scores to 0'), t('keep this round scores')]
    });
  }
  const handleNewGame = ({ index }) => {
    if (index === 0) {
      GameStore.resetScoringForNextRound();
    } else {
      GameStore.resetScoringForThisRound();
    }
  }

  if (!open) return null; // ==> EARLY EXIT
  if (!isScoreKeeper || !gameOptions.keepScore) return null; // ==> EARLY EXIT

  return (
    <Root className={classes.root} sx={sxIn}>
      <Button className={classes.gameButton} color='primary' variant="contained" onClick={handleNewGameConfirmNow} disabled={!relativeCurrentHand}>
        {t('new-game')}
      </Button>
    </Root >
  );
}
NewGameButton.defaultProps = {
  open: true,
}
