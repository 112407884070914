import React from 'react';
import Globals from '../../appSupport/Globals';
import BoxCG from '../../components/box/BoxCG';
import ControllerStatusLine from '../../components/ControllerStatusLine';
import DividerH from '../../components/DividerH';
import GameControls from '../../components/GameControls';
import PlayControls from '../../components/PlayControls';
import PlayerStatusLine, { STATUS_WAITING_CONTROLLER, STATUS_WAITING_OTHER_PLAYERS } from '../../components/PlayerStatusLine';
import PlayOptionControls from '../../components/PlayOptionControls';
import PlayTimer from '../../components/PlayTimer';
import PublishControls from '../../components/PublishControls';
import GameStore from '../../db/GameStore';
import PerformAutoScoringReader from '../../db/PerformAutoScoringReader';
import { useFreshResults } from '../../db/ResultStore';
import ScoreStore from '../../db/ScoreStore';
import GamePlay from '../../fields/GamePlay';
import RoundData from '../../fields/RoundData';
import TimerValues, { ANSWER_TIMER, QUESTION_TIMER } from '../../fields/TimerValues';
import { generatePlayerResults } from './PlayWaitContent';

export default function PlayWaitController(props) {
  const { game } = props;
  const [isRoundOverWork, setIsRoundOverWork] = React.useState(false);
  const lastAnswerTimerActive = React.useRef(null);
  const isController = Globals.isGameController();
  const isRoundOver = Globals.isRoundOver();
  const gamePlay = GamePlay.fromGame(game);
  const roundData = RoundData.fromGame(game);
  const playerResultsIn = useFreshResults();
  const playerResults = generatePlayerResults(playerResultsIn, roundData);

  const questionTimerValues = TimerValues.newTimerValues(QUESTION_TIMER, game);
  const questionTimerIsRunning = questionTimerValues.isRunning();

  const answerTimerValues = TimerValues.newTimerValues(ANSWER_TIMER, game);
  const answerTimerIsReady = answerTimerValues.isReady();
  const answerTimerHasTime = answerTimerValues.hasTime();

  React.useEffect(() => {
    if (isRoundOver) {
      ScoreStore.initialQueryCompletePromise()
        .then(f => {
          PerformAutoScoringReader.perform();
        });
    }
  }, [isRoundOver]);

  React.useEffect(() => {
    if (!isRoundOverWork && isRoundOver) {
      setIsRoundOverWork(true);
      lastAnswerTimerActive.current = gamePlay.isAnswerTimerActive;
      if (questionTimerIsRunning) {
        GameStore.timerKill(QUESTION_TIMER);
      }
    } else if (isRoundOverWork) { // Allow for the timer being added/removed
      if (lastAnswerTimerActive.current !== gamePlay.isAnswerTimerActive) {
        lastAnswerTimerActive.current = gamePlay.isAnswerTimerActive;
        if (gamePlay.isAnswerTimerActive) {
          if (!answerTimerIsReady || !answerTimerHasTime) GameStore.timerReady(ANSWER_TIMER, 15); // Ready for a 15 second start
        } else {
          GameStore.timerKill(ANSWER_TIMER); // Remove timer from UI
        }
      }
    }
  }, [isRoundOver, questionTimerIsRunning, answerTimerIsReady, answerTimerHasTime, gamePlay.isAnswerTimerActive, isRoundOverWork]);

  function handleSavePlayOptionControls(gamePlay) {
    GameStore.updateGamePlay(game, gamePlay);
  }

  function handleTimerExpired() {
    if (gamePlay.isAnswerTimerActive) {
      GameStore.timerReady(ANSWER_TIMER, 15);
    } else {
      GameStore.timerKill(ANSWER_TIMER); // Remove timer from UI
    }
  }

  const renderControllerContent = () => {
    return <React.Fragment>
      <DividerH />
      <PlayOptionControls game={game} onSave={handleSavePlayOptionControls} open={isController} />
      <DividerH />
      <PublishControls game={game} playerResults={playerResults} />
      <GameControls game={game} />
    </React.Fragment>
  }

  const renderStatusLines = () => {
    const status = isRoundOver ? STATUS_WAITING_CONTROLLER : STATUS_WAITING_OTHER_PLAYERS;
    return <React.Fragment>
      <PlayerStatusLine game={game} status={status} />
      <ControllerStatusLine game={game} />
    </React.Fragment>
  }

  return (
    <BoxCG >
      {renderStatusLines()}
      <PlayTimer game={game} onTimerExpired={handleTimerExpired} whichTimer={QUESTION_TIMER} />
      <PlayControls whichTimer={QUESTION_TIMER} />
      {renderControllerContent()}
    </BoxCG>
  );
}
