// NLS-NLS(player)
import { Button, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import { STORAGE_viewedCardIds } from '../appSupport/storageConstants';
import BoxCG from '../components/box/BoxCG';
import BoxR from '../components/box/BoxR';
import PdfCard from '../components/PdfCard';
import { useFreshCards } from '../db/CardStore';
import GameStore from '../db/GameStore';
import { useStateWithSessionStorage } from '../db/StorageStore';
import ViewedCardIds from '../fields/ViewedCardIds';


const classes = {
  root: 'TabbedView-root',
  buttonTabRow: 'TabbedView-buttonTabRow',
  navButton: 'TabbedView-navButton'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`& .${classes.buttonTabRow}`]: {
    flexWrap: 'wrap',
  },
  [`& .${classes.navButton}`]: {
    margin: theme.spacing(1),
  }
}));


export const SELECT_CONTROLLER_TAB = 'SELECT_CONTROLLER_TAB';
export const SELECT_MAIN_TAB = 'SELECT_MAIN_TAB';

export default function TabbedView(props) {
  const { controllerView, mainView, mainLabel, selectTab,
    onControllerViewSelected, onMainViewSelected, onCardViewSelected,
    buttonTabs, showCards: showCardsIn, ...rest } = props;
  const showCards = (showCardsIn === undefined) ? true : showCardsIn;

  const game = GameStore.getGame();
  const isController = Globals.isGameController();
  const showControllerTab = isController && !!controllerView;
  const controllerTabIndex = showControllerTab ? 0 : -1;
  const mainTabIndex = showControllerTab ? 1 : 0;
  const [currentTab, setCurrentTab] = React.useState(mainTabIndex);
  const [viewedCardIdsJson, setViewedCardIdsJson] = useStateWithSessionStorage(STORAGE_viewedCardIds, '');
  const theme = useTheme();
  const cards = useFreshCards();
  const cardsReversed = [...cards].reverse();
  const hasCards = !!cards.length && showCards;
  const { t } = useTranslation('player');


  // Clean up and make sure viewed card data is not stale.
  const viewedCardIds = new ViewedCardIds(viewedCardIdsJson);
  if (viewedCardIds.gameId !== game.id) {
    setViewedCardIdsJson(new ViewedCardIds().asJSON());
  } else {
    const newViewedCardIds = new ViewedCardIds();
    if (cards.length) {
      cards.forEach(e => { if (viewedCardIds.viewedIds.includes(e.cardKey)) newViewedCardIds.viewedIds.push(e.cardKey) });
      if (newViewedCardIds.viewedIds.length !== viewedCardIds.viewedIds.length) {
        setViewedCardIdsJson(newViewedCardIds.asJSON());
      }
    }
  }

  React.useEffect(() => {
    if (!selectTab) return undefined;
    if (selectTab === SELECT_CONTROLLER_TAB && showControllerTab) setCurrentTab(controllerTabIndex);
    if (selectTab === SELECT_MAIN_TAB) setCurrentTab(mainTabIndex);
  }, [controllerTabIndex, mainTabIndex, selectTab, showControllerTab]);

  function cardForTabIndex(tabIndex) {
    if (tabIndex === 0) return showControllerTab ? -1 : -1;
    if (tabIndex === 1) return showControllerTab ? -1 : 0;
    if (tabIndex > 1) return tabIndex - (showControllerTab ? 2 : 1);
    return -1;
  }
  function reportCardVisited(card) {
    const newViewedCardIds = new ViewedCardIds(viewedCardIdsJson);
    if (!newViewedCardIds.viewedIds.includes(card.cardKey)) {
      newViewedCardIds.viewedIds.push(card.cardKey);
      setViewedCardIdsJson(newViewedCardIds.asJSON());
    }
  }
  function reportTabChange(tabIndex) {
    if (onControllerViewSelected && (tabIndex === controllerTabIndex)) onControllerViewSelected();
    if (onMainViewSelected && (tabIndex === mainTabIndex)) onMainViewSelected();
    if (onCardViewSelected && (tabIndex > mainTabIndex)) onCardViewSelected();
    const cardIndex = cardForTabIndex(tabIndex);
    if (cardIndex !== -1) {
      const card = cardsReversed[cardIndex];
      reportCardVisited(card);
    }
  }

  function handleChangeViaTab(event, newIndex) {
    setCurrentTab(newIndex);
    reportTabChange(newIndex);
  }
  function RenderButton({ text, disabled, onClick, open = true }) {
    if (!open) return null;
    return (
      <Button className={classes.navButton} variant="contained"
        disabled={disabled} color='secondary' onClick={onClick} >
        {text}
      </Button>
    );
  }
  function renderButtonTabs() {
    if (!hasCards && !showControllerTab) return null; // ==> EARLY EXIT
    const bump = showControllerTab ? 1 : 0;
    return (
      <BoxR className={classes.buttonTabRow}>
        <RenderButton text={t('controller')} disabled={controllerTabIndex === currentTab} onClick={f => setCurrentTab(controllerTabIndex)} open={showControllerTab} />
        <RenderButton text={`${mainLabel}`} disabled={mainTabIndex === currentTab} onClick={f => setCurrentTab(mainTabIndex)} />
        {
          cards.map((m, i) => <RenderButton text={t('card-cardno', { cardNo: i + 1 })} key={`card ${i + 1}`}
            disabled={cards.length - i + bump === currentTab} onClick={f => setCurrentTab(cards.length + bump - i)} />).reverse()
        }
      </BoxR>
    );
  }
  function renderTabs() {
    if (!showControllerTab && !hasCards) return null; // ==> EARLY EXIT
    return (
      <Tabs
        key='tabs'
        value={currentTab}
        onChange={handleChangeViaTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      // aria-label="full width tabs example"
      >
        {showControllerTab && <Tab label={t('controller')} key='controllerTab' />}
        <Tab label={mainLabel ? mainLabel : t('main')} key='mainTab' />
        {renderCardTabs()}
      </Tabs>
    );
  }
  function renderCardTabs() {
    return cards.map((m, i) => {
      const style = (viewedCardIds.viewedIds.includes(m.cardKey)) ? {}
        : { backgroundColor: theme.palette.secondary.light, borderRadius: 0 };
      return <Tab label={t('card-cardno', { cardNo: i + 1 })} key={`cardTab${i}`} style={style} />
    }).reverse();
  }
  function renderInDiv(child, keyText, inView) {
    return (
      <div key={keyText} hidden={!inView}>
        {child}
      </div>
    );
  }

  function renderTabContent() {
    const children = [];
    const userName = Globals.getDataOwner();
    if (showControllerTab) children.push(renderInDiv(controllerView, 'controllerView', (currentTab === controllerTabIndex)));
    children.push(renderInDiv(mainView, 'mainView', (currentTab === mainTabIndex)));

    const cardIndex = currentTab - (showControllerTab ? 2 : 1);
    const reversedCards = [...cards].reverse();
    reversedCards.forEach((e, i) => children.push(renderInDiv(
      <PdfCard userName={userName} deckId={e.deckId} fileName={e.cardKey} key={e.cardKey + i} inView={(cardIndex === i)} />,
      `card-${i}`,
      (cardIndex === i)
    )));
    return children;
  }

  return (
    <StyledBoxCG className={classes.root} {...rest}>
      {buttonTabs ? renderButtonTabs() : renderTabs()}
      <BoxCG style={{ overflow: 'hidden' }}>
        {renderTabContent()}
      </BoxCG>
    </StyledBoxCG>
  );
}
