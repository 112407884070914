
export default class EntryRequests {
  static entryRequestsFor(game) {
    const er = game ? game.entryRequests : null;
    return er ? new EntryRequests(er) : null;
  }

  constructor(jsonInput) {
    if (jsonInput) {
      const input = JSON.parse(jsonInput);
      this.requests = input.requests || [];
    } else {
      this.requests = [];
    }
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

