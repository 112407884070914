const TIMER_STATE_READY = 'READY';
const TIMER_STATE_RUNNING = 'RUNNING';
const TIMER_STATE_PAUSED = 'PAUSED';
const TIMER_STATE_EXPIRED = 'EXPIRED';

export const ANSWER_TIMER = 'ANSWER_TIMER';
export const QUESTION_TIMER = 'QUESTION_TIMER';

export default class TimerValues {
  static newTimerValues(whichTimer, game) {
    if (whichTimer === ANSWER_TIMER) {
      return new TimerValues(game.answerTimerValues);
    } else {
      return new TimerValues(game.questionTimerValues);
    }
  }
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.state = input ? input.state : TIMER_STATE_READY;
    this.startTime = input ? input.startTime : null;
    this.remainingTime = input ? input.remainingTime : null;
    this.totalTime = input ? input.totalTime : null;
  }
  hasTime() {
    return this.remainingTime !== null;
  }
  isDefaultValues() {
    return this.isReady() && !this.hasTime();
  }
  isExpired() {
    return this.state === TIMER_STATE_EXPIRED;
  }
  isPaused() {
    return this.state === TIMER_STATE_PAUSED;
  }
  isReady() {
    return this.state === TIMER_STATE_READY;
  }
  isRunning() {
    return this.state === TIMER_STATE_RUNNING;
  }
  setToExpired() {
    this.state = TIMER_STATE_EXPIRED;
    this.remainingTime = 0;
  }
  setToPaused() {
    this.state = TIMER_STATE_PAUSED;
  }
  setToRunning() {
    this.state = TIMER_STATE_RUNNING;
  }
  setToReady() {
    this.state = TIMER_STATE_READY;
  }
  getRemainingSeconds() {
    if (this.isExpired()) return 0; // ==> EARLY EXIT
    const secondsGoneBy = (new Date().getTime() - new Date(this.startTime).getTime()) / 1000;
    return Math.max(this.remainingTime - (this.isRunning() ? secondsGoneBy : 0), 0);
  }
  readyWith(seconds) {
    this.startTime = new Date().toISOString();
    this.remainingTime = isNaN(seconds) ? null : seconds;
    this.totalTime = isNaN(seconds) ? null : seconds;
    this.setToReady();
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

