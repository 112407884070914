// NLS-NLS(support)
import { Hub } from '@aws-amplify/core';
import { IconButton, Tooltip } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { nlsChannel } from '../../appSupport/GlobalConstants';
import Globals from '../../appSupport/Globals';
import UserAssistance from '../../appSupport/UserAssistance';
import SupportedLanguages from '../../i18n/SupportedLanguages';
import UserAssistancePopover from '../UserAssistancePopover';

export default function TranslateTool() {

  const [localLanguageCode, setLocalLanguageCode] = React.useState(Globals.getLanguageCode());
  const isBeta = SupportedLanguages.isBeta(localLanguageCode);
  const { t } = useTranslation('support');

  React.useEffect(() => {
    const cb = (event) => {
      const newLanguageCode = event.payload.newLanguage || localLanguageCode;
      if (event.payload.overrideBetaLanguages || event.payload.newLanguage) {
        setLocalLanguageCode(newLanguageCode);
      }
    }
    Hub.listen(nlsChannel, cb);
    return () => Hub.remove(nlsChannel, cb);
  }, [localLanguageCode, setLocalLanguageCode]);

  function handleTranslate() {
    Globals.dispatchSupport({ openTranslateToolPrompt: true });
  }

  if (!isBeta) return null;  // ==> EARLY EXIT
  return (
    <UserAssistancePopover userAssistance={[UserAssistance.translateToolTip()]}>
      <Tooltip title={t('translate-options')}>
        <IconButton
          style={{ edge: 'start', color: 'inherit' }}
          onClick={handleTranslate}
          size="large">
          <TranslateIcon />
        </IconButton>
      </Tooltip>
    </UserAssistancePopover>
  );
}
