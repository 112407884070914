// NLS-NLS(support)
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../../appSupport/UiUtil';
import SupportRequestStore from '../../db/SupportRequestStore';
import PromptMessage from '../PromptMessage';

const ID_FEEDBACK = 'feedback';
const ID_COMMENT = 'comment';

export default function GeneralFeedbackPrompt(props) {
  const EMPTY_STAGED_VALUES = {};
  const { open, onClose } = props;

  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [errorText, setErrorText] = React.useState('');
  const { t } = useTranslation('support');

  const PROMPT_TITLE = t('share-your-feedback');
  const PROMPT_TEXT_1 = t('good-or-bad-we-would-love-to-h');

  const clearValidations = () => {
    setErrorText('');
  }
  const validations = {
    feedbackEntered: () => {
      if (!stagedValues[ID_FEEDBACK]) {
        setErrorText(t('value-may-not-be-blank'));
        return true;
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      return validations.feedbackEntered();
    }
  }

  function handleClose() {
    clearValidations();
    setStagedValues(EMPTY_STAGED_VALUES);
    onClose();
  }

  function handleSave() {
    if (!validations.performValidate()) {
      SupportRequestStore.createGeneralFeedback(stagedValues[ID_FEEDBACK], stagedValues[ID_COMMENT]);
      setStagedValues(EMPTY_STAGED_VALUES);
      onClose();
    }
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  return (
    <React.Fragment>
      <Dialog
        onClose={(event, reason) => UiUtil.onClose(event, reason, handleClose)}
        open={open}
      >
        <DialogTitle>{PROMPT_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText >{PROMPT_TEXT_1}</DialogContentText>
          <TextField autoFocus fullWidth autoComplete='off' onChange={handleValueChange} error={!!errorText}
            id={ID_FEEDBACK} label={t('tell-us-what-you-think')} type="text" defaultValue={stagedValues[ID_FEEDBACK]} margin='normal' />
          <PromptMessage errorText={errorText} />

          <TextField fullWidth multiline rows={3} onChange={handleValueChange}
            id={ID_COMMENT} label={t('comment')} type="text" defaultValue={stagedValues[ID_COMMENT]} margin='normal' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
