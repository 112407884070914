import PropTypes from 'prop-types';
import React from 'react';

export const THIN = 1;
export const FAT = 4;

export default function DividerH(props) {
  const { open, size, color, style, ...rest } = props;
  if (!open) return null; // ==> EARLY EXIT
  return (
    <div style={{ width: '100%', minHeight: size, backgroundColor: color, ...style }} {...rest} />
  );
}

DividerH.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
};

DividerH.defaultProps = {
  open: true,
  color: 'grey',
  size: THIN,
}