import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import SupportRequestStore, { useFreshSupportRequests } from '../../db/SupportRequestStore';
import { gameRoutesFull } from '../../routing/gameRoutes';
import TabbedPage from '../TabbedPage';
import SupportRequestContent from './SupportRequestsContent';

export default function SupportRequests() {
  const [isLoading, setIsLoading] = React.useState(true);
  const records = useFreshSupportRequests();
  const { t } = useTranslation('player');
  const tabTitle = t('Bueno Fun - Support');

  React.useEffect(() => {
    SupportRequestStore.initialQueryCompletePromise().then(() => setIsLoading(false));
  }, []);

  const handleFinished = () => {
    Globals.dispatchNavigate(gameRoutesFull.landing, { replace: false });
  }

  return (
    <TabbedPage tabTitle={tabTitle}>
      <SupportRequestContent supportRequests={records} isLoading={isLoading} onFinished={handleFinished} />
    </TabbedPage>
  );
}
