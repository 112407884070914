import { styled } from '@mui/material/styles';
import { Chip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import UiUtil from '../appSupport/UiUtil';
import BoxCG from '../components/box/BoxCG';
import BoxR from '../components/box/BoxR';
import BoxRG from '../components/box/BoxRG';
import ProfileStore from '../db/ProfileStore';
import AddNewPlayersPrompt from './AddNewPlayersPrompt';

const classes = {
  availablePlayersBox: 'ConfigureTeamNamesPrompt-availablePlayersBox',
  selectedPlayersText: 'ConfigureTeamNamesPrompt-selectedPlayersText',
  selectedPlayersBox: 'ConfigureTeamNamesPrompt-selectedPlayersBox',
  chip: 'ConfigureTeamNamesPrompt-chip',
  noneSelectedText: 'ConfigureTeamNamesPrompt-noneSelectedText'
};

const Root = styled('div')(({ theme }) => ({
}));
const SelectedPlayersBox = styled(BoxR)(({ theme }) => ({
  [`&.${classes.selectedPlayersBox}`]: {
    alignItems: 'center',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
  }
}));
const AvailablePlayersBox = styled(BoxR)(({ theme }) => ({
  [`&.${classes.availablePlayersBox}`]: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
  }
}));

const SelectedPlayersText = styled(Typography)(({ theme }) => ({
  [`&.${classes.selectedPlayersText}`]: {
    margin: theme.spacing(2),
  }
}));
const NoneText = styled(Typography)(({ theme }) => ({
  [`&.${classes.noneSelectedText}`]: {
    margin: theme.spacing(2),
  }
}));
const StyledChip = styled(Chip)(({ theme }) => ({
  [`&.${classes.chip}`]: {
    margin: 2,
    backgroundColor: theme.palette.playerChip.main,
    color: theme.palette.playerChip.contrastText,
    textTransform: 'none',
  },
}));


const ID_SELECTED_PLAYER_NAMES = 'selectedPlayerNames';
const EMPTY_STAGED_VALUES = { [ID_SELECTED_PLAYER_NAMES]: [] };

export default function ConfigureTeamNamesPrompt(props) {

  const { open, onCancel, onSave, selectedPlayerNames: selectedPlayerNamesIn } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [isAddNewPlayersPromptOpen, setIsAddNewPlayersPromptOpen] = React.useState(false);

  const { t } = useTranslation('controller');


  React.useEffect(() => {
    setStagedValues({ [ID_SELECTED_PLAYER_NAMES]: selectedPlayerNamesIn });
  }, [selectedPlayerNamesIn]);
  const selectedPlayerNames = stagedValues[ID_SELECTED_PLAYER_NAMES];
  const availablePlayerNames = ProfileStore.getPlayerNames().filter(f => !selectedPlayerNames.includes(f))
    .sort(Globals.sorterAnyStringCaseInsensitive);
  const clearValidations = () => {
  }
  const validations = {
    performValidate: () => {
      clearValidations();
      return false;
    }
  }

  function handleAddNewPlayersSave(newPlayers) {
    const newNames = newPlayers.split(',').map(m => m.trim());
    if (newNames.length && newNames[0].length) {
      ProfileStore.addPlayerNames(newNames);
      const newStaged = { ...stagedValues };
      newStaged[ID_SELECTED_PLAYER_NAMES] = [...selectedPlayerNames, ...newNames];
      setStagedValues(newStaged);
    }
    setIsAddNewPlayersPromptOpen(false);
  }

  function handleDeleteSave() {
    ProfileStore.removePlayerNames(selectedPlayerNames);
    const newStaged = { ...stagedValues };
    newStaged[ID_SELECTED_PLAYER_NAMES] = [];
    setStagedValues(newStaged);
  }

  const handleCancel = () => {
    setStagedValues(EMPTY_STAGED_VALUES);
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      onSave(selectedPlayerNames);
      clearValidations();
      setStagedValues(EMPTY_STAGED_VALUES);
    }
  }
  const handlePlayerClick = (event) => {
    const player = event.target.textContent;
    const newSelectedPlayers = (selectedPlayerNames.includes(player))
      ? [...selectedPlayerNames] : [...selectedPlayerNames, player];
    const newStaged = { ...stagedValues };
    newStaged[ID_SELECTED_PLAYER_NAMES] = newSelectedPlayers;
    setStagedValues(newStaged);
  }
  const handleSelectedPlayerClick = (event) => {
    const player = event.target.textContent;
    const newSelectedPlayers = selectedPlayerNames.filter(f => f !== player);
    const newStaged = { ...stagedValues };
    newStaged[ID_SELECTED_PLAYER_NAMES] = newSelectedPlayers;
    setStagedValues(newStaged);
  }

  function showDeleteConfirmation() {
    Globals.showConfirmation({
      title: t('delete-players'),
      text: deleteText,
      continueButtonText: deleteButtonText,
      onSave: handleDeleteSave
    });
  }

  const RenderPlayerButtons = () => {
    if (!availablePlayerNames.length) return <NoneText className={classes.noneSelectedText}>{t('none')}</NoneText>
    return availablePlayerNames.map(m =>
      <Button id={m} key={m} onClick={handlePlayerClick}>
        <StyledChip className={classes.chip} label={m} />
      </Button>
    );
  }

  const RenderSelectedPlayerButtons = () => {
    if (!selectedPlayerNames.length) return <NoneText className={classes.noneSelectedText}>{t('none')}</NoneText>
    return selectedPlayerNames.map(m =>
      <Button id={m} key={m} onClick={handleSelectedPlayerClick}>
        <StyledChip className={classes.chip} label={m} />
      </Button>
    );
  }

  if (!open) return null;
  const formattedSelectedPlayers = ProfileStore.formatTeamNames(selectedPlayerNames);
  const deleteText = selectedPlayerNames.length
    ? t('are-you-sure-you-want-to-delPl', { playerNames: formattedSelectedPlayers })
    : t('you-must-first-select-the-play');
  const deleteButtonText = selectedPlayerNames.length ? t('delete-players') : undefined;

  return (
    <Root>
      <AddNewPlayersPrompt open={isAddNewPlayersPromptOpen} onSave={handleAddNewPlayersSave}
        onClose={f => setIsAddNewPlayersPromptOpen(false)} />
      <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{t('configure-players')}</DialogTitle>
        <DialogContent>
          <Typography>{t('previously-used-players')}</Typography>
          <AvailablePlayersBox className={classes.availablePlayersBox}>
            <RenderPlayerButtons />
          </AvailablePlayersBox>
          <SelectedPlayersText className={classes.selectedPlayersText} >{t('selected-players')}</SelectedPlayersText>
          <SelectedPlayersBox className={classes.selectedPlayersBox}>
            <RenderSelectedPlayerButtons />
          </SelectedPlayersBox>
        </DialogContent>
        <DialogActions>
          <BoxCG >
            <BoxR>
              <BoxRG />
              <Button onClick={handleCancel} color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
                {t('continue')}
              </Button>
            </BoxR>
            <BoxR>
              <Button onClick={f => setIsAddNewPlayersPromptOpen(true)} color="secondary">
                {t('add-new-names')}
              </Button>
              <Button onClick={f => showDeleteConfirmation()} color="secondary">
                {t('delete-selected')}
              </Button>
            </BoxR>
          </BoxCG>
        </DialogActions>
      </Dialog>
    </Root>
  );
}