import { styled } from '@mui/material/styles';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';

const classes = {
  centeringBox: 'ProgressPrompt-centeringBox'
};

const sxMargin2 = { m: 2 };

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.centeringBox}`]: {
    justifyContent: 'center',
  }
}));

export default function ProgressPrompt(props) {

  const { open, value, title, statusText } = props;
  const isDeterminate = value !== undefined;

  function handleOnClose(event, reason) {
    UiUtil.onClose(event, reason);
  }
  function RenderCircularProgress() {
    if (isDeterminate) return null;
    return (
      <StyledBoxR className={classes.centeringBox}>
        <CircularProgress sx={sxMargin2} />
      </StyledBoxR>
    );
  }
  function RenderLinearProgress() {
    if (!isDeterminate) return null;
    return (
      <LinearProgress sx={sxMargin2} variant='determinate' value={value} />
    );
  }
  if (!open) return null;
  const minWidth = Math.min(isDeterminate ? 400 : 200, window.innerWidth * .8);

  return (
    <Dialog open={open} onClose={handleOnClose} >
      <DialogTitle style={{ minWidth: minWidth }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <RenderLinearProgress />
        <RenderCircularProgress />
        <Typography >{statusText}</Typography>
      </DialogContent>
    </Dialog>
  );
}