import { Hub } from "aws-amplify";
import Globals from "../appSupport/Globals";
import TraceLog from "../appSupport/TraceLog";
import StoreFailure from "./StoreFailure";

const TRANSACTION_TIMEOUT = 5000;
const PUT = 'PUT';
const DELETE = 'DELETE';

export default class AbstractStore {
  constructor(storeName, channel, optimizeUpdates = true) {
    this.storeName = storeName;
    this.channel = channel;
    // If false, updates happen in order received.  If true, pending updates are consolidated into one transaction.
    this.optimizeUpdates = optimizeUpdates;
    this.records = [];
    this.localCreates = {};
    this.localUpdates = new LocalUpdates();
    this.valuesForInit = '';
    this.createSubscription = null;
    this.updateSubscription = null;
    this.localUpdateTxnKey = 1;
  }
  loadStore(onCreateFn, onUpdateFn, onDeleteFn) {
    const valuesForInit = this.buildValuesForInit();
    TraceLog.addTrace(`${this.storeName} loading.  Initial values: ${valuesForInit}`);
    this.loadedPromiseData = new Promise(resolve => {
      this.pendingTransactions = [];
      if (onCreateFn) {
        this.createSubscription = onCreateFn(this.handleRecordCreate.bind(this), this.handleSubscriptionError.bind(this), this.onCreateProps());
      }
      if (onUpdateFn) {
        this.updateSubscription = onUpdateFn(this.handleRecordUpdate.bind(this), this.handleSubscriptionError.bind(this), this.onUpdateProps());
      }
      if (onDeleteFn) {
        this.deleteSubscription = onDeleteFn(this.handleRecordDelete.bind(this), this.handleSubscriptionError.bind(this), this.onDeleteProps());
      }
      // The code below happens asynchronously but can be waited on with the this.initialQueryCompletePromise()
      this.setValuesForInit(valuesForInit);
      this.loadInitialRecords()
        .then(f => TraceLog.addTrace(`${this.storeName} load complete.`));
      resolve();
    });
  }
  onCreateProps() {
    return {};
  }
  onDeleteProps() {
    return {};
  }
  onUpdateProps() {
    return {};
  }
  queryRecords() {
    throw new Error('Implementation required');
  }
  performCreate(properties) {
    throw new Error('Implementation required');
  }
  performDelete(keyFields) {
    throw new Error('Implementation required');
  }
  performUpdate(keyFields, updates) {
    throw new Error('Implementation required');
  }
  getFilterFn() {
    return undefined;
  }
  getSortFn() {
    return undefined;
  }
  recKey(record) {
    throw new Error('Implementation required');
  }
  assertReady(debug) {
    if (this.getValuesForInit().toString() !== this.buildValuesForInit().toString()) {
      const error = `Store ${this.storeName} (${debug}) is being accessed inconsistently.  Accessed with: [${this.buildValuesForInit().toString()}]  Initialized with: [${this.getValuesForInit().toString()}]`;
      TraceLog.addError(error);
      Globals.dispatchUserError(error);
    }
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    return '';
  }
  getValuesForInit() {
    return this.valuesForInit;
  }
  setValuesForInit(valuesForInit) {
    this.valuesForInit = valuesForInit;
  }
  assimilateList(inputList) {
    const list = this.removeDuplicates(inputList);
    if (this.getSortFn()) {
      return list.sort(this.getSortFn());
    }
    return list;
  }
  release() {
    if (this.createSubscription) this.createSubscription.unsubscribe();
    if (this.updateSubscription) this.updateSubscription.unsubscribe();
    if (this.deleteSubscription) this.deleteSubscription.unsubscribe();
    this.createSubscription = null;
    this.updateSubscription = null;
    this.deleteSubscription = null;
    this.records = [];
    this.localCreates = {};
    this.localUpdates = new LocalUpdates();
  }
  removeDuplicates(inputList) {
    const names = {};
    const duplicatesRemoved = [];
    inputList.forEach(e => {
      const key = this.recKey(e);
      if (!names[key]) {
        names[key] = true;
        duplicatesRemoved.push(e);
      }
    });
    return duplicatesRemoved;
  }
  reFilterRecords(filter) {
    this.assertReady('reFilterRecords');
    this.records = this.records.filter(filter);
    this.dispatchActivity();
  }
  loadedPromise() {
    return this.loadedPromiseData;
  }
  initialQueryCompletePromise() {
    return this.initialQueryCompletePromiseData;
  }
  loadInitialRecords(valuesForInit) {
    this.initialQueryCompletePromiseData = this.queryRecords()
      .then(records => {
        this.records = this.assimilateList(records);
        if (valuesForInit !== undefined) this.setValuesForInit(valuesForInit);
        this.dispatchActivity();
      });
    return this.initialQueryCompletePromiseData;
  }
  getRecords(filtered = true, unsafe = false) {
    if (!unsafe) this.assertReady('getRecords');
    const result = [...this.records, ...Object.values(this.localCreates)]
      .map(m => {
        const key = this.recKey(m);
        return this.localUpdates.applyUpdates(key, m);
      });
    return filtered && this.getFilterFn() ? result.filter(this.getFilterFn()) : result;
  }
  handleEndlessTransaction() {
    StoreFailure.addFailedStore(this.storeName);
  }
  handleRecordCreate(newRecord, withActivity = true) {
    const inKey = this.recKey(newRecord);
    this.relieveLocalCreate(inKey);
    const candidateRecs = [...this.records, newRecord];
    this.records = this.assimilateList(candidateRecs);
    if (withActivity) this.dispatchActivity();
  }
  handleRecordDelete(record, withActivity = true) {
    // There is no local delete to relieve.
    const inKey = this.recKey(record);
    const candidateRecs = this.records.filter(f => this.recKey(f) !== inKey);
    this.records = this.assimilateList(candidateRecs);
    if (withActivity) this.dispatchActivity();
  }
  handleRecordUpdate(updatedRecord, withActivity = true) {
    const inKey = this.recKey(updatedRecord);
    this.localUpdates.relieveUpdate(inKey, updatedRecord);
    const candidateRecs = this.records.map(m => {
      const match = this.recKey(m) === inKey;
      const hasVersion = !!m.version;
      const isVersionGood = !hasVersion || (updatedRecord.version >= m.version);
      const isDateGood = (updatedRecord.updatedAt >= m.updatedAt);
      const useUpdatedRec = match && ((hasVersion && isVersionGood) || (!hasVersion && isDateGood));
      if (match && !useUpdatedRec) {
        TraceLog.addTrace(`AbstractStore: stale update... existing updatedAt: ${m.updatedAt}  new updatedAt: ${updatedRecord.updatedAt}`);
      }
      return useUpdatedRec ? updatedRecord : m;
    })
    this.records = this.assimilateList(candidateRecs);
    if (withActivity) this.dispatchActivity();
  }
  handleSubscriptionError = (err) => {
    Globals.dispatchApiError(err);
  }

  relieveLocalCreate(key) {
    delete this.localCreates[key];
  }
  dispatchActivity(records) {
    if (this.channel) {
      Hub.dispatch(this.channel, { records: records ? records : this.getRecords() });
    }
  }
  listen(callBack) {
    this.assertReady('listen');
    Hub.listen(this.channel, callBack);
  }
  stopListen(callBack) {
    Hub.remove(this.channel, callBack);
  }
  deleteRec(keyFields) {
    try {
      const inKey = this.recKey(keyFields);
      const existing = this.getRecords().find(f => this.recKey(f) === inKey);
      if (existing) {
        this.records = this.getRecords().filter(f => f !== existing);
      }
      // Submit even if !existing as a pendingTxn comes through this function in two passes, the first
      // pass removes .records and the second pass performs the DB delete.
      if (this.transactionInProgress) {
        this.addPendingTxn(DELETE, keyFields);
      } else {
        this.startTransaction(inKey);
        this.performDelete(keyFields)
          .then(() => this.endTransaction());
      }
      // Do this asynchronously or it can't be used within a component render (without warnings).
      setTimeout(() => this.dispatchActivity());
    } catch (error) {
      Globals.dispatchApiError(error);
    }
  }
  put(keyFields, properties, conflictResolver) {
    const inKey = this.recKey(keyFields);
    const existing = this.getRecords().find(f => this.recKey(f) === inKey);
    this.putTxn(new Txn({ type: PUT, keyFields, properties, isCreate: !existing, conflictResolver }));
  }
  putTxn(txn) {
    // The pendingTxnKey is used to relieve a local update in the event of the .catch block.
    const pendingTxnKey = txn.pendingTxnKeyIn ? txn.pendingTxnKeyIn : this.localUpdateTxnKey++;
    try {
      const inKey = this.recKey(txn.keyFields);
      if (!txn.isCreate) {
        if (!txn.pendingTxnKey) { // If txn.pendingTxnKey, then already applied locally
          if (this.updateSubscription) {
            this.localUpdates.addUpdate(inKey, txn.properties, pendingTxnKey); // Hold locally until subscription fires
          } else {
            this.handleRecordUpdate(txn.properties, false); // No subscription, update now (can't be relieved on failure)
          }
        }
        if (this.transactionInProgress) {
          this.addPendingTxn(txn.newWithPendingTxnKey(pendingTxnKey));
        } else {
          this.startTransaction(inKey);
          this.performUpdate(txn.keyFields, txn.properties, txn.conflictResolver, pendingTxnKey)
            .then(() => this.endTransaction())
            .catch(err => {
              this.endTransaction();
              this.localUpdates.relieveFailedTxn(inKey, pendingTxnKey);
              throw err;
            });
        }
      } else {
        const allProps = { ...txn.keyFields, ...txn.properties };
        const simProps = { ...allProps }
        if (this.createSubscription) {
          this.localCreates[inKey] = simProps; // Hold locally until subscription fires
        } else {
          this.handleRecordCreate(simProps, false); // No subscription, update now
        }
        if (this.transactionInProgress) {
          this.addPendingTxn(txn);
        } else {
          this.startTransaction(inKey);
          this.performCreate(allProps)
            .then(() => this.endTransaction())
            .catch(err => {
              this.endTransaction();
              this.relieveLocalCreate(inKey);
              throw err;
            });
        }
      }
      // Do this asynchronously or it can't be used within a component render (without warnings).
      setTimeout(() => this.dispatchActivity());
    } catch (error) {
      Globals.dispatchApiError(error);
    }
  }
  addPendingTxn(txn) {
    const inKey = this.recKey(txn.keyFields);
    if (this.optimizeUpdates && !txn.conflictResolver) {
      const newPendingTransactions = [];
      let found = false;
      this.pendingTransactions.forEach(e => {
        const eKey = this.recKey(e.keyFields);
        if (eKey === inKey) {
          // Combine this update with the existing pending transaction
          newPendingTransactions.push(txn.newWithEarlierTxn(e));
          found = true;
        } else {
          // No transaction match found so keep the existing
          newPendingTransactions.push(e);
        }
      });
      // If we didn't find a match, then add the new transaction
      if (!found) newPendingTransactions.push(txn);
      this.pendingTransactions = newPendingTransactions;
    } else {
      // Not optimizing, so just add our pending transaction
      this.pendingTransactions.push(txn);
    }
  }
  startTransaction(key) {
    this.transactionInProgress = key;
    this.endlessTransactionTimer = setTimeout(this.handleEndlessTransaction.bind(this), TRANSACTION_TIMEOUT);
  }
  endTransaction() {
    TraceLog.addTrace(`endTransaction: ${this.transactionInProgress}`);
    this.transactionInProgress = null;
    clearTimeout(this.endlessTransactionTimer);
    StoreFailure.removeFailedStore(this.storeName);
    if (this.pendingTransactions.length) {
      const txn = this.pendingTransactions.shift();
      if (txn.isPut()) {
        this.putTxn(txn);
      } else if (txn.isDelete()) {
        this.deleteRec(txn.keyFields);
      }
    }
  }
}

class Txn {
  constructor({ type, keyFields, properties, isCreate, pendingTxnKey, conflictResolver }) {
    this.type = type;
    this.keyFields = keyFields;
    this.properties = properties;
    this.isCreate = isCreate;
    this.pendingTxnKey = pendingTxnKey;
    this.conflictResolver = conflictResolver;
  }
  newWithEarlierTxn(earlierTxn) {
    const newProperties = { ...earlierTxn.properties, ...this.properties };
    return new Txn({
      type: this.type, keyFields: this.keyFields, properties: newProperties, isCreate: this.isCreate,
      pendingTxnKey: this.pendingTxnKey, conflictResolver: this.conflictResolver
    });
  }
  newWithPendingTxnKey(pendingTxnKey) {
    return new Txn({
      type: this.type, keyFields: this.keyFields, properties: this.properties, isCreate: this.isCreate,
      pendingTxnKey: pendingTxnKey, conflictResolver: this.conflictResolver
    });
  }
  isPut() {
    return this.type === PUT;
  }
  isDelete() {
    return this.type === DELETE;
  }
}
class LocalUpdates {
  // LocalUpdates are updates that have been submitted to the DB, but have not been received 
  // in a subscription. So they are applied to records to simulate the update.
  updates = {};

  addUpdate(key, updatesIn, txnKey) {
    const updatesArray = this.updates[key] || [];
    updatesArray.push(new UpdateHolder({ ...updatesIn }, txnKey));
    this.updates[key] = updatesArray;
  }
  applyUpdates(key, inputProps) {
    let result = { ...inputProps };
    const updatesArray = this.updates[key] || [];
    updatesArray.forEach(e => result = { ...result, ...e.updates });
    if (result.expectedVersion) {
      result.version = result.expectedVersion;
      delete result.expectedVersion;
    }
    return updatesArray.length ? result : inputProps; // Return an unaltered inputProps if there are no updates
  }
  putArray(key, updatesArray) {
    if (updatesArray.length) {
      this.updates[key] = updatesArray;
    } else {
      delete this.updates[key];
    }
  }
  relieveUpdate(key, updatedRecord) {
    if (updatedRecord.version) {
      this.relieveUpdateWithVersion(key, updatedRecord);
    } else {
      const updatesArray = this.updates[key] || [];
      updatesArray.shift();
      this.putArray(key, updatesArray);
    }
  }
  relieveUpdateWithVersion(key, updatedRecord) {
    const peekFn = (a) => a.length ? a[0] : undefined;
    const updatesArray = this.updates[key] || [];
    let peek = peekFn(updatesArray);
    while (peek && peek.updates.expectedVersion <= updatedRecord.version) {
      // console.log(`relieveUpdate ${key}  expectedVersion: ${peek.updates.expectedVersion}`);
      updatesArray.shift();
      peek = peekFn(updatesArray);
    }
    this.putArray(key, updatesArray);
  }
  relieveFailedTxn(key, txnKey) {
    const updatesArray = (this.updates[key] || []).filter(f => f.txnKey !== txnKey);
    this.putArray(key, updatesArray);
  }
}
class UpdateHolder {
  constructor(updates, txnKey) {
    this.updates = updates;
    this.txnKey = txnKey;
  }
}