// NLS-NLS(player)
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';


export default function AnswerNotesPrompt(props) {
  const { open, onCancel, onSave, text } = props;
  const textInput = React.useRef();
  const { t } = useTranslation('player');

  if (!open) return null;

  const handleCancel = () => {
    onCancel();
  }
  const handleSave = () => {
    const newText = textInput.current.value;
    onSave(newText);
  }

  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{t('answer-notes')}</DialogTitle>
      <DialogContent>
        <TextField autoFocus fullWidth multiline inputRef={textInput} margin='normal'
          label="Notes" type="text" defaultValue={text} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}