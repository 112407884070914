import { Storage } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import UserStore from "../appSupport/UserStore";
import DeckStore from "./DeckStore";

const getDataOwnerLocal = () => {
  return PathUtil.getGameParam() ? Globals.getDataOwner() : UserStore.getUser().username;
}
const assembleUserName = (userName) => userName ? userName : getDataOwnerLocal();
const assembleFilePath = (userName, deckName, fileName='') => `${userName}/${deckName}/${fileName}`;

export default class CardStoreStorage {
  // see https://docs.amplify.aws/lib/storage/list/q/platform/js#accessing-aws-services
  static list(userNameIn, deckId) {
    const userName = assembleUserName(userNameIn);
    const deckName = DeckStore.deckFor(deckId).name;
    return Storage.list(`${assembleFilePath(userName, deckName)}`);
      // .then(result => {
      //   console.log(result);
      // })
      // .catch(error => Globals.dispatchApiError(error));
  }

  static put(userNameIn, deckId, fileName, file, options) {
    const userName = assembleUserName(userNameIn);
    const deckName = DeckStore.deckFor(deckId).name;
    return Storage.put(assembleFilePath(userName, deckName, fileName), file, options)
      .catch(error => Globals.dispatchApiError(error));
  }

  static get(userNameIn, deckId, fileName) {
    const userName = assembleUserName(userNameIn);
    const deckName = DeckStore.deckFor(deckId).name;
    return Storage.get(assembleFilePath(userName, deckName, fileName), {
      level: 'public', // defaults to `public`
      expires: 300, // 5 minutes
      // identityId?: string, // id of another user, if `level: protected`
      // download?: boolean, // defaults to false
      // expires?: number, // validity of the URL, in seconds. defaults to 900 (15 minutes)
      // contentType?: string // set return content type, eg "text/html"
    })
      .catch(error => Globals.dispatchApiError(error));
  }

  static remove(fullFileName) {
    return Storage.remove(fullFileName, { level: 'public'})
      .catch(error => Globals.dispatchApiError(error));
  }

}

