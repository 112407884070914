import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/splurt.jpg';

export default function Splurt({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset left src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Splurt')}</StyleTopic>
      <StyleParagraph>{t("Think fast, and say it first! Quick—name something fun to play beginning with the letter S! Slide whistle? Slot machine? How about Splurt, the hilarious, fast-reaction word game! Flip a card, and be the first to shout out an answer that matches the clues. Can you name an animal containing 5 letters? A song ending in E? Think fast, say it first, and win Splurt!")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("A great BuenoFun variation for this game is to allow everybody to answer, but limit the think time with a timer.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Set the "Time limit (seconds)"\n...Select "Keep Score"')} />
    </SiteSection>
  );
}
