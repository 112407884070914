import React from 'react';
import { useTranslation } from 'react-i18next';
import { siteRoutesFull } from '../../routing/siteRoutes';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';
import SiteNav from '../siteComponents/SiteNav';

export default function DescFaqWhatGames() {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <StyleTopic>{t('What games can I play?')}</StyleTopic>
      <StyleParagraph>{t("Well that's up to you.  BuenoFun works well for games where a person reads a game card and the players provide a response.  The reader can rotate with each round of play.")}</StyleParagraph>
      <StyleParagraph>{t('The rules of many games can be slightly tweaked to work well in a group call setting. For example, instead of trying to figure out who authored an answer, vote for your favorite answer.')}</StyleParagraph>
      <SiteNav target={siteRoutesFull.games}>{t('Here is a working list of games')}</SiteNav>
      <br />
      <br />
    </React.Fragment>
  );
}
