import React from 'react';
import useMonitorGame from '../../appSupport/monitorGame';
import useMonitorGameState from '../../appSupport/monitorGameState';
import RecentGame from '../../fields/RecentGame';
import Loading from '../Loading';
import NewRound from './NewRound';

export default function ReadyNewRound() {
  const game = useMonitorGame();
  useMonitorGameState(game);

  if (!game) return <Loading /> // ==> EARLY EXIT

  RecentGame.setRecentGame();

  return <NewRound game={game} />
}
