export default class GamePlay {
  static fromGame(game) {
    if (!game) return new GamePlay(); // ==> EARLY EXIT
    return game.gamePlay ? new GamePlay(game.gamePlay) : new GamePlay();
  }
  static fromString(inputString) {
    return new GamePlay(inputString);
  }
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.anonymousResult = input ? input.anonymousResult : false;
    this.isAnswerTimerActive = input ? input.isAnswerTimerActive : false;
    this.areAnswerSelectionsActive = input ? input.areAnswerSelectionsActive : false;
    this.areAnswerNotesActive = input ? input.areAnswerNotesActive : false;
    this.areAnswerSelectionsPublic = input ? input.areAnswerSelectionsPublic : false;
    this.areAnswerNotesPublic = input ? input.areAnswerNotesPublic : false;
    this.showPlayerNames = input ? input.showPlayerNames : false;
    this.showStackedAnswers = input ? input.showStackedAnswers : true;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

