import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import Globals from "../appSupport/Globals";
import PathUtil from '../appSupport/PathUtil';
import UserStore from '../appSupport/UserStore';
import SRBetterWords from '../fields/support/SRBetterWords';
import SRBleedThrough from '../fields/support/SRBleedThrough';
import SRBugReport from '../fields/support/SRBugReport';
import SRDialogEntry from '../fields/support/SRDialogEntry';
import SREnhancementRequest from '../fields/support/SREnhancementRequest';
import SRGeneralFeedback from '../fields/support/SRGeneralFeedback';
import SRLanguageRequest from '../fields/support/SRLanguageRequest';
import SRTrace from '../fields/support/SRTrace';
import AbstractStore from "./AbstractStore";
import SupportRequestLocalStore from './SupportRequestLocalStore';
import SupportRequestOps, { COMMON_OWNER, getNewStatus, PLATFORM_GAME, REQUEST_TYPE_BUG_REPORT, REQUEST_TYPE_GENERAL_FEEDBACK, REQUEST_TYPE_NLS_MISSING, REQUEST_TYPE_NLS_NEW_LANGUAGE, REQUEST_TYPE_NLS_SUGGESTION, REQUEST_TYPE_PRODUCT_ENHANCEMENT, STATUS_USER_DELETED, STATUS_WAIT_CLIENT, STATUS_WAIT_SUPPORT, REQUEST_TYPE_TRACE } from './SupportRequestOps';

const SUPPORT_REQUEST_CHANNEL = 'SUPPORT_REQUEST_CHANNEL';
let INSTANCE;

export function useFreshSupportRequests(filter) {
  const [records, setRecords] = React.useState([]);
  const userName = UserStore.getUserName();

  React.useEffect(() => {
    var hasEnded = false;
    var hasListener = false;
    if (!userName) {
      setRecords([]);
      return undefined; // ==> EARLY EXIT (can't do this without a user)
    }
    const getRecords = () => SupportRequestStore.getRecords(filter);
    const handleChange = (event) => {
      // const { records } = event.payload;
      if (!hasEnded) setRecords(getRecords(filter));
    }
    SupportRequestStore.initialQueryCompletePromise()
      .then(r => {
        if (!hasEnded) {
          hasListener = true;
          SupportRequestStore.listen(handleChange);
          setRecords(getRecords(filter));
        }
      });
    return () => {
      hasEnded = true;
      if (hasListener) {
        hasListener = false;
        SupportRequestStore.stopListen(handleChange);
      }
    }
  }, [filter, userName]);

  return records;
}

export default class SupportRequestStore {

  static instance() {
    if (!INSTANCE) {
      INSTANCE = new SupportRequestStoreInst();
    }
    return INSTANCE;
  }
  static initialQueryCompletePromise() {
    return this.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return this.instance().loadedPromise();
  }
  static listen(callBack) {
    this.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getRecords(filtered) {
    return this.instance().getRecords(filtered);
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

  static createBetterTranslation(languageKey, languageNamespace, badWords, betterWords, comment, platform = PLATFORM_GAME) {
    const payload = new SRBetterWords();
    payload.languageKey = languageKey;
    payload.languageNamespace = languageNamespace;
    payload.badWords = badWords;
    payload.betterWords = betterWords;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_NLS_SUGGESTION, payload, platform);
  }
  static createBugReport(problemDescription, stepsToReproduce, comment, platform = PLATFORM_GAME) {
    const payload = new SRBugReport();
    payload.problemDescription = problemDescription;
    payload.stepsToReproduce = stepsToReproduce;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_BUG_REPORT, payload, platform);
  }
  static createEnhancementRequest(enhancementDescription, comment, platform = PLATFORM_GAME) {
    const payload = new SREnhancementRequest();
    payload.enhancementDescription = enhancementDescription;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_PRODUCT_ENHANCEMENT, payload, platform);
  }
  static createRequestedLanguage(requestedLanguage, comment, platform = PLATFORM_GAME) {
    const payload = new SRLanguageRequest();
    payload.requestedLanguage = requestedLanguage;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_NLS_NEW_LANGUAGE, payload, platform);
  }
  static createGeneralFeedback(feedback, comment, platform = PLATFORM_GAME) {
    const payload = new SRGeneralFeedback();
    payload.feedback = feedback;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_GENERAL_FEEDBACK, payload, platform);
  }
  static createReportBleedThrough(englishText, translation, comment, platform = PLATFORM_GAME) {
    const payload = new SRBleedThrough();
    payload.englishText = englishText;
    payload.translation = translation;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_NLS_MISSING, payload, platform);
  }
  static createTrace(logEntries, comment, platform = PLATFORM_GAME) {
    const payload = new SRTrace();
    payload.playerName = PathUtil.getPlayerNameParam();
    payload.gameFinderId = PathUtil.getFinderIdParam();
    payload.logEntries = logEntries;
    if (comment) payload.dialog.push(SRDialogEntry.newComment(comment));
    return this.createSupportRequest(REQUEST_TYPE_TRACE, payload, platform);
  }

  static createSupportRequest(requestType, payload, platform = PLATFORM_GAME) {
    const id = uuidV4();
    const languageCode = Globals.getLanguageCode();
    const status = getNewStatus();
    const commonOwner = COMMON_OWNER;
    const originatingUsername = UserStore.getUserName();
    const paramObj = { id, languageCode, requestType, status, platform, commonOwner, originatingUsername, payload: payload.asJSON() };
    // A trace can process as the default user, but if there are already queued transactions, then queue
    // the trace also.
    const isTraceRequest = requestType === REQUEST_TYPE_TRACE;
    const alreadyHasQueuedTransactions = !!SupportRequestLocalStore.numberOfPendingTransactions();
    const queueTransaction = Globals.isDefaultUser() && (alreadyHasQueuedTransactions || !isTraceRequest);
    if (queueTransaction) {
      SupportRequestLocalStore.addTransaction(paramObj);
      return Promise.resolve(null);
    } else {
      Globals.dispatchSupport({ requestCreated: true });
      return this.instance().performCreate(paramObj);
    }
  }
  static playLocalTransaction(paramObjIn) {
    const originatingUsername = UserStore.getUserName();
    const paramObj = { ...paramObjIn, originatingUsername };
    return this.instance().performCreate(paramObj);
  }
  static deleteSupportRequest(id) {
    return this.instance().performDelete({ id });
  }
  static updateStatus(id, status, prevStatus, payload) {
    const updates = {};
    if (status) updates.status = status;
    if (prevStatus && (prevStatus !== STATUS_WAIT_CLIENT) && (prevStatus !== STATUS_WAIT_SUPPORT)) updates.prevStatus = prevStatus;
    if (payload) updates.payload = payload;
    return this.instance().performUpdate({ id }, updates);
  }
}

class SupportRequestStoreInst extends AbstractStore {
  constructor() {
    const isProductSupporter = Globals.isProductSupporter();
    super('SupportRequestStore', SUPPORT_REQUEST_CHANNEL);
    this.loadStore(
      isProductSupporter ? SupportRequestOps.onSupportRequestCreate : SupportRequestOps.onSupportRequestCreateUser,
      isProductSupporter ? SupportRequestOps.onSupportRequestUpdate : SupportRequestOps.onSupportRequestUpdateUser,
      isProductSupporter ? SupportRequestOps.onSupportRequestDelete : SupportRequestOps.onSupportRequestDeleteUser);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    return `All`;
  }
  queryRecords() {
    const constraints = Globals.isProductSupporter() ? {}
      : { filter: { originatingUsername: { eq: UserStore.getUserName() }, status: { ne: STATUS_USER_DELETED } } };
    return SupportRequestOps.getSupportRequests(constraints);
  }
  performCreate(properties) {
    return SupportRequestOps.createSupportRequest(properties.id, properties);
  }
  performDelete(keyFields) {
    return SupportRequestOps.deleteSupportRequest(keyFields.id);
  }
  performUpdate(keyFields, updates) {
    return SupportRequestOps.updateSupportRequest(keyFields.id, updates);
  }
  getSortFn() {
    return Globals.sorterCreatedAtDesc;
  }
  getFilterFn() {
    return f => (Globals.isProductSupporter() || (f.status !== STATUS_USER_DELETED));
  }
  recKey(record) {
    return record.id;
  }

}

