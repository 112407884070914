// NLS-NLS(support)
import React from 'react';
import { useTranslation } from 'react-i18next';
import SRBleedThrough from '../../../fields/support/SRBleedThrough';
import BoxC from '../../box/BoxC';
import LabelAndData from '../../LabelAndData';
import SreDialog from './SreDialog';

export default function SreBleedThrough(props) {
  const { row, ...rest } = props;
  const payload = new SRBleedThrough(row.payload);
  const { t } = useTranslation('support');

  return (
    <BoxC {...rest}>
      <LabelAndData key='englishText' label={t('english-text-colon')} data={payload.englishText} />
      <LabelAndData key='translation' label={t('translation-colon')} data={payload.translation} />
      <SreDialog key='dialog' dialogArray={payload.dialog} />
    </BoxC>
  );
}
