import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import SelectionPointsHelp from '../components/help/SelectionPointsHelp';
import GameStore from '../db/GameStore';
import AutoScoreSelect from '../fields/AutoScoreSelect';
import GameOptions from '../fields/GameOptions';
import ScoreOptions from '../fields/ScoreOptions';
import SelectOptions from '../fields/SelectOptions';
import PromptMessage from './PromptMessage';
import PromptUtil from './PromptUtil';

const classes = {
  scoreValue: 'AutoScoreSelectPrompt-scoreValue',
  marginTop: 'AutoScoreSelectPrompt-marginTop'
};

const ScoreValue = styled(TextField)(({ theme }) => ({
  [`&.${classes.scoreValue}`]: {
    marginLeft: theme.spacing(2),
    minWidth: 100,
  },
}));

const ID_ANSWER_AUTHOR = 'answerAuthor';
// const ID_ANSWER_SELECTOR = 'answerSelector';

const ID_READERS_ANSWER_CB = 'enableReadersValues';
const ID_READERS_ANSWER_AUTHOR = 'readersAnswerAuthor';
const ID_READERS_ANSWER_SELECTOR = 'readersAnswerSelector';
const ID_READERS_ANSWER_NO_ONE = 'readersAnswerNoOne';

const allIds = [ID_ANSWER_AUTHOR, ID_READERS_ANSWER_CB, ID_READERS_ANSWER_AUTHOR, ID_READERS_ANSWER_SELECTOR, ID_READERS_ANSWER_NO_ONE];
const numericIds = [ID_READERS_ANSWER_AUTHOR, ID_READERS_ANSWER_SELECTOR, ID_READERS_ANSWER_NO_ONE];
const checkboxControls = [ID_READERS_ANSWER_CB];

const EMPTY_STAGED_VALUES = {};

export default function AutoScoreSelectPrompt(props) {
  const { open, onCancel, onSave, autoScoreSelectJSON } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);

  const gameOptions = new GameOptions(GameStore.getGame().gameOptions);
  const selectOptions = new SelectOptions(gameOptions.selectOptions);
  const { t } = useTranslation('controller');


  React.useEffect(() => {
    setStagedValues(PromptUtil.mergeProps(new AutoScoreSelect(autoScoreSelectJSON), {}, allIds));
  }, [autoScoreSelectJSON]);

  const clearValidations = () => {
  }
  const validations = {
    // validateDecks: (newStaged) => {
    //   if (!newStaged[ID_DECKS_USED].length) {
    //     setDecksUsedErrorText('You must select at least one deck');
    //     return true;
    //   }
    //   return false;
    // },

    performValidate: () => {
      clearValidations();
      return false; // No errors
    }
  }

  const handleCancel = () => {
    clearValidations();
    onCancel();
  }
  function adjustPointsForAuthor() {
    let pointsForAuthor = stagedValues[ID_ANSWER_AUTHOR] || '';
    if (typeof pointsForAuthor === 'string' && pointsForAuthor.includes(',')) {
      pointsForAuthor = pointsForAuthor.split(',').map(m => parseFloat(m));
    } else {
      pointsForAuthor = parseFloat(pointsForAuthor);
    }
    return pointsForAuthor;
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      const autoScoreSelect = new AutoScoreSelect();
      PromptUtil.mergeProps(stagedValues, autoScoreSelect, allIds, numericIds);
      autoScoreSelect[ID_ANSWER_AUTHOR] = adjustPointsForAuthor();
      onSave(autoScoreSelect.asJSON());
      const newScoreOptions = new ScoreOptions(GameStore.getGame().scoreOptions);
      newScoreOptions.autoScoreSelect = autoScoreSelect.asJSON();
      GameStore.updateScoreOptions(newScoreOptions);
      clearValidations();
      onCancel();
    }
  }

  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  const title = t('scoring-on-answer-selections');
  if (!open) return null;

  let pointsForAuthorWarning = '';
  if (selectOptions.numberOfSelectionsAllowed === 1 && (stagedValues[ID_ANSWER_AUTHOR] || '').toString().includes(',')) {
    pointsForAuthorWarning = t('allow-users-to-select-multiple');
  }
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <FormGroup>
          <BoxR>
            <TextField onChange={handleValueChange} margin='normal'
              id={ID_ANSWER_AUTHOR} label={t('points-for-author')} defaultValue={stagedValues[ID_ANSWER_AUTHOR]} />
            <SelectionPointsHelp />
          </BoxR>
          <PromptMessage warningText={pointsForAuthorWarning} />
          <Typography>{t('optional-comma-separated-value')}</Typography>
        </FormGroup>

        <FormGroup sx={{ mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_READERS_ANSWER_CB]} onChange={handleValueChange} id={ID_READERS_ANSWER_CB} />}
            label={t('selection-of-readers-answer')}
          />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_READERS_ANSWER_CB]}
            id={ID_READERS_ANSWER_AUTHOR} label={t('points-for-reader')} type="number" defaultValue={stagedValues[ID_READERS_ANSWER_AUTHOR]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_READERS_ANSWER_CB]}
            id={ID_READERS_ANSWER_SELECTOR} label={t('points-for-selector')} type="number" defaultValue={stagedValues[ID_READERS_ANSWER_SELECTOR]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_READERS_ANSWER_CB]}
            id={ID_READERS_ANSWER_NO_ONE} label={t('reader-points-if-no-one-select')} type="number" defaultValue={stagedValues[ID_READERS_ANSWER_NO_ONE]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
        </FormGroup>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}