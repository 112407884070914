import React from 'react';
import StyleTopic from '../siteComponents/StyleTopic';
import StyleParagraph from '../siteComponents/StyleParagraph';
import { useTranslation } from 'react-i18next';

export default function DescFaqIsItHard() {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <StyleTopic>{t('Is it hard to use?')}</StyleTopic>
      <StyleParagraph>{t('There is a learning curve for the game controller. Other players have virtually no learning curve as their actions are controlled by the game controller.  Players simply follow on screen prompts.')}</StyleParagraph>
    </React.Fragment>
  );
}
