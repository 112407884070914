import { Button } from '@mui/material';
import React from 'react';

export default function UploadButton(props) {
  const { tag, children, acceptTypes, onFileSelect, multiple = false, ...rest } = props;
  return (
    <label htmlFor={tag}>
      <input
        style={{ display: 'none' }}
        id={tag}
        name={tag}
        type="file"
        multiple={multiple}
        accept={acceptTypes}
        onChange={(event) => onFileSelect(event)}
      />

      <Button color="secondary" variant="contained" component="span" {...rest}>
        {children}
      </Button>
    </label>
  );
}

