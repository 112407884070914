import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import TimeDelta from '../../appSupport/TimeDelta';
import TraceLog from '../../appSupport/TraceLog';
import BoxC from '../../components/box/BoxC';
import BoxCG from '../../components/box/BoxCG';
import BoxR from '../../components/box/BoxR';
import ControllerStatusLine from '../../components/ControllerStatusLine';
import DividerH, { FAT } from '../../components/DividerH';
import ResultTableList from '../../components/list/ResultTableList';
import PlayerStatusLine, { STATUS_SELECT_ANSWER, STATUS_WAITING_CONTROLLER } from '../../components/PlayerStatusLine';
import PlayOptionControls from '../../components/PlayOptionControls';
import PlayTimer from '../../components/PlayTimer';
import ScoreTapInstructions from '../../components/ScoreTapInstructions';
import StatusBoxWithLogo from '../../components/StatusBoxWithLogo';
import GameStore from '../../db/GameStore';
import PendingPlayerStore from '../../db/PendingPlayerStore';
import ResultStore, { useFreshResults } from '../../db/ResultStore';
import ScoreStore, { useFreshScores } from '../../db/ScoreStore';
import AnswerAnnotations from '../../fields/AnswerAnnotations';
import GameOptions from '../../fields/GameOptions';
import GamePlay from '../../fields/GamePlay';
import PublishedTo from '../../fields/PublishedTo';
import RoundData from '../../fields/RoundData';
import ScoreOptions from '../../fields/ScoreOptions';
import SelectOptions from '../../fields/SelectOptions';
import { ANSWER_TIMER } from '../../fields/TimerValues';
import AnswerNotesPrompt from '../../prompt/AnswerNotesPrompt';
import AnswerOptionsPrompt from '../../prompt/AnswerOptionsPrompt';

const classes = {
  root: 'ResultsContent-root',
  maskedName: 'ResultsContents-maskedName',
  unmaskedName: 'ResultsContents-unmaskedName',
  publicBox: 'ResultsContents-publicBox'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`& .${classes.maskedName}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.unmaskedName}`]: {
    marginLeft: theme.spacing(2),
    color: '#a09f9f',
  },
  [`& .${classes.publicBox}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.resultsBackground.main,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
  }
}));


const SHOW_NAMES = false;
const DEFAULT_NOTES_DATA = { open: false };

export default function ResultsContent(props) {
  const { game, ...rest } = props;
  const [scoreOpenMap, setScoreOpenMap] = React.useState(false);
  const [selectedPlayerResult, setSelectedPlayerResult] = React.useState(null);
  const [showScoringButtonsValue, setShowScoringButtonsValue] = React.useState(false);
  const [answerNotesData, setAnswerNotesData] = React.useState(DEFAULT_NOTES_DATA);

  const playerResults = useFreshResults();
  const scoresByName = useFreshScores();
  const refScoresByName = scoresByName; // Get rid of this
  const onScoreChange = (scoreRow, handScore) => ScoreStore.setScore(game.currentHand, (scoreRow.name || scoreRow.playerName), handScore);
  const gamePlay = GamePlay.fromGame(game);
  const gameOptions = new GameOptions(game.gameOptions);
  const selectOptions = new SelectOptions(gameOptions.selectOptions);
  const isController = Globals.isGameController(game);
  const { randomResultOrder, unmasked } = Globals.getPublishedData(game);
  const { keepScore } = gameOptions;
  const { anonymousResult, areAnswerSelectionsActive } = gamePlay;
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const { t } = useTranslation('controller');
  const debugHand = React.useRef(game.currentHand);

  if (debugHand.current !== game.currentHand) {
    TraceLog.addTrace(`ResultContents... being used for multiple hands: ${debugHand.current} & ${game.currentHand}`);
    debugHand.current = game.currentHand;
  }

  reportHandMissMatch();
  if (playerResults.length > PendingPlayerStore.getPlayerCount()) {
    TraceLog.addTrace(`ResultContents... unexpected playerResults.length: ${playerResults.length} where PendingPlayerStore.getPlayerCount(): ${PendingPlayerStore.getPlayerCount()}`);
  }

  function reportHandMissMatch() {
    const counts = {};
    playerResults.forEach(e => {
      if (counts[e.hand]) {
        counts[e.hand] = counts[e.hand] + 1;
      } else {
        counts[e.hand] = 1;
      }
    });
    if (Object.keys(counts).length > 1) {
      let result = '';
      Object.keys(counts).forEach(e => {
        result += `hand: ${e} count: ${counts[e]}  `;
      });
      TraceLog.addTrace(`ResultsContent: Bad data... ${result}`);
    }
  }
  function handleAnswerNotesClick(event, playerName) {
    event.stopPropagation();
    const playerNumber = Globals.getPlayerNumber();
    const playerResult = playerResults.find(f => f.playerName === playerName);
    const aa = AnswerAnnotations.forPlayerNumber(playerResult, playerNumber);
    setAnswerNotesData({ open: true, playerName: playerName, text: aa ? aa.answerNoteText : '' });
  }
  function handleCancelAnswerNotesPrompt(event) {
    setAnswerNotesData(DEFAULT_NOTES_DATA);
  }
  function handleOnSaveAnswerNotesPrompt(newText) {
    const playerResult = playerResults.find(f => f.playerName === answerNotesData.playerName);
    ResultStore.saveAnswerNote(playerResult, newText);
    setAnswerNotesData(DEFAULT_NOTES_DATA);
  }

  function handleAnswerSelected(event) {
    event.stopPropagation();
    reportHandMissMatch();
    const { id: playerNumber, checked } = event.target;
    const playerName = Globals.getPlayerName(playerNumber);
    const playerResult = playerResults.find(f => f.playerName === playerName);
    ResultStore.resultSelection(playerResults, playerResult, checked);
  }

  function handleAnswerClick(playerName) {
    if (isController) {
      const playerResult = playerResults.find(f => f.playerName === playerName);
      setSelectedPlayerResult(playerResult);
    }
  }

  function handleSaveAnswerOptionsPrompt(mask, unmask, selectForPlayers) {
    updateMaskChanges(mask, unmask);

    const oldSelections = Globals.getPlayerNamesSelecting(selectedPlayerResult);
    const toUnselect = oldSelections.filter(f => !selectForPlayers.includes(f));
    toUnselect.forEach(e => ResultStore.resultSelection(playerResults, selectedPlayerResult, false, e));
    const toSelect = selectForPlayers.filter(f => !oldSelections.includes(f));
    toSelect.forEach(e => ResultStore.resultSelection(playerResults, selectedPlayerResult, true, e));
    setSelectedPlayerResult(null);
  }

  function handleCancelAnswerOptionsPrompt() {
    setSelectedPlayerResult(null);
  }
  function handleChangeNameListValue(event) {
    const { checked } = event.target;
    gamePlay.showPlayerNames = checked;
    GameStore.updateGamePlay(game, gamePlay);
  }
  function handleChangeStackedAnswers(event) {
    const { checked } = event.target;
    gamePlay.showStackedAnswers = checked;
    GameStore.updateGamePlay(game, gamePlay);
  }
  function handleSavePlayOptionControls(gamePlay) {
    GameStore.updateGamePlay(game, gamePlay);
  }
  function handleOpenScoreToggle(name, isOpen) {
    setScoreOpenMap({ [name]: isOpen });
  }
  function handleScoringButtonsChange(checked) {
    setShowScoringButtonsValue(checked);
  }

  function getCorrectAnswer(playerResults) {
    let result = undefined;
    const gameOptions = new GameOptions(game.gameOptions);
    if (gameOptions.readersAnswerIsCorrect) {
      const roundData = RoundData.fromGame(game);
      const currentReader = roundData.currentReader;
      const currentReaderResult = playerResults.find(f => f.playerName === currentReader);
      result = currentReaderResult ? currentReaderResult.answer : undefined;
    }
    return result;
  }

  function orderedPlayers() {
    let result;
    const formatSelectionChip = ({ name, selection }) => {
      return selectOptions.numberOfSelectionsAllowed > 1 ? `${selection} ${name}` : name;
    }
    if (!playerResults.length || !randomResultOrder) {
      result = playerResults;
    } else {
      // Order the players by the random sequence.
      result = [];
      randomResultOrder.forEach(e => result.push(playerResults[e]));
    }

    let timeDelta = new TimeDelta();
    timeDelta.setShowDelta(!(randomResultOrder));
    return result.map((m, i) => {
      const { prevTotalScore, handScore, totalScore } = scoresByName[m.playerName] || {};
      timeDelta = timeDelta.newTime(m.createdAt);
      const newData = { ...m };
      newData.id = game ? Globals.getPlayerNumber(m.playerName) : 1;
      newData.playerNumber = newData.id;
      newData.playerIndex = i;
      newData.myAnnotations = AnswerAnnotations.forPlayerNumber(m, Globals.getPlayerNumber()) || new AnswerAnnotations();
      newData.timeDelta = timeDelta;
      newData.isMasked = !unmasked.includes(m.playerName);
      newData.prevTotalScore = prevTotalScore;
      newData.handScore = handScore;
      newData.totalScore = totalScore;
      newData.selections = Globals.getPlayerSelections(m).map(m => formatSelectionChip(m));
      return newData;
    });
  }

  function updateMaskChanges(mask, unmask) {
    const publishedTo = (game && game.publishedTo) ? new PublishedTo(game.publishedTo) : new PublishedTo();
    let maskChange = false;
    if (unmask) {
      maskChange = true;
      publishedTo.unmasked.push(selectedPlayerResult.playerName);
    } else if (mask) {
      maskChange = true;
      const i = publishedTo.unmasked.findIndex(f => f === selectedPlayerResult.playerName);
      publishedTo.unmasked.splice(i, 1);
    }
    if (maskChange) {
      GameStore.updatePublishedTo(publishedTo);
    }
  }

  function shouldEnableScoringOptions() {
    return Globals.isScoreKeeper(game) && keepScore && !scoreOptions.teamNames;
  }

  function renderRows(rows, areResultsAnonymous) {
    const correctAnswer = getCorrectAnswer(playerResults);
    return <ResultTableList game={game} rows={rows} areResultsAnonymous={areResultsAnonymous}
      correctAnswer={correctAnswer} refScoresByName={refScoresByName} scoreOpenMap={scoreOpenMap}
      onAnswerSelected={handleAnswerSelected} onAnswerClick={handleAnswerClick} onAnswerNotesClick={handleAnswerNotesClick}
      onOpenScoreToggle={handleOpenScoreToggle} onScoreChange={onScoreChange} showScoreButtons={showScoringButtonsValue} />
  }

  function renderHeader() {
    const status = areAnswerSelectionsActive ? STATUS_SELECT_ANSWER : STATUS_WAITING_CONTROLLER;
    return (
      <React.Fragment>
        <StatusBoxWithLogo>
          <PlayerStatusLine game={game} status={status} />
          <ControllerStatusLine open={isController} />
        </StatusBoxWithLogo>
        <DividerH open={isController} />
        <PlayTimer game={game} whichTimer={ANSWER_TIMER} />
      </React.Fragment>
    );
  }

  function renderOtherOptions() {
    if (!isController) return null;  // ==> EARLY EXIT
    return (
      <BoxC>
        <BoxR style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          <FormGroup>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={<Checkbox checked={gamePlay.showPlayerNames} onChange={handleChangeNameListValue} />}
              label={t('name-list')} />
          </FormGroup>
          {gameOptions.numberOfAnswers > 1 ?
            <FormGroup>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={<Checkbox checked={gamePlay.showStackedAnswers} onChange={handleChangeStackedAnswers} />}
                label={t('stack-answers')} />
            </FormGroup>
            : null}
        </BoxR>
        <BoxR style={{ alignItems: 'center', flexWrap: 'wrap' }}>
          <FormGroup style={{ marginRight: 10 }}>
            <Typography>{t('tap-result-for-more-options')}</Typography>
          </FormGroup>
          <ScoreTapInstructions game={game} open={shouldEnableScoringOptions()}
            showScoringButtonsValue={showScoringButtonsValue}
            onScoringButtonsChange={handleScoringButtonsChange} style={{ marginLeft: isControllerWithScoring ? 0 : 0 }} />
        </BoxR>
      </BoxC>
    );
  }

  function renderNameList() {
    if (!gamePlay.showPlayerNames) return null; // ==> EARLY EXIT
    function renderNames() {
      const names = PendingPlayerStore.getPlayerNames();
      return names.map(m => {
        const cn = unmasked.includes(m) ? classes.unmaskedName : classes.maskedName;
        return <Typography className={cn} key={m}>{m}</Typography>
      });
    }
    return (
      <BoxR style={{ alignItems: 'center', flexWrap: 'wrap' }}>
        {renderNames()}
      </BoxR>
    );
  }

  const orderedPlayersList = orderedPlayers();
  const rowsMasked = orderedPlayersList.filter(f => !unmasked.includes(f.playerName));
  const rowsUnmasked = orderedPlayersList.filter(f => unmasked.includes(f.playerName));

  const showDivider = !!rowsUnmasked.length;
  const isControllerWithScoring = isController && shouldEnableScoringOptions();

  return (
    <StyledBoxCG className={classes.root} {...rest} >
      <AnswerOptionsPrompt open={!!selectedPlayerResult} playerResult={selectedPlayerResult}
        onSave={handleSaveAnswerOptionsPrompt} onCancel={handleCancelAnswerOptionsPrompt} />
      <AnswerNotesPrompt open={answerNotesData.open} onCancel={handleCancelAnswerNotesPrompt}
        onSave={handleOnSaveAnswerNotesPrompt} text={answerNotesData.text} />
      {renderHeader()}
      <BoxC style={{ maxWidth: '100%' }}>
        <BoxC style={{ alignItems: 'center' }}>
          <PlayOptionControls onSave={handleSavePlayOptionControls} open={isController} />
          {/* <DividerH open={isController} /> */}
          {renderOtherOptions()}
          {renderNameList()}
        </BoxC>
        <BoxC className={classes.publicBox} >
          {rowsMasked.length ? renderRows(rowsMasked, anonymousResult) : null}
          <DividerH open={showDivider} size={FAT} />
          {rowsUnmasked.length ? renderRows(rowsUnmasked, SHOW_NAMES) : null}
        </BoxC>
      </BoxC>
    </StyledBoxCG>
  );
}
