import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { specialUserAll, specialUserDealer, specialUserNone } from '../../appSupport/GlobalConstants';
import BoxCG from '../../components/box/BoxCG';
import BoxR from '../../components/box/BoxR';
import EditScoreButton from '../../components/button/EditScoreButton';
import ControllerStatusLine from '../../components/ControllerStatusLine';
import DealtToStatus from '../../components/DealtToStatus';
import DividerH from '../../components/DividerH';
import PlayerStatusLine from '../../components/PlayerStatusLine';
import PlayOptionControls from '../../components/PlayOptionControls';
import Selections from '../../components/Selections';
import CardStore from '../../db/CardStore';
import DeckStore, { useFreshDecks } from '../../db/DeckStore';
import GameStore from '../../db/GameStore';
import PendingPlayerStore from '../../db/PendingPlayerStore';
import DeckOptions from '../../fields/DeckOptions';
import GameOptions from '../../fields/GameOptions';
import GamePlay from '../../fields/GamePlay';
import RoundData from '../../fields/RoundData';
import DeckPrompt from '../../prompt/DeckPrompt';
import PlayerPrompt from '../../prompt/PlayerPrompt';
import PromptMessage from '../../prompt/PromptMessage';
import BoxC from '../../components/box/BoxC';

const classes = {
  root: 'NewRoundContent-root',
  playButton: 'NewRoundContent-playButton',
  secondaryButton: 'NewRoundContent-secondaryButton',
  dealButton: 'NewRoundContent-dealButton',
  dealStatusLine: 'NewRoundContent-dealStatusLine',
  optionsInstructions: 'NewRoundContent-optionsInstructions',
  optionsRow: 'NewRoundContent-optionsRow'
};

const StyledBoxC = styled(BoxC)(({ theme }) => ({
  [`& .${classes.playButton}`]: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
  },
  [`& .${classes.secondaryButton}`]: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.contrastText,
  },
  [`& .${classes.dealButton}`]: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.contrastText,
    textTransform: 'none',
  },
  [`& .${classes.dealStatusLine}`]: {
    fontSize: 12,
  },
  [`& .${classes.optionsInstructions}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.optionsRow}`]: {
    alignItems: 'center',
    flexWrap: 'wrap',
  }
}));

export default function NewRoundContent(props) {
  const { game, ...rest } = props;

  const [readerPromptOpen, setReaderPromptOpen] = React.useState(false);
  const [dealToPromptOpen, setDealToPromptOpen] = React.useState(false);
  const [dealErrorText, setDealErrorText] = React.useState(null);
  const [deckIdToEdit, setDeckIdToEdit] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);
  const workingHand = game.currentHand + 1;
  const { t } = useTranslation('controller');

  React.useEffect(() => {
    // Automatically open the reader per round prompt if appropriate
    const gameOptions = new GameOptions(game.gameOptions);
    if (!gameOptions.readerPerRound) {
      if (initialized) setInitialized(false); // If the setting is changed, we will re-prompt
      return undefined;
    } else {
      if (!initialized) {
        setReaderPromptOpen(gameOptions.readerPerRound);
        setInitialized(true);
      }
    }
  }, [game, initialized]);

  const decks = useFreshDecks();
  const gamePlayDefaults = GamePlay.fromString(game.gamePlayDefaults);
  const roundDataDefaults = RoundData.fromString(game.roundDataDefaults);
  const gameOptions = new GameOptions(game.gameOptions);
  const deckOptions = new DeckOptions(game.deckOptions);

  function buildReaderPromptValues() {
    const allReaderNames = PendingPlayerStore.getPlayerNames();
    let readerPromptTitle = t('select-the-reader');
    return { readerPromptTitle, allReaderNames };
  }

  function buildDealToPromptValues() {
    const allDealToNames = PendingPlayerStore.getPlayerNames();
    allDealToNames.unshift(specialUserAll);
    if (gameOptions.readerPerRound) allDealToNames.unshift(specialUserDealer);

    const dealToPromptTitle = t('select-the-players-to-receive-');
    const selectedDealToNames = roundDataDefaults.dealTo;
    return { dealToPromptTitle, selectedDealToNames, allDealToNames };
  }

  function handleDealCard(deck) {
    setDealErrorText(null);
    if (deck.nextCardIndex >= deck.cardKeys.length) {
      setDeckIdToEdit(deck.id);
    } else {
      const dealToNames = DeckStore.playersToDealTo(roundDataDefaults);
      if (dealToNames.length) {
        if (deckOptions.dealSameCardToMultiplePlayers) {
          CardStore.dealCard(deck, dealToNames, workingHand);
        } else {
          const availableCardCount = deck.cardKeys.length - deck.nextCardIndex;
          if (availableCardCount >= dealToNames.length) {
            dealToNames.forEach(e => {
              const freshDeck = DeckStore.deckFor(deck.id);
              CardStore.dealCard(freshDeck, [e], workingHand);
            });
          } else {
            setDealErrorText(t('there-are-not-enough-cards-sel'));
          }
        }
      } else {
        setDealErrorText(t('select-somebody-to-receive-the'));
      }
    }
  }
  function handleDealToPromptCancel() {
    setDealToPromptOpen(false);
  }
  function handleDealToPromptSave(players) {
    if (players.includes(specialUserAll)) {
      roundDataDefaults.dealTo = [specialUserAll];
    } else {
      roundDataDefaults.dealTo = players;
    }
    GameStore.updateRoundDataDefaults(game, roundDataDefaults);
    setDealToPromptOpen(false);
  }

  function handleDeckPromptClose() {
    setDeckIdToEdit(null);
  }
  function handleReaderPromptCancel() {
    setReaderPromptOpen(false);
  }
  function handleReaderPromptSave(players = []) {
    roundDataDefaults.currentReader = players.length ? players[0] : null;
    GameStore.updateRoundDataDefaults(game, roundDataDefaults);
    setReaderPromptOpen(false);
  }

  function handlePlayOptionControlsSave(gamePlayDefaults) {
    GameStore.updateGamePlayDefaults(game, gamePlayDefaults);
  }

  function handleStartPlay() {
    GameStore.startNewRound();
  }
  function handleChangeReader() {
    setReaderPromptOpen(true);;
  }
  function handleChangeDealTo() {
    setDealToPromptOpen(true);;
  }

  function renderCurrentReaderControls() {
    if (!gameOptions.readerPerRound) return null; // ==> EARLY EXIT
    return (
      <React.Fragment>
        <BoxR className={classes.optionsRow}>
          <Typography>{t('current-reader')}</Typography>
          <Selections names={[roundDataDefaults.currentReader || specialUserNone]} />
          <Button className={classes.secondaryButton} color='secondary' variant="contained" onClick={handleChangeReader} >
            {t('change-ellipse')}
          </Button>
        </BoxR>
        <DividerH />
      </React.Fragment>
    );
  }

  function renderDeckControls() {
    if (!deckOptions.decksUsed.length) return null; // ==> EARLY EXIT
    const dealToNames = roundDataDefaults.dealTo.length ? roundDataDefaults.dealTo : [specialUserNone];
    return (
      <React.Fragment>
        <BoxR className={classes.optionsRow}>
          <Typography>{t('deal-to-colon')}</Typography>
          <Selections names={dealToNames} />
          <Button className={classes.secondaryButton} color='secondary' variant="contained" onClick={handleChangeDealTo} >
            {t('change-ellipse')}
          </Button>
        </BoxR>
        <BoxR className={classes.optionsRow}>
          <section>
            <Typography>{t('each-press-deals-one-card')}</Typography>
            <Typography>{t('x-of-y-available-decks', { x: deckOptions.decksUsed.length, y: decks.length })}</Typography>
          </section>
          {renderDeckDealButtons()}
        </BoxR>
        <PromptMessage errorText={dealErrorText} />
        <DealtToStatus />
        <DividerH />
      </React.Fragment>
    );
  }
  function renderDeckDealButtons() {
    const activeDecks = DeckOptions.asDecks(deckOptions.decksUsed);
    return activeDecks.map((m, i) =>
      <Button key={`deckDeal-${i}`} className={classes.dealButton} color='secondary' variant="contained" onClick={() => handleDealCard(m)} >
        <section>
          <Typography>{m.name}</Typography>
          <Typography className={classes.dealStatusLine}>{t('x-of-y-available', { x: m.cardKeys.length - m.nextCardIndex, y: m.cardKeys.length })}</Typography>
        </section>
      </Button>
    );
  }

  const { readerPromptTitle, allReaderNames } = buildReaderPromptValues();
  const { dealToPromptTitle, selectedDealToNames, allDealToNames } = buildDealToPromptValues();

  return (
    <StyledBoxC className={classes.root} {...rest} >
      <PlayerPrompt open={readerPromptOpen} onCancel={handleReaderPromptCancel}
        onSave={handleReaderPromptSave} allPlayerNames={allReaderNames}
        title={readerPromptTitle} currentReader={roundDataDefaults.currentReader} />
      <PlayerPrompt open={dealToPromptOpen} onCancel={handleDealToPromptCancel}
        onSave={handleDealToPromptSave} allPlayerNames={allDealToNames}
        selectedPlayerNames={selectedDealToNames}
        title={dealToPromptTitle} allowMultiSelect />
      <DeckPrompt open={!!deckIdToEdit} onClose={handleDeckPromptClose} deckIdToEdit={deckIdToEdit} />

      <BoxCG >
        <PlayerStatusLine game={game} handToDisplay={game.currentHand + 1} includePublishedTo={false} />
        <ControllerStatusLine game={game} />
        <DividerH />
        <Typography color='primary' className={classes.optionsInstructions}><b>{t('select-options-for-the-start-o')}</b></Typography>
        <PlayOptionControls game={game} gamePlay={gamePlayDefaults} onSave={handlePlayOptionControlsSave} />
        <DividerH />
        {renderCurrentReaderControls()}
        {renderDeckControls()}
        <BoxR style={{ justifyContent: 'center', maxWidth: '100%' }}>
          <EditScoreButton />
          <Button className={classes.playButton} color='primary' variant="contained" onClick={handleStartPlay} >
            {t('start-play')}
          </Button>
        </BoxR>
      </BoxCG>
    </StyledBoxC>
  );
}
