import React from 'react';
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import UserStore from "../appSupport/UserStore";
import ScoreOptions from "../fields/ScoreOptions";
import AbstractStore from "./AbstractStore";
import GameStore from "./GameStore";
import PendingPlayerStore from './PendingPlayerStore';
import ScoreOps from "./ScoreOps";

const SCORE_CHANNEL = 'SCORE_CHANNEL';
const BY_PLAYER_TEMPLATE = { prevTotalScore: 0, handScore: undefined, totalScore: 0 };

let INSTANCE;

export function useFreshScores(filter, currentHandIn) {
  const [records, setRecords] = React.useState({});
  const game = GameStore.getGame();
  const currentHand = currentHandIn || game.currentHand;

  React.useEffect(() => {
    var hasEnded = false;
    var hasListener = false;
    const getRecords = () => ScoreStore.getRecords(filter);
    const prepScores = (scores) => ScoreStore.scoresByName(scores, currentHand);
    const handleChange = (event) => {
      // const { records } = event.payload;
      if (!hasEnded) setRecords(prepScores(getRecords(filter)));
    }
    ScoreStore.initialQueryCompletePromise()
      .then(r => {
        if (!hasEnded) {
          hasListener = true;
          ScoreStore.listen(handleChange);
          setRecords(prepScores(getRecords(filter)));
        }
      });
    return () => {
      hasEnded = true;
      if (hasListener) {
        hasListener = false;
        ScoreStore.stopListen(handleChange);
      }
    }
  }, [filter, currentHand]);

  return records;
}

export default class ScoreStore {

  static instance() {
    const id = PathUtil.getGameParam();
    const game = INSTANCE ? INSTANCE.getGame() : { id };
    if (!INSTANCE || (game.id !== id)) {
      if (INSTANCE) INSTANCE.release();
      INSTANCE = new ScoreStoreInst();
    }
    return INSTANCE;
  }
  static initialQueryCompletePromise() {
    return ScoreStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return ScoreStore.instance().loadedPromise();
  }
  static listen(callBack) {
    ScoreStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }
  static getRecords(filtered) {
    return ScoreStore.instance().getRecords(filtered);
  }

  static resetTotals(hand) {
    ScoreStore.instance().resetTotals(hand);
  }

  static setBid(hand, scoreRow, bidIn) {
    const test = parseFloat(bidIn);
    if (!isNaN(test)) {
      ScoreStore.instance().setBid(hand, scoreRow, bidIn);
    }
  }

  static setScore(hand, name, handScore) {
    const score = parseFloat(handScore);
    if (!isNaN(score)) {
      ScoreStore.instance().setScore(hand, name, handScore);
    }
  }

  static sortedScores(game, byNameIn) {
    // Construct an array sorted by score with a content of (name, prevTotalScore, handScore, totalScore)
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    // Scores are by player name. First build a list of scores by player...
    const byName = byNameIn || ScoreStore.scoresByName();

    // ...then add player entries for players who have no score...
    const allNames = scoreOptions.teamNames
      ? Globals.getTeamNamesArray(scoreOptions.teamNames) : PendingPlayerStore.getPlayerNames();
    allNames.forEach(e => {
      if (!byName[e]) {
        byName[e] = { ...BY_PLAYER_TEMPLATE };
      }
      // NOTE: This is a bit of a mess.  Score should be by name and not playerName because scores may be by team names
      //       and not player names.  The .name=e below should be .playerName=e, and all the places that reference .name
      //       should be updated.  PREFERABLY the Score object should have the playerName field renamed to name.  Both 
      //       are a bit of an undertaking.
      byName[e].name = e; // add the player name to our object   
      byName[e].id = e;
    });

    // ... return a sorted list
    const sorter = (a, b) => {
      return scoreOptions.reverseScoring ? a.totalScore - b.totalScore || Globals.sorterAny(a.name, b.name)
        : b.totalScore - a.totalScore || Globals.sorterAny(a.name, b.name);
    }
    return Object.values(byName).sort(sorter)
      .filter(f => f.name); // Filter... necessary if toggling between teams and players
  }

  static scoresByName(scoresIn, currentHandIn) {
    // Construct an object that is keyed by playerName and has a sub object of (prevTotalScore, handScore, totalScore)
    const result = {};
    const currentHand = currentHandIn || GameStore.getGame().currentHand;
    const scores = scoresIn || ScoreStore.getRecords();
    scores.forEach(e => {
      if (e.hand <= currentHand) {
        const existingResult = result[e.playerName] || { ...BY_PLAYER_TEMPLATE };
        const newO = { ...e };
        newO.prevTotalScore = e.hand < currentHand ? e.totalScore : existingResult.totalScore;
        newO.handScore = e.hand === currentHand ? e.handScore : undefined;
        newO.bid = e.hand === currentHand ? newO.bid : undefined;
        result[e.playerName] = newO;
      }
    });
    return result;
  }
}

class ScoreStoreInst extends AbstractStore {
  constructor() {
    super('ScoreStore', SCORE_CHANNEL);
    this.game = GameStore.getGame();
    this.boundGameUpdate = this.handleGameUpdate.bind(this);
    GameStore.listen(this.boundGameUpdate);
    this.startHand = this.getCurrentStartHand();
    this.loadStore(ScoreOps.onScoreCreate, ScoreOps.onScoreUpdate, null);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    const gameId = PathUtil.getGameParam();
    if (!gameId) return this.getValuesForInit();  // If we have navigated back from game, don't fail async ops
    return `User: ${UserStore.getUserName()}  Id: ${gameId}  subscriptionOwner: ${Globals.getDataOwner()}`;
  }
  queryRecords() {
    return ScoreOps.getScores();
  }
  performCreate(properties) {
    return ScoreOps.createScore(properties);
  }
  performUpdate(keyFields, updatesIn) {
    const updates = { ...keyFields, ...updatesIn };
    return ScoreOps.updateScore(updates);
  }
  recKey(record) {
    const zeroPad = (num, places) => String(num).padStart(places, '0')
    return `#${zeroPad(record.hand, 5)}#${record.playerName}`;
  }

  assimilateList(inputList) {
    const sortFn = (a, b) => Globals.sorterAny(this.recKey(a), this.recKey(b));
    return inputList.filter(this.getStartHandFilter()).sort(sortFn);
  }
  getStartHandFilter() {
    const csh = this.getCurrentStartHand();
    return f => f.hand >= csh;
  }
  release() {
    super.release();
    GameStore.stopListen(this.boundGameUpdate);
  }
  getGame() {
    return this.game;
  }
  getCurrentStartHand() {
    return this.getGame().scoringStartHandsArray[this.getGame().scoringStartHandsArray.length - 1];
  }
  handleGameUpdate(event) {
    // Don't update the game if the id has changed.  This allows for the Store to perform release() for new game.
    const { game: newGame } = event.payload;
    if (this.game.id === newGame.id) {
      this.game = newGame;
      const csh = this.getCurrentStartHand();
      if (this.startHand !== csh) {
        this.startHand = csh;
        this.reFilterRecords(this.getStartHandFilter());
      }
    }
  }
  resetTotals(hand) {
    const sbn = ScoreStore.scoresByName(undefined, hand);
    Object.keys(sbn).forEach(e => {
      const { handScore, totalScore } = sbn[e];
      if (handScore || totalScore) {
        this.setScore(hand, e, handScore);
      }
    });
  }
  setBid(hand, scoreRow, bid) {
    const playerName = scoreRow.name || scoreRow.playerName;
    const keyFields = { id: this.getGame().id, hand, playerName };
    const scoreOrZero = v => (v === undefined || (v === null)) ? 0 : v;
    const updates = { bid: parseFloat(bid) };
    const existingScoreRec = this.getRecords().find(f => f.playerName === playerName && f.hand === hand);
    if (!existingScoreRec) { // If this is a create, include the totalScore
      const totalScore = this.getRecords().reduce((pv, cv) => {
        return pv + (cv.playerName === playerName ? scoreOrZero(cv.handScore) : 0);
      }, 0);
      updates.totalScore = totalScore;
    }
    this.put(keyFields, updates);
  }
  setScore(hand, name, handScore) {
    const keyFields = { id: this.getGame().id, hand, playerName: name };
    const scoreOrZero = v => (v === undefined || (v === null)) ? 0 : v;
    const currentHand = this.getGame().currentHand;
    const totalScore = this.getRecords().reduce((pv, cv) => {
      return pv + (cv.playerName === name && (cv.hand !== currentHand) ? scoreOrZero(cv.handScore) : 0);
    }, handScore);
    this.put(keyFields, { handScore: parseFloat(handScore), totalScore: parseFloat(totalScore) });
  }
}

