import { styled } from '@mui/material/styles';
import React from 'react';
import BoxCG from './box/BoxCG';

const classes = {
  root: 'PageContainer-root'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxHeight: '100%',
  }
}));

const PageContainer = React.forwardRef((props, ref) =>
  (function () {
    const { children, ...rest } = props;
    return (
      <StyledBoxCG className={classes.root} ref={ref} {...rest}>
        {children}
      </StyledBoxCG>
    );
  })()
);
export default (PageContainer);
