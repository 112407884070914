import initI18n from '../i18n';

class LanguageStore {
  constructor() {
    this.languageCode = null;
    this.pseudoMode = null;
  }
  init(languageCode, pseudoMode) {
    if (this.languageCode !== languageCode || (this.pseudoMode !== pseudoMode)) {
      this.languageCode = languageCode;
      this.pseudoMode = pseudoMode;
      initI18n(languageCode, pseudoMode);
    }
  }
}
export default new LanguageStore();