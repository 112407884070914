export default class SRBugReport {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.problemDescription = input ? input.problemDescription : null;
    this.stepsToReproduce = input ? input.stepsToReproduce : null;
    this.dialog = input ? input.dialog : [];
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

