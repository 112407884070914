// NLS-NLS(player)
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import { STORAGE_inControllerTab, STORAGE_refreshTipDisplayed } from '../../appSupport/storageConstants';
import UiUtil from '../../appSupport/UiUtil';
import UserAssistance from '../../appSupport/UserAssistance';
import { useStateWithLocalStorage, useStateWithSessionStorage } from '../../db/StorageStore';
import GameView from '../GameView';
import TabbedView, { SELECT_CONTROLLER_TAB } from '../TabbedView';
import ResultsContent from './ResultsContents';
import ResultsController from './ResultsController';

export default function Results(props) {
  const { game } = props;
  const { isPublishedToMe } = Globals.getPublishedData(game);
  const [inControllerViewForHand, setInControllerViewForHand] = useStateWithSessionStorage(STORAGE_inControllerTab, '');
  const [hasRefreshTipBeenDisplayed, setHasRefreshTipBeenDisplayed] = useStateWithLocalStorage(STORAGE_refreshTipDisplayed, '');
  const inControllerView = inControllerViewForHand === game.currentHand.toString();
  const selectTab = (!isPublishedToMe || !!inControllerView) ? SELECT_CONTROLLER_TAB : undefined;
  const { t } = useTranslation('player');
  const TITLE_PLAYER = t('answers-in');

  React.useEffect(() => {
    if (!hasRefreshTipBeenDisplayed) {
      setHasRefreshTipBeenDisplayed(true);
      UserAssistance.displayRefreshToolTip();
    }
  }, [hasRefreshTipBeenDisplayed, setHasRefreshTipBeenDisplayed]);


  function handleControllerViewSelected() {
    setInControllerViewForHand(game.currentHand);
  }
  function handleOtherViewSelected() {
    setInControllerViewForHand('');
  }
  return (
    <GameView game={game} playerTitle={TITLE_PLAYER} key={UiUtil.gameViewKey(game)}>
      <TabbedView game={game} mainLabel={t('results')} buttonTabs
        mainView={<ResultsContent game={game} />}
        controllerView={<ResultsController game={game} />}
        selectTab={selectTab}
        onControllerViewSelected={handleControllerViewSelected}
        onMainViewSelected={handleOtherViewSelected}
        onCardViewSelected={handleOtherViewSelected} />
    </GameView>
  );
}
