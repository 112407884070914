import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function ScoreOnlyHelp() {
  const { t } = useTranslation('help');
  return (
    <HelpTool helpText={t("if-selected-then-you-remain-on")} />
  );
}
