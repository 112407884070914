import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ConfigureIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import { Autocomplete } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import ScoreOnlyHelp from '../components/help/ScoreOnlyHelp';
import TeamNamesHelp from '../components/help/TeamNamesHelp';
import ProfileStore from '../db/ProfileStore';
import ScoreOptions from '../fields/ScoreOptions';
import AutoScoreReaderPrompt from './AutoScoreReaderPrompt';
import AutoScoreSelectPrompt from './AutoScoreSelectPrompt';
import ConfigureTeamNamesPrompt from './ConfigureTeamNamesPrompt';
import ErrorsExistPrompt from './ErrorsExistPrompt';
import PromptMessage from './PromptMessage';
import ScoreButtonsPrompt from './ScoreButtonsPrompt';


const SettingsTool = styled(IconButton)(({ theme }) => ({
  edge: 'start',
  color: theme.palette.secondary.main
}));
const sxIndentedMessage = { ml: 2 };

const EMPTY_STAGED_VALUES = {};

const ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB = 'showScoreBeforeNewRound';
const ID_REVERSE_CB = 'reverseScoring';
const ID_SCORE_ONLY_CB = 'scoreOnly';
const ID_ENABLE_BIDS_CB = 'enableBids';
const ID_SCORE_BUTTONS_CB = 'useScoreButtons';
const ID_SCORE_BUTTONS_JSON = 'scoreButtons';
const ID_AUTO_SCORE_READER_CB = 'useAutoScoreReader';
const ID_AUTO_SCORE_READER_JSON = 'autoScoreReader';
const ID_AUTO_SCORE_SELECT_CB = 'useAutoScoreSelect';
const ID_AUTO_SCORE_SELECT_JSON = 'autoScoreSelect';
const ID_TEAM_NAMES = 'teamNames';

const checkboxControls = [ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB, ID_REVERSE_CB, ID_SCORE_ONLY_CB, ID_ENABLE_BIDS_CB,
  ID_SCORE_BUTTONS_CB, ID_AUTO_SCORE_READER_CB, ID_AUTO_SCORE_SELECT_CB];

export default function ScoreOptionsPrompt(props) {

  const { open, onCancel, onSave, game, gameOptions } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [prevOpenState, setPrevOpenState] = React.useState(false);
  const [enableBidsErrorText, setEnableBidsErrorText] = React.useState('');
  const [teamNamesErrorText, setTeamNamesErrorText] = React.useState('');
  const [autoScoreReaderErrorText, setAutoScoreReaderErrorText] = React.useState('');
  const [autoScoreSelectErrorText, setAutoScoreSelectErrorText] = React.useState('');
  const [isConfigurePlayersOpen, setIsConfigurePlayersOpen] = React.useState(false);
  const [isScoreButtonsPromptOpen, setIsScoreButtonsPromptOpen] = React.useState(false);
  const [isAutoScoreReaderPromptOpen, setIsAutoScoreReaderPromptOpen] = React.useState(false);
  const [isAutoScoreSelectPromptOpen, setIsAutoScoreSelectPromptOpen] = React.useState(false);
  const [errorsExistPromptOpen, setErrorsExistPromptOpen] = React.useState(false);
  const [errorsExist, setErrorsExist] = React.useState(false);
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const lastTeams = ProfileStore.getLastTeams() || [];
  const { t } = useTranslation('controller');


  // React.useEffect(() => {
  //   ProfileStore.setLastTeams([]);
  // }, []);

  if (open && !prevOpenState) {
    setStagedValues({
      [ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB]: scoreOptions[ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB],
      [ID_REVERSE_CB]: scoreOptions[ID_REVERSE_CB],
      [ID_SCORE_ONLY_CB]: scoreOptions[ID_SCORE_ONLY_CB],
      [ID_ENABLE_BIDS_CB]: scoreOptions[ID_ENABLE_BIDS_CB],
      [ID_TEAM_NAMES]: scoreOptions[ID_TEAM_NAMES],
      [ID_SCORE_BUTTONS_CB]: scoreOptions[ID_SCORE_BUTTONS_CB],
      [ID_SCORE_BUTTONS_JSON]: scoreOptions[ID_SCORE_BUTTONS_JSON],
      [ID_AUTO_SCORE_READER_CB]: scoreOptions[ID_AUTO_SCORE_READER_CB],
      [ID_AUTO_SCORE_READER_JSON]: scoreOptions[ID_AUTO_SCORE_READER_JSON],
      [ID_AUTO_SCORE_SELECT_CB]: scoreOptions[ID_AUTO_SCORE_SELECT_CB],
      [ID_AUTO_SCORE_SELECT_JSON]: scoreOptions[ID_AUTO_SCORE_SELECT_JSON],
    });
    setPrevOpenState(true);
  }
  const clearValidations = () => {
    setEnableBidsErrorText('');
    setTeamNamesErrorText('');
    setAutoScoreReaderErrorText('');
    setAutoScoreSelectErrorText('');
    setErrorsExist(false);
    setErrorsExistPromptOpen(false);
  }
  const validations = {
    validateAutoScoringReader: (newStaged) => {
      if (newStaged[ID_AUTO_SCORE_READER_CB] && newStaged[ID_TEAM_NAMES]) {
        setAutoScoreReaderErrorText(t('you-may-not-auto-score-when-us'));
        return true;
      }
      return false;
    },
    validateAutoScoringSelect: (newStaged) => {
      if (newStaged[ID_AUTO_SCORE_SELECT_CB] && newStaged[ID_TEAM_NAMES]) {
        setAutoScoreSelectErrorText(t('you-may-not-auto-score-when-us'));
        return true;
      }
      return false;
    },
    validateEnableBids: (newStaged) => {
      if (newStaged[ID_ENABLE_BIDS_CB] && !newStaged[ID_SCORE_BUTTONS_CB]) {
        setEnableBidsErrorText(t('you-may-only-enable-bids-if-us'));
        return true;
      }
      return false;
    },
    validateTeamNames: (newStaged) => {
      const hasDuplicates = (array) => { return (new Set(array)).size !== array.length; }
      const names = newStaged[ID_TEAM_NAMES].split(',').map(m => m.trim().toLowerCase());
      if (hasDuplicates(names)) {
        setTeamNamesErrorText(t('you-may-not-use-the-same-name-'));
        return true;
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      return validations.validateAutoScoringReader(stagedValues)
        || validations.validateAutoScoringSelect(stagedValues)
        || validations.validateEnableBids(stagedValues)
        || validations.validateTeamNames(stagedValues);
    }
  }

  const scrub = (input) => {
    // Remove any quotes tha would mess up the JSON storage
    return input.replace(/"/g, "'");
  }
  function handleErrorsExistPromptClose() {
    setErrorsExistPromptOpen(false);
  }
  const handleCancel = () => {
    setPrevOpenState(false);
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (validations.performValidate()) {
      setErrorsExistPromptOpen(true);
      setErrorsExist(true);
    } else {
      const oldTeamName = scoreOptions[ID_TEAM_NAMES];
      scoreOptions[ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB] = stagedValues[ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB];
      scoreOptions[ID_REVERSE_CB] = stagedValues[ID_REVERSE_CB];
      scoreOptions[ID_SCORE_ONLY_CB] = stagedValues[ID_SCORE_ONLY_CB];
      scoreOptions[ID_ENABLE_BIDS_CB] = stagedValues[ID_ENABLE_BIDS_CB];
      scoreOptions[ID_TEAM_NAMES] = scrub(stagedValues[ID_TEAM_NAMES]);
      scoreOptions[ID_SCORE_BUTTONS_CB] = stagedValues[ID_SCORE_BUTTONS_CB];
      scoreOptions[ID_SCORE_BUTTONS_JSON] = stagedValues[ID_SCORE_BUTTONS_JSON];
      scoreOptions[ID_AUTO_SCORE_READER_CB] = stagedValues[ID_AUTO_SCORE_READER_CB];
      scoreOptions[ID_AUTO_SCORE_READER_JSON] = stagedValues[ID_AUTO_SCORE_READER_JSON];
      scoreOptions[ID_AUTO_SCORE_SELECT_CB] = stagedValues[ID_AUTO_SCORE_SELECT_CB];
      scoreOptions[ID_AUTO_SCORE_SELECT_JSON] = stagedValues[ID_AUTO_SCORE_SELECT_JSON];
      onSave(scoreOptions);
      if (scoreOptions[ID_TEAM_NAMES] !== oldTeamName) {
        ProfileStore.addLastTeams(scoreOptions[ID_TEAM_NAMES]);
      }
      clearValidations();
      setPrevOpenState(false);
    }
  }

  function handleAutoScoreReaderSave(autoScoreReaderJSON) {
    stagedValues[ID_AUTO_SCORE_READER_JSON] = autoScoreReaderJSON;
    setIsAutoScoreReaderPromptOpen(false);
  }

  function handleAutoScoreSelectSave(autoScoreSelectJSON) {
    stagedValues[ID_AUTO_SCORE_SELECT_JSON] = autoScoreSelectJSON;
    setIsAutoScoreSelectPromptOpen(false);
  }

  function handleScoreButtonsSave(scoreButtonJSON) {
    stagedValues[ID_SCORE_BUTTONS_JSON] = scoreButtonJSON;
    setIsScoreButtonsPromptOpen(false);
  }

  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    // event.stopPropagation();
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  const handleTeamNameChange = (event) => {
    const { outerText } = event.currentTarget;
    const newStaged = { ...stagedValues };
    newStaged[ID_TEAM_NAMES] = outerText;
    setStagedValues(newStaged);
  }
  function handleConfigureTeamNamesSave(selected) {
    const newStaged = { ...stagedValues };
    newStaged[ID_TEAM_NAMES] = ProfileStore.formatTeamNames(selected);
    setStagedValues(newStaged);
    setIsConfigurePlayersOpen(false);
  }

  const workingTeamNames = stagedValues[ID_TEAM_NAMES] || '';
  const selectedPlayerNames = workingTeamNames ? workingTeamNames.trim().split(',').map(m => m.trim()) : [];

  const noError = '';
  if (!open) return null;

  const autoScoreReaderWarning = stagedValues[ID_AUTO_SCORE_READER_CB] && !gameOptions.readersAnswerIsCorrect
    ? t('warning-you-must-select-reader') : null;
  const autoScoreSelectWarning = stagedValues[ID_AUTO_SCORE_SELECT_CB] && !gameOptions.isAnswerSelectable
    ? t('warning-you-must-select-allow-') : null;
  return (
    <div>
      <ConfigureTeamNamesPrompt open={isConfigurePlayersOpen} onCancel={f => setIsConfigurePlayersOpen(false)}
        selectedPlayerNames={selectedPlayerNames} onSave={handleConfigureTeamNamesSave} />
      <ErrorsExistPrompt open={errorsExistPromptOpen} onClose={handleErrorsExistPromptClose} />
      <ScoreButtonsPrompt open={isScoreButtonsPromptOpen} onCancel={f => setIsScoreButtonsPromptOpen(false)}
        onSave={handleScoreButtonsSave} scoreButtonsJSON={stagedValues[ID_SCORE_BUTTONS_JSON]} />
      <AutoScoreReaderPrompt open={isAutoScoreReaderPromptOpen} onCancel={f => setIsAutoScoreReaderPromptOpen(false)}
        onSave={handleAutoScoreReaderSave} autoScoreReaderJSON={stagedValues[ID_AUTO_SCORE_READER_JSON]} />
      <AutoScoreSelectPrompt open={isAutoScoreSelectPromptOpen} onCancel={f => setIsAutoScoreSelectPromptOpen(false)}
        onSave={handleAutoScoreSelectSave} autoScoreSelectJSON={stagedValues[ID_AUTO_SCORE_SELECT_JSON]} />
      <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{t('score-options')}</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB]} onChange={handleValueChange} id={ID_SHOW_SCORE_BEFORE_NEW_ROUND_CB} />}
              label={t('automatically-show-score-befor')}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_REVERSE_CB]} onChange={handleValueChange} id={ID_REVERSE_CB} />}
              label={t('reverse-scoring')}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_SCORE_BUTTONS_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTONS_CB} />}
              label={t('score-buttons')}
            />
            <SettingsTool
              onClick={f => setIsScoreButtonsPromptOpen(true)}
              disabled={!stagedValues[ID_SCORE_BUTTONS_CB]}
              size="large">
              <SettingsIcon />
            </SettingsTool>
          </FormGroup>
          <FormGroup row style={{ flexWrap: 'noWrap' }}>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_AUTO_SCORE_READER_CB]} onChange={handleValueChange} id={ID_AUTO_SCORE_READER_CB} />}
              label={t('auto-scoring-on-readers-answer')}
            />
            <SettingsTool
              onClick={f => setIsAutoScoreReaderPromptOpen(true)}
              disabled={!stagedValues[ID_AUTO_SCORE_READER_CB]}
              size="large">
              <SettingsIcon />
            </SettingsTool>
          </FormGroup>
          <PromptMessage errorText={autoScoreReaderErrorText} warningText={autoScoreReaderWarning} sx={sxIndentedMessage} />
          <FormGroup row style={{ flexWrap: 'noWrap' }}>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_AUTO_SCORE_SELECT_CB]} onChange={handleValueChange} id={ID_AUTO_SCORE_SELECT_CB} />}
              label={t('auto-scoring-on-answer-selecti')}
            />
            <SettingsTool
              onClick={f => setIsAutoScoreSelectPromptOpen(true)}
              disabled={!stagedValues[ID_AUTO_SCORE_SELECT_CB]}
              size="large">
              <SettingsIcon />
            </SettingsTool>
          </FormGroup>
          <PromptMessage errorText={autoScoreSelectErrorText} warningText={autoScoreSelectWarning} sx={sxIndentedMessage} />
          <FormGroup row>
            <BoxR>
              <FormControlLabel
                control={<Checkbox checked={stagedValues[ID_SCORE_ONLY_CB]} onChange={handleValueChange} id={ID_SCORE_ONLY_CB} />}
                label={t('score-only-no-play')}
              />
              <ScoreOnlyHelp />
            </BoxR>
          </FormGroup>
          <FormGroup row sx={{ ml: 2 }}>
            <FormControlLabel disabled={!stagedValues[ID_SCORE_ONLY_CB]}
              control={<Checkbox checked={stagedValues[ID_ENABLE_BIDS_CB]} onChange={handleValueChange} id={ID_ENABLE_BIDS_CB} />}
              label={t('enable-bids')}
            />
            <PromptMessage errorText={enableBidsErrorText} sx={sxIndentedMessage} />
          </FormGroup>
          <BoxR >
            <Autocomplete
              id={ID_TEAM_NAMES}
              clearOnBlur={false}
              freeSolo
              fullWidth
              options={lastTeams}
              onChange={handleTeamNameChange}
              value={stagedValues[ID_TEAM_NAMES]}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField margin='normal' {...params} autoFocus fullWidth onChange={handleValueChange} error={Boolean(noError)}
                id={ID_TEAM_NAMES} label={t('comma-separated-names')} type="text" value={stagedValues[ID_TEAM_NAMES]} />
              } />
            <SettingsTool
              onClick={f => setIsConfigurePlayersOpen(true)}
              size="large">
              <ConfigureIcon />
            </SettingsTool>
            <TeamNamesHelp />
          </BoxR>
          <Typography>{t('optional-leave-blank-for-playe')}</Typography>
          <PromptMessage errorText={teamNamesErrorText} sx={sxIndentedMessage} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
            <PromptMessage errorText={errorsExist && t('continue')} plainText />
            {!errorsExist && t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}