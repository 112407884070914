// NLS-NLS(controller)
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import UserStore from '../../appSupport/UserStore';
import BoxR from '../../components/box/BoxR';

const classes = {
  root: 'LandingFooter-root',
  footerText: 'LandingFooter-footerText'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.footerText}`]: {
    fontWeight: 'bold',
    color: 'lightGrey',
  }
}));


export default function LandingFooter() {

  const { t } = useTranslation('controller');

  const userName = Globals.isDefaultUser() ? t('Not logged in') : UserStore.getUserName();

  return (
    <StyledBoxR className={classes.root}>
      <Typography className={classes.footerText}>{userName}</Typography>
      <Typography className={classes.footerText}>{process.env.REACT_APP_VERSION}</Typography>
    </StyledBoxR>
  );
}
