// NLS-NLS(support)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';

const classes = {
  root: 'LandingButton-root',
  smallSize: 'LandingButton-smallSize',
  largeSize: 'LandingButton-largeSize'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  [`&.${classes.smallSize}`]: {
    fontSize: 15,
  },
  [`&.${classes.largeSize}`]: {
    fontSize: 25,
  }
}));

export default function LandingButton(props) {
  const { children, small = false, ...rest } = props;

  const size = small ? classes.smallSize : classes.largeSize;

  return (
    <StyledButton className={clsx(classes.root, size)}
      fullWidth variant="contained" color="secondary" {...rest} >
      {children}
    </StyledButton>
  );
}
