import React from 'react';
import Globals from '../../appSupport/Globals';
import AnswersInStatus from '../../components/AnswersInStatus';
import DividerH from '../../components/DividerH';
import PlayControls from '../../components/PlayControls';
import PlayerStatusLine, { STATUS_ENTER_ANSWER } from '../../components/PlayerStatusLine';
import PlayTimer from '../../components/PlayTimer';
import { QUESTION_TIMER } from '../../fields/TimerValues';

export default function PlayController(props) {
  const { game } = props;
  const isController = Globals.isGameController(game);

  if (!isController) return null; // ==> EARLY EXIT
  return (
    <React.Fragment>
      <PlayerStatusLine game={game} status={STATUS_ENTER_ANSWER} includePublishedTo={false} />
      <PlayTimer game={game} whichTimer={QUESTION_TIMER} />
      <PlayControls whichTimer={QUESTION_TIMER} />
      <DividerH />
      <AnswersInStatus />
    </React.Fragment>
  );
}
