import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Auth } from 'aws-amplify';
import React from 'react';
import Globals from '../appSupport/Globals';
import TraceLog from '../appSupport/TraceLog';
import UiUtil from '../appSupport/UiUtil';
import LogEntry from '../components/LogEntry';


export default function DebugPrompt(props) {
  const [autoReloadErrors, setAutoReloadErrors] = React.useState(false);
  const [showAdditional, setShowAdditional] = React.useState(false);
  const [additionalContent, setAdditionalContent] = React.useState(null);
  const auto = Globals.getAutoReloadErrors();
  React.useEffect(() => {
    setAutoReloadErrors(Globals.getAutoReloadErrors());
  }, [auto]);

  React.useEffect(() => {
    async function retrieveAdditionalContent(err) {
      const debug = (await Auth.currentSession()).getIdToken();
      setAdditionalContent(JSON.stringify(debug));
    }
    retrieveAdditionalContent();
  }, []);

  const { open, onCancel } = props;
  if (!open) return null;

  const handleCancel = () => {
    onCancel();
  }
  const handleAutoReloadChange = (event) => {
    const newValue = !Globals.getAutoReloadErrors();
    Globals.setAutoReloadErrors(newValue);
    setAutoReloadErrors(newValue);
  }
  const handleShowAdditionalChange = (event) => {
    const { checked } = event.target;
    setShowAdditional(checked);
  }
  let entries = TraceLog.getEntries();
  entries = entries.length ? entries : [{ type: 'No entries in log' }];
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">Debug Log</DialogTitle>
      <DialogContent>
        {entries.map((m, i) => <LogEntry key={`${i}`} entry={m} last={i === entries.length - 1} />)}
        <p style={{ color: 'blue' }}>
          {showAdditional ? additionalContent : ''}
        </p>
      </DialogContent>
      <DialogActions>
        <FormGroup row>
          {false ?
            <FormControlLabel
              control={<Checkbox checked={showAdditional} onChange={handleShowAdditionalChange} />}
              label="Show additional"
            /> : null}
          <FormControlLabel
            control={<Checkbox checked={autoReloadErrors} onChange={handleAutoReloadChange} />}
            label="Auto reload errors"
          />
        </FormGroup>

        <Button onClick={handleCancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}