import { Box, Grow } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const classes = {
  root: 'IconAnimation-root',
  growMarker: 'IconAnimation-growMarker'
};

const StyledGrow = styled(Grow)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.growMarker}`]: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.info.main,
    borderRadius: 1000,
  }
}));

const timerOverage = 100;
export default class IconAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationInProgress: false,
      displayNow: false,
    };
  }
  componentDidMount() {
    this.resumeAnimation();
  }
  componentDidUpdate() {
    this.resumeAnimation();
  }
  componentWillUnmount() {
    this.stopAnimation();
  }

  resumeAnimation() {
    const { animateNow } = this.props;
    const { animationInProgress } = this.state;
    if (animationInProgress !== animateNow) {
      if (animateNow) {
        this.setState({ animationInProgress: true, displayNow: true });
        this.startAnimationCycle2();
      } else {
        this.stopAnimation();
      }
    }
  }

  stopAnimation() {
    if (this.animationTimer) {
      clearTimeout(this.animationTimer);
      this.animationTimer = null;
    }
    this.setState({ animationInProgress: false, displayNow: false });
  }

  startAnimationCycle1 = () => {
    const { duration } = this.props;
    this.animationTimer = setTimeout(() => {
      this.setState({ displayNow: true });
      this.startAnimationCycle2();
    }, duration + timerOverage);
  }
  startAnimationCycle2 = () => {
    const { duration } = this.props;
    this.animationTimer = setTimeout(() => {
      this.setState({ displayNow: false });
      this.startAnimationCycle1();
    }, duration + timerOverage);
  }

  render() {
    const { duration } = this.props;
    const { displayNow } = this.state;
    const GrowMarker = Box;

    return (
      <StyledGrow in={displayNow} exit timeout={duration}
        style={{ display: displayNow }} className={classes.root}>
        <GrowMarker className={classes.growMarker} />
      </StyledGrow>
    );
  }
}
IconAnimation.propTypes = {
  animateNow: PropTypes.bool.isRequired,
  duration: PropTypes.number,
};
IconAnimation.defaultProps = {
  duration: 500,
}

