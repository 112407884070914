import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HAND_STATUS_END, HAND_STATUS_PLAY } from '../db/GameOps';
import PendingPlayerStore from '../db/PendingPlayerStore';
import NavigationCode from '../fields/NavigationCode';
import { gameRoutesFull } from '../routing/gameRoutes';
import Globals, { clearRecentGame } from './Globals';
import PathUtil from './PathUtil';

const globalMemoryLeakHack = Date.now();

export default function useMonitorGameState(game, results) {
  const navigate = useNavigate();
  const goneBack = React.useRef(false);
  const isWaitingForEntryRef = React.useRef(false);
  // results effects useMonitorGameState for ReadyPlay
  if (results) { (() => 'sillyUnreference')() }

  const deriveNewPath = (game) => {
    const { isPublishedToMe } = Globals.getPublishedData(game);
    const hasPendingEntryKey = Globals.isWaitingForEntry(game);
    const entryKeyFromURL = PathUtil.getEntryKeyParam();
    const entryKeyFromPlayer = PendingPlayerStore.getClaimEntryKey();
    // Check to see if the user has been ejected from the game
    if (!hasPendingEntryKey && entryKeyFromURL && (entryKeyFromURL !== entryKeyFromPlayer)) {
      if (isWaitingForEntryRef.current) {
        // If we are being kicked back to landing, then we have been denied entry to the game
        // and we don't want to prompt for re-entry to a recent game that we were not a part of.
        clearRecentGame();
      }
      return gameRoutesFull.landing;
    }
    isWaitingForEntryRef.current = hasPendingEntryKey;
    if (Globals.getGameStaleMinutes(game) >= 60) {
      Globals.dispatchAbortGame('monitorGameState, game stale.');
    }

    let result
    const navigationCode = new NavigationCode(game.navigationCode);
    const gameHandStatus = Globals.isAttachMode() ? HAND_STATUS_PLAY : game.handStatus;
    switch (gameHandStatus) {
      case HAND_STATUS_PLAY:
        if (navigationCode.isNewRound()) {
          result = `${gameRoutesFull.newRound}${PathUtil.currentPathSearch()}`;
        } else if (hasPendingEntryKey || game.currentHand === 0) {
          result = `${gameRoutesFull.addPlayers}${PathUtil.currentPathSearch()}`;
        } else if (navigationCode.isShowScore() || navigationCode.isEditScore()) {
          result = `${gameRoutesFull.score}${PathUtil.currentPathSearch()}`;
        } else if (isPublishedToMe) {
          result = `${gameRoutesFull.results}${PathUtil.currentPathSearch()}`;
        } else if (Globals.hasPlayerPlayed()) {
          result = `${gameRoutesFull.playWait}${PathUtil.currentPathSearch()}`;
        } else {
          // Append the entry key if it is not already in the URL.  It is not established until play starts
          // and should be in the URL so that we don't allow two players to play with the same name.
          // i.e. if a player is re-admitted, the original ui will be ejected from the game.
          let searchPath = PathUtil.currentPathSearch();
          if (!entryKeyFromURL) {
            searchPath = `${searchPath}&entryKey=${entryKeyFromPlayer}`;
          }
          result = `${gameRoutesFull.play}${searchPath}`;
        }
        break;
      case HAND_STATUS_END:
        if (!goneBack.current) {
          goneBack.current = true;
          navigate(-1);
        }
        result = gameRoutesFull.landing; // This is where the stack should go
        break;
      default:
        result = PathUtil.currentPathWithSearch();
    }
    return result;
  }
  if (game) {
    const newPath = deriveNewPath(game);
    // const testPath = currentPath || PathUtil.currentPathWithSearch();
    const testPath = PathUtil.currentPathWithSearch();

    if (newPath !== testPath) {
      // To combat the memory leak, refresh if the user is going to the playerWait
      // screen after 10 minutes.

      // Note on the memory leak.  I created a simple scenario that just switched between
      // to pages. The only thing on the page was a button to switch to the other page via
      // the router.  If the page was constructed with a <div> no node leak. If I use the
      // Material-UI Box, it leaked nodes. Seems out of my control.

      // NOTE: Leak not validated since MUI upgrade
      const minutesSinceLastRefresh = (Date.now() - globalMemoryLeakHack) / 1000 / 60;
      const isGoingToPlayWait = newPath.includes(gameRoutesFull.playWait);
      if (minutesSinceLastRefresh > 10 && isGoingToPlayWait) {
        setTimeout(() => PathUtil.navigateExternal(newPath));  // setTimeout - don't navigate on render
      } else {
        setTimeout(() => Globals.dispatchNavigate(newPath));
      }
    }
  }
}