/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRg0PendingPlayer = /* GraphQL */ `
  subscription OnCreateRg0PendingPlayer($id: ID!, $owner: String) {
    onCreateRg0PendingPlayer(id: $id, owner: $owner) {
      id
      playerName
      entryKey
      playerNumber
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRg0PendingPlayer = /* GraphQL */ `
  subscription OnUpdateRg0PendingPlayer($id: ID!, $owner: String) {
    onUpdateRg0PendingPlayer(id: $id, owner: $owner) {
      id
      playerName
      entryKey
      playerNumber
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRg0Game = /* GraphQL */ `
  subscription OnUpdateRg0Game($id: ID!, $owner: String) {
    onUpdateRg0Game(id: $id, owner: $owner) {
      id
      entryRequests
      currentHand
      handStatus
      navigationCode
      scoringStartHandsArray
      controllerName
      scoreKeeperName
      gameOptions
      scoreOptions
      deckOptions
      gamePlay
      gamePlayDefaults
      roundData
      roundDataDefaults
      questionTimerValues
      answerTimerValues
      publishedTo
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRg0PlayerResult = /* GraphQL */ `
  subscription OnCreateRg0PlayerResult($id: ID!, $owner: String) {
    onCreateRg0PlayerResult(id: $id, owner: $owner) {
      id
      hand
      handString
      playerName
      createdAt
      answer
      annotations
      owner
      updatedAt
      version
    }
  }
`;
export const onUpdateRg0PlayerResult = /* GraphQL */ `
  subscription OnUpdateRg0PlayerResult($id: ID!, $owner: String) {
    onUpdateRg0PlayerResult(id: $id, owner: $owner) {
      id
      hand
      handString
      playerName
      createdAt
      answer
      annotations
      owner
      updatedAt
      version
    }
  }
`;
export const onCreateRg0Score = /* GraphQL */ `
  subscription OnCreateRg0Score($id: ID!, $owner: String) {
    onCreateRg0Score(id: $id, owner: $owner) {
      id
      hand
      handString
      playerName
      bid
      handScore
      totalScore
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRg0Score = /* GraphQL */ `
  subscription OnUpdateRg0Score($id: ID!, $owner: String) {
    onUpdateRg0Score(id: $id, owner: $owner) {
      id
      hand
      handString
      playerName
      bid
      handScore
      totalScore
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRg0Card = /* GraphQL */ `
  subscription OnCreateRg0Card($id: ID!, $owner: String) {
    onCreateRg0Card(id: $id, owner: $owner) {
      id
      hand
      handString
      playerName
      sequence
      deckId
      cardKey
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRg0Card = /* GraphQL */ `
  subscription OnUpdateRg0Card($id: ID!, $owner: String) {
    onUpdateRg0Card(id: $id, owner: $owner) {
      id
      hand
      handString
      playerName
      sequence
      deckId
      cardKey
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRg0SupportRequestUser = /* GraphQL */ `
  subscription OnCreateRg0SupportRequestUser($originatingUsername: String) {
    onCreateRg0SupportRequestUser(originatingUsername: $originatingUsername) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const onDeleteRg0SupportRequestUser = /* GraphQL */ `
  subscription OnDeleteRg0SupportRequestUser($originatingUsername: String) {
    onDeleteRg0SupportRequestUser(originatingUsername: $originatingUsername) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const onUpdateRg0SupportRequestUser = /* GraphQL */ `
  subscription OnUpdateRg0SupportRequestUser($originatingUsername: String) {
    onUpdateRg0SupportRequestUser(originatingUsername: $originatingUsername) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const onCreateRg0SupportRequest = /* GraphQL */ `
  subscription OnCreateRg0SupportRequest($commonOwner: String) {
    onCreateRg0SupportRequest(commonOwner: $commonOwner) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const onDeleteRg0SupportRequest = /* GraphQL */ `
  subscription OnDeleteRg0SupportRequest($commonOwner: String) {
    onDeleteRg0SupportRequest(commonOwner: $commonOwner) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const onUpdateRg0SupportRequest = /* GraphQL */ `
  subscription OnUpdateRg0SupportRequest($commonOwner: String) {
    onUpdateRg0SupportRequest(commonOwner: $commonOwner) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const onCreateRg0Deck = /* GraphQL */ `
  subscription OnCreateRg0Deck($owner: String!) {
    onCreateRg0Deck(owner: $owner) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRg0Deck = /* GraphQL */ `
  subscription OnUpdateRg0Deck($owner: String!) {
    onUpdateRg0Deck(owner: $owner) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRg0Deck = /* GraphQL */ `
  subscription OnDeleteRg0Deck($owner: String!) {
    onDeleteRg0Deck(owner: $owner) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateRg0Profile = /* GraphQL */ `
  subscription OnCreateRg0Profile($owner: String!) {
    onCreateRg0Profile(owner: $owner) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRg0Profile = /* GraphQL */ `
  subscription OnUpdateRg0Profile($owner: String!) {
    onUpdateRg0Profile(owner: $owner) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRg0Profile = /* GraphQL */ `
  subscription OnDeleteRg0Profile($owner: String!) {
    onDeleteRg0Profile(owner: $owner) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
