export default class RoundData {
  static fromGame(game, hand) {
    if (!game) return new RoundData(); // ==> EARLY EXIT
    const workingHand = hand || game.currentHand;
    return (workingHand && game.roundData) ? new RoundData(game.roundData[workingHand - 1]) : new RoundData();
  }
  static fromString(inputString) {
    return new RoundData(inputString);
  }
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.currentReader = input ? input.currentReader : null;
    this.dealTo = input ? input.dealTo : [];
    this.isRoundForcedEnd = input ? input.isRoundForcedEnd : false;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

