import { Hub } from '@aws-amplify/core';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { globalUiChannel } from '../../appSupport/GlobalConstants';
import SimpleMenu from './SimpleMenu';

const classes = {
  appBar: 'PlainHeader-appBar',
  toolBar: 'PlainHeader-toolBar',
  iconButton: 'PlainHeader-iconButton'
}

export default function PlainHeader(props) {

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  // NOTE: menuActions is deprecated
  const { title, tools, menuActions, menuActionItems, ...rest } = props;

  React.useEffect(() => {
    function handleGlobalUi(event) {
      if (event.payload.closeAppMenu) {
        setMenuAnchor(null);
      }
    }
    Hub.listen(globalUiChannel, handleGlobalUi);
    return () => {
      Hub.remove(globalUiChannel, handleGlobalUi);
    }
  }, []);

  const handleMenuClose = () => setMenuAnchor(null);
  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);

  const showMenuDeprecated = menuActions && menuActions.length;
  const showMenu = menuActionItems && menuActionItems.length;
  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position="static" {...rest}>
        <Toolbar className={classes.toolBar} >
          {(showMenu || showMenuDeprecated) ?
            <IconButton className={classes.iconButton}
              style={{ edge: 'start', color: 'inherit' }}
              onClick={handleMenuOpen}
              size="large">
              <MenuIcon />
            </IconButton>
            : null}
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {tools}
        </Toolbar>
      </AppBar>
      {menuActions && <SimpleMenu anchorElement={menuAnchor} onClose={handleMenuClose} actions={menuActions} />}
      {menuActionItems && <SimpleMenu anchorElement={menuAnchor} onClose={handleMenuClose} menuActionItems={menuActionItems} />}
    </React.Fragment>
  );
}

PlainHeader.propTypes = {
  title: PropTypes.string,
  tools: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  menuActions: PropTypes.array,
};

PlainHeader.defaultProps = {
  title: '',
}