import { Hub } from 'aws-amplify';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import ReadyAddPlayers from '../page/addPlayers/ReadyAddPlayers';
import Decks from '../page/decks/Decks';
import Landing from '../page/landing/Landing';
import ReadyNewRound from '../page/newRound/ReadyNewRound';
import ReadyPlay from '../page/play/ReadyPlay';
import ReadyPlayWait from '../page/playWait/ReadyPlayWait';
import ReadyResults from '../page/results/ReadyResults';
import ReadyScore from '../page/score/ReadyScore';
import SupportRequests from '../page/supportRequests/SupportRequests';
import ChangePasswordPrompt from '../prompt/ChangePasswordPrompt';
import DebugPrompt from '../prompt/DebugPrompt';
import SharePrompt from '../prompt/SharePrompt';
import { gamePrefix, gameRoutes, gameRoutesFull } from './gameRoutes';

export const LOG_CHANNEL = 'log_channel';
export const SHARE_CHANNEL = 'share_channel';
export const PASSWORD_CHANNEL = 'password_channel';

export default function GameRouter(props) {
  const [logPromptOpen, setLogPromptOpen] = React.useState(false);
  const [sharePromptOpen, setSharePromptOpen] = React.useState(false);
  const [changePasswordPromptOpen, setChangePasswordPromptOpen] = React.useState(false);
  const { user, originalPathname } = props;
  const i = typeof originalPathname === 'string' ? originalPathname.indexOf('?') : -1;
  const testPath = i === -1 ? originalPathname : originalPathname.substring(0, i);
  const searchParams = i === -1 ? '' : originalPathname.substring(i);
  const isDefaultRoute = !testPath
    || testPath === '/'
    || testPath === gameRoutesFull.loading
    || testPath === gamePrefix
    || testPath === `${gamePrefix}/`;

  const defaultPath = isDefaultRoute ? buildLandingRoute() : originalPathname;

  React.useEffect(() => {
    const cb1 = () => setLogPromptOpen(true);
    Hub.listen(LOG_CHANNEL, cb1);
    const cb2 = () => setSharePromptOpen(true);
    Hub.listen(SHARE_CHANNEL, cb2);
    const cb3 = () => setChangePasswordPromptOpen(true);
    Hub.listen(PASSWORD_CHANNEL, cb3);
    return () => {
      Hub.remove(LOG_CHANNEL, cb1);
      Hub.remove(SHARE_CHANNEL, cb2);
      Hub.remove(PASSWORD_CHANNEL, cb3);
    }
  }, []);

  function buildLandingRoute() {
    if (!originalPathname) return gameRoutes.landing;  // ==> EARLY EXIT
    return gameRoutes.landing + searchParams;
  }

  function handleDebugPromptClose() {
    setLogPromptOpen(false);
  }
  function handleSharePromptClose() {
    setSharePromptOpen(false);
  }
  function handleChangePasswordPromptClose() {
    setChangePasswordPromptOpen(false);
  }

  return (
    <PageContainer>
      <DebugPrompt open={logPromptOpen} onCancel={handleDebugPromptClose} />
      <SharePrompt open={sharePromptOpen} onCancel={handleSharePromptClose} />
      <ChangePasswordPrompt open={changePasswordPromptOpen} onClose={handleChangePasswordPromptClose} />
      <Routes>
        <Route path={gameRoutes.landing} element={<Landing user={user} />} replace={true} />
        <Route path={gameRoutes.decks} element={<Decks />} />
        <Route path={gameRoutes.supportRequests} element={<SupportRequests />} />
        <Route path={gameRoutes.addPlayers} element={<ReadyAddPlayers />} />
        <Route path={gameRoutes.newRound} element={<ReadyNewRound />} />
        <Route path={gameRoutes.play} element={<ReadyPlay />} />
        <Route path={gameRoutes.playWait} element={<ReadyPlayWait />} />
        <Route path={gameRoutes.results} element={<ReadyResults />} />
        <Route path={gameRoutes.score} element={<ReadyScore />} />
        <Route path={'*'} element={<Navigate replace={true} to={defaultPath} />} />
      </Routes>
    </PageContainer>
  );
}
