// NLS-NLS(controller)
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import BoxR from '../components/box/BoxR';
import GameStore from '../db/GameStore';
import PendingPlayerStore from '../db/PendingPlayerStore';
import GameOptions from '../fields/GameOptions';
import GamePlay from '../fields/GamePlay';
import PublishedTo from '../fields/PublishedTo';
import RoundData from '../fields/RoundData';
import PlayerPrompt from '../prompt/PlayerPrompt';

const classes = {
  root: 'PublishControls-root',
  publishButton: 'PublishControls-publishButton',
  button: 'PublishControls-button'
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`& .${classes.publishButton}`]: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.contrastText,
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  }
}));

export default function PublishControls(props) {
  const { game: gameIn, playerResults, sx: sxIn } = props;
  const game = gameIn || GameStore.getGame();
  const [playerPromptOpen, setPlayerPromptOpen] = React.useState(false);

  const gameOptions = game ? new GameOptions(game.gameOptions) : {};
  const gamePlay = game ? new GamePlay(game.gamePlay) : {};
  const roundData = game ? RoundData.fromGame(game) : {};
  const isController = Globals.isGameController(game);
  const isRoundOver = Globals.isRoundOver(game);
  const publishedTo = (game && game.publishedTo) ? new PublishedTo(game.publishedTo) : new PublishedTo();

  const { t } = useTranslation('controller');


  const randomPlayerOrder = () => {
    const ordered = [];
    let i = 0;
    const playerCount = playerResults.length;
    while (i < playerCount) {
      ordered.push(i++);
    }
    return Globals.shuffleArray(ordered);
  }

  const handleShowScores = () => {
    GameStore.showScoreNow();
  }

  const handleCancelPlayerPrompt = () => {
    setPlayerPromptOpen(false);
  }

  const handleSavePlayerPrompt = (players) => {
    if (players.length) {
      publishToPlayers(players);
    }
    setPlayerPromptOpen(false);
  }
  const handlePublishToPlayer = () => {
    setPlayerPromptOpen(true);
  }

  const handlePublishToAll = () => {
    publishToPlayers([]);
  }

  const publishToPlayers = (players) => {
    publishedTo.players = players;
    if (!gamePlay.anonymousResult) {
      publishedTo.unmasked = []; // If not anonymous, there is nothing to unmask
    }
    // Preserve a previous random ordering if it exists
    if (gameOptions.isRandomResult) {
      if (!publishedTo.randomResultOrder) {
        publishedTo.randomResultOrder = randomPlayerOrder();
      }
    } else {
      publishedTo.randomResultOrder = null;
    }
    GameStore.updatePublishedTo(publishedTo);
  }

  if (!isController || !isRoundOver) return null;

  let playerPromptTitle = '';
  let selectedPlayerNames = [];
  if (playerPromptOpen) {
    selectedPlayerNames = publishedTo.players;
    playerPromptTitle = t('select-player-to-see-results');
  }
  const allPlayerNames = PendingPlayerStore.getPlayerNames();
  return (
    <Root className={classes.root} sx={sxIn}>
      <PlayerPrompt open={Boolean(playerPromptOpen)} onCancel={handleCancelPlayerPrompt}
        onSave={handleSavePlayerPrompt} allPlayerNames={allPlayerNames}
        selectedPlayerNames={selectedPlayerNames} title={playerPromptTitle}
        allowMultiSelect={true} currentReader={roundData.currentReader} />
      <BoxR style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <Typography>Publish to:</Typography>
        <Button className={classes.publishButton} color='secondary' variant="contained" onClick={handlePublishToAll} >
          {t('all')}
        </Button>
        <Button className={classes.publishButton} color='secondary' variant="contained" onClick={handlePublishToPlayer} >
          {t('player')}
        </Button>
        {gameOptions.keepScore ?
          < Button className={classes.publishButton} color='secondary' variant="contained" onClick={handleShowScores} >
            {t('scores')}
          </Button>
          : null}
      </BoxR>
    </Root >
  );
}
