import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import SequenceTitle from '../siteComponents/SequenceTitle';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';

const gameAnswersFactCrap = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameAnswersFactCrap.png';
const gameAnswersThings = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameAnswersThings.png';

export default function FlowScoreRound(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <SiteSection>
      <SequenceTitle sequence='5' title={t('Score the Round')} />
      <div>
        <Asset src={gameAnswersFactCrap} mobile={mobile} />
        <StyleParagraph>{t("Games that you play with buttons, like 'Fact or Crap', can be automatically scored.  A reader is assigned for each round and the game knows the readers answer is correct.")}</StyleParagraph>
        <Asset src={gameAnswersThings} mobile={mobile} />
        <StyleParagraph>{t("Games where you select your favorite answer, like 'Things', can be automatically scored also. Each player types in their answer and then each player selects their favorite answer.")}</StyleParagraph>
        <StyleParagraph>{t('If you have enough players, you can allow users to select their favorite 2 or 3 answers and assign more points for their first pick.')}</StyleParagraph>
        <StyleParagraph>{t('Many games just require manual scoring.  The game controller can manually assign points for each player. This can happen publicly with discussion or on a private screen only the game controller sees.')}</StyleParagraph>
      </div>
    </SiteSection>
  );
}
