// NLS-NLS(player)
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DeleteTool(props) {
  const { onDelete } = props;
  const { t } = useTranslation('player');

  return (
    <Tooltip title={t('delete')} key='delete'>
      <IconButton onClick={onDelete} size="large">
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
