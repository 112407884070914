import clsx from 'clsx';
import React from 'react';
import Clear from './Clear';

export default function SiteSection(props) {
  const { children, classes: classesIn = {}, className: classNameIn, ...rest } = props;
  return (
    <React.Fragment>
      <Clear />
      <div className={clsx(classesIn.root, classNameIn)} {...rest} >
        {children}
      </div >
    </React.Fragment>
  );
}
