import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import ReadersAnswerCorrectHelp from '../components/help/ReadersAnswerCorrectHelp';
import ValuesButtonHelp from '../components/help/ValuesButtonHelp';
import Selections from '../components/Selections';
import DeckOptions from '../fields/DeckOptions';
import GameOptions from '../fields/GameOptions';
import DeckOptionsPrompt from './DeckOptionsPrompt';
import ErrorsExistPrompt from './ErrorsExistPrompt';
import PromptMessage from './PromptMessage';
import ScoreOptionsPrompt from './ScoreOptionsPrompt';
import SelectOptionsPrompt from './SelectOptionsPrompt';

const classes = {
  iconButton: 'GameOptionsPrompt-settings',
};

const sxIndentedOnce = { ml: 2 };
const sxIndentedTwice = { ml: 4 };
const Root = styled('div')(({ theme }) => ({
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.iconButton}`]: {
    edge: 'start',
    color: theme.palette.secondary.main
  },
}));

const EMPTY_STAGED_VALUES = {};

const ID_NUMBER_OF_ANSWERS = 'numberOfAnswers';
const ID_TIME_LIMIT = 'timeLimit';
const ID_MANUAL_TIMER_CB = 'manualTimerStart';
const ID_VALUE_BUTTONS = 'valueButtons';
const ID_READER_CB = 'readerPerRound';
const ID_READER_CORRECT_CB = 'readersAnswerIsCorrect';
const ID_SCORE_CB = 'keepScore';
const ID_RANDOM_CB = 'isRandomResult';
const ID_ANSWER_SELECTION_CB = 'isAnswerSelectable';
const ID_ANSWER_NOTES_CB = 'isAnswerNotes';
const ID_DECKS_USED_CB = 'useDecksCB';
const checkboxControls = [ID_MANUAL_TIMER_CB, ID_READER_CB, ID_READER_CORRECT_CB, ID_SCORE_CB,
  ID_RANDOM_CB, ID_ANSWER_SELECTION_CB, ID_ANSWER_NOTES_CB, ID_DECKS_USED_CB];

const toGameOptions = (gameOptionsIn, stagedValues) => {
  const scrub = (input) => {
    return input ? input.replace(/"/g, "'") : undefined; // Remove any quotes tha would mess up the JSON storage
  }
  const gameOptions = gameOptionsIn;
  gameOptions[ID_TIME_LIMIT] = stagedValues[ID_TIME_LIMIT];
  gameOptions[ID_NUMBER_OF_ANSWERS] = parseFloat(stagedValues[ID_NUMBER_OF_ANSWERS]);
  gameOptions[ID_MANUAL_TIMER_CB] = stagedValues[ID_MANUAL_TIMER_CB];
  gameOptions[ID_VALUE_BUTTONS] = scrub(stagedValues[ID_VALUE_BUTTONS]);
  gameOptions[ID_SCORE_CB] = stagedValues[ID_SCORE_CB];
  gameOptions[ID_RANDOM_CB] = stagedValues[ID_RANDOM_CB];
  gameOptions[ID_ANSWER_SELECTION_CB] = stagedValues[ID_ANSWER_SELECTION_CB];
  gameOptions[ID_ANSWER_NOTES_CB] = stagedValues[ID_ANSWER_NOTES_CB];
  gameOptions[ID_READER_CB] = stagedValues[ID_READER_CB];
  gameOptions[ID_READER_CORRECT_CB] = stagedValues[ID_READER_CORRECT_CB];
}
const fromGameOptions = (gameOptions, deckOptions) => {
  const result = {};
  result[ID_TIME_LIMIT] = gameOptions[ID_TIME_LIMIT];
  result[ID_NUMBER_OF_ANSWERS] = gameOptions[ID_NUMBER_OF_ANSWERS];
  result[ID_MANUAL_TIMER_CB] = gameOptions[ID_MANUAL_TIMER_CB];
  result[ID_VALUE_BUTTONS] = gameOptions[ID_VALUE_BUTTONS] || '';
  result[ID_SCORE_CB] = gameOptions[ID_SCORE_CB];
  result[ID_RANDOM_CB] = gameOptions[ID_RANDOM_CB];
  result[ID_ANSWER_SELECTION_CB] = gameOptions[ID_ANSWER_SELECTION_CB];
  result[ID_ANSWER_NOTES_CB] = gameOptions[ID_ANSWER_NOTES_CB];
  result[ID_READER_CB] = gameOptions[ID_READER_CB];
  result[ID_READER_CORRECT_CB] = gameOptions[ID_READER_CORRECT_CB];
  result[ID_DECKS_USED_CB] = !!deckOptions.decksUsed.length;
  return result;
}

export default function GameOptionsPrompt(props) {

  const { open, onCancel, onSave, onSaveScore, onSaveDeck, game } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [prevOpenState, setPrevOpenState] = React.useState(false);
  const [isScoreOptionsOpen, setIsScoreOptionsOpen] = React.useState(false);
  const [isSelectOptionsOpen, setIsSelectOptionsOpen] = React.useState(false);
  const [isDeckOptionsPromptOpen, setIsDeckOptionsPromptOpen] = React.useState(false);
  const [decksUsedErrorText, setDecksUsedErrorText] = React.useState('');
  const [readerCorrectErrorText, setReaderCorrectErrorText] = React.useState('');
  const [errorsExistPromptOpen, setErrorsExistPromptOpen] = React.useState(false);
  const [errorsExist, setErrorsExist] = React.useState(false);
  const { t } = useTranslation('controller');


  const gameOptions = (game && game.gameOptions) ? new GameOptions(game.gameOptions) : new GameOptions();
  const deckOptions = (game && game.gameOptions) ? new DeckOptions(game.deckOptions) : new DeckOptions();

  if (open && !prevOpenState) {
    setStagedValues(fromGameOptions(gameOptions, deckOptions));
    setPrevOpenState(true);
  }
  const clearValidations = () => {
    setDecksUsedErrorText('');
    setReaderCorrectErrorText('');
    setErrorsExist(false);
    setErrorsExistPromptOpen(false);
  }
  const validations = {
    validateDecks: (newStaged) => {
      if (newStaged[ID_DECKS_USED_CB] && !deckOptions.decksUsed.length) {
        setDecksUsedErrorText(t('you-must-select-at-least-one-d2'));
        return true;
      }
      return false;
    },
    validateReaderCorrect: (newStaged) => {
      const valueButtonsArray = Globals.getValueButtonsArray(stagedValues[ID_VALUE_BUTTONS]);
      if (newStaged[ID_READER_CORRECT_CB] && valueButtonsArray.length < 2) {
        setReaderCorrectErrorText(t('to-specify-the-readers-answer-'));
        return true;
      }
      return false;
    },

    performValidate: () => {
      clearValidations();
      return validations.validateDecks(stagedValues)
        || validations.validateReaderCorrect(stagedValues);
    }
  }

  function handleErrorsExistPromptClose() {
    setErrorsExistPromptOpen(false);
  }

  const handleCancel = () => {
    setPrevOpenState(false);
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!stagedValues[ID_NUMBER_OF_ANSWERS]) {
      stagedValues[ID_NUMBER_OF_ANSWERS] = 1;
    }
    if (validations.performValidate()) {
      setErrorsExistPromptOpen(true);
      setErrorsExist(true);
    } else {
      toGameOptions(gameOptions, stagedValues);
      if (!stagedValues[ID_DECKS_USED_CB]) { // Users can select decks and toggle the use decks CB with no penalty
        deckOptions.decksUsed = [];          // But when they save, their deck selections are lost
      }
      onSave({ gameOptions, deckOptions });
      clearValidations();
      setPrevOpenState(false);
    }
  }
  const handleSaveDeckOptions = (deckOptions) => {
    onSaveDeck(deckOptions);
    clearValidations();
    setIsDeckOptionsPromptOpen(false);
  }
  const handleSaveScoreOptions = (scoreOptions) => {
    onSaveScore(scoreOptions);
    setIsScoreOptionsOpen(false);
  }
  const handleCancelScoreOptions = (scoreOptions) => {
    setIsScoreOptionsOpen(false);
  }
  const handleDeckCBValueChange = (event) => {
    if (Globals.isDefaultUser()) {
      setDecksUsedErrorText(t('you-must-sign-in-with-a-specif'));
    } else {
      handleValueChange(event);
    }

  }
  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    // event.stopPropagation();
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    if (id === ID_TIME_LIMIT && value === '0') {
      newStaged[id] = null;
    }
    setStagedValues(newStaged);
  }

  const handleScoreSettings = () => {
    setIsScoreOptionsOpen(true);
  }
  const handleSelectSettings = () => {
    setIsSelectOptionsOpen(true);
  }
  const handleDeckSettings = () => {
    setIsDeckOptionsPromptOpen(true);
  }
  function handleDeckSelectionCancel() {
    setIsDeckOptionsPromptOpen(false);
  }
  const noError = '';
  if (!open) return null;
  const disableReaderCorrectCB = !stagedValues[ID_READER_CB];
  const selectedDecks = DeckOptions.asDecks(deckOptions.decksUsed);
  const localGameOptions = new GameOptions();
  toGameOptions(localGameOptions, stagedValues);
  return (
    <Root>
      <ScoreOptionsPrompt open={isScoreOptionsOpen} onSave={handleSaveScoreOptions}
        onCancel={handleCancelScoreOptions} game={game} gameOptions={localGameOptions} />
      <DeckOptionsPrompt open={isDeckOptionsPromptOpen} onSave={handleSaveDeckOptions} onCancel={handleDeckSelectionCancel}
        game={game} />
      <ErrorsExistPrompt open={errorsExistPromptOpen} onClose={handleErrorsExistPromptClose} />
      <SelectOptionsPrompt open={isSelectOptionsOpen} onClose={f => setIsSelectOptionsOpen(false)}
        selectOptionsJSON={gameOptions.selectOptions} game={game} />
      <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{t('game-options')}</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <TextField style={{ flexGrow: 1 }} autoFocus onChange={handleValueChange} margin='normal'
              id={ID_VALUE_BUTTONS} label={t('values-buttons-comma-separated')} type="text" defaultValue={stagedValues[ID_VALUE_BUTTONS]} />
            <ValuesButtonHelp />
          </FormGroup>

          <FormGroup row>
            <TextField fullWidth onChange={handleValueChange} disabled={!!stagedValues[ID_VALUE_BUTTONS]} margin='normal'
              id={ID_NUMBER_OF_ANSWERS} label={t('number-of-answer-fields')} type="number" defaultValue={stagedValues[ID_NUMBER_OF_ANSWERS]}
              InputProps={{
                inputProps: {
                  max: 20, min: 1
                }
              }} />
          </FormGroup>
          <FormGroup row>
            <TextField style={{ minWidth: 190, marginRight: 20 }} onChange={handleValueChange} error={Boolean(noError)}
              id={ID_TIME_LIMIT} label={t('time-limit-seconds')} type="number" defaultValue={stagedValues[ID_TIME_LIMIT]} margin='normal'
              InputProps={{
                inputProps: {
                  max: 600, min: 0
                }
              }} />
            <FormControlLabel
              control={<Checkbox disabled={!stagedValues[ID_TIME_LIMIT]} checked={stagedValues[ID_MANUAL_TIMER_CB]} onChange={handleValueChange} id={ID_MANUAL_TIMER_CB} />}
              label={t('manual-start')}
            />
          </FormGroup>

          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_SCORE_CB]} onChange={handleValueChange} id={ID_SCORE_CB} />}
              label={t('keep-score')}
            />
            <StyledIconButton
              className={classes.iconButton}
              onClick={handleScoreSettings}
              disabled={!stagedValues[ID_SCORE_CB]}
              size="large">
              <SettingsIcon />
            </StyledIconButton>
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_RANDOM_CB]} onChange={handleValueChange} id={ID_RANDOM_CB} />}
              label={t('publish-to-random-order')}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_ANSWER_SELECTION_CB]} onChange={handleValueChange} id={ID_ANSWER_SELECTION_CB} />}
              label={t('allow-users-to-select-an-answe')}
            />
            <StyledIconButton
              className={classes.iconButton}
              onClick={handleSelectSettings}
              disabled={!stagedValues[ID_ANSWER_SELECTION_CB]}
              size="large">
              <SettingsIcon />
            </StyledIconButton>
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_ANSWER_NOTES_CB]} onChange={handleValueChange} id={ID_ANSWER_NOTES_CB} />}
              label={t('allow-users-to-notate-answers')}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_READER_CB]} onChange={handleValueChange} id={ID_READER_CB} />}
              label={t('reader-is-assigned-for-each-ro')}
            />
          </FormGroup>
          <FormGroup row >
            <BoxR>
              <FormControlLabel sx={sxIndentedOnce}
                control={<Checkbox checked={stagedValues[ID_READER_CORRECT_CB]} onChange={handleValueChange} id={ID_READER_CORRECT_CB} />}
                label={t('readers-answer-is-correct')} disabled={disableReaderCorrectCB}
              />
              <ReadersAnswerCorrectHelp />
            </BoxR>
            <PromptMessage errorText={readerCorrectErrorText} sx={sxIndentedTwice} />
          </FormGroup>
          <FormGroup row >
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_DECKS_USED_CB]} onChange={handleDeckCBValueChange} id={ID_DECKS_USED_CB} />}
              label={t('use-card-decks')}
            />
            <StyledIconButton
              className={classes.iconButton}
              onClick={handleDeckSettings}
              disabled={!stagedValues[ID_DECKS_USED_CB]}
              size="large">
              <SettingsIcon />
            </StyledIconButton>
          </FormGroup>
          <PromptMessage errorText={decksUsedErrorText} sx={sxIndentedOnce} />
          {stagedValues[ID_DECKS_USED_CB] ?
            <Selections names={selectedDecks.map(m => m.name)} />
            : null}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
            <PromptMessage errorText={errorsExist && t('continue')} plainText />
            {!errorsExist && t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}