import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import DeckStore, { useFreshDecks } from '../../db/DeckStore';
import { gameRoutesFull } from '../../routing/gameRoutes';
import TabbedPage from '../TabbedPage';
import DecksContent from './DecksContent';

export default function Decks() {
  const [isDecksLoading, setIsDecksLoading] = React.useState(true);
  const { t } = useTranslation('player');
  const decks = useFreshDecks();
  const tabTitle = t('Bueno Fun - Decks');
  React.useEffect(() => {
    DeckStore.initialQueryCompletePromise().then(() => setIsDecksLoading(false));
  }, []);

  const handleFinished = () => {
    Globals.dispatchNavigate(gameRoutesFull.landing, { replace: false });
  }

  return (
    <TabbedPage tabTitle={tabTitle}>
      <DecksContent decks={decks} isDecksLoading={isDecksLoading} onFinished={handleFinished} />
    </TabbedPage>
  );
}
