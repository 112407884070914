import React from 'react';
import { useTranslation } from 'react-i18next';
import { youTubeOverview } from '../../appSupport/GlobalConstants';
import PathUtil from '../../appSupport/PathUtil';
import Asset from '../siteComponents/Asset';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const sxAlignText = {  // Using a BoxR made text fontSize off?
  display: 'inline',
  textAlign: 'center',
  verticalAlign: 'middle',
};

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/yt/shallowDive.png';
const videoCall = 'https://remote-game-image-assets.s3.amazonaws.com/site/videoCall.jpg';

export default function DescFaqWhatIs({ mobile = false }) {
  const { t } = useTranslation('site');

  function handleShowVideo() {
    PathUtil.navigateExternal(youTubeOverview);
  }
  return (
    <React.Fragment>
      <Asset src={videoCall} mobile={mobile} />
      <StyleTopic>{t('What is BuenoFun?')}</StyleTopic>
      <StyleParagraph>{t("Ever tried to play a game on a video call? It's great to see everybody on the call, but it can be a frustrating experience to try and play a game. BuenoFun makes it closer to a kitchen table experience by coordinating player answers and scoring.")}</StyleParagraph>
      <StyleParagraph>{t('Have up to 20 players join a game and use their phone, tablet or computer to share the experience. Works in person or over a video call.')}</StyleParagraph>
      <div style={{ overflow: 'auto' }}>
        <StyleParagraph sx={sxAlignText}>{t('Here is a 2 minute overview')}</StyleParagraph>
        <Asset sx={sxAlignText} src={imageUrl} mobile={mobile} float={false} onClick={handleShowVideo} size={150} />
      </div>
      <br />
      <br />
    </React.Fragment>
  );
}
