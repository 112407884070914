import SelectOptions from "./SelectOptions";

export default class GameOptions {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.keepScore = input ? input.keepScore : false;
    this.numberOfAnswers = input ? input.numberOfAnswers :  1;
    this.timeLimit = input ? input.timeLimit :  null;
    this.manualTimerStart = input ? input.manualTimerStart : false;
    this.valueButtons = input ? input.valueButtons : '';
    this.isRandomResult = input ? input.isRandomResult : false;
    this.isAnswerSelectable = input ? input.isAnswerSelectable : false;
    this.isAnswerNotes = input ? input.isAnswerNotes : false;
    this.readerPerRound = input ? input.readerPerRound : false;
    this.readersAnswerIsCorrect = input ? input.readersAnswerIsCorrect : false;
    this.selectOptions = input ? input.selectOptions : (new SelectOptions()).asJSON();
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

