import DeckStore from "../db/DeckStore";

export default class DeckOptions {
  static asDecks(deckIds) {
    const decksById = {};
    DeckStore.getRecords().forEach(e=> decksById[e.id] = e);
    return deckIds.map(m=>decksById[m]).filter(f=>f!==undefined);
  }
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.decksUsed = input ? input.decksUsed : [];
    this.cardsExpirePerRound = input ? input.cardsExpirePerRound : true;
    this.dealSameCardToMultiplePlayers = input ? input.dealSameCardToMultiplePlayers : false;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

