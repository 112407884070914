import React, { useState } from 'react';
import DeckStore from '../db/DeckStore';

export function useMonitorDeck(deckId) {
  const [deck, setDeck] = useState(null);

  React.useEffect(() => {
    const handleUpdate = (event) => {
      const newDeck = DeckStore.deckFor(deckId);
      setDeck(newDeck);
    }
    if (!deckId) {
      setDeck(null);
      return undefined; // ==> EARLY EXIT
    }
    const newDeck = DeckStore.deckFor(deckId);
    setDeck(newDeck);
    DeckStore.listen(handleUpdate);
    return () => {
      DeckStore.stopListen(handleUpdate)
    }
  }, [deckId]);

  return deck;
}