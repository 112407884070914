// NLS-NLS(controller)
import { IconButton, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AddTool(props) {
  const { onAdd } = props;
  const { t } = useTranslation('controller');

  return (
    <Tooltip title={t('add')} key='add'>
      <IconButton onClick={onAdd} size="large">
        <CreateIcon />
      </IconButton>
    </Tooltip>
  );
}
