import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/things.jpg';
const gameUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/thingsGame.png';

export default function Things({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset right src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Things')}</StyleTopic>
      <StyleParagraph>{t("In this hilarious party game, read a funny topic such as “THINGS. . . you shouldn’t do when you’re naked.” Players write their responses which could be “fly a kite”, “jumping jacks” or “take out the garbage”. There are no wrong answers. The goal of The Game of THINGS. . . is to guess who provided which response.")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("A great BuenoFun variation for this game is to allow everybody to choose their favorite answer. Let one player read the answers to the group, and then let all players choose their favorite answer.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Select "Keep Score"\n...Select "Publish to random order"\n...Select "Allow users to select an answer"\nScore Settings\n...Select "Auto scoring on Answer Selections"')} />
      <Asset open={!compact} src={gameUrl} left mobile={mobile} />
    </SiteSection>
  );
}
