// NLS-NLS(player)
import { CircularProgress, Typography } from '@mui/material';
import { Hub } from 'aws-amplify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderMenu from '../appSupport/HeaderMenu';
import PathUtil from '../appSupport/PathUtil';
import BoxCG from '../components/box/BoxCG';
import BoxR from '../components/box/BoxR';
import PlainFooter from '../components/header/PlainFooter';
import PlainHeader from '../components/header/PlainHeader';
import PageLayout from '../components/PageLayout';
import RefreshTool from '../components/tools/RefreshTool';
import DebugPrompt from '../prompt/DebugPrompt';
import { LOG_CHANNEL } from '../routing/GameRouter';
import ReloadIfReady from './ReloadIfReady';

export default function ErrorView(props) {
  const { errorText, reloadNow } = props;
  const [logPromptOpen, setLogPromptOpen] = React.useState(false);
  const { t } = useTranslation('player');

  const TITLE_ERROR = t('errors');
  const REFRESH_TEXT = t('refresh-this-page-to-try-again');
  const AUTO_RELOAD_TEXT = t('Please wait. Refresh in progress.');

  React.useEffect(() => {
    const cb = () => setLogPromptOpen(true);
    Hub.listen(LOG_CHANNEL, cb);
    return () => Hub.remove(LOG_CHANNEL, cb);
  }, []);

  const handleRefresh = () => {
    PathUtil.reloadPage();
  }
  const handleDebugPromptClose = () => {
    setLogPromptOpen(false);
  }

  function getMainText() {
    return reloadNow ? AUTO_RELOAD_TEXT : REFRESH_TEXT;
  }

  const title = TITLE_ERROR;
  const tools = [];
  tools.push(<RefreshTool key="refresh" onClick={handleRefresh} />);

  return (
    <React.Fragment>
      <BoxCG >
        <DebugPrompt open={logPromptOpen} onCancel={handleDebugPromptClose} />
        <ReloadIfReady reloadNow={reloadNow} />
        <PlainHeader title={title} tools={tools} menuActions={HeaderMenu.getPlayActions()} />
        <PageLayout>
          {errorText ? <div>{errorText}</div> : null}
          <BoxR>
            {reloadNow ? <CircularProgress color='primary' size={20} /> : null}
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', ml: 2, mr: 2 }} >{getMainText()}</Typography>
            {reloadNow ? <CircularProgress color='primary' size={20} /> : null}
          </BoxR>
        </PageLayout>
        <PlainFooter />
      </BoxCG>
    </React.Fragment>
  );
}

