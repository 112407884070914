// NLS-NLS(controller)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsStore from '../../appSupport/AnalyticsStore';
import GameStore from '../../db/GameStore';
import ScoreStore from '../../db/ScoreStore';
import GameOptions from '../../fields/GameOptions';
import ScoreOptions from '../../fields/ScoreOptions';

const classes = {
  root: 'NewRoundButton-root'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
  }
}));

export default function NewRoundButton(props) {
  const { open, sx: sxIn } = props;

  const game = GameStore.getGame();
  const gameOptions = new GameOptions(game.gameOptions);
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const scoreRecords = ScoreStore.getRecords().filter(f => f.hand === game.currentHand);
  const { t } = useTranslation('controller');

  function noScoreActivity() {
    return scoreOptions.scoreOnly && !scoreRecords.length;
  }
  function handleNewRound() {
    AnalyticsStore.recordNewRound(game.currentHand + 1);
    if (scoreOptions.scoreOnly) {
      GameStore.startNewRound();
    } else if (gameOptions.keepScore && scoreOptions.showScoreBeforeNewRound) {
      GameStore.showScoreThenNewRound(); // New round will be started at close of score
    } else {
      GameStore.navigateNewRound();
    }
  }

  if (!open) return null; // ==> EARLY EXIT
  return (
    <StyledButton className={classes.root} sx={sxIn} color='primary' variant="contained" onClick={handleNewRound} disabled={noScoreActivity()}>
      {t('new-round')}
    </StyledButton>
  );
}

NewRoundButton.defaultProps = {
  open: true,
}
