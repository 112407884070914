import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTipKeepScore from '../siteComponents/SetupTipKeepScore';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/familyFeud.jpg';

export default function FamilyFeud({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Family Feud')}</StyleTopic>
      <StyleParagraph>{t("Survey says.it’s time to play Platinum Family Feud! Bring Family Feud home and put your family and friends to the test. Based on a national survey, the Family Feud team placed the top answers on the Face Off cards. Can you name the #1 way to wake someone up in the morning that would be really mean? How about the top talent people wish they had? ")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("A great BuenoFun variation for this game is to allow everybody to enter a single answer. Then award points based on the answer card.")}</StyleParagraph>
      <SetupTipKeepScore open={!compact} />
    </SiteSection>
  );
}
