import Globals from "../../appSupport/Globals";

export const AUTHOR_CLIENT = 'AUTHOR_CLIENT';
export const AUTHOR_SUPPORT = 'AUTHOR_SUPPORT';
const getDialogEntryAuthor = () => Globals.isProductSupporter() ? AUTHOR_SUPPORT : AUTHOR_CLIENT;
export default class SRDialogEntry {
  static newComment(comment) {
    const result = new SRDialogEntry();
    result.time = new Date().toISOString();
    result.author = getDialogEntryAuthor();
    result.comment = comment;
    return result;
  };
  constructor(jsonOrObjectInput) {
    const input = typeof jsonOrObjectInput === 'string' ? JSON.parse(jsonOrObjectInput) : jsonOrObjectInput;
    this.time = input ? input.time : null;
    this.author = input ? input.author : null;
    this.comment = input ? input.comment : null;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

