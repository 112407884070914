import { styled } from '@mui/material/styles';
import { Hidden } from '@mui/material';
import React from 'react';
import Globals from '../../appSupport/Globals';
import BoxC from '../box/BoxC';
import DividerH from '../DividerH';
import PlayerName, { PLAY_WAIT_LIST } from '../PlayerName';
import TableList from '../TableList';
import RowCell from './RowCell';

const classes = {
  root: 'PlayWaitTableList-root',
  isMe: 'PlayWaitTableList-isMe',
  notMe: 'PlayWaitTableList-notMe'
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.isMe}`]: {
    backgroundColor: theme.palette.playerWaitingBackground.me,
  },
  [`& .${classes.notMe}`]: {}
}));

export function createTableData(id, playerName, isCurrentReader, timeDelta) {
  return { id, playerName, isCurrentReader, timeDelta };
}
const loadingOrNoData = f => null;

export default function PlayWaitTableList(props) {
  const { rows, color, ...rest } = props;
  const playerName = Globals.getPlayerName();
  const tools = [];
  const fullTableProps = {
    rows, rowRenderer,
    dense: true,
    hidePaper: true,
    selectedRows: [],
    disableFlexSizing: true,
    noDataRenderer: loadingOrNoData,
    oneSelectedTools: tools,
    moreSelectedTools: tools,
    noneSelectedTools: tools,
  };
  const mobileTableProps = { ...fullTableProps }

  function rowRenderer(row, rows) {
    const isLastRow = row === rows[rows.length - 1];
    const isMe = row.playerName === playerName;
    return (
      <RowCell className={isMe ? classes.isMe : classes.notMe} showDivider={!isLastRow} >
        <BoxC style={{ alignItems: 'center' }}>
          <PlayerName playerName={row.playerName} size={PLAY_WAIT_LIST} timeDelta={row.timeDelta}
            isReader={row.isCurrentReader} color={color} isMe={isMe} />
          {isLastRow ? null : <DividerH />}
        </BoxC>

      </RowCell>
    );
  }

  return (
    <Root className={classes.root} {...rest}>
      <Hidden smDown>
        <TableList {...fullTableProps} />
      </Hidden>
      <Hidden smUp>
        <TableList {...mobileTableProps} />
      </Hidden>
    </Root>
  );
}
