import { styled } from '@mui/material/styles';
import { DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import Globals from '../appSupport/Globals';
import { useMonitorDeck } from '../appSupport/monitorDeck';
import PdfImageBuilder from '../appSupport/PdfImageBuilder';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import EditCardHelp from '../components/help/EditCardHelp';
import UploadButton from '../components/UploadButton';
import CardStoreStorage from '../db/CardStoreStorage';
import DeckStore, { useFreshDecks } from '../db/DeckStore';
import AddImageCardPrompt from './AddImageCardPrompt';
import ProgressPrompt from './ProgressPrompt';
import PromptMessage from './PromptMessage';

const classes = {
  instructions: 'DeckPrompt-instructions',
  errorText: 'DeckPrompt-errorText',
  cardButton: 'DeckPrompt-cardButton'
};

const sxButton = { mr: 1 };

const Root = styled('div')(({ theme }) => ({
}));
const InstructionText = styled(DialogContentText)(({ theme }) => ({
  [`&.${classes.instructions}`]: {
    marginTop: theme.spacing(2),
  },
}));
const ErrorText = styled(DialogContentText)(({ theme }) => ({
  [`&.${classes.errorText}`]: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));


const PDF_PAGE_SIZE = 600;
const ID_NAME = 'deckName';
const ID_SORT_SEQUENCE = 'sortSequence';
const EMPTY_STAGED_VALUES = { [ID_NAME]: '', [ID_SORT_SEQUENCE]: '' };

export default function DeckPrompt(props) {
  const { open, onClose, deckIdToEdit } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [nameErrorText, setNameErrorText] = React.useState('');
  const [cardErrorText, setCardErrorText] = React.useState(null);
  const [isAddImageCardPromptOpen, setIsAddImageCardPromptOpen] = React.useState(false);
  const [progressPrompt, setProgressPrompt] = React.useState({ open: false });

  const decks = useFreshDecks();
  const deckToEdit = useMonitorDeck(deckIdToEdit);
  const { t } = useTranslation('controller');

  React.useEffect(() => {
    if (open) { // Set the deckToEdit and the stagedValues
      if (deckToEdit) {
        const stagedValues = { [ID_NAME]: deckToEdit.name, [ID_SORT_SEQUENCE]: deckToEdit.sortSequence };
        setStagedValues(stagedValues);
      } else {
        setStagedValues(EMPTY_STAGED_VALUES);
      }
    }
  }, [open, deckToEdit]);

  const clearValidations = () => {
    setNameErrorText(null);
  }
  const validations = {
    validateName: (newStaged) => {
      const value = newStaged[ID_NAME] || '';
      if (value.length === 0) {
        setNameErrorText(t('the-name-may-not-be-blank'));
        return true;
      }
      const existing = decks.find(f => f.name === value);
      if (existing && (!deckToEdit || (existing.id !== deckToEdit.id))) {
        setNameErrorText(t('that-name-has-already-been-use'));
        return true;
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      return validations.validateName(stagedValues);
    }
  }
  function handleAddPdfCard(e) {
    setCardErrorText(null);
    const files = Array.from(e.target.files);
    let errorText = '';
    let errorCount = 0;
    // Validate the selected files for content type (file extension).
    files.forEach(file => {
      const contentType = file.type;
      if (errorCount < 1) {
        const et = UiUtil.validatePdfContentType(contentType, file.name);
        if (et) {
          if (errorCount) errorText += '/n';
          errorText += UiUtil.validatePdfContentType(contentType, file.name);
          errorCount++;
        }
      }
    });
    if (errorText) {
      // if (errorText > 1) errorText = 'Multiple files are not PDF files.\n' + errorText;
      setCardErrorText(errorText);
    } else {
      const fileExtension = files[0].name.split('.').pop();
      const contentType = files[0].type;
      saveFiles(files, fileExtension, contentType);
    }
  }
  function handleSaveAddImageCardPrompt(addedPages) {
    // Knit the added pages into one PDF card
    setCardErrorText(null);
    const pdf = PdfImageBuilder.buildWith(addedPages);
    saveFile(pdf.output('blob'), 'pdf', 'application/pdf');
    setIsAddImageCardPromptOpen(false);
  }

  function saveFile(file, fileExtension, contentType) {
    const fileName = `${uuidV4()}.${fileExtension}`;
    return CardStoreStorage.put(null, deckToEdit.id, fileName, file, { contentType })
      .then(result => {
        DeckStore.addCard(deckToEdit.id, fileName);
        // CardStoreStorage.get(null, deckIdToEdit, fileName)
        //   .then(result => console.log(result));
      })
      .catch(err => Globals.dispatchUserError(err));
  }
  async function saveFiles(files, fileExtension, contentType) {
    let workingOn = 1;
    let newProgressPrompt = { ...progressPrompt };
    newProgressPrompt.open = true;
    newProgressPrompt.statusText = t('x-of-y', { x: workingOn, y: files.length });
    newProgressPrompt.value = 0;
    setProgressPrompt(newProgressPrompt);
    files.forEach(async (e, i) => {
      await saveFile(e, fileExtension, contentType);
      workingOn += 1;
      newProgressPrompt = { ...newProgressPrompt };
      newProgressPrompt.open = workingOn < files.length;
      newProgressPrompt.value = (workingOn / files.length) * 100
      newProgressPrompt.statusText = t('x-of-y', { x: workingOn, y: files.length });
      setProgressPrompt(newProgressPrompt);
    });
  }
  function handleShuffleCards() {
    // CardStoreStorage.list(null, "c09bce78-0ac1-4f79-b75d-a71cc42bd313");
    setCardErrorText(null);
    DeckStore.updateDeck(deckToEdit.id, { nextCardIndex: 0, cardKeys: Globals.shuffleArray(deckToEdit.cardKeys) });
  }
  const handleCancel = () => {
    setStagedValues(EMPTY_STAGED_VALUES);
    clearValidations();
    onClose();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      let newId;
      const name = stagedValues[ID_NAME];
      const sortSequence = stagedValues[ID_SORT_SEQUENCE];
      if (deckToEdit) {
        DeckStore.updateDeck(deckToEdit.id, { name, sortSequence });
      } else {
        newId = uuidV4();
        DeckStore.createDeck(newId, name, sortSequence);
      }
      clearValidations();
      setStagedValues(EMPTY_STAGED_VALUES);
      onClose(newId);
    }
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  function handelCancelAddImageCardPrompt() {
    setIsAddImageCardPromptOpen(false);
  }
  function handleAddCardPressed() {
    setIsAddImageCardPromptOpen(true);
  }
  function renderEditControls() {
    const normalDialogText = <InstructionText className={classes.instructions} id="alert-dialog-description">There are <b>{unusedCardCount}</b> unused cards of <b>{totalCardCount}</b> total cards for this deck.</InstructionText>;
    const errorDialogText = <ErrorText className={classes.errorText} id="alert-dialog-description">{cardErrorText}</ErrorText>;
    const dialogText = cardErrorText ? errorDialogText : normalDialogText;
    return (
      <Root>
        {dialogText}
        <BoxR>
          <Button sx={sxButton} onClick={handleAddCardPressed} variant="contained" color="secondary" >
            {t('add-image-card')}
          </Button>
          <UploadButton sx={sxButton} tag="upload-card-pdf" multiple acceptTypes=".pdf" onFileSelect={handleAddPdfCard}>
            {t('add-pdf-card')}
          </UploadButton>
          <Button sx={sxButton} onClick={handleShuffleCards} variant="contained" color="secondary" >
            {t('shuffle-cards')}
          </Button>
        </BoxR>
      </Root>
    );
  }
  const totalCardCount = deckToEdit ? deckToEdit.cardKeys.length : 0;
  const nextCardIndex = deckToEdit ? deckToEdit.nextCardIndex : 0;
  const unusedCardCount = totalCardCount - nextCardIndex;
  if (!open) return null;
  const title = deckToEdit ? t('edit-card-deck') : t('add-card-deck');
  return (
    <React.Fragment>
      <AddImageCardPrompt open={isAddImageCardPromptOpen} onCancel={handelCancelAddImageCardPrompt} onSave={handleSaveAddImageCardPrompt} pageSize={PDF_PAGE_SIZE} />
      <ProgressPrompt open={progressPrompt.open} value={progressPrompt.value} statusText={progressPrompt.statusText} title={t('loading-cards')} />
      <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">
          <BoxR style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            {title}
            <EditCardHelp />
          </BoxR>
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus fullWidth onChange={handleValueChange} error={!!nameErrorText}
            id={ID_NAME} label={t('enter-the-deck-name')} type="text" value={stagedValues[ID_NAME]} margin='normal' />
          <PromptMessage errorText={nameErrorText} />
          <TextField autoFocus fullWidth onChange={handleValueChange}
            id={ID_SORT_SEQUENCE} label={t('sort-sequence')} type="text" value={stagedValues[ID_SORT_SEQUENCE]} margin='normal' />
          {deckToEdit ? renderEditControls() : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
            {deckToEdit ? t('continue') : t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}