export const sitePrefix = '/';
export const sitePrefixGeneric = '/*';
function fullPath(path) {
  return (sitePrefix + path);
}
export const siteRoutes = {
  faq: 'faq/',
  games: 'games/',
  instructions: 'instructions/',
  scanTips: 'scanTips/',
  setupTips: 'setupTips/',
}

export const siteRoutesFull =
  Object.assign({}, ...Object.entries(siteRoutes).map(([key, value]) => {
    return { [key]: fullPath(value) }
  }));
