import { styled } from '@mui/material/styles';
import React from 'react';
import { useFreshCards } from '../db/CardStore';
import DeckStore from '../db/DeckStore';
import GameStore from '../db/GameStore';

const classes = {
  root: 'DealtToStatus-root',
  deckName: 'DealtToStatus-deckName',
  playerName: 'DealtToStatus-playerName'
};

const Root = styled('p')(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontSize: 18
  },
  [`& .${classes.deckName}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.playerName}`]: {
    color: theme.palette.dataText,
    fontWeight: 'initial',
  }
}));

export default function DealtToStatus(props) {
  const game = GameStore.getGame();
  const cards = useFreshCards(false);


  function addPlayer(players, playerName) {
    // If the player already exists, change the name to player(2). Otherwise add player
    let found = false;
    players.forEach((e, i) => {
      if (e === playerName) {
        found = true;
        players[i] = `${playerName}(2)`;
      } else {
        const index1 = e.lastIndexOf('(');
        const index2 = e.lastIndexOf(')');
        const foundName = index1 > 0 ? e.substring(0, index1) : '';
        if (foundName === playerName && (index2 === e.length - 1)
          && ((index1 === e.length - 3) || (index1 === e.length - 4))) { // handle name(2) & name(12)
          const oldCount = parseInt(e.substring(index1 + 1, index2));
          const newCount = oldCount + 1;
          const newName = `${e.substring(0, index1)}(${newCount})`;
          players[i] = newName;
          found = true;
        }
      }
    });
    if (!found) players.push(playerName);
  }
  function renderDealtCards() {
    // Organize cards into { deckName: name, playerNames: [] }
    const nextHand = game.currentHand + 1;
    const organized = {};
    cards.forEach(e => {
      if (e.hand === nextHand) {
        const deckName = DeckStore.deckFor(e.deckId).name;
        const playerNames = organized[deckName] || [];
        addPlayer(playerNames, e.playerName);
        organized[deckName] = playerNames;
      }
    });

    // Push deck names and player names into result.
    const result = [];
    Object.keys(organized).forEach((deckName, i) => {
      const isFirst = i === 0;
      result.push(<b key={`deck-${deckName}`} className={classes.deckName}>{!isFirst ? '  |  ' : ''}{deckName}: </b>);
      const playerNameArray = organized[deckName];
      playerNameArray.forEach((playerName, i2) => {
        const isLast = i2 === playerNameArray.length - 1;
        result.push(<b key={`player-${playerName}`} className={classes.playerName}>{playerName}{!isLast ? ', ' : ''}</b>);
      });
    })
    return result;
  }

  return (
    <Root className={classes.root}>
      {renderDealtCards()}
    </Root>
  );
}
