import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_WAIT_CLIENT } from '../../db/SupportRequestOps';
import { useFreshSupportRequests } from '../../db/SupportRequestStore';
import LandingButton from './LandingButton';


const classes = {
  chipRoot: 'SupportRequestButtonUser-chip-root'
};

const StyledChip = styled(Chip)(({ theme }) => ({
  [`&.${classes.chipRoot}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }
}));

export default function SupportRequestButtonUser(props) {
  const { onClick, ...rest } = props;

  const { t } = useTranslation('player');
  const supportRequests = useFreshSupportRequests();
  const waitingOnClient = !!supportRequests.find(f => f.status === STATUS_WAIT_CLIENT);

  if (!supportRequests.length) return null;  // ==> EARLY EXIT
  return (
    <LandingButton onClick={onClick} {...rest}>
      {waitingOnClient ? <StarIcon /> : null}
      <StyledChip label={supportRequests.length} className={classes.chipRoot} />
      {t('support-requests')}
    </LandingButton>
  );
}
