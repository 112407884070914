import React from 'react';
import PathUtil from '../appSupport/PathUtil';
import TraceLog from '../appSupport/TraceLog';

export default function ReloadIfReady(props) {
  const { reloadNow } = props;
  const webPing = React.useRef(null);
  const hasAlreadyReloaded = React.useRef(false);

  React.useEffect(() => {
    webPing.current = null;
    async function performConditionalReload() {
      if (await testConnection()) {
        reloadURL();
      } else {
        // This will continue until a connection is valid or we are unloaded. 
        // If we are offline, then this will be unloaded in preference of the OnlineRouter
        webPing.current = setInterval(
          async () => {
            if (!hasAlreadyReloaded.current) {
              if (await testConnection()) {
                reloadURL();
                clearInterval(webPing.current);
                webPing.current = null;
              }
            }
          }, 2000);
      }
    }
    if (reloadNow && !hasAlreadyReloaded.current) {
      performConditionalReload();
    }
    return () => (webPing.current) && clearInterval(webPing.current);
  }, [reloadNow]);

  function reloadURL() {
    if (!hasAlreadyReloaded.current) {
      hasAlreadyReloaded.current = true; // Never needs to be set to false... full reload
      PathUtil.reloadPageForWake();
    }
  }
  async function testConnection() {
    var isNetworkAvailable = false;
    await fetch('//www.google.com', { mode: 'no-cors' })
      .then(() => {
        TraceLog.addEvent('ReloadIfReady', 'Network check');
        isNetworkAvailable = true;
      })
      .catch((err) => {
        isNetworkAvailable = false;
        TraceLog.addTrace('ReloadIfReady', 'Not ready');
      })
    return isNetworkAvailable;
  }

  return null; // There is nothing to render
}