import React from 'react';
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import UserStore from "../appSupport/UserStore";
import DeckOptions from "../fields/DeckOptions";
import AbstractStore from "./AbstractStore";
import CardOps from "./CardOps";
import DeckStore from "./DeckStore";
import GameStore from "./GameStore";

const CARD_CHANNEL = 'CARD_CHANNEL';
const HAND_NOT_ESTABLISHED = undefined;

let INSTANCE;

export function useFreshCards(filter) {
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    var hasEnded = false;
    var hasListener = false;
    const getRecords = () => CardStore.getRecords(filter);
    const handleChange = (event) => {
      // const { records } = event.payload;
      if (!hasEnded) setRecords(getRecords(filter));
    }
    CardStore.initialQueryCompletePromise()
      .then(r => {
        if (!hasEnded) {
          hasListener = true;
          CardStore.listen(handleChange);
          setRecords(getRecords(filter));
        }
      });
    return () => {
      hasEnded = true;
      if (hasListener) {
        hasListener = false;
        CardStore.stopListen(handleChange);
      }
    }
  }, [filter]);

  return records;
}

export default class CardStore {

  static instance() {
    const id = PathUtil.getGameParam();
    const game = INSTANCE ? INSTANCE.getGame() : { id };
    if (!INSTANCE || (game.id !== id)) {
      CardStore.release();
      INSTANCE = new CardStoreInst(id);
    }
    return INSTANCE;
  }
  static initialQueryCompletePromise() {
    return CardStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return CardStore.instance().loadedPromise();
  }
  static listen(callBack) {
    CardStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getRecords(filtered) {
    return CardStore.instance().getRecords(filtered);
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

  static dealCard(deck, playerNames, hand) {
    return CardStore.instance().dealCard(deck, playerNames, hand);
  }
}

class CardStoreInst extends AbstractStore {
  constructor(id) {
    super('CardStore', CARD_CHANNEL);
    this.game = GameStore.getGame();
    this.boundGameUpdate = this.handleGameUpdate.bind(this);
    GameStore.listen(this.boundGameUpdate);
    this.currentHand = this.game.notLoaded ? HAND_NOT_ESTABLISHED : this.game.currentHand;
    this.playerName = PathUtil.getPlayerNameParam();
    this.loadStore(CardOps.onCardCreate, null, null);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    const gameId = PathUtil.getGameParam();
    if (!gameId) return this.getValuesForInit();  // If we have navigated back from game, don't fail async ops
    return `User: ${UserStore.getUserName()}  Id: ${gameId}  subscriptionOwner: ${Globals.getDataOwner()}`;
  }
  queryRecords() {
    return CardOps.getCards();
  }
  recKey(record) {
    return `${record.id}-${record.hand}-${record.playerName}-${record.sequence}`;
  }
  performCreate(properties) {
    return CardOps.createCard(properties);
  }

  release() {
    super.release();
    GameStore.stopListen(this.boundGameUpdate);
  }
  getFilterFn() {
    const deckOptions = new DeckOptions(this.game.deckOptions);
    return f => (!deckOptions.cardsExpirePerRound || (f.hand === this.currentHand)) && (f.playerName === this.playerName);
  }
  getGame() {
    return this.game;
  }
  async handleGameUpdate(event) {
    // If this is a change that effects the card filter, handle it.
    const { game: newGame } = event.payload;
    if (this.game.id === newGame.id) {
      if (this.game.currentHand !== newGame.currentHand) {
        this.currentHand = newGame.currentHand;
        // Remove unapplicable records with a reFilterRecords. It will also dispatchActivity
        // this.reFilterRecords(f => f.hand === this.currentHand);
      }
      this.game = newGame;
    }
  }

  dealCard(deck, playerNames, hand) {
    const DO_NOT_FILTER_RECORDS = false; // We need to filter on hand, not this.currentHand
    const gameId = PathUtil.getGameParam();
    const cardKey = DeckStore.nextCardKey(deck);
    DeckStore.playCard(deck);
    const lastSeq = {};
    this.getRecords(DO_NOT_FILTER_RECORDS).forEach(e => {
      if (e.hand === hand) {
        const seq = lastSeq[e.playerName] ? lastSeq[e.playerName] : 0;
        lastSeq[e.playerName] = Math.max(e.sequence, seq);
      }
    });
    playerNames.forEach(e => {
      lastSeq[e] = lastSeq[e] !== undefined ? lastSeq[e] : -1;
      const keyFields = { id: gameId, hand, playerName: e, sequence: lastSeq[e] + 1 };
      this.put(keyFields, { deckId: deck.id, cardKey });
    });
  }

}

