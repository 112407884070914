import Globals from "../appSupport/Globals";

const NAV_ALL_PLAYERS = '*allPlayers';
const NAV_CONTROLLER = '*controller';
const ENTRY_WAIT = 'ENTRY_WAIT';
const PLAY = 'PLAY';
const SHOW_SCORE = 'SHOW_SCORE';
const EDIT_SCORE = 'EDIT_SCORE';
const NEW_ROUND = 'NEW_ROUND';

function getValueForTest(source) {
  const isController = Globals.isGameController();
  const playerNumber = Globals.getPlayerNumber();
  let playerOverride = source[playerNumber];
  if (playerOverride !== undefined) return playerOverride;
  if (isController) {
    playerOverride = source[NAV_CONTROLLER];
    if (playerOverride !== undefined) return playerOverride;
  } else {
    playerOverride = source[NAV_ALL_PLAYERS];
    if (playerOverride !== undefined) return playerOverride;
  }
}
function testForValue(navigationCode, value) {
  let toTest = getValueForTest(navigationCode.byPlayerOverride);
  if (toTest !== undefined) return toTest === value;
  toTest = getValueForTest(navigationCode.byPlayer);
  return navigationCode.default === value;
}

export default class NavigationCode {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.default = input ? input.default : ENTRY_WAIT;            // to display if no byPlayer value and no override
    this.byPlayer = input ? input.byPlayer : {};                  // byPlayer instruction, trumps default
    this.byPlayerOverride = input ? input.byPlayerOverride : {};  // byPlayer override, allows for interrupt screen (show scores) and return to byPlayer
  }
  clearControllerOverride() {
    delete this.byPlayerOverride[NAV_CONTROLLER];
    return this;
  }
  clearPlayerOverride() {
    delete this.byPlayerOverride[NAV_ALL_PLAYERS];
    return this;
  }
  playRound() {
    this.default = PLAY;
    this.byPlayer = {};
    return this;
  }
  showScore() {
    this.byPlayerOverride[NAV_CONTROLLER] = SHOW_SCORE;
    this.byPlayerOverride[NAV_ALL_PLAYERS] = SHOW_SCORE;
    return this;
  }
  showScoreThenNewRound() {
    this.default = NEW_ROUND;
    this.byPlayerOverride[NAV_CONTROLLER] = SHOW_SCORE;
    this.byPlayerOverride[NAV_ALL_PLAYERS] = SHOW_SCORE;
    return this;
  }
  editScore() {
    this.byPlayerOverride[NAV_CONTROLLER] = EDIT_SCORE;
    return this;
  }
  stopScore() {
    this.byPlayerOverride = {};
    return this;
  }
  newRound() {
    // Leave players where they are now
    this.byPlayerOverride[NAV_ALL_PLAYERS] = this.default;
    this.default = NEW_ROUND;
    return this;
  }

  isEditScore() {
    return testForValue(this, EDIT_SCORE);
  }
  isShowScore() {
    return testForValue(this, SHOW_SCORE);
  }
  isNewRound() {
    return testForValue(this, NEW_ROUND);
  }
  isDefaultNewRound() {
    return this.default === NEW_ROUND;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

