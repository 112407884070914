import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuFaq from '../menu/MenuFaq';
import MenuGames from '../menu/MenuGames';
import MenuGotoGame from '../menu/MenuGotoGame';
import MenuInstructions from '../menu/MenuInstructions';
import MenuSetupTips from '../menu/MenuSetupTips';
import ScanTipsContent from '../scanTips/ScanTipsContent';
import SitePage from '../siteComponents/SitePage';

export default function ScanTips(props) {
  const { t } = useTranslation('site');

  const title = t("Bueno Fun - Scan Tips");
  const tabTitle = t("Bueno Fun - Scan Tips");
  const component = <ScanTipsContent />
  const mobileComponent = <ScanTipsContent mobile />

  const menuActionItems = [
    <MenuGotoGame key='gotoGame' />,
    <MenuFaq key='faq' />,
    <MenuGames key='games' />,
    <MenuInstructions key='instructions' />,
    <MenuSetupTips key='setupTips' />
  ];

  return (
    <SitePage title={title} tabTitle={tabTitle} component={component} mobileComponent={mobileComponent} menuActionItems={menuActionItems} />
  );
}
