// NLS-NLS(controller)
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import GameStore from '../db/GameStore';
import PendingPlayerStore from '../db/PendingPlayerStore';
import EntryRequests from '../fields/EntryRequests';
import ConfirmationPrompt from './ConfirmationPrompt';

export default function AdmitNewPlayerPrompt(props) {
  const { game } = props;
  const { t } = useTranslation('controller');
  const [lastPlayerName, setLastPlayerName] = useState(null);

  const handleAdmitPlayer = () => {
    GameStore.acceptEntryRequest(playerName);
    // The gameStore update is delayed to allow the pendingPlayer update to propagate first.
    // This dance allows the prompt to close.
    setLastPlayerName(playerName);
    setTimeout(f => setLastPlayerName(null), 2000);
  }
  const handleDoNotAdmitPlayer = () => {
    GameStore.denyEntryRequest(playerName);
  }

  const getEntryRequest = () => {
    const erList = EntryRequests.entryRequestsFor(game);
    if (!erList || !erList.requests.length) return null;

    // This instance is the controller, so reject any request that is seeking controller.
    const controllerRequest = erList.requests.find(f => f.playerName === game.controllerName);
    if (controllerRequest) {
      GameStore.denyEntryRequest(controllerRequest.playerName);
      return null; // Next entry will be processed on game update.
    }

    // Process one at a time, staring with 0.
    return erList.requests[0].playerName;
  }

  if (!Globals.isGameController(game)) return null;
  const playerName = getEntryRequest();
  if (!playerName || (playerName === lastPlayerName)) return null;

  const isReAdmit = PendingPlayerStore.getPlayerNames().includes(playerName);
  const title = isReAdmit ? t('re-admit-player') : t('admit-player');
  const text = isReAdmit ? t('would-you-like-to-re-admit-pla', { playerName })
    : t('would-you-like-to-admit-player', { playerName });

  return (
    <ConfirmationPrompt open={true} text={text} title={title} continueButtonText={t('admit')}
      onSave={handleAdmitPlayer} onClose={handleDoNotAdmitPlayer} />
  );
}