import enControllerJson from './en/controller.json';
import enPlayerJson from './en/player.json';
import enHelpJson from './en/help.json';
import enSupportJson from './en/support.json';
import enLoginJson from './en/login.json';
import enSiteJson from './en/site.json';

export default class SupportedLanguages {
  static isBeta(languageCode) {
    const lang = this.allLanguages()[languageCode];
    return lang && lang.isBeta;
  }
  static getOverrideBetaLanguages() {
    return [...SupportedLanguages.overrideBetaLanguages];
  }
  static setOverrideBetaLanguages(overrideBetaLanguages) {
    SupportedLanguages.overrideBetaLanguages = overrideBetaLanguages;
  }
  static allLanguages() {
    return {
      en: {
        desc: 'English',
        resources: {
          controller: enControllerJson,
          player: enPlayerJson,
          help: enHelpJson,
          support: enSupportJson,
          login: enLoginJson,
          site: enSiteJson,
        },
        isBeta: false || this.overrideBetaLanguages.includes('en'),
      },
      es: {
        desc: 'Español',
        resources: {
          controller: enControllerJson,
          player: enPlayerJson,
          help: enHelpJson,
          support: enSupportJson,
          login: enLoginJson
        },
        isBeta: true || this.overrideBetaLanguages.includes('es'),
      }
    };
  }

}