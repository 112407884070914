// NLS-NLS(support)
import React from 'react';
import { useTranslation } from 'react-i18next';
import SRGeneralFeedback from '../../../fields/support/SRGeneralFeedback';
import BoxC from '../../box/BoxC';
import LabelAndData from '../../LabelAndData';
import SreDialog from './SreDialog';


export default function SreGeneralFeedback(props) {
  const { row, ...rest } = props;
  const payload = new SRGeneralFeedback(row.payload);
  const { t } = useTranslation('support');

  return (
    <BoxC {...rest}>
      <LabelAndData key='feedback' label={t('feedback-colon')} data={payload.feedback} />
      <SreDialog key='dialog' dialogArray={payload.dialog} />
    </BoxC>
  );
}
