import { CircularProgress, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import FlexRow from './flex/FlexRow';

const classes = {
  linkText: 'BusyLink-linkText',
  progress: 'BusyLink-progress',
  nativeLink: 'BusyLink-nativeLink',
  root: 'BusyLink-root'
};

const StickyBox = styled(FlexRow)(({ theme, dimensions }) => ({
  [`&.${classes.root}`]: {
    minWidth: !dimensions.width ? undefined : dimensions.width,
    minHeight: !dimensions.height ? undefined : dimensions.height,
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.linkText}`]: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: 18,
  },
  [`& .${classes.progress}`]: {},
  [`& .${classes.nativeLink}`]: {}
}));

// React docs: How can I measure a dom node
// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export default function BusyLink(props) {
  const { open, busy, linkId, onClick, children, useNativeLink = false, ...rest } = props;
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  const measuredRef = React.useCallback(node => {
    // If already set, set the same object back to prevent endless update loop.
    if (node) {
      const { width, height } = node.getBoundingClientRect();
      if (width !== dimensions.width || height !== dimensions.height) {
        setDimensions(f => f.width ? f : { width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height });
      }
    }
  }, [dimensions.height, dimensions.width]);

  function handleClick(event) {
    if (onClick) onClick(event);
  }
  function RenderLink({ open }) {
    if (!open || useNativeLink) return null;
    return (
      <FormLabel ref={measuredRef} id={linkId} className={classes.linkText} color='secondary' onClick={handleClick}>
        {children}
      </FormLabel>
    )
  }
  function RenderNativeLink({ open }) {
    if (!open || !useNativeLink) return null;
    return (
      <Link to='nowhere' onClick={handleClick} className={classes.nativeLink}>
        {children}
      </Link>
    )
  }
  function RenderSpinner({ open }) {
    if (!open) return null;
    return (
      <CircularProgress className={classes.progress}
        color='primary' size={15} />
    )
  }

  if (!open) return null;
  return (
    <StickyBox dimensions={dimensions} className={classes.root} {...rest}>
      <RenderSpinner open={busy} />
      <RenderLink open={!busy} />
      <RenderNativeLink open={!busy} />
    </StickyBox>
  );
}

BusyLink.defaultProps = {
  open: true,
}
