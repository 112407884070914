import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';

export default function PasswordField(props) {
  const [showPassword, setShowPassword] = React.useState(false);

  function handlePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <TextField type={showPassword ? 'text' : 'password'} margin='normal' {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handlePasswordVisibility} edge="end" size="large">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
