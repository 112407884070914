/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRg0GameFinder = /* GraphQL */ `
  mutation CreateRg0GameFinder(
    $input: CreateRg0GameFinderInput!
    $condition: ModelRg0GameFinderConditionInput
  ) {
    createRg0GameFinder(input: $input, condition: $condition) {
      id
      gameId
      createdAt
      owner
      updatedAt
      version
    }
  }
`;
export const updateRg0GameFinder = /* GraphQL */ `
  mutation UpdateRg0GameFinder(
    $input: UpdateRg0GameFinderInput!
    $condition: ModelRg0GameFinderConditionInput
  ) {
    updateRg0GameFinder(input: $input, condition: $condition) {
      id
      gameId
      createdAt
      owner
      updatedAt
      version
    }
  }
`;
export const deleteRg0GameFinder = /* GraphQL */ `
  mutation DeleteRg0GameFinder(
    $input: DeleteRg0GameFinderInput!
    $condition: ModelRg0GameFinderConditionInput
  ) {
    deleteRg0GameFinder(input: $input, condition: $condition) {
      id
      gameId
      createdAt
      owner
      updatedAt
      version
    }
  }
`;
export const createRg0Game = /* GraphQL */ `
  mutation CreateRg0Game(
    $input: CreateRg0GameInput!
    $condition: ModelRg0GameConditionInput
  ) {
    createRg0Game(input: $input, condition: $condition) {
      id
      entryRequests
      currentHand
      handStatus
      navigationCode
      scoringStartHandsArray
      controllerName
      scoreKeeperName
      gameOptions
      scoreOptions
      deckOptions
      gamePlay
      gamePlayDefaults
      roundData
      roundDataDefaults
      questionTimerValues
      answerTimerValues
      publishedTo
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateRg0Game = /* GraphQL */ `
  mutation UpdateRg0Game(
    $input: UpdateRg0GameInput!
    $condition: ModelRg0GameConditionInput
  ) {
    updateRg0Game(input: $input, condition: $condition) {
      id
      entryRequests
      currentHand
      handStatus
      navigationCode
      scoringStartHandsArray
      controllerName
      scoreKeeperName
      gameOptions
      scoreOptions
      deckOptions
      gamePlay
      gamePlayDefaults
      roundData
      roundDataDefaults
      questionTimerValues
      answerTimerValues
      publishedTo
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRg0Game = /* GraphQL */ `
  mutation DeleteRg0Game(
    $input: DeleteRg0GameInput!
    $condition: ModelRg0GameConditionInput
  ) {
    deleteRg0Game(input: $input, condition: $condition) {
      id
      entryRequests
      currentHand
      handStatus
      navigationCode
      scoringStartHandsArray
      controllerName
      scoreKeeperName
      gameOptions
      scoreOptions
      deckOptions
      gamePlay
      gamePlayDefaults
      roundData
      roundDataDefaults
      questionTimerValues
      answerTimerValues
      publishedTo
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createRg0PlayerResult = /* GraphQL */ `
  mutation CreateRg0PlayerResult(
    $input: CreateRg0PlayerResultInput!
    $condition: ModelRg0PlayerResultConditionInput
  ) {
    createRg0PlayerResult(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      createdAt
      answer
      annotations
      owner
      updatedAt
      version
    }
  }
`;
export const updateRg0PlayerResult = /* GraphQL */ `
  mutation UpdateRg0PlayerResult(
    $input: UpdateRg0PlayerResultInput!
    $condition: ModelRg0PlayerResultConditionInput
  ) {
    updateRg0PlayerResult(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      createdAt
      answer
      annotations
      owner
      updatedAt
      version
    }
  }
`;
export const deleteRg0PlayerResult = /* GraphQL */ `
  mutation DeleteRg0PlayerResult(
    $input: DeleteRg0PlayerResultInput!
    $condition: ModelRg0PlayerResultConditionInput
  ) {
    deleteRg0PlayerResult(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      createdAt
      answer
      annotations
      owner
      updatedAt
      version
    }
  }
`;
export const createRg0PendingPlayer = /* GraphQL */ `
  mutation CreateRg0PendingPlayer(
    $input: CreateRg0PendingPlayerInput!
    $condition: ModelRg0PendingPlayerConditionInput
  ) {
    createRg0PendingPlayer(input: $input, condition: $condition) {
      id
      playerName
      entryKey
      playerNumber
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateRg0PendingPlayer = /* GraphQL */ `
  mutation UpdateRg0PendingPlayer(
    $input: UpdateRg0PendingPlayerInput!
    $condition: ModelRg0PendingPlayerConditionInput
  ) {
    updateRg0PendingPlayer(input: $input, condition: $condition) {
      id
      playerName
      entryKey
      playerNumber
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRg0PendingPlayer = /* GraphQL */ `
  mutation DeleteRg0PendingPlayer(
    $input: DeleteRg0PendingPlayerInput!
    $condition: ModelRg0PendingPlayerConditionInput
  ) {
    deleteRg0PendingPlayer(input: $input, condition: $condition) {
      id
      playerName
      entryKey
      playerNumber
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createRg0Score = /* GraphQL */ `
  mutation CreateRg0Score(
    $input: CreateRg0ScoreInput!
    $condition: ModelRg0ScoreConditionInput
  ) {
    createRg0Score(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      bid
      handScore
      totalScore
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateRg0Score = /* GraphQL */ `
  mutation UpdateRg0Score(
    $input: UpdateRg0ScoreInput!
    $condition: ModelRg0ScoreConditionInput
  ) {
    updateRg0Score(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      bid
      handScore
      totalScore
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRg0Score = /* GraphQL */ `
  mutation DeleteRg0Score(
    $input: DeleteRg0ScoreInput!
    $condition: ModelRg0ScoreConditionInput
  ) {
    deleteRg0Score(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      bid
      handScore
      totalScore
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createRg0Deck = /* GraphQL */ `
  mutation CreateRg0Deck(
    $input: CreateRg0DeckInput!
    $condition: ModelRg0DeckConditionInput
  ) {
    createRg0Deck(input: $input, condition: $condition) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRg0Deck = /* GraphQL */ `
  mutation UpdateRg0Deck(
    $input: UpdateRg0DeckInput!
    $condition: ModelRg0DeckConditionInput
  ) {
    updateRg0Deck(input: $input, condition: $condition) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRg0Deck = /* GraphQL */ `
  mutation DeleteRg0Deck(
    $input: DeleteRg0DeckInput!
    $condition: ModelRg0DeckConditionInput
  ) {
    deleteRg0Deck(input: $input, condition: $condition) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRg0Card = /* GraphQL */ `
  mutation CreateRg0Card(
    $input: CreateRg0CardInput!
    $condition: ModelRg0CardConditionInput
  ) {
    createRg0Card(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      sequence
      deckId
      cardKey
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateRg0Card = /* GraphQL */ `
  mutation UpdateRg0Card(
    $input: UpdateRg0CardInput!
    $condition: ModelRg0CardConditionInput
  ) {
    updateRg0Card(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      sequence
      deckId
      cardKey
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRg0Card = /* GraphQL */ `
  mutation DeleteRg0Card(
    $input: DeleteRg0CardInput!
    $condition: ModelRg0CardConditionInput
  ) {
    deleteRg0Card(input: $input, condition: $condition) {
      id
      hand
      handString
      playerName
      sequence
      deckId
      cardKey
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createRg0Profile = /* GraphQL */ `
  mutation CreateRg0Profile(
    $input: CreateRg0ProfileInput!
    $condition: ModelRg0ProfileConditionInput
  ) {
    createRg0Profile(input: $input, condition: $condition) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRg0Profile = /* GraphQL */ `
  mutation UpdateRg0Profile(
    $input: UpdateRg0ProfileInput!
    $condition: ModelRg0ProfileConditionInput
  ) {
    updateRg0Profile(input: $input, condition: $condition) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRg0Profile = /* GraphQL */ `
  mutation DeleteRg0Profile(
    $input: DeleteRg0ProfileInput!
    $condition: ModelRg0ProfileConditionInput
  ) {
    deleteRg0Profile(input: $input, condition: $condition) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRg0SupportRequest = /* GraphQL */ `
  mutation CreateRg0SupportRequest(
    $input: CreateRg0SupportRequestInput!
    $condition: ModelRg0SupportRequestConditionInput
  ) {
    createRg0SupportRequest(input: $input, condition: $condition) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const updateRg0SupportRequest = /* GraphQL */ `
  mutation UpdateRg0SupportRequest(
    $input: UpdateRg0SupportRequestInput!
    $condition: ModelRg0SupportRequestConditionInput
  ) {
    updateRg0SupportRequest(input: $input, condition: $condition) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const deleteRg0SupportRequest = /* GraphQL */ `
  mutation DeleteRg0SupportRequest(
    $input: DeleteRg0SupportRequestInput!
    $condition: ModelRg0SupportRequestConditionInput
  ) {
    deleteRg0SupportRequest(input: $input, condition: $condition) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
