import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import UserAssistance from '../../appSupport/UserAssistance';

const localOnly = ['indent'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledSpan = styled('span', { shouldForwardProp: localOnlyTest })
  (({ theme, indent }) => ({
    fontSize: 18,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    marginBottom: theme.spacing(2),
    marginLeft: indent ? theme.spacing(indent) : undefined,
  }));

export default function SetupTip(props) {
  const { open = true, tip, indent, children } = props;
  const { t } = useTranslation('site');

  function showUnderConstruction() {
    Globals.showConfirmation({
      title: 'Under Construction!',
      text: "Haven't got to this yet",
      showCancelButton: false,
    })
  }
  function handleLink() {
    if (!tip) {
      showUnderConstruction();
    } else {
      UserAssistance.displayGlobalInfoMessage(tip);
    }
  }

  if (!open) return null; // ==> EARLY EXIT
  return (
    <StyledSpan indent={indent} to={undefined} onClick={handleLink} >
      {children || t('Setup Tip')}
    </StyledSpan>
  );
}
