import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderMenu from '../appSupport/HeaderMenu';
import BoxCG from '../components/box/BoxCG';
import PlainHeader from '../components/header/PlainHeader';

const classes = {
  centeringBox: 'Loading-container'
};

const CenteringBox = styled(BoxCG)(({ theme }) => ({
  [`&.${classes.centeringBox}`]: {
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function Loading(props) {
  const { showHeader, title: titleIn } = props;

  const [progressColor, setProgressColor] = React.useState('secondary');
  const { t } = useTranslation('player');

  React.useEffect(() => {
    Loading.colorState = !Loading.colorState;
    setProgressColor(Loading.colorState ? 'primary' : 'secondary');
  }, []);

  const title = titleIn || t('loading-ellipse');
  return (
    <BoxCG>
      {showHeader ? <PlainHeader title={title} menuActions={HeaderMenu.getPlayActions()} /> : null}
      <CenteringBox className={classes.centeringBox}>
        <CircularProgress color={progressColor} />
      </CenteringBox>
    </BoxCG>
  );
}

Loading.defaultProps = {
  showHeader: true,
}