import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import Clear from '../siteComponents/Clear';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';
import SetupAnswerTimer from './SetupAnswerTimer';
import SetupAutoScoreButtons from './SetupAutoScoreButtons';
import SetupAutoScoreSelections from './SetupAutoScoreSelections';
import SetupButtonsForAnswers from './SetupButtonsForAnswers';
import SetupMultipleEntry from './SetupMultipleEntry';
import SetupReader from './SetupReader';
import SetupScoreOnly from './SetupScoreOnly';
import SetupScoreTeams from './SetupScoreTeams';
import SetupScoring from './SetupScoring';
import SetupSelectNotate from './SetupSelectNotate';
import { youTubeOverview, youTubeFeatures } from '../../appSupport/GlobalConstants';

const Root = styled('div')(({
}));

const IndentedDiv = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const empty = '';
const gameSettings = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameSettings.png';

export default function SetupTipsContent(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <Root>
      <StyleTopic>{t('Setup Help')}</StyleTopic>
      <StyleParagraph>{t("There are many setup options for the BuenoFun software.")}</StyleParagraph>
      <Asset left src={gameSettings} mobile={mobile} />
      <StyleParagraph>{t("They are all accessed with the setup tool (gear) in the header of the game controllers view.")}</StyleParagraph>
      <StyleParagraph>{t("Once inside settings, some additional drill-in tools allow for deeper settings.")}</StyleParagraph>
      <Clear />

      <StyleTopic>{t('How to')}</StyleTopic>
      <StyleParagraph>
        {/* https://react.i18next.com/legacy-v9/step-by-step-guide */}
        <Trans>
          {t("If you have watched the <1>overview</1> video or the <2>features</2> video you have seen BuenoFun doing some pretty cool things. This section shows you how to setup these features.")}
          <a href={youTubeOverview}>{empty}</a>
          <a href={youTubeFeatures}>{empty}</a>
        </Trans>
      </StyleParagraph>

      <SetupButtonsForAnswers />
      <SetupMultipleEntry />
      <SetupAnswerTimer />
      <SetupSelectNotate />
      <SetupReader />
      <SetupScoring />
      <IndentedDiv>
        <SetupAutoScoreButtons />
        <SetupAutoScoreSelections />
        <SetupScoreOnly />
        <SetupScoreTeams />
      </IndentedDiv>

    </Root>
  );
}
