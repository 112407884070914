import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React from 'react';


export default function ExistingTranslation(props) {
  const { value, label, options, onChange, labelRenderer = f => f[1], error, ...rest } = props;

  function handleTeamNameChange(event, selection) {
    onChange(selection);
  }

  return (
    <Autocomplete
      fullWidth
      options={options}
      onChange={handleTeamNameChange}
      value={value}
      getOptionLabel={labelRenderer}
      renderInput={(params) => <TextField {...params} autoFocus fullWidth multiline error={error}
        label={label} type="text" defaultValue={value} margin='normal' />
      }
      {...rest} />
  );
}
