// NLS-NLS(controller)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import GameStore from '../db/GameStore';
import TimerValues from '../fields/TimerValues';
import BoxR from './box/BoxR';

const classes = {
  root: 'PlayControls-root',
  button: 'PlayControls-button',
  timerLabel: 'PlayControls-timerLabel'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    justifyContent: 'center'
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.timerLabel}`]: {
    marginLeft: theme.spacing(1),
  }
}));

export default function PlayControls(props) {
  const { game: gameIn, whichTimer, sx: sxIn } = props;
  const game = gameIn || GameStore.getGame();
  const isController = Globals.isGameController(game);
  const isRoundOver = Globals.isRoundOver(game);
  const timerValues = TimerValues.newTimerValues(whichTimer, game);

  const { t } = useTranslation('controller');

  const handleForceHandEnd = () => {
    GameStore.forceRoundEnd();
  }

  function handlePlay() {
    GameStore.timerStart(whichTimer);
  }
  function handlePlus15() {
    GameStore.timerAddSeconds(whichTimer, 15);
  }
  function handlePause() {
    GameStore.timerPause(whichTimer);
  }

  function renderForceHandEndButton() {
    return (
      <Button className={classes.button} key='forceHandEndButton' variant="contained"
        onClick={handleForceHandEnd} color="primary" >
        {t('force-hand-end')}
      </Button>
    );
  }

  function renderTimerButtons() {
    if (!timerValues.hasTime() || timerValues.isExpired()) return null;
    const playDisabled = timerValues.isRunning() || timerValues.isExpired();
    const pauseDisabled = !timerValues.isRunning();
    const plus15Disabled = timerValues.isExpired();
    const result = [];
    result.push(<p className={classes.timerLabel} key='timerLabel'>Timer</p>)
    result.push(
      <Button className={classes.button} disabled={playDisabled}
        variant="contained" onClick={handlePlay} color="primary" key='playButton' >
        <PlayIcon />
      </Button>
    );
    result.push(
      <Button className={classes.button} disabled={pauseDisabled}
        variant="contained" onClick={handlePause} color="primary" key='pauseButton'>
        <PauseIcon />
      </Button>
    );
    result.push(
      <Button className={classes.button} disabled={plus15Disabled}
        variant="contained" onClick={handlePlus15} color="primary" key='plusButton'>
        +15
      </Button>
    );
    return result;
  }

  if (!isController) return null; // ==> EARLY EXIT
  return (
    <StyledBoxR className={classes.root} sx={sxIn}>
      {isRoundOver ? null : renderForceHandEndButton()}
      {renderTimerButtons()}
    </StyledBoxR>
  );
}
