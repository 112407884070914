// NLS-NLS(support)
import React from 'react';
import { useTranslation } from 'react-i18next';
import SREnhancementRequest from '../../../fields/support/SREnhancementRequest';
import BoxC from '../../box/BoxC';
import LabelAndData from '../../LabelAndData';
import SreDialog from './SreDialog';


export default function SreEnhancementRequest(props) {
  const { row, ...rest } = props;
  const payload = new SREnhancementRequest(row.payload);
  const { t } = useTranslation('support');

  return (
    <BoxC {...rest}>
      <LabelAndData key='description' label={t('enhancement-description')} data={payload.enhancementDescription} />
      <SreDialog dialogArray={payload.dialog} />
    </BoxC>
  );
}
