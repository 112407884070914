import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoBox from '../siteComponents/InfoBox';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';
import { supportEmail } from '../../appSupport/GlobalConstants';

export default function NotAGame() {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <StyleTopic>{t('BuenoFun is not any specific game!')}</StyleTopic>
      <StyleParagraph>{t("It's more like a kitchen table than a game. BuenoFun allows people who are not in the same room to share answers and a scoreboard for games they already own.")}</StyleParagraph>
      <StyleParagraph>{t("Here are the games our family has played on a rotational basis during our weekly game night.")}</StyleParagraph>
      <InfoBox>
        <Typography> {t('There are many other games that play well with BuenoFun and we would like to hear from you on games that you enjoy. Give us a shout at: {{contactEmail}}', { contactEmail: supportEmail })}</Typography>
      </InfoBox>
    </React.Fragment>
  );
}
