import { Typography } from '@mui/material';
import RightIcon from '@mui/icons-material/CheckCircle';
import WrongIcon from '@mui/icons-material/RemoveCircle';
import PropTypes from 'prop-types';
import React from 'react';
import AnswerId from './AnswerId';
import BoxR from './box/BoxR';

export function formatAnswer(answer, multiRow = false) {
  const formatIndex = (i) => {
    return <b style={{ color: 'grey', fontWeight: 'normal' }}>{answer.length > 3 ? i + 1 + ')' : ''}</b>
  }
  let formattedAnswer;
  if (answer.length === 1) {
    formattedAnswer = answer[0];
  } else {
    if (multiRow) {
      formattedAnswer = <section>
        {answer.map((m, i) => <div key={i}>{formatIndex(i)}{m}</div>)}
      </section>
    } else {
      formattedAnswer = answer.map(m => `[${m}] `);
    }
  }
  return formattedAnswer;
}

export default function Answer(props) {
  const { answer, playerName, playerIndex, isRandomResult, color, variant, correctAnswer, multiRow } = props;
  let AnswerAnnotation = null;
  if (correctAnswer) {
    AnswerAnnotation = (correctAnswer.toString() === answer.toString()) ? <RightIcon style={{ color: 'green' }} /> : <WrongIcon style={{ color: 'red' }} />;
  }
  return (
    <BoxR key={`${playerName}-answer`} style={{ alignItems: 'center' }}>
      {AnswerAnnotation}
      <AnswerId playerIndex={playerIndex} isRandomResult={isRandomResult} color={color} variant={variant} />
      <div style={{ width: 5 }} />
      <Typography variant={variant} style={{ color }}>{formatAnswer(answer, multiRow)}</Typography>
    </BoxR>
  );
}

Answer.propTypes = {
  answer: PropTypes.array,
  playerName: PropTypes.string,
  playerIndex: PropTypes.number,
  isRandomResult: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  correctAnswer: PropTypes.array,
};

Answer.defaultProps = {
  variant: 'h5',
  multiRow: true,
}