import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { gamePrefixGeneric } from './gameRoutes';
import { sitePrefixGeneric } from './siteRoutes';
import OnlineRouter from './OnlineRouter';
import ScrollToTop from './ScrollToTop';
import SiteRouter from './SiteRouter';
import NavigationSupport from './NavigationSupport';

export default function MasterRouter() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavigationSupport />
      <Routes>
        <Route path={gamePrefixGeneric} element={<OnlineRouter />} />
        <Route path={sitePrefixGeneric} element={<SiteRouter />} />
      </Routes>
    </BrowserRouter>
  );
}