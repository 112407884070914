import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupScoreOnly(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Check the "Keep Score"\n...Click the settings tool on the "Keep Score"\n...Check the "Score only (no play)"\n...(Optional) Check the "Enable bids" if your game requires bidding\n...Enter a comma separated list of players or teams in the "Comma separated names" field')}>
        {t('Score only mode')}
      </SetupTip>
    </p>
  );
}
