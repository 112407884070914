import { Hub } from 'aws-amplify';
import React from 'react';
import { storeResetChannel } from '../appSupport/GlobalConstants';
import Globals from "../appSupport/Globals";
import PathUtil from '../appSupport/PathUtil';
import TraceLog from "../appSupport/TraceLog";
import UserStore from '../appSupport/UserStore';
import CardStore from "./CardStore";
import DeckStore from "./DeckStore";
import FinderStore from "./FinderStore";
import GameStore from "./GameStore";
import PendingPlayerStore from "./PendingPlayerStore";
import ProfileStore from "./ProfileStore";
import ResultStore from "./ResultStore";
import ScoreStore from "./ScoreStore";
import SupportRequestStore from './SupportRequestStore';

export function usePreloadStores(debug) {
  const [areStoresReady, setAreStoresReady] = React.useState(false);
  const [storeResetCount, setStoreResetCount] = React.useState(0);
  const [isUserReset, setIsUserReset] = React.useState(false);
  const [isUserReady, setIsUserReady] = React.useState(false);
  const earlyExit = React.useRef(false); // Used to ensure we don't update an unloaded component
  const gameId = PathUtil.getGameParam();

  React.useEffect(() => {
    const gameId = PathUtil.getGameParam();
    const isDefaultUser = Globals.isDefaultUser(); // Don't have credentials unless it's default user
    (gameId && isUserReset && isDefaultUser ? UserStore.signInWithGameId(gameId) : UserStore.getUserAsync())
      .then(r => { setIsUserReady(true); setIsUserReset(false); });
  }, [isUserReset]);

  React.useEffect(() => {
    function handleStoreReset() {
      setAreStoresReady(false);
      setIsUserReady(false);
      setIsUserReset(true);
      setStoreResetCount(c => c + 1);
    }
    earlyExit.current = false;
    if (gameId) {
      Hub.listen(storeResetChannel, handleStoreReset);
      setAreStoresReady(false);
      if (isUserReady) {
        TraceLog.addTrace('Stores waiting on loadedPromise');
        FinderStore.initialQueryCompletePromise()             // Must be first
          .then(() => (earlyExit.current) ? null : GameStore.initialQueryCompletePromise())  // Must be second
          .then(() => (earlyExit.current) ? null : PendingPlayerStore.loadedPromise()) // Third as it is needed quickly for a join game scenario
          .then(() => (earlyExit.current) ? null : CardStore.loadedPromise())  // Remaining order does not matter
          .then(() => (earlyExit.current) ? null : DeckStore.initialQueryCompletePromise())
          .then(() => (earlyExit.current) ? null : ProfileStore.initialQueryCompletePromise())
          .then(() => (earlyExit.current) ? null : ResultStore.loadedPromise())
          .then(() => (earlyExit.current) ? null : ScoreStore.loadedPromise())
          .then(() => (earlyExit.current) ? null : SupportRequestStore.loadedPromise())
          .then(() => {
            TraceLog.addTrace('LoadedPromise complete');
            if (!earlyExit.current) setAreStoresReady(true);
          })
          .then(f => TraceLog.addTrace(`GlobalStore.usePreloadStores complete.  Data owner: ${Globals.getDataOwner()}`));
      }
    }
    return () => {
      earlyExit.current = true;
      Hub.remove(storeResetChannel, handleStoreReset);
    }
  }, [gameId, storeResetCount, isUserReady, debug]);

  return areStoresReady;
}

export default class GlobalStore {
  static releaseAllStores() {
    TraceLog.addTrace('Releasing all stores');
    SupportRequestStore.release();
    CardStore.release();
    DeckStore.release();
    PendingPlayerStore.release();
    ProfileStore.release();
    ResultStore.release();
    ScoreStore.release();
    GameStore.release(); // Do these two last as other stores may have listeners that need to be released
    FinderStore.release();
    Globals.dispatchStoreReset();
  }
}
