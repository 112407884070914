import React from 'react';
import { useTranslation } from 'react-i18next';
import { youTubeFeatures } from '../../appSupport/GlobalConstants';
import PathUtil from '../../appSupport/PathUtil';
import Asset from '../siteComponents/Asset';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const sxAlignText = {  // Using a BoxR made text fontSize off?
  display: 'inline',
  textAlign: 'center',
  verticalAlign: 'middle',
};

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/yt/features.png';

export default function DescFaqWhatCanItDo({ mobile = false }) {
  const { t } = useTranslation('site');

  function handleShowVideo() {
    PathUtil.navigateExternal(youTubeFeatures);
  }
  return (
    <React.Fragment>
      <StyleTopic>{t('What can BuenoFun do?')}</StyleTopic>
      <StyleParagraph>{t("The software helps you play games you already own. The software is pretty flexible and can be changed to behave in a variety of ways.")}</StyleParagraph>
      <div >
        <StyleParagraph sx={sxAlignText}>{t('Here is a 4 minute list of features')}</StyleParagraph>
        <Asset sx={sxAlignText} src={imageUrl} mobile={mobile} float={false} onClick={handleShowVideo} size={150} />
      </div>
      <br />
      <br />
    </React.Fragment>
  );
}
