import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import GameStore from '../db/GameStore';
import AutoScoreReader from '../fields/AutoScoreReader';
import ScoreOptions from '../fields/ScoreOptions';
import PromptUtil from './PromptUtil';

const classes = {
  scoreValue: 'AutoScoreReaderPrompt-scoreValue',
  firstLastAnswers: 'AutoScoreReaderPrompt-firstLastAnswers'
};

const ScoreValue = styled(TextField)(({ theme }) => ({
  [`&.${classes.scoreValue}`]: {
    marginLeft: theme.spacing(2),
    maxWidth: 100,
    flexShrink: 1,
  }
}));
const FirstLastFormGroup = styled(FormGroup)(({ theme }) => ({
  [`&.${classes.firstLastAnswers}`]: {
    flexWrap: 'noWrap',
    marginTop: theme.spacing(2),
  }
}));

const ID_FIRST_ANSWER_SCORING_CB = 'firstAnswerScoring';
const ID_FIRST_ANSWER_CORRECT = 'firstAnswerCorrect';
const ID_FIRST_ANSWER_INCORRECT = 'firstAnswerIncorrect';

const ID_ANSWER_CORRECT = 'answerCorrect';
const ID_ANSWER_INCORRECT = 'answerIncorrect';

const ID_LAST_ANSWER_SCORING_CB = 'lastAnswerScoring';
const ID_LAST_ANSWER_CORRECT = 'lastAnswerCorrect';
const ID_LAST_ANSWER_INCORRECT = 'lastAnswerIncorrect';

const ID_READER_POINTS = 'readerPoints';
const ID_NO_ANSWER_SUBMITTED_POINTS = 'noAnswerSubmittedPoints';

const allIds = [ID_FIRST_ANSWER_SCORING_CB, ID_FIRST_ANSWER_CORRECT, ID_FIRST_ANSWER_INCORRECT, ID_ANSWER_CORRECT,
  ID_ANSWER_INCORRECT, ID_LAST_ANSWER_SCORING_CB, ID_LAST_ANSWER_CORRECT, ID_LAST_ANSWER_INCORRECT, ID_READER_POINTS,
  ID_NO_ANSWER_SUBMITTED_POINTS];
const numericIds = [ID_FIRST_ANSWER_CORRECT, ID_FIRST_ANSWER_INCORRECT, ID_ANSWER_CORRECT,
  ID_ANSWER_INCORRECT, ID_LAST_ANSWER_CORRECT, ID_LAST_ANSWER_INCORRECT, ID_READER_POINTS, ID_NO_ANSWER_SUBMITTED_POINTS];
const checkboxControls = [ID_FIRST_ANSWER_SCORING_CB, ID_LAST_ANSWER_SCORING_CB];

const EMPTY_STAGED_VALUES = {};

export default function AutoScoreReaderPrompt(props) {

  const { open, onCancel, onSave, autoScoreReaderJSON } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);

  const { t } = useTranslation('controller');

  React.useEffect(() => {
    setStagedValues(PromptUtil.mergeProps(new AutoScoreReader(autoScoreReaderJSON), {}, allIds));
  }, [autoScoreReaderJSON]);

  const clearValidations = () => {
  }
  const validations = {
    // validateDecks: (newStaged) => {
    //   if (!newStaged[ID_DECKS_USED].length) {
    //     setDecksUsedErrorText('You must select at least one deck');
    //     return true;
    //   }
    //   return false;
    // },

    performValidate: () => {
      clearValidations();
      return false; // No errors
    }
  }

  const handleCancel = () => {
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      const autoScoreReader = new AutoScoreReader();
      PromptUtil.mergeProps(stagedValues, autoScoreReader, allIds, numericIds);
      onSave(autoScoreReader.asJSON());
      const newScoreOptions = new ScoreOptions(GameStore.getGame().scoreOptions);
      newScoreOptions.autoScoreReader = autoScoreReader.asJSON();
      GameStore.updateScoreOptions(newScoreOptions);
      clearValidations();
      onCancel();
    }
  }

  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  const title = t('scoring-when-readers-answer-is');
  if (!open) return null;
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <FormGroup>
          <TextField onChange={handleValueChange} margin='normal'
            id={ID_ANSWER_CORRECT} label={t('answer-correct')} type="number" defaultValue={stagedValues[ID_ANSWER_CORRECT]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} />
          <TextField onChange={handleValueChange} margin='normal'
            id={ID_ANSWER_INCORRECT} label={t('answer-incorrect')} type="number" defaultValue={stagedValues[ID_ANSWER_INCORRECT]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} />
        </FormGroup>

        <FirstLastFormGroup row className={classes.firstLastAnswers}>
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_FIRST_ANSWER_SCORING_CB]} onChange={handleValueChange} id={ID_FIRST_ANSWER_SCORING_CB} />}
            label={t('first-answer')}
          />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_FIRST_ANSWER_SCORING_CB]}
            id={ID_FIRST_ANSWER_CORRECT} label="✅" type="number" defaultValue={stagedValues[ID_FIRST_ANSWER_CORRECT]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_FIRST_ANSWER_SCORING_CB]}
            id={ID_FIRST_ANSWER_INCORRECT} label="❌" type="number" defaultValue={stagedValues[ID_FIRST_ANSWER_INCORRECT]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
        </FirstLastFormGroup>

        <FirstLastFormGroup row className={classes.firstLastAnswers}>
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_LAST_ANSWER_SCORING_CB]} onChange={handleValueChange} id={ID_LAST_ANSWER_SCORING_CB} />}
            label={t('last-answer')}
          />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_LAST_ANSWER_SCORING_CB]}
            id={ID_LAST_ANSWER_CORRECT} label="✅" type="number" defaultValue={stagedValues[ID_LAST_ANSWER_CORRECT]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
          <ScoreValue className={classes.scoreValue} onChange={handleValueChange} disabled={!stagedValues[ID_LAST_ANSWER_SCORING_CB]}
            id={ID_LAST_ANSWER_INCORRECT} label="❌" type="number" defaultValue={stagedValues[ID_LAST_ANSWER_INCORRECT]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} margin='normal' />
        </FirstLastFormGroup>

        <FormGroup>
          <TextField onChange={handleValueChange} margin='normal'
            id={ID_READER_POINTS} label={t('reader-points')} type="number" defaultValue={stagedValues[ID_READER_POINTS]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} />
        </FormGroup>

        <FormGroup>
          <TextField onChange={handleValueChange} margin='normal'
            id={ID_NO_ANSWER_SUBMITTED_POINTS} label={t('no-answer-submitted-points')} type="number" defaultValue={stagedValues[ID_NO_ANSWER_SUBMITTED_POINTS]}
            InputProps={{ inputProps: { max: 100000, min: 0 } }} />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}