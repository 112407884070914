export default class SelectOptions {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;

    this.canSelectOwnAnswer = input ? input.canSelectOwnAnswer : false;
    this.numberOfSelectionsAllowed = input ? input.numberOfSelectionsAllowed : 1;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

