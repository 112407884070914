import { BugReport, ExitToApp, Home, Share, Translate } from '@mui/icons-material';
import { Hub } from 'aws-amplify';
import GameStore from '../db/GameStore';
import { LOG_CHANNEL, PASSWORD_CHANNEL, SHARE_CHANNEL } from '../routing/GameRouter';
import { gameRoutesFull } from '../routing/gameRoutes';
import { siteRoutesFull } from '../routing/siteRoutes';
import Globals, { clearRecentGame } from './Globals';
import nlsStore from './NlsStore';
import PathUtil from './PathUtil';

const addChangePassword = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    Hub.dispatch(PASSWORD_CHANNEL, { noData: true });
  }
  if (!Globals.isDefaultUser()) {
    actions.push({ onClick: handleClick, text: nlsStore.getValueLogin('change-password'), separatorBefore, separatorAfter });
  }
}
const addExitGame = (actions, separatorBefore, separatorAfter) => {
  const isController = GameStore.isReady() && Globals.isGameController();
  const exitText = isController ? nlsStore.getValueController('menu-exit-end-play-for-all') : nlsStore.getValuePlayer('menu-exit');
  const handleClick = () => {
    clearRecentGame();
    if (isController) {
      GameStore.endPlay();
    } else {
      Globals.dispatchNavigate(gameRoutesFull.landing);
    }
  }
  actions.push({ onClick: handleClick, text: exitText, IconClass: ExitToApp, separatorBefore, separatorAfter });
}
const addExitToSite = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    clearRecentGame();
    Globals.dispatchNavigate(siteRoutesFull.faq, { replace: false });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValuePlayer('exit-to-site'), IconClass: ExitToApp, separatorBefore, separatorAfter });
}
const addDebug = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    Hub.dispatch(LOG_CHANNEL, { noData: true });
  }
  if (Globals.isProductSupporter()) {
    actions.push({ onClick: handleClick, text: 'Debug', separatorBefore, separatorAfter });
  }
}
// const addForceWake = (actions) => {
//   const handleClick = () => {
//     setTimeout(f => Hub.dispatch(WAKE_CHANNEL, { noData: true }), 1000);
//   }
//   actions.push({ onClick: handleClick, text: 'Force Wake' });
// }
const addShare = (actions, separatorBefore, separatorAfter) => {
  const gameId = PathUtil.getFinderIdParam();
  const handleClick = () => {
    Hub.dispatch(SHARE_CHANNEL, { noData: true });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValuePlayer('menu-share-game') + ` (${gameId})`, IconClass: Share, separatorBefore, separatorAfter });
}
const addToHome = (actions, separatorBefore, separatorAfter) => {
  const handleShowInstructions = () => {
    window.open(
      "https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/", "_blank");
  }
  const handleClick = () => {
    Globals.showConfirmation({
      title: nlsStore.getValuePlayer('add-to-home'),
      text: nlsStore.getValuePlayer('if-you-use-this-app-a-lot-you-'),
      continueButtonText: nlsStore.getValuePlayer('show-instructions'),
      onSave: handleShowInstructions
    });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValuePlayer('add-to-home'), IconClass: Home, separatorBefore, separatorAfter });
}
const addLanguageRequest = (actions, separatorBefore, separatorAfter) => {
  if (Globals.isLanguageFeatureActive()) {
    const handleClick = () => {
      Globals.dispatchSupport({ openLanguageRequestPrompt: true });
    }
    actions.push({ onClick: handleClick, text: nlsStore.getValueSupport('menu-language-request-or-defect'), IconClass: Translate, separatorBefore, separatorAfter });
  }
}
const addReportBug = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    Globals.dispatchSupport({ openReportBugPrompt: true });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValueSupport('report-a-bug'), IconClass: BugReport, separatorBefore, separatorAfter });
}
const addSuggestEnhancement = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    Globals.dispatchSupport({ openSuggestEnhancementPrompt: true });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValueSupport('suggest-enhancement'), separatorBefore, separatorAfter });
}
const addGeneralFeedback = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    Globals.dispatchSupport({ openGeneralFeedbackPrompt: true });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValueSupport('share-your-feedback'), separatorBefore, separatorAfter });
}
const addSendTrace = (actions, separatorBefore, separatorAfter) => {
  const handleClick = () => {
    Globals.dispatchSupport({ openSendTracePrompt: true });
  }
  actions.push({ onClick: handleClick, text: nlsStore.getValueSupport('menu-send-trace'), separatorBefore, separatorAfter });
}

export default class HeaderMenu {

  static getLandingActions() {
    const menuActions = [];
    addToHome(menuActions);
    addChangePassword(menuActions);
    addLanguageRequest(menuActions, !Globals.isDefaultUser());
    addReportBug(menuActions);
    addSuggestEnhancement(menuActions);
    addGeneralFeedback(menuActions);
    addSendTrace(menuActions, true);
    addDebug(menuActions, true);
    addExitToSite(menuActions, true);
    return menuActions;
  }
  static getPlayActions() {
    const menuActions = [];
    addToHome(menuActions);
    addShare(menuActions);
    addLanguageRequest(menuActions, true);
    addReportBug(menuActions);
    addSendTrace(menuActions, true);
    addDebug(menuActions);
    // this.addForceWake();
    addExitGame(menuActions, true);
    return menuActions;
  }

}