import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import TraceLog from "../appSupport/TraceLog";
import UserAssistance from "../appSupport/UserAssistance";
import EntryRequests from "../fields/EntryRequests";
import GameOptions from "../fields/GameOptions";
import GamePlay from "../fields/GamePlay";
import NavigationCode from "../fields/NavigationCode";
import { createRg0Game, updateRg0Game } from "../graphql/mutations";
import { getRg0Game } from "../graphql/queries";
import { onUpdateRg0Game } from "../graphql/subscriptions";
import DeckOps from "./DeckOps";
import FinderOps from './FinderOps';
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';
import RoundData from "../fields/RoundData";

export const HAND_STATUS_GAME_SETUP = 0;
export const HAND_STATUS_PLAY = 1;
export const HAND_STATUS_END = 9;

export const ANSWER_MISSING = 0;
export const ANSWER_PRESENT = 1;
export const ANSWER_FORCED = 2;

export default class GameOps {

  static getGameWithId(gameId, reportError = true) {
    const defaults = { id: gameId };
    TraceLog.addDbEvent('Game:get', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(getRg0Game, defaults))
      .then(
        result => result ? result.data.getRg0Game : null,
        error => {
          if (reportError) {
            Globals.dispatchApiError(error);
            return null;
          } else {
            throw error;
          }
        }
      )
  }

  static async createGame(gameId, playerName) {
    // Reserve the finder ID to be used with the game that's about to be created
    const reservedFinderId = await FinderOps.nextFindId();
    if (reservedFinderId === -1) {
      UserAssistance.displaySomethingWentWrong();
      return [null, null];  // Something went wrong. Don't know what to do.
    }

    // Create a game.
    const defaults = {
      id: gameId,
      currentHand: 0,
      handStatus: HAND_STATUS_GAME_SETUP,
      controllerName: playerName,
      scoreKeeperName: playerName,
      gameOptions: (new GameOptions()).asJSON(),
      navigationCode: (new NavigationCode()).asJSON(),
      scoringStartHandsArray: [1],
      gamePlayDefaults: new GamePlay().asJSON(),
      roundDataDefaults: new RoundData().asJSON(),
    };
    TraceLog.addDbEvent('Game:create', JSON.stringify(defaults));
    const result = await API.graphql(graphqlOperation(createRg0Game, { input: defaults }))
      .catch(err => {
        Globals.dispatchApiError(err);
        return null;
      });
    if (!result) return [null, null];
    const game = result.data.createRg0Game;

    // Create a finder record for the newly created game
    await FinderOps.createFinderRecord(reservedFinderId, gameId);

    // Authorize players to use the decks
    await DeckOps.authorizeUsers(game.id); // Can't use DeckStore as DeckStore requires established gameId.

    return [game, reservedFinderId];
  }

  static performUpdate(keyFields, updates) {
    return GameOps.updateGame(keyFields.id, updates);
  }

  static updateGame(gameId, updates) {
    const defaults = { id: gameId, ...updates };
    TraceLog.addDbEvent('Game:update', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(updateRg0Game, { input: defaults }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  // Updating an "otherGame" keeps this out of the GameStore
  static addEntryRequest(otherGame, playerName, entryKey) {
    const er = EntryRequests.entryRequestsFor(otherGame) || new EntryRequests();
    const newRequests = er.requests.filter(f => f.playerName !== playerName);
    newRequests.push({ entryKey, playerName });
    er.requests = newRequests;
    const updates = { entryRequests: er.asJSON() };
    return GameOps.updateGame(otherGame.id, updates);
  }

  static defineEntryKeys(count, existingKeys = []) {
    const MIN = 0xAAAA;
    const MAX = 0xFFFF;
    const range = MAX - MIN;
    const existingKeysToTest = [...existingKeys];
    const result = [];
    const random = () => (Math.floor(Math.random() * range) + MIN).toString(16);
    for (let i = 0; i < count; i++) {
      let x = random();
      while (existingKeysToTest.includes(x)) {
        x = random();
      }
      result.push(x);
      existingKeysToTest.push(x);
    }
    return result;
  }

  // Subscriptions ----------------------------------------------------
  static onGameUpdate(onUpdate, onError) {
    const handleUpdate = (event) => {
      const game = event.value.data.onUpdateRg0Game;
      if (!game) {
        console.log('null game for pub/sub event.');
      } else if (game.id !== PathUtil.getGameParam()) {
        const gameIdFromUrl = PathUtil.getGameParam();
        if (gameIdFromUrl) {
          console.log(`Game ID miss-match for pub/sub event (${game.id}/${gameIdFromUrl}).`);
        }
      } else {
        TraceLog.addSubscriptionEvent('SBS Game:update', game);
        onUpdate(game);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: Globals.getDataOwner() };
    TraceLog.addDbEvent('Game:subscribeUpdate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0Game, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

}