// NLS-NLS(controller)
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';


export default function AddNewPlayersPrompt(props) {
  const { open, onClose, onSave } = props;
  const textFieldRef = React.useRef();
  const { t } = useTranslation('controller');

  function handleSave() {
    onSave(textFieldRef.current.value);
  }
  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => UiUtil.onClose(event, reason, onClose)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('add-players')}</DialogTitle>
      <DialogContent >
        {/* <DialogContentText id="alert-dialog-description">Add new players by typing a comma separated list of names</DialogContentText> */}
        <TextField autoFocus fullWidth type="text" margin='normal' style={{ minWidth: 400 }}
          inputRef={r => textFieldRef.current = r}
          label={t('comma-separated-names')} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}