import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import BoxR from './box/BoxR';

export default function LabelAndData(props) {
  const { open: openIn, label, data, labelStyle, dataStyle, ...rest } = props;
  const open = openIn === undefined ? true : openIn;

  function RenderData() {
    return (
      <Typography style={{ marginLeft: 5, ...dataStyle }} color='primary' >{data}</Typography>
    );
  }

  if (!open) return null; // ==> EARLY EXIT
  return (
    <BoxR style={{ flexWrap: 'wrap', alignItems: 'center' }} {...rest}>
      <Typography style={{ fontWeight: 'bold', ...labelStyle }} color='primary'>{label}</Typography>
      {typeof data === 'object' ? data : <RenderData />}
    </BoxR>
  );
}

LabelAndData.propTypes = {
  label: PropTypes.string,
  data: PropTypes.any,
  labelStyle: PropTypes.any,
  dataStyle: PropTypes.any,
};

LabelAndData.defaultProps = {
  labelStyle: {},
  dataStyle: {},
}