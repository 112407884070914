// NLS-NLS(player)
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import UiUtil from '../../appSupport/UiUtil';
import GameView from '../GameView';
import AddPlayerContent from './AddPlayersContent';


export default function AddPlayers(props) {
  const { game } = props;
  const { t } = useTranslation('player');

  const TITLE_CONTROLLER = t('review-and-start');
  const TITLE_WAITING_FOR_PLAYERS = t('waiting-for-players');
  const TITLE_WAITING_FOR_CONTROLLER = t('waiting-for-game-controller');

  const playerTitle = Globals.isWaitingForEntry(game) ? TITLE_WAITING_FOR_CONTROLLER : TITLE_WAITING_FOR_PLAYERS;

  return (
    <GameView game={game} controllerTitle={TITLE_CONTROLLER} playerTitle={playerTitle} key={UiUtil.gameViewKey(game)}>
      <AddPlayerContent game={game} />
    </GameView>
  );
}