// NLS-NLS(support)
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../../appSupport/UiUtil';
import SupportRequestStore from '../../db/SupportRequestStore';
import PromptMessage from '../PromptMessage';

const ID_ENHANCEMENT = 'enhancementDescription';
const ID_COMMENT = 'comment';

export default function SuggestEnhancementPrompt(props) {
  const EMPTY_STAGED_VALUES = {};
  const { open, onClose } = props;

  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [errorText, setErrorText] = React.useState('');
  const { t } = useTranslation('support');

  const PROMPT_TITLE = t('suggest-enhancement');

  const clearValidations = () => {
    setErrorText('');
  }
  const validations = {
    enhancementEntered: () => {
      if (!stagedValues[ID_ENHANCEMENT]) {
        setErrorText(t('you-must-enter-a-description-e'));
        return true;
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      return validations.enhancementEntered();
    }
  }

  function handleClose() {
    clearValidations();
    setStagedValues(EMPTY_STAGED_VALUES);
    onClose();
  }

  function handleSave() {
    if (!validations.performValidate()) {
      SupportRequestStore.createEnhancementRequest(stagedValues[ID_ENHANCEMENT], stagedValues[ID_COMMENT]);
      setStagedValues(EMPTY_STAGED_VALUES);
      onClose();
    }
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event, reason) => UiUtil.onClose(event, reason, handleClose)}
      >
        <DialogTitle>{PROMPT_TITLE}</DialogTitle>
        <DialogContent>
          <TextField autoFocus fullWidth multiline rows={3} onChange={handleValueChange} error={!!errorText}
            id={ID_ENHANCEMENT} label={t("describe-the-enhancement")} type="text" defaultValue={stagedValues[ID_ENHANCEMENT]} margin='normal' />
          <PromptMessage errorText={errorText} />

          <TextField fullWidth multiline rows={3} onChange={handleValueChange}
            id={ID_COMMENT} label={t("comment")} type="text" defaultValue={stagedValues[ID_COMMENT]} margin='normal' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            {t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
