import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import GamePlay from '../fields/GamePlay';
import PublishedTo from '../fields/PublishedTo';
import Globals from '../appSupport/Globals';
import PlayerPrompt from './PlayerPrompt';
import BoxR from '../components/box/BoxR';
import GameOptions from '../fields/GameOptions';
import GameStore from '../db/GameStore';
import { formatAnswer } from '../components/Answer';
import { useTranslation } from 'react-i18next';
import PendingPlayerStore from '../db/PendingPlayerStore';
import UiUtil from '../appSupport/UiUtil';
import RoundData from '../fields/RoundData';


const classes = {
  buttonRoot: 'AnswerOptionsPrompt-button-root'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.buttonRoot}`]: {
    margin: theme.spacing(1),
  }
}));

export default function AnswerOptionsPrompt(props) {

  const { open, onCancel, onSave, game: gameIn, playerResult } = props;
  const game = gameIn || GameStore.getGame();
  const [playerPromptOpen, setPlayerPromptOpen] = React.useState(false);
  const [mask, setMask] = React.useState(false);
  const [unmask, setUnmask] = React.useState(false);
  const [prevOpenState, setPrevOpenState] = React.useState(false);
  const [selectedPlayers, setSelectedPlayers] = React.useState([]);
  const { t } = useTranslation('controller');

  if (!open) return null;

  // const gameOptions = (game && game.gameOptions) ? new GameOptions(game.gameOptions) : new GameOptions();
  const gamePlay = GamePlay.fromGame(game);
  const roundData = RoundData.fromGame(game);
  const gameOptions = (game && game.gameOptions) ? new GameOptions(game.gameOptions) : new GameOptions();
  const publishedTo = (game && game.publishedTo) ? new PublishedTo(game.publishedTo) : new PublishedTo();

  if (open && !prevOpenState) {
    setMask(false);
    setUnmask(false);
    setPrevOpenState(true);
    setSelectedPlayers(Globals.getPlayerNamesSelecting(playerResult));
  }

  const isDialogCurrentlyMasked = () => {
    if (mask) return true;
    if (unmask) return false;
    return isPlayerResultCurrentlyMasked();
  }

  const isPlayerResultCurrentlyMasked = () => {
    return !publishedTo.unmasked.includes(playerResult.playerName);
  }

  const handleCancel = () => {
    setPrevOpenState(false);
    onCancel();
  }
  const handleSave = () => {
    const isMasked = isPlayerResultCurrentlyMasked();
    onSave(mask && !isMasked, unmask && isMasked, selectedPlayers);
    setPrevOpenState(false);

  }
  const handleButtonClick = () => {
    if (isDialogCurrentlyMasked()) {
      setMask(false);
      setUnmask(true);
    } else {
      setMask(true);
      setUnmask(false);
    }
  }
  const handleSelectFor = () => {
    setPlayerPromptOpen(true);
  }

  const handleCancelPlayerPrompt = () => {
    setPlayerPromptOpen(false);
  }

  const handleSavePlayerPrompt = (playerNames) => {
    setSelectedPlayers(playerNames);
    setPlayerPromptOpen(false);
  }

  const formatSelectedPlayers = () => {
    let result = '';
    selectedPlayers.map((m, i) => {
      result += m;
      if (i < selectedPlayers.length - 1) {
        result += ', ';
      }
      return result;
    });
    return result;
  }

  const showPlayerName = !gamePlay.anonymousResult || !isPlayerResultCurrentlyMasked();
  const allPlayerNames = PendingPlayerStore.getPlayerNames();

  return (
    <div>
      <PlayerPrompt open={playerPromptOpen} onCancel={handleCancelPlayerPrompt}
        onSave={handleSavePlayerPrompt} allPlayerNames={allPlayerNames}
        selectedPlayerNames={selectedPlayers}
        nameButtonsAdditive={true} title={t('select-player')}
        allowMultiSelect={true} currentReader={roundData.currentReader} />

      <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{t('answer-options')}</DialogTitle>
        <DialogContent>
          <BoxR style={{ alignItems: 'center' }}>
            <StyledButton className={classes.buttonRoot} variant="contained"
              color="secondary" onClick={handleButtonClick}>
              {isDialogCurrentlyMasked() ? t('unmask') : t('mask')}
            </StyledButton>

            {gameOptions.isAnswerSelectable ?
              <React.Fragment>
                <StyledButton className={classes.buttonRoot} variant="contained" color="secondary"
                  onClick={handleSelectFor}>
                  {t('select-for')}
                </StyledButton>
                <Typography >{formatSelectedPlayers()}</Typography>
              </React.Fragment>
              : null}
          </BoxR>
          <Typography >{`${t('player-colon')} ${showPlayerName ? playerResult.playerName : '********'}`}</Typography>
          <Typography >{`${t('answer-colon')} ${formatAnswer(playerResult.answer)}`}</Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" type="submit" autoFocus >
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}