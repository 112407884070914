import { Auth, Hub } from "aws-amplify";
import { defaultPassword, defaultUser, userChannel } from "./GlobalConstants";
import Globals from "./Globals";
import PathUtil from "./PathUtil";
import TraceLog from "./TraceLog";

let INSTANCE;
const LOGIN_WITH_UI = 'LOGIN_WITH_UI';

export default class UserStore {

  static instance() {
    if (!INSTANCE) {
      INSTANCE = new UserStoreInst();
    }
    return INSTANCE;
  }
  static listen(callBack) {
    UserStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

  static getUser() {
    return UserStore.instance().getUser();
  }

  static getUserName() {
    const user = UserStore.getUser();
    return user ? user.username : undefined;
  }

  static async getUserAsync() {
    return await UserStore.instance().getUserAsync();
  }
  static async signInWithGameId(gameId) {
    return await UserStore.instance().signInWithGameId(gameId);
  }

  static loginWithUI() {
    Hub.dispatch(userChannel, { action: LOGIN_WITH_UI });
  }
  static isLoginWithUiEvent(event) {
    const { action } = event.payload;
    return action === LOGIN_WITH_UI;
  }

}

class UserStoreInst {
  constructor() {
    this.listeners = {};
  }
  async getUserAsync() {
    const debugTokens = async () => {
      // const at = (await Auth.currentSession()).getAccessToken();
      // const it = (await Auth.currentSession()).getIdToken();
      // const rt = (await Auth.currentSession()).getRefreshToken();
      // console.log('Break point here');  // Use https://jwt.io/ to view
    }
    try {
      const oldUserName = UserStore.getUserName();
      this.user = await Auth.currentAuthenticatedUser();
      TraceLog.addTrace(`UserStore.getUserAsync user retrieved: ${UserStore.getUserName()}  previous: ${oldUserName}`);
      debugTokens();
    } catch (err) {
      if (err === 'not authenticated') {
        // Expected error when not logged in.
      } else {
        console.log(err);
      }
      await this.signInWithGameId(PathUtil.getGameParam());
      debugTokens();
    }
    return this.user;
  }
  getUser() {
    return this.user;
  }
  async signInWithGameId(gameId) {
    const clientMetadata = { gameId: (gameId ? gameId : 'na') };
    // if (clientMetadata.gameId === 'na') {
    //   StoreFailure.addFailedStore('UserStore');
    // }
    TraceLog.addEvent(`clientMetadata: ${JSON.stringify(clientMetadata)}`, '-SignIn');
    TraceLog.addTrace(`UserStore.signInWithGameId assigning new users.  Old user: ${UserStore.getUserName()}`);
    this.user = await Auth.signIn(defaultUser, defaultPassword, clientMetadata)
      .catch(err => {
        if (gameId) {
          Globals.dispatchUserError(err);
        }
      });
    TraceLog.addTrace(`UserStore.signInWithGameId new user assigned: ${UserStore.getUserName()}`);
    return this.user;
  }
  release() {
    Object.values(this.listeners).forEach(v => {
      v();
    });
    this.listeners = {};
  }

  listen(callBack) {
    this.listeners[callBack] = this.monitorAuthorityEvents(callBack);
  }
  stopListen(callBack) {
    const releaser = this.listeners[callBack];
    if (releaser) {
      delete this.listeners[callBack];
      releaser();
    }
  }

  monitorAuthorityEvents(onAuthorityEvent) {
    const monitorAuth = async (input) => {
      let { payload: { event, data } } = input;
      TraceLog.addEvent(`event: ${event}`, '-Security');
      // console.log(`${new Date()} ********************* Auth event: ${event}  ${data}`);
      if (event === 'configured') {
        // Configured
      } else if (event === 'signIn') {
        TraceLog.addTrace(`UserStore.SignInEvent, assigning new user.  Old user: ${UserStore.getUserName()}`);
        this.user = await Auth.currentAuthenticatedUser();
        TraceLog.addTrace(`UserStore.SignInEvent, new user assigned: ${UserStore.getUserName()}`);
        onAuthorityEvent({ user: this.user });
      } else if (event === 'signOut' || event === 'oAuthSignOut') {
        TraceLog.addTrace(`UserStore.SignOutEvent, new user set to null`);
        this.user = null;
        onAuthorityEvent({ user: this.user });
      } else if (data && (data.name === 'Error')) {
        onAuthorityEvent({ errorText: data.message });
      }
    }
    const listener = (input) => monitorAuth(input);

    // Hub.listen(/.*/, (data) => {
    //   console.log('Listening for all messages: ', data.payload.data)
    // });
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }


}

