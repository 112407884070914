import { Hub } from '@aws-amplify/core';
import React from 'react';
import AnalyticsStore from '../../appSupport/AnalyticsStore';
import { supportChannel } from '../../appSupport/GlobalConstants';
import HeaderMenu from '../../appSupport/HeaderMenu';
import UserStore from '../../appSupport/UserStore';
import PlainFooter from '../../components/header/PlainFooter';
import PlainHeader from '../../components/header/PlainHeader';
import PageContainer from '../../components/PageContainer';
import PageLayout from '../../components/PageLayout';
import TranslateTool from '../../components/tools/TranslateTool';
import SupportRequestLocalStore from '../../db/SupportRequestLocalStore';
import SupportRequestStore from '../../db/SupportRequestStore';
import LandingContent from './LandingContent';
import LandingSupport from './LandingSupport';
import { useTranslation } from 'react-i18next';
import TabbedPage from '../TabbedPage';

export default function Landing(props) {
  const [localTransactionCount, setLocalTransactionCount] = React.useState(SupportRequestLocalStore.numberOfPendingTransactions());
  const tools = [<TranslateTool key='translationTool' />];
  const [contentKey, setContentKey] = React.useState(0);
  const [lastUser, setLastUser] = React.useState(UserStore.getUserName());
  const currentUserName = UserStore.getUserName();
  const hasUserChanged = lastUser !== currentUserName;
  const { t } = useTranslation('player');

  React.useEffect(() => {
    AnalyticsStore.recordGameLandingVisit();
  }, []);

  React.useEffect(() => {
    const cb = (event) => {
      if (event.payload.localTransactionCreated
        || event.payload.localTransactionsCleared
        || event.payload.localTransactionsPlayed) {
        setLocalTransactionCount(SupportRequestLocalStore.numberOfPendingTransactions());
      }
    }
    Hub.listen(supportChannel, cb);
    return () => Hub.remove(supportChannel, cb);
  }, []);

  React.useEffect(() => {
    SupportRequestStore.release();
    setLastUser(currentUserName);
    SupportRequestStore.initialQueryCompletePromise().then(a => {
      if (localTransactionCount) {
        setContentKey(v => v + 1);
      }
    });
  }, [hasUserChanged, currentUserName, localTransactionCount]);

  const tabTitle = t('Bueno Fun');
  return (
    <TabbedPage tabTitle={tabTitle}>
      <PageContainer>
        <PlainHeader title="" tools={tools} menuActions={HeaderMenu.getLandingActions()} />
        <PageLayout>
          {localTransactionCount ? <LandingSupport /> : <LandingContent key={`landingContent.${contentKey}`} />}
        </PageLayout>
        <PlainFooter />
      </PageContainer>
    </TabbedPage>
  );
}
