import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../siteComponents/Separator';
import FlowStartGame from './Flow1StartGame';
import FlowJoinGame from './Flow2JoinGame';
import FlowConfigure from './Flow3Configure';
import FlowPlay from './Flow5Play';
import FlowScoreRound from './Flow6ScoreRound';
import FlowNextRound from './Flow7NextRound';

export default function FlowContent(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <div>
      <Typography>{t('General flow for game play')}</Typography>
      <FlowStartGame mobile={mobile} />
      <Separator />
      <FlowJoinGame mobile={mobile} />
      <Separator />
      <FlowConfigure mobile={mobile} />
      {/* <FlowStartPlay mobile={mobile} /> */}
      <Separator />
      <FlowPlay mobile={mobile} />
      <Separator />
      <FlowScoreRound mobile={mobile} />
      <Separator />
      <FlowNextRound mobile={mobile} />
    </div>
  );
}
