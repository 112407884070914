import React from 'react';
import StyleTopic from '../siteComponents/StyleTopic';
import StyleParagraph from '../siteComponents/StyleParagraph';
import { useTranslation } from 'react-i18next';

export default function DescFaqDoINeedAccount() {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <StyleTopic>{t('Do I need an account?')}</StyleTopic>
      <StyleParagraph>{t('Though accounts are free, you do not need an account. Launch it and run it with no strings attached.')}</StyleParagraph>
    </React.Fragment>
  );
}
