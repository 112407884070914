import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import grey from '@mui/material/colors/grey';
import PropTypes from 'prop-types';
import React from 'react';
import { MessageTypes } from '../appSupport/UserAssistance';

const classes = {
  root: 'PopoverContainer-root',
  lineBreakStyle: 'PopoverContainer-lineBreakStyle'
};

const localOnly = ['messageType'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledBox = styled(Box, { shouldForwardProp: localOnlyTest })(({ theme, messageType }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
    backgroundColor: deriveBackgroundColor(theme, messageType),
  },
  [`& .${classes.lineBreakStyle}`]: {
    whiteSpace: 'pre-line',
  }
}));

function deriveBackgroundColor(theme, messageType) {
  let bColor;
  switch (messageType) {
    case MessageTypes.error:
      bColor = theme.palette.error.light;
      break;
    case MessageTypes.diagnostic:
      bColor = grey;
      break;
    case MessageTypes.info:
      bColor = theme.palette.info.light;
      break;
    default:
      bColor = 'transparent';
  }
  return bColor;
}

export default function PopoverContainer(props) {
  const { messageType } = props;
  return (
    <StyledBox className={classes.root} messageType={messageType}>
      <div className={classes.lineBreakStyle}>
        {props.children}
      </div>
    </StyledBox>
  );
}

PopoverContainer.propTypes = {
  messageType: PropTypes.string,
};
