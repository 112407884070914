import { styled } from '@mui/material/styles';
import React from 'react';
import Globals from '../../appSupport/Globals';
import TimeDelta from '../../appSupport/TimeDelta';
import BoxCG from '../../components/box/BoxCG';
import ControllerStatusLine from '../../components/ControllerStatusLine';
import DividerH, { FAT } from '../../components/DividerH';
import PlayWaitTableList, { createTableData } from '../../components/list/PlayWaitTableList';
import PlayerStatusLine, { STATUS_WAITING_CONTROLLER, STATUS_WAITING_OTHER_PLAYERS } from '../../components/PlayerStatusLine';
import PlayTimer from '../../components/PlayTimer';
import StatusBoxWithLogo from '../../components/StatusBoxWithLogo';
import PendingPlayerStore from '../../db/PendingPlayerStore';
import { useFreshResults } from '../../db/ResultStore';
import RoundData from '../../fields/RoundData';
import { QUESTION_TIMER } from '../../fields/TimerValues';

const classes = {
  root: 'PlayWaitContent-root',
  publicBox: 'PlayWaitContent-publicBox'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`& .${classes.publicBox}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    maxWidth: '100%',
    backgroundColor: theme.palette.playerWaitingBackground.main,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
  }
}));

export function generatePlayerResults(playerResultsIn, roundData) {
  const { currentReader } = roundData;
  let timeDelta = new TimeDelta();
  return playerResultsIn.map(m => {
    timeDelta = timeDelta.newTime(m.createdAt);
    return createTableData(m.playerName, m.playerName, m.playerName === currentReader, timeDelta);
  });
}

export default function PlayWaitContent(props) {
  const { game, ...rest } = props;

  const isController = Globals.isGameController(game);
  const allPlayers = PendingPlayerStore.getPlayerNames();
  const isRoundOver = Globals.isRoundOver(game);
  const roundData = RoundData.fromGame(game);
  const playerResultsIn = useFreshResults();
  const playerResults = generatePlayerResults(playerResultsIn, roundData);

  const calcRemaining = () => {
    const result = [];
    const playerNames = playerResults.map(m => m.playerName);
    allPlayers.forEach(p => {
      if (!playerNames.includes(p)) {
        result.push(createTableData(p, p));
      }
    });
    return result;
  }

  const renderStatusLines = () => {
    const status = isRoundOver ? STATUS_WAITING_CONTROLLER : STATUS_WAITING_OTHER_PLAYERS;
    return <React.Fragment>
      <PlayerStatusLine game={game} status={status} />
      {isController ? <ControllerStatusLine game={game} /> : null}
    </React.Fragment>
  }

  const remainingPlayers = calcRemaining(playerResults)
  return (
    <StyledBoxCG className={classes.root} {...rest} >
      <StatusBoxWithLogo>
        {renderStatusLines()}
      </StatusBoxWithLogo>
      <PlayTimer game={game} whichTimer={QUESTION_TIMER} />
      <div className={classes.publicBox} >
        {playerResults.length ? <PlayWaitTableList rows={playerResults} /> : null}
        {remainingPlayers.length ? <DividerH size={FAT} /> : null}
        {remainingPlayers.length ? <PlayWaitTableList rows={remainingPlayers} color='#a09f9f' /> : null}
      </div>
    </StyledBoxCG>
  );
}
