import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupAnswerTimer(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Set the "Time limit (seconds)" to the number of seconds you want the timer to run\n...(Optional) Select the "Manual Start" checkbox if you need discussion before starting the timer')}>
        {t('Answer timer')}
      </SetupTip>
    </p>
  );
}
