import { styled } from '@mui/material/styles';
import React from 'react';
import BoxC from '../../components/box/BoxC';

const localOnly = ['sizeW', 'sizeH', 'hasPointer', 'float'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledBoxC = styled(BoxC, { shouldForwardProp: localOnlyTest })
  (({ theme, sizeW, sizeH, hasPointer, float }) => ({
    margin: theme.spacing(2),
    width: sizeW,
    height: sizeH,
    cursor: (hasPointer ? 'pointer' : undefined),
    float: (float ? float : undefined)
  }));

const StyledImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
});

export default function Asset(props) {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const { open = true, onClick, src, mobile: isMobile = false, left, right, float = true,
    size: webSizeIn, mobileSize: mobileSizeIn, ...rest } = props;
  const isWidthBased = (dimensions.width === 0 && dimensions.height === 0) || (dimensions.width > dimensions.height);
  const webSize = webSizeIn ? webSizeIn : 250;
  const mobileSize = mobileSizeIn ? mobileSizeIn : 180;
  const size = isMobile ? mobileSize : webSize;
  const sizeW = isWidthBased ? size : undefined;
  const sizeH = !isWidthBased ? size : undefined;
  const floatCalc = (float && right && 'right')
    || (float && ((!left && !right) || left) && 'left');

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setDimensions({
        width: node.naturalWidth,
        height: node.naturalHeight
      });
    }
  }, []);

  if (!open) return null; // ==> EARLY EXIT
  return (
    <StyledBoxC sizeW={sizeW} sizeH={sizeH} onClick={onClick}
      hasPointer={!!onClick} float={floatCalc} {...rest}>
      <StyledImage ref={measuredRef} src={src} alt={''} />
    </StyledBoxC>
  );
}
