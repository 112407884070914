import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import React from 'react';
import StoreFailure from '../../db/StoreFailure';
import BoxR from '../box/BoxR';
import clsx from 'clsx';

const classes = {
  root: 'PlainFooter-root',
  failedStore: 'PlainFooter-failedStore',
  failedUserStore: 'PlainFooter-failedUserStore',
  storesInError: 'PlainFooter-storesInError'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`&.${classes.root}.${classes.failedStore}`]: {
    backgroundColor: theme.palette.error.main,
  },
  [`&.${classes.root}.${classes.failedUserStore}`]: {
    backgroundColor: 'green',
  },
  [`& .${classes.storesInError}`]: {
    marginLeft: theme.spacing(1),
  }
}));


export default function PlainFooter(props) {
  const { sx: sxIn } = props;
  const [failedStores, setFailedStores] = React.useState([]);

  React.useEffect(() => {
    const handleStoreFailure = failedStores => setFailedStores(failedStores);
    StoreFailure.listen(handleStoreFailure);
    return () => StoreFailure.stopListen(handleStoreFailure);
  }, []);

  const formatFailedStores = () => {
    if (!failedStores.length) return '';
    return `(${failedStores})`;
  }
  function getFooterClassName() {
    if (!failedStores.length) return classes.root; // ==> EARLY EXIT
    if (failedStores.includes('UserStore')) {
      return clsx(classes.root, classes.failedUserStore);
    } else {
      return clsx(classes.root, classes.failedStore);
    }
  }

  return (
    <StyledBoxR className={getFooterClassName()} sx={sxIn} position="static" >
      <Typography variant="h6" className={classes.storesInError} >
        {formatFailedStores()}
      </Typography>
    </StyledBoxR>
  );
}
