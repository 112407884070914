import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/styles';
import React from 'react';

const classes = {
  root: 'PlayerSelect-root',
  formControl: 'PlayerSelect-formControl',
  chips: 'PlayerSelect-chips',
  chip: 'PlayerSelect-chip',
  noLabel: 'PlayerSelect-noLabel'
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
    // maxWidth: 300,
  },
  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`& .${classes.chip}`]: {
    margin: 2,
  },
  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

function getStyles(name, players, theme) {
  return {
    fontWeight:
      players.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PlayerSelect(props) {
  const { playerNames: names, label, selectedPlayers: players, onChange, sx: sxIn } = props;

  const theme = useTheme();
  // const [players, setPlayers] = React.useState([]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Root className={classes.root} sx={sxIn} >
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          fullWidth
          value={players}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, players, theme)}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
}