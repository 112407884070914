import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupSelectNotate(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Check the "Allow users to select answer"\n...and/or Check the "Allow user to notate Answers"')}>
        {t('Select or notate answers')}
      </SetupTip>
    </p>
  );
}
