// NLS-NLS(controller)
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GameStore from '../db/GameStore';
import GameOptions from '../fields/GameOptions';
import BoxR from './box/BoxR';
import LabelAndData from './LabelAndData';

export default function ControllerStatusLine(props) {
  const { open, game: gameIn } = props;
  const { t } = useTranslation('controller');

  if (!open) return null; // ==> EARLY EXIT
  const game = gameIn || GameStore.getGame();
  const gameOptions = new GameOptions(game.gameOptions);
  return (
    <BoxR style={{ alignSelf: 'stretch', flexWrap: 'wrap', alignItems: 'center' }}>
      <LabelAndData label={t('pub-sequence')} data={gameOptions.isRandomResult ? t('random') : t('fifo')} />
      <LabelAndData label={t('timer-colon')} labelStyle={{ marginLeft: 10 }}
        data={gameOptions.timeLimit ? gameOptions.timeLimit : t('off')} />
    </BoxR>
  );
}

ControllerStatusLine.propTypes = {
  open: PropTypes.bool,
  game: PropTypes.any,
};

ControllerStatusLine.defaultProps = {
  open: true,
}