import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import { createRg0Score, updateRg0Score } from "../graphql/mutations";
import { listRg0Scores } from "../graphql/queries";
import { onCreateRg0Score, onUpdateRg0Score } from "../graphql/subscriptions";
import GameStore from "./GameStore";
import TraceLog from "../appSupport/TraceLog";
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';

const handToString = h => (h || 0).toString().padStart(10, '0');

export default class ScoreOps {
  static async getScores() {
    return new Promise(async resolve => {
      const gameId = PathUtil.getGameParam();
      const getResult = async (nextToken) => {
        const constraint = { id: gameId, nextToken };
        TraceLog.addDbEvent('Score:list', JSON.stringify(constraint));
        const result = await API.graphql(graphqlOperation(listRg0Scores, constraint))
          .catch(err => {
            Globals.dispatchApiError(err);
            return null;
          });
        return result ? result.data.listRg0Scores : null;
      }
      let result = await getResult();
      let resultArray = result ? result.items : [];
      while (result && result.nextToken) {
        result = await getResult(result.nextToken);
        resultArray = result ? [...resultArray, ...result.items] : resultArray;
      }
      return resolve(resultArray);
    });
  }

  static createScore(properties) {
    const handString = handToString(properties.hand);
    const values = { ...properties, handString };
    TraceLog.addDbEvent('Score:create', JSON.stringify(values));
    return API.graphql(graphqlOperation(createRg0Score, { input: values }))
      .catch(err => {
        Globals.dispatchApiError(err, JSON.stringify(values, null, ' '));
      });
  }

  static updateScore(updatesIn) {
    const handString = handToString(updatesIn.hand);
    const updates = { ...updatesIn, handString };
    TraceLog.addDbEvent('Score:update', JSON.stringify(updates));
    return API.graphql(graphqlOperation(updateRg0Score, { input: updates }))
      .catch(err => {
        Globals.dispatchApiError(err, JSON.stringify(updates, null, ' '));
      });
  }

  // Subscriptions ----------------------------------------------------
  static onScoreCreate(onCreate, onError) {
    const handleCreate = (event) => {
      const data = event.value.data.onCreateRg0Score;
      if (data.id === PathUtil.getGameParam()) {
        TraceLog.addSubscriptionEvent('SBS Score:create', data);
        onCreate(data);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: GameStore.getGame().owner };
    TraceLog.addDbEvent('Score:subscribeCreate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0Score, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  static onScoreUpdate(onUpdate, onError) {
    const handleUpdate = (event) => {
      const data = event.value.data.onUpdateRg0Score;
      if (data.id === PathUtil.getGameParam()) {
        TraceLog.addSubscriptionEvent('SBS Score:update', data);
        onUpdate(data);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: GameStore.getGame().owner };
    TraceLog.addDbEvent('Score:subscribeUpdate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0Score, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }
}
