import React from 'react';
import FamilyFeud from './FamilyFeud';
import NotAGame from './NotAGame';
import OverUnder from './OverUnder';
import Things from './Things';
import PunIntended from './PunIntended';
import FactOrCrap from './FactOrCrap';
import FiveSecondRule from './FiveSecondRule';
import IWishIDidntKnow from './IWishIDidntKnow';
import SmartAss from './SmartAss';
import Splurt from './Splurt';
import IShouldHaveKnownThat from './IShouldHaveKnownThat';
import Separator from '../siteComponents/Separator';

export default function GameContent(props) {
  const { mobile = false } = props;

  return (
    <React.Fragment>
      <NotAGame mobile={mobile} />
      <OverUnder mobile={mobile} />
      <Separator />
      <Things mobile={mobile} />
      <Separator />
      <FamilyFeud mobile={mobile} />
      <Separator />
      <PunIntended mobile={mobile} />
      <Separator />
      <FactOrCrap mobile={mobile} />
      <Separator />
      <FiveSecondRule mobile={mobile} />
      <Separator />
      <IWishIDidntKnow mobile={mobile} />
      <Separator />
      <SmartAss mobile={mobile} />
      <Separator />
      <Splurt mobile={mobile} />
      <Separator />
      <IShouldHaveKnownThat mobile={mobile} />
    </React.Fragment>
  );
}
