import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import UserStore from '../appSupport/UserStore';
import PasswordField from '../components/PasswordField';
import ProgressPrompt from '../prompt/ProgressPrompt';
import PromptMessage from '../prompt/PromptMessage';

const classes = {
  button: 'ChangePasswordPrompt-button',
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.button}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const ID_OLD_PASSWORD = 'oldPassword';
const ID_NEW_PASSWORD = 'newPassword';

export default function ChangePasswordPrompt(props) {
  const { open, onClose } = props;

  const [progressTitle, setProgressTitle] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const { t } = useTranslation('login');
  if (!open) return null; // ==> EARLY EXIT

  const currentUsername = UserStore.getUser().username;

  function handleCancel() {
    onClose();
  }
  function handleChangePassword() {
    setErrorMessage('');
    if (!oldPassword) {
      setErrorMessage(t('previous-password-cannot-be-em'));
    } else if (oldPassword.trim().length < 8) {
      setErrorMessage(t('old-password-must-be-8-charact'));
    } else if (!newPassword) {
      setErrorMessage(t('new-password-cannot-be-empty'));
    } else if (newPassword.trim().length < 8) {
      setErrorMessage(t('new-password-must-be-8-charact'));
    } else {
      setProgressTitle(t('changing-password'));
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, oldPassword, newPassword))
        .then(data => onClose())
        .catch(error => {
          setProgressTitle('');
          setErrorMessage(error.message);
        });
    }
  }
  function handleOldPasswordChange(event) {
    const { value } = event.target;
    setOldPassword(value);
  }
  function handleNewPasswordChange(event) {
    const { value } = event.target;
    setNewPassword(value);
  }

  function RenderCancelButton() {
    return (
      <StyledButton className={classes.button} variant="contained" onClick={handleCancel} >
        {t('cancel')}
      </StyledButton>
    );
  }
  function RenderChangePasswordButton() {
    return (
      <StyledButton className={classes.button} variant="contained" onClick={handleChangePassword} >
        {t('change-password')}
      </StyledButton>
    );
  }

  return (
    <div>
      <ProgressPrompt open={!!progressTitle} statusText={t('please-wait')} title={progressTitle} />

      <Dialog
        open={true}
        onClose={(event, reason) => UiUtil.onClose(event, reason)}
      >
        <DialogTitle id="alert-dialog-title">{t('change-password')}</DialogTitle>
        <DialogContent sx={{ maxWidth: 400 }}>
          <DialogContentText id="alert-dialog-description">{t('currentusername-enter-your-pre', { currentUsername })}</DialogContentText>

          <PasswordField autoFocus fullWidth onChange={handleOldPasswordChange}
            id={ID_OLD_PASSWORD} label={t('previous-password')} value={oldPassword} />
          <PasswordField fullWidth onChange={handleNewPasswordChange}
            id={ID_NEW_PASSWORD} label={t('new-password')} value={newPassword} />

          <PromptMessage sx={{ mt: 3 }} errorText={errorMessage} />

        </DialogContent>
        <DialogActions sx={{ mt: 3 }}>
          <RenderCancelButton />
          <RenderChangePasswordButton />
        </DialogActions>
      </Dialog>
    </div>
  );
}
