import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function SupportRequestHelp(props) {
  const { t } = useTranslation('help');

  return (
    <HelpTool helpText={t("the-support-requests-view-allo")} {...props} />
  );
}
