import TraceLog from "../appSupport/TraceLog";
import AnswerAnnotations from "../fields/AnswerAnnotations";
import AutoScoreSelect from "../fields/AutoScoreSelect";
import GameOptions from "../fields/GameOptions";
import RoundData from "../fields/RoundData";
import ScoreOptions from "../fields/ScoreOptions";
import GameStore from "./GameStore";
import ResultStore from "./ResultStore";
import ScoreStore from "./ScoreStore";

export default class PerformAutoScoringSelect {
  static perform() {
    const instance = new PerformAutoScoringSelect();
    instance.perform();
  }
  constructor() {
    this.game = GameStore.getGame();
    this.gameOptions = new GameOptions(this.game.gameOptions);
    this.scoreOptions = new ScoreOptions(this.game.scoreOptions);
    this.autoScoreSelect = new AutoScoreSelect(this.scoreOptions.autoScoreSelect);
  }
  initValues() {
    this.results = ResultStore.getRecords()
      .filter(f => f.hand === this.game.currentHand);
    const currentReader = RoundData.fromGame(this.game).currentReader;
    this.readerResult = this.results.find(f => f.playerName === currentReader);
    this.scoresByName = {};
    ScoreStore.getRecords()
      .filter(f => f.hand === this.game.currentHand)
      .map(m => this.scoresByName[m.playerName] = m);
  }
  perform() {
    if (this.scoreOptions.useAutoScoreSelect) {
      this.initValues();
      TraceLog.addEvent('Performing auto score SELECT');
      this.performScoring();
    }
  }
  performScoring() {
    const newScores = {};
    let wasReadersAnswerSelected = false;
    const adjustScore = (name, score) => {
      if (!newScores[name]) {
        const startScore = this.scoresByName[name] ? this.scoresByName[name].handScore : 0;
        newScores[name] = startScore;
      }
      newScores[name] += score;
    }
    this.results.forEach(e => {
      // Cycle through each user's selection fields and award points.
      AnswerAnnotations.visitEachAnnotation(e, (result, aa, selectorName) => {
        if (aa.answerSelected) {
          wasReadersAnswerSelected = wasReadersAnswerSelected || (e === this.readerResult);
          // Reader's answer 
          if (e === this.readerResult && (this.autoScoreSelect.enableReadersValues)) {
            if (this.autoScoreSelect.readersAnswerAuthor) {
              adjustScore(result.playerName, this.autoScoreSelect.readersAnswerAuthor);
            }
            if (this.autoScoreSelect.readersAnswerSelector) {
              adjustScore(selectorName, this.autoScoreSelect.readersAnswerSelector);
            }
          } else {
            // Non-Reader answer
            if (Array.isArray(this.autoScoreSelect.answerAuthor)) {
              const index = Math.min(aa.answerSelected, this.autoScoreSelect.answerAuthor.length) - 1;
              const points = this.autoScoreSelect.answerAuthor[index];
              adjustScore(result.playerName, points);
            } else {
              adjustScore(result.playerName, this.autoScoreSelect.answerAuthor);
            }
          }
        }
      });
    });

    // If nobody selected the readers answer, they may get points
    if (!wasReadersAnswerSelected && this.autoScoreSelect.readersAnswerNoOne) {
      adjustScore(this.readerResult.playerName, this.autoScoreSelect.readersAnswerNoOne);
    }

    // Update scores based on newScores
    Object.keys(newScores).forEach(name => {
      this.addScoreForName(name, newScores[name]);
    });
  }
  addScoreForName(name, score) {
    ScoreStore.setScore(this.game.currentHand, name, score);
  }

}
