import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageTools from '../appSupport/ImageTools';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import UploadButton from '../components/UploadButton';

const classes = {
  instructions: 'AddImageCardPrompt-instructions',
  errorText: 'AddImageCardPrompt-errorText',
  cardButton: 'AddImageCardPrompt-cardButton',
  buttonRow: 'AddImageCardPrompt-buttonRo'
};

const InstructionText = styled('p')(({ theme }) => ({
  [`&.${classes.instructions}`]: {
    marginTop: theme.spacing(2),
  },
}));
const ErrorText = styled('p')(({ theme }) => ({
  [`&.${classes.errorText}`]: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

export default function AddImageCardPrompt(props) {

  const { open, onCancel, onSave, pageSize } = props;
  const [addedPages, setAddedPages] = React.useState([]);
  const [cardErrorText, setCardErrorText] = React.useState('');

  const { t } = useTranslation('controller');

  React.useEffect(() => {
    setAddedPages([]);
    setCardErrorText('');
  }, [open]);

  function handleAddCard(e) {
    setCardErrorText(null);
    const file = e.target.files[0];
    const contentType = file.type;
    const errorText = UiUtil.validateImageContentType(contentType, file.name);
    if (errorText) {
      setCardErrorText(errorText);
    } else {
      // Resize the image
      const it = new ImageTools();
      it.resize(file, { width: pageSize, height: pageSize })
        .then((imageData) => {
          setAddedPages([...addedPages, imageData]);
        });
    }
  }
  const handleCancel = () => {
    setAddedPages([]);
    onCancel();
  }
  const handleSave = () => {
    if (addedPages.length) {
      onSave(addedPages);
      setAddedPages([]);
    } else {
      onCancel();
    }
  }

  if (!open) return null;
  const title = t('add-card-pages');
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <InstructionText className={classes.instructions} id="alert-dialog-description">{t('this-card-can-be-dealt-to-a-pl')}</InstructionText>
        <ErrorText className={classes.errorText} id="alert-dialog-description">{cardErrorText}</ErrorText>
        <BoxR sx={{ alignItems: 'center' }}>
          <UploadButton sx={{ mr: 1 }} tag="upload-card-image" acceptTypes="image/*" onFileSelect={handleAddCard} multiple={false}>
            {t('add')}
          </UploadButton>
          <InstructionText className={classes.instructions} id="alert-dialog-description">Pages added: {addedPages.length}</InstructionText>
        </BoxR>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}