import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function ReadersAnswerCorrectHelp() {
  const { t } = useTranslation('help');
  return (
    <HelpTool helpText={t("if-you-are-using-buttons-playe")} />
  );
}
