import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import MenuActionItem from '../../components/header/MenuActionItem';
import { siteRoutesFull } from '../../routing/siteRoutes';


const MenuSetupTips = React.forwardRef((props, ref) => {
  const { t } = useTranslation('site');

  const TEXT = t('Setup Tips');

  function handleClick() {
    Globals.dispatchNavigate(siteRoutesFull.setupTips, { replace: false });
    Globals.dispatchCloseAppMenu();
  }

  return (
    <MenuActionItem onClick={handleClick} text={TEXT} />
  );
});
export default MenuSetupTips;
