// NLS-NLS(player)
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { specialUserAll, specialUserDealer, specialUserNone } from '../appSupport/GlobalConstants';
import UiUtil from '../appSupport/UiUtil';
import PromptMessage from './PromptMessage';

const ID_FINDER_ID = 'finderId';
const ID_PLAYER_NAME = 'playerName';
const EMPTY_STAGED_VALUES = {};
export default function GamePrompt(props) {
  const { open, title, onCancel, onSave, playerName, finderId, protectFinderId, idErrorText, playerErrorText, promptForId } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [finderIdErrorText, setFinderIdErrorText] = React.useState(null);
  const [playerNameErrorText, setPlayerNameErrorText] = React.useState(null);
  const { t } = useTranslation('player');

  React.useEffect(() => {
    setStagedValues({ playerName: playerName ? playerName : '', finderId: finderId ? finderId : '' });
  }, [open, playerName, finderId]);

  const clearValidations = () => {
    setPlayerNameErrorText(null);
    setFinderIdErrorText(null);
  }
  const validations = {
    validatePlayerName: (newStaged) => {
      const value = newStaged[ID_PLAYER_NAME] || '';
      if (value.length === 0) {
        setPlayerNameErrorText(t('please-enter-your-name'));
        return true;
      }
      const test = value.toUpperCase();
      if ([specialUserAll, specialUserDealer, specialUserNone].includes(test)) {
        setPlayerNameErrorText(t('name-is-a-reserved-name-please', { name: value }));
        return true;
      }
      return false;
    },
    validateGameId: (newStaged) => {
      if (promptForId) {
        // const value = newStaged[ID_GAME_ID] || '';
        // if (value.length !== 2) {
        //   setGameIdErrorText('Game ID must be two characters long');
        //   return true;
        // }
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      const error = validations.validateGameId(stagedValues);
      return validations.validatePlayerName(stagedValues) || error;
    }
  }

  const handleCancel = () => {
    setStagedValues(EMPTY_STAGED_VALUES);
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      onSave(stagedValues[ID_PLAYER_NAME], stagedValues[ID_FINDER_ID]);
      clearValidations();
      setStagedValues(EMPTY_STAGED_VALUES);
    }
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = id === ID_FINDER_ID ? value.toLowerCase() : value;
    setStagedValues(newStaged);
  }

  const finderIdErrorPrompt = idErrorText || finderIdErrorText;
  const playerErrorPrompt = playerNameErrorText || playerErrorText;
  if (!open) return null;
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField autoFocus fullWidth onChange={handleValueChange} error={Boolean(playerNameErrorText)}
          id={ID_PLAYER_NAME} label={t('enter-your-name')} type="text" value={stagedValues[ID_PLAYER_NAME]} margin='normal' />
        <PromptMessage errorText={playerErrorPrompt} />
        <TextField autoFocus fullWidth onChange={handleValueChange} error={Boolean(finderIdErrorPrompt)}
          id={ID_FINDER_ID} label={t('game-id')} type="text" value={stagedValues[ID_FINDER_ID]} autoComplete='off'
          style={{ display: (promptForId ? 'block' : 'none') }} disabled={protectFinderId} margin='normal' />
        <PromptMessage errorText={finderIdErrorPrompt} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
          {promptForId ? t('join-game') : t('create-game')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}