import React from 'react';
import SRTrace from '../../../fields/support/SRTrace';
import BoxC from '../../box/BoxC';
import DividerH from '../../DividerH';
import LabelAndData from '../../LabelAndData';
import LogEntry from '../../LogEntry';
import SreDialog from './SreDialog';


export default function SreTrace(props) {
  const { row, ...rest } = props;
  const payload = new SRTrace(row.payload);
  const entries = payload.logEntries;
  const { playerName, gameFinderId } = payload;

  return (
    <BoxC {...rest}>
      <LabelAndData key='playerName' label='Player name:' data={playerName ? playerName : 'n/a'} />
      <LabelAndData key='gameFinderId' label='Game Finder ID:' data={gameFinderId ? gameFinderId : 'n/a'} />
      {entries.map((m, i) => <LogEntry key={`${i}`} entry={m} last={i === entries.length - 1} />)}
      <DividerH />
      <DividerH />
      <SreDialog dialogArray={payload.dialog} />
    </BoxC>
  );
}
