import PropTypes from 'prop-types';
import React from 'react';

export const THIN = 1;
export const FAT = 4;

export default function DividerV(props) {
  const { size, color, style } = props;
  return (
    <div style={{ height: '100%', width: size, backgroundColor: color, ...style }} />
  );
}

DividerV.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
};

DividerV.defaultProps = {
  color: 'grey',
  size: THIN,
}