import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupAutoScoreButtons(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Check the "Keep Score"\n...Click the settings tool on the "Keep Score"\n...Check the "Auto scoring on Readers Answer"\n...(Optional) Explore the drill-in settings for this option')}>
        {t('Auto score with buttons')}
      </SetupTip>
    </p>
  );
}
