import { styled } from '@mui/material/styles';
import React from 'react';
import { Fragment } from 'react';
import BoxC from '../../components/box/BoxC';
import Clear from './Clear';

const StyledBoxC = styled(BoxC)(({ theme }) => ({
  borderWidth: 1,
  borderColor: 'grey',
  borderStyle: 'solid',
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

export default function InfoBox(props) {
  const { children, ...rest } = props;

  return (
    <Fragment>
      <Clear />
      <StyledBoxC {...rest}>
        {children}
      </StyledBoxC>
    </Fragment>
  );
}