export default class SRLanguageRequest {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.requestedLanguage = input ? input.requestedLanguage : null;
    this.dialog = input ? input.dialog : [];
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

