import { Typography } from '@mui/material';
import React from 'react';
import Globals from '../appSupport/Globals';
import HeaderMenu from '../appSupport/HeaderMenu';
import PathUtil from '../appSupport/PathUtil';
import PlainFooter from '../components/header/PlainFooter';
import PlainHeader from '../components/header/PlainHeader';
import PageContainer from '../components/PageContainer';
import PageLayout from '../components/PageLayout';
import RefreshTool from '../components/tools/RefreshTool';
import SettingsTool from '../components/tools/SettingsTool';
import TranslateTool from '../components/tools/TranslateTool';
import { HAND_STATUS_PLAY } from '../db/GameOps';
import GameStore from '../db/GameStore';
import GameOptions from '../fields/GameOptions';
import GamePlay from '../fields/GamePlay';
import RoundData from '../fields/RoundData';
import ScoreOptions from '../fields/ScoreOptions';
import AdmitNewPlayerPrompt from '../prompt/AdmitNewPlayerPrompt';
import GameOptionsPrompt from '../prompt/GameOptionsPrompt';

function GameView(props) {
  const [isGameOptionsOpen, setIsGameOptionsOpen] = React.useState(false);
  const { game, children, playerTitle, controllerTitle: controllerTitleIn } = props;
  const controllerTitle = controllerTitleIn || playerTitle;

  const handleRefresh = () => {
    PathUtil.reloadPage();
  }
  const handleGameOptions = () => {
    setIsGameOptionsOpen(true);
  }
  const handleCancelGameOptions = () => {
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    setIsGameOptionsOpen(false);
    if (scoreOptions.scoreOnly) {
      GameStore.startNewRound();
    }
  }
  const handleSaveGameOptions = ({ gameOptions, deckOptions }) => {
    setIsGameOptionsOpen(false);
    const prevGameOptions = new GameOptions(game.gameOptions);
    const gamePlay = GamePlay.fromGame(game);
    const roundData = RoundData.fromGame(game);
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    if (!gameOptions.isAnswerSelectable) {
      gamePlay.areAnswerSelectionsActive = false;
      gamePlay.areAnswerSelectionsPublic = false;
    }
    if (!gameOptions.isAnswerNotes) {
      gamePlay.areAnswerNotesActive = false;
      gamePlay.areAnswerNotesPublic = false;
    }
    if (!gameOptions.readerPerRound) {
      roundData.currentReader = null;
      gameOptions.readersAnswerIsCorrect = false;
    }
    if (!gameOptions.timeLimit) {
      gameOptions.manualTimerStart = false;
    }
    let gamePlayDefaults = undefined;
    if (gameOptions.isRandomResult !== prevGameOptions.isRandomResult) {
      gamePlayDefaults = GamePlay.fromString(game.gamePlayDefaults);
      gamePlayDefaults.anonymousResult = gameOptions.isRandomResult;
    }
    GameStore.updateFields({ gameOptions, deckOptions, gamePlay, gamePlayDefaults, roundData });
    if (scoreOptions.scoreOnly && (game.handStatus === HAND_STATUS_PLAY)) {
      GameStore.showScoreNow();
    }

  }
  const handleSaveDeckOptions = (scoreOptions) => {
    GameStore.updateDeckOptions(scoreOptions);
  }
  const handleSaveScoreOptions = (scoreOptions) => {
    if (!scoreOptions.scoreOnly) scoreOptions.enableBids = false;
    GameStore.updateScoreOptions(scoreOptions);
  }

  const tools = [];
  const playerName = Globals.isGameController(game) ? `${Globals.getPlayerName()} (${PathUtil.getFinderIdParam()})` : Globals.getPlayerName();
  tools.push(
    <Typography key="playerName" style={{ marginRight: 10, fontSize: 16 }} >{playerName}</Typography>
  );
  let title;
  if (Globals.isGameController(game)) {
    title = controllerTitle;
    tools.push(<SettingsTool key="settings" onClick={handleGameOptions} />);
  } else {
    title = playerTitle;
  }
  tools.push(<TranslateTool key="translate" />);
  tools.push(<RefreshTool key="refresh" onClick={handleRefresh} />);

  return (
    <React.Fragment>
      <GameOptionsPrompt open={isGameOptionsOpen} onSave={handleSaveGameOptions} onSaveScore={handleSaveScoreOptions}
        onSaveDeck={handleSaveDeckOptions} onCancel={handleCancelGameOptions} game={game} />
      <AdmitNewPlayerPrompt game={game} />
      <PageContainer >
        <PlainHeader title={title} tools={tools} menuActions={HeaderMenu.getPlayActions()} />
        <PageLayout>
          {children}
        </PageLayout>
        <PlainFooter />
      </PageContainer>
    </React.Fragment>
  );
}

export default GameView;
