// NLS-NLS(player)
import BetaIcon from '@mui/icons-material/Build';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import UserAssistance from '../../appSupport/UserAssistance';
import BoxR from '../../components/box/BoxR';
import BusyLink from '../../components/BusyLink';
import SupportedLanguages from '../../i18n/SupportedLanguages';
import ConfirmationPrompt from '../../prompt/ConfirmationPrompt';

export default function LanguageLinks() {
  const [isBetaLangPromptOpen, setIsBetaLangPromptOpen] = React.useState(null);
  const { t } = useTranslation('player');

  const BETA_LANG_TITLE = t('please-help');
  const BETA_LANG_TEXT = t('the-language-you-have-selected');

  function handleBetaLangPromptClose() {
    Globals.dispatchNls({ newLanguage: isBetaLangPromptOpen, pseudoMode: false });
    setIsBetaLangPromptOpen(null);
    setTimeout(UserAssistance.displayTranslateToolTip, 500);
  }

  function handleLanguageSelect(event) {
    const { id } = event.target;
    const isPseudoModeChange = id === 'pseudo';
    const isBeta = !isPseudoModeChange && SupportedLanguages.isBeta(id);
    if (isPseudoModeChange) {
      Globals.dispatchNls({ newLanguage: 'en', pseudoMode: true });
    } else if (isBeta) {
      setIsBetaLangPromptOpen(id); // Language dispatch after confirmation
    } else {
      Globals.dispatchNls({ newLanguage: id });
    }
  }

  function RenderLanguageLinks() {
    const all = SupportedLanguages.allLanguages();
    return Object.keys(all).map(langId => {
      const Beta = (all[langId].isBeta) ? <BetaIcon /> : undefined;
      return <BusyLink linkId={langId} key={langId} sx={{ mr: 2 }}
        onClick={handleLanguageSelect}>
        <BoxR id={langId} style={{ alignItems: 'center' }}>{all[langId].desc}{Beta}</BoxR>
      </BusyLink>
    }
    );
  }

  if (!Globals.isLanguageFeatureActive()) return null;
  return (
    <React.Fragment>
      <ConfirmationPrompt open={!!isBetaLangPromptOpen} onClose={handleBetaLangPromptClose} showCancelButton={false}
        title={BETA_LANG_TITLE} text={BETA_LANG_TEXT} />
      <BoxR sx={{ ml: 1, mr: 1, flexWrap: 'wrap' }}>
        <RenderLanguageLinks />
        {Globals.isDevMode() ? <BusyLink linkId="pseudo" sx={{ mr: 2 }} onClick={handleLanguageSelect}>Pseudo</BusyLink> : null}
      </BoxR>
    </React.Fragment>
  );
}
