export default class PublishedTo {
  constructor(jsonInput) {
    if (jsonInput) {
      const input = JSON.parse(jsonInput);
      this.players = input.players || [];
      this.order = input.order || undefined;
      this.randomResultOrder = input.randomResultOrder || undefined;
      this.unmasked = input.unmasked || [];
    } else {
      this.players = [];
      this.order = undefined;
      this.randomResultOrder = undefined;
      this.unmasked = [];
    }
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

