// NLS-NLS(player)
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../../appSupport/UiUtil';
import GameStore from '../../db/GameStore';
import NavigationCode from '../../fields/NavigationCode';
import GameView from '../GameView';
import ScoreContent from './ScoreContent';

export default function Score(props) {
  const { game } = props;
  const { t } = useTranslation('player');
  const TITLE_PLAYER = t('score');

  const handleFinished = () => {
    const navigationCode = new NavigationCode(game.navigationCode);
    if (navigationCode.isDefaultNewRound()) {
      GameStore.clearNavigationOverride(true);
    } else {
      GameStore.clearNavigationOverride(true, true);
    }
  }

  return (
    <GameView game={game} playerTitle={TITLE_PLAYER} key={UiUtil.gameViewKey(game)}>
      <ScoreContent game={game} onFinished={handleFinished} />
    </GameView>
  );
}