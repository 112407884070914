// NLS-NLS(support,controller)
import { Hidden, Typography } from '@mui/material';
import MessageIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import SupportRequestOps from '../../db/SupportRequestOps';
import BoxC from '../box/BoxC';
import BoxR from '../box/BoxR';
import LabelAndData from '../LabelAndData';
import TableList from '../TableList';
import SupportRequestExpansion from './support/SupportRequestExpansion';
import AddTool from './tools/AddTool';
import DeleteTool from './tools/DeleteTool';
import GenericTool from './tools/GenericTool';
import { noneSelectedTools } from './util';

export default function SupportRequestTableList(props) {
  const { rows, isLoading, selectedRows, onRowSelection, onAdd, onDelete, onEdit } = props;
  const isProductSupporter = Globals.isProductSupporter();
  const { t } = useTranslation('controller');

  // originatingUsername, languageCode & platform might should be added as collapse data
  const headersSupport = [
    { id: 'status', justify: 'left', disablePadding: true, canSort: true, label: t('status'), formatter: v => SupportRequestOps.getStatusText(v) },
    { id: 'createdAt', justify: 'left', disablePadding: true, canSort: true, label: t('created'), formatter: v => new Date(v).toLocaleDateString() },
    { id: 'updatedAt', justify: 'left', disablePadding: true, canSort: true, label: t('updated'), formatter: v => new Date(v).toLocaleDateString() },
    { id: 'requestType', justify: 'left', disablePadding: true, canSort: true, label: t('type'), formatter: v => SupportRequestOps.getTypeText(v) },
    { id: 'originatingUsername', justify: 'left', disablePadding: true, canSort: true, label: t('requestor') },
  ];
  const headersClient = [
    { id: 'status', justify: 'left', disablePadding: true, canSort: true, label: t('status'), formatter: v => SupportRequestOps.getStatusText(v) },
    { id: 'createdAt', justify: 'left', disablePadding: true, canSort: true, label: t('created'), formatter: v => new Date(v).toLocaleDateString() },
    { id: 'updatedAt', justify: 'left', disablePadding: true, canSort: true, label: t('updated'), formatter: v => new Date(v).toLocaleDateString() },
    { id: 'requestType', justify: 'left', disablePadding: true, canSort: true, label: t('type'), formatter: v => SupportRequestOps.getTypeText(v) },
  ];

  function loadingOrNoData() {
    return isLoading ? renderLoading() : renderNoData();
  }

  function renderLoading() {
    return (
      <Typography key='loadingKey' align='center' >{t('loading-ellipse')}</Typography>
    );
  }
  function renderNoData() {
    return (
      <Typography key='noDataKey' align='center' >{t('no-support-requests-exist')}</Typography>
    );
  }

  function RenderSupportData({ row }) {
    if (!isProductSupporter) return null; // ==> EARLY EXIT
    return (
      <BoxR key='collapse-1' style={{ flexWrap: 'wrap' }}>
        <LabelAndData key={`collapse-1b-${row.id}`} style={{ marginRight: 15 }} label='Platform:' data={SupportRequestOps.getPlatformText(row.platform)} />
        <LabelAndData key={`collapse-1c-${row.id}`} label='Language:' data={row.languageCode} />
      </BoxR>
    );
  }
  function RowCollapseRenderer(row) {
    return (
      <BoxC>
        <RenderSupportData row={row} />
        <SupportRequestExpansion style={{ marginLeft: 20 }} row={row} key={`collapse-2-${row.id}`} />
      </BoxC>
    )
  }

  const customTools = [];
  if (onAdd) customTools.push(<AddTool onAdd={onAdd} key='addTool' />);
  if (onEdit) customTools.push(
    <GenericTool onClick={onEdit} toolTip={isProductSupporter ? t('edit') : t('message')} key='editTool'>
      {isProductSupporter ? <EditIcon /> : <MessageIcon />}
    </GenericTool>
  );
  if (onDelete) customTools.push(<DeleteTool onDelete={onDelete} key='deleteTool' />);

  const handlers = { onAdd, onDelete, onEdit };
  const headers = isProductSupporter ? headersSupport : headersClient;
  const fullTableProps = {
    rows, headers, selectedRows, onRowSelection,
    title: t('support-requests'),
    rowCollapseRenderer: RowCollapseRenderer,
    // dense: true,
    // hidePaper: true,
    // disableFlexSizing: true,
    noDataRenderer: loadingOrNoData,
    oneSelectedTools: customTools,
    moreSelectedTools: customTools,
    noneSelectedTools: noneSelectedTools(handlers),
  };
  const mobileTableProps = { ...fullTableProps }

  return (
    <React.Fragment>
      <Hidden smDown>
        <TableList {...fullTableProps} />
      </Hidden>
      <Hidden smUp>
        <TableList {...mobileTableProps} />
      </Hidden>
    </React.Fragment>
  );
}
