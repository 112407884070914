// NLS-NLS(support)

import { Analytics } from "@aws-amplify/analytics";
import Globals from "./Globals";
import PathUtil from "./PathUtil";

// amplify console analytics

const recordEvent = (objParm) => {
  if (!Globals.isDevMode()) {
    Analytics.record(objParm);
  }
}

class AnalyticsStore {
  init() {
    if (!PathUtil.isWwwSubDomain()) { // Disable for localhost
      Analytics.disable();
    }
  }
  recordSiteLandingVisit() {
    recordEvent({
      name: 'siteLandingVisit',
    });
  }
  recordGameLandingVisit() {
    recordEvent({
      name: 'gameLandingVisit',
    });
  }
  recordCreateGame(playerName) {
    recordEvent({
      name: 'createGame',
      // Attribute values must be strings
      attributes: { playerName }
    });
  }
  recordRegisterPlayer(playerName) {
    recordEvent({
      name: 'registerPlayer',
      // Attribute values must be strings
      attributes: { playerName }
    });
  }
  recordNewRound(roundNumber) {
    recordEvent({
      name: 'newRound',
      // Attribute values must be strings
      attributes: {},
      metrics: { roundNumber }
    });
  }
}
export default new AnalyticsStore();