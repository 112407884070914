import React from 'react';
import { useTranslation } from 'react-i18next';
import SequenceTitle from '../siteComponents/SequenceTitle';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';

export default function FlowNextRound(props) {
  const { t } = useTranslation('site');

  return (
    <SiteSection>
      <SequenceTitle sequence='6' title={t('Play the next round')} />
      <div>
        <StyleParagraph>{t('You have been through the cycle. Play the next round and enjoy the fun.')}</StyleParagraph>
      </div>
    </SiteSection>
  );
}
