// import { unstable_createMuiStrictModeTheme } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { createTheme, lighten, darken } from '@mui/material/styles';

function createThemeFromColors(themeColorsArray) {
  // Create a new colors array adding dark and light variants if they were not provided.
  const colors = themeColorsArray.map(m => {
    const newColor = { main: m.main, light: m.light, dark: m.dark, contrastText: m.contrastText };
    if (!newColor.light) newColor.light = lighten(newColor.main, 0.3);
    if (!newColor.dark) newColor.dark = darken(newColor.main, 0.2);
    return newColor;
  });

  const theme = createTheme({  // The line below removes an annoying deprecation warning resulting from animations on Modal. Should be resolved after 4.11.0 of Material-UI/core
    // const theme = unstable_createMuiStrictModeTheme({

    // NOTE: See app.css for Cognito signin themeing

    // https://material-ui.com/customization/palette/
    palette: {
      primary: {
        main: colors[0].main,
        light: colors[0].light,
        dark: colors[0].dark,
        contrastText: colors[0].contrastText,
      },
      secondary: {
        main: colors[1].main,
        light: colors[1].light,
        dark: colors[1].dark,
        contrastText: colors[1].contrastText,
      },
      landingBackground: {
        loggedIn: colors[0].main,
        loggedOut: colors[2].main,
      },
      playerWaitingBackground: {
        main: colors[2].main,
        me: colors[2].main,
      },
      playBackground: {
        main: colors[2].light,
      },
      resultsBackground: {
        main: colors[3].main,
        me: colors[3].main,
      },
      scoreBackground: {
        main: colors[4].main,
        light: colors[4].light,
        me: colors[4].main,
        scoreEntry: colors[4].dark,
        bidEntry: colors[1].light,
      },
      dataText: colors[0].main,
      notesTool: colors[0].main,
      readerIcon: colors[1].main,
      notesChip: {
        main: colors[0].main,
        light: colors[0].light,
        dark: colors[0].dark,
        contrastText: colors[0].contrastText,
      },
      selectionsChip: {
        main: colors[1].main,
        light: colors[1].light,
        dark: colors[1].dark,
        contrastText: colors[1].contrastText,
      },
      playerChip: {
        main: colors[2].main,
        light: colors[2].light,
        dark: colors[2].dark,
        contrastText: colors[2].contrastText,
      },
      isMeIcon: colors[0].main,
      warning: {
        main: yellow[700],
      }
    },
    overrides: {
      // MuiAppBar: {
      //   colorPrimary: { backgroundColor: 'red', color: 'blue' },
      // },
      // MuiListItemIcon: {
      //   root: {
      //     // minWidth: 30,  // Default is 56 on 7/1/2020
      //   }
      // }
    },
    components: {
      // MuiListItemIcon: {
      //   styleOverrides: {
      //     root: {
      //       minWidth: 30,  // Default is 56 on 7/1/2020
      //     }
      //   }
      // }
    }
  });
  return theme;
}

export default createThemeFromColors;
