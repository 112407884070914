import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupButtonsForAnswers(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Set the "Buttons" text to "Fact,Crap"\n    (a comma separated list of button names)')}>
        {t('Buttons for answers')}
      </SetupTip>
    </p>
  );
}
