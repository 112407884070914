import PathUtil from "../appSupport/PathUtil";
import GameStore from "../db/GameStore";

export default class RecentGame {

  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.gameId = input ? input.gameId : null;
    this.playerName = input ? input.playerName : null;
    this.finderId = input ? input.finderId : null;
    this.startedAt = input ? input.startedAt : null;
    this.jsonInput = jsonInput;
  }

  static clearRecentGame() {
    RecentGame.currentRecentGame = undefined;
  }
  static setRecentGame() {
    const game = GameStore.getGame();
    if (game) {
      const searchPath = PathUtil.currentPathSearch();
      const newJsonObj = {
        gameId: PathUtil.getGameParam(searchPath),
        playerName: PathUtil.getPlayerNameParam(searchPath),
        finderId: PathUtil.getFinderIdParam(searchPath),
        entryKey: PathUtil.getEntryKeyParam(searchPath),
        startedAt: game.createdAt,
      };
      const newJsonInput = JSON.stringify(newJsonObj);
      if (!RecentGame.currentRecentGame || (newJsonInput !== RecentGame.currentRecentGame.jsonInput)) {
        RecentGame.currentRecentGame = new RecentGame(newJsonInput);
      }
    }
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

