import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/overUnder.jpg';
const gameUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/overUnderGame.png';

export default function OverUnder({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Over Under')}</StyleTopic>
      <StyleParagraph>{t("How many bricks are in the Empire State building? How far away is Pluto? Make your best guess in this party game of estimating far-out facts.")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("When playing Over Under setup the software to have three buttons. Each player gets a turn reading while the other players make the over/under assessment.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Set the "Buttons" text to "Over,Under,Exact"\n...Select "Keep Score"\n...Select "Reader is assigned for each round"\n...Select "Reader\'s answer is correct"\nScore Settings\n...Select "Auto scoring on Reader\'s Answer"')} />
      <Asset open={!compact} src={gameUrl} right mobile={mobile} />
    </SiteSection>
  );
}
