import React from 'react';
import { REQUEST_TYPE_BUG_REPORT, REQUEST_TYPE_GENERAL_FEEDBACK, REQUEST_TYPE_NLS_MISSING, REQUEST_TYPE_NLS_NEW_LANGUAGE, REQUEST_TYPE_NLS_SUGGESTION, REQUEST_TYPE_PRODUCT_ENHANCEMENT, REQUEST_TYPE_TRACE } from '../../../db/SupportRequestOps';
import SreBetterWords from './SreBetterWords';
import SreBleedThrough from './SreBleedThrough';
import SreBugReport from './SreBugReport';
import SreEnhancementRequest from './SreEnhancementRequest';
import SreGeneralFeedback from './SreGeneralFeedback';
import SreNewLanguage from './SreNewLanguage';
import SreTrace from './SreTrace';


export default function SupportRequestExpansion(props) {
  const { row, ...rest } = props;
  const type = row.requestType;

  if (type === REQUEST_TYPE_NLS_NEW_LANGUAGE) return (<SreNewLanguage row={row} {...rest} />);
  if (type === REQUEST_TYPE_NLS_SUGGESTION) return (<SreBetterWords row={row} {...rest} />);
  if (type === REQUEST_TYPE_NLS_MISSING) return (<SreBleedThrough row={row} {...rest} />);
  if (type === REQUEST_TYPE_BUG_REPORT) return (<SreBugReport row={row} {...rest} />);
  if (type === REQUEST_TYPE_PRODUCT_ENHANCEMENT) return (<SreEnhancementRequest row={row} {...rest} />);
  if (type === REQUEST_TYPE_GENERAL_FEEDBACK) return (<SreGeneralFeedback row={row} {...rest} />);
  if (type === REQUEST_TYPE_TRACE) return (<SreTrace row={row} {...rest} />);

  return null;
}
