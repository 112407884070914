import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from './SetupTip';

export default function SetupTipKeepScore(props) {
  const { ...rest } = props;
  const { t } = useTranslation('site');

  return (
    <SetupTip {...rest}
      tip={t('In Settings\n...Select "Keep Score"')} />
  );
}
