import React from 'react';

export default function TabbedPage(props) {
  const { tabTitle, children } = props;

  React.useEffect(() => {
    if (tabTitle) {
      document.title = tabTitle;
    }
  }, [tabTitle]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}
