// NLS-NLS(support)
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import SupportRequestStore from '../../db/SupportRequestStore';
import PromptMessage from '../PromptMessage';
import ExistingTranslation from '../../components/ExistingTranslation';
import UiUtil from '../../appSupport/UiUtil';

const ID_BAD_WORDS_KEY = 'badWordsKey';
const ID_BAD_WORDS_NAMESPACE = 'badWordsNamespace';
const ID_BAD_WORDS_VALUE = 'badWordsValue';
const ID_BETTER_WORDS = 'betterWords';
const ID_COMMENT = 'comment';

export default function TranslateBetterWordsPrompt(props) {
  const EMPTY_STAGED_VALUES = {};
  const { open, onClose } = props;

  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [errorText, setErrorText] = React.useState('');
  const [resources, setResources] = React.useState([]);
  const { t } = useTranslation('support');

  const PROMPT_TITLE = t('request-better-words');
  const PROMPT_TEXT_1 = t("provide-better-words-for-somet");

  React.useEffect(() => {
    function loadResources(newResources, language, nameSpace) {
      const rawResources = i18next.getResourceBundle(language, nameSpace);
      Object.keys(rawResources).forEach(e => newResources.push([e, rawResources[e], nameSpace]));
    }
    const newResources = [];
    loadResources(newResources, i18next.language, 'controller');
    loadResources(newResources, i18next.language, 'player');
    loadResources(newResources, i18next.language, 'help');
    loadResources(newResources, i18next.language, 'support');
    loadResources(newResources, i18next.language, 'login');
    // loadResources(newResources, i18next.language, 'site'); // New prompt for site?
    setResources(newResources);
  }, []);

  const clearValidations = () => {
    setErrorText('');
  }
  const validations = {
    badWordsEntered: () => {
      if (!stagedValues[ID_BAD_WORDS_KEY]) {
        setErrorText(t('value-may-not-be-blank'));
        return true;
      }
      return false;
    },
    betterWordsEntered: () => {
      if (!stagedValues[ID_BETTER_WORDS]) {
        setErrorText(t('value-may-not-be-blank'));
        return true;
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      return validations.badWordsEntered() || validations.betterWordsEntered();
    }
  }

  function handleClose() {
    clearValidations();
    setStagedValues(EMPTY_STAGED_VALUES);
    onClose();
  }

  function handleSave() {
    if (!validations.performValidate()) {
      SupportRequestStore.createBetterTranslation(stagedValues[ID_BAD_WORDS_KEY], stagedValues[ID_BAD_WORDS_NAMESPACE],
        stagedValues[ID_BAD_WORDS_VALUE], stagedValues[ID_BETTER_WORDS], stagedValues[ID_COMMENT]);
      setStagedValues(EMPTY_STAGED_VALUES);
      onClose();
    }
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = value;
    setStagedValues(newStaged);
  }
  function handleExistingTranslationChange(selectedValue) {
    const newStaged = { ...stagedValues };
    newStaged[ID_BAD_WORDS_KEY] = selectedValue ? selectedValue[0] : '';
    newStaged[ID_BAD_WORDS_VALUE] = selectedValue ? selectedValue[1] : '';
    newStaged[ID_BAD_WORDS_NAMESPACE] = selectedValue ? selectedValue[2] : '';
    setStagedValues(newStaged);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event, reason) => UiUtil.onClose(event, reason, handleClose)}
      >
        <DialogTitle>{PROMPT_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText >{PROMPT_TEXT_1}</DialogContentText>

          <ExistingTranslation value={stagedValues[ID_BAD_WORDS_VALUE]} error={!!errorText && !stagedValues[ID_BAD_WORDS_VALUE]}
            label={t("poorly-written-translation")} options={resources} onChange={handleExistingTranslationChange} />
          <PromptMessage errorText={errorText} />

          <TextField fullWidth multiline onChange={handleValueChange} error={!!errorText && !stagedValues[ID_BETTER_WORDS]}
            id={ID_BETTER_WORDS} label={t("better-words-to-use")} type="text" defaultValue={stagedValues[ID_BETTER_WORDS]} margin='normal' />
          <PromptMessage errorText={errorText} />

          <TextField fullWidth multiline rows={3} onChange={handleValueChange}
            id={ID_COMMENT} label={t("comment")} type="text" defaultValue={stagedValues[ID_COMMENT]} margin='normal' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            {t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
