// NLS-NLS(controller)
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPrompt from './ConfirmationPrompt';

export default function ErrorsExistPrompt(props) {

  const { open, onClose } = props;
  const { t } = useTranslation('controller');

  const handleClose = () => {
    onClose();
  }

  if (!open) return null;
  const title = t('Errors Exist');
  const text = t('You must fix the errors before you can save.');
  return (
    <ConfirmationPrompt open={true} onClose={handleClose} title={title} text={text} showCancelButton={false} />);
}