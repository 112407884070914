import { Hub } from '@aws-amplify/core';
// import { adaptV4Theme } from '@mui/material/styles';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { pdfjs } from 'react-pdf';
import AnalyticsStore from './appSupport/AnalyticsStore';
// import './App.css';
import createThemeFromColors from './appSupport/createTheme';
import { confirmationChannel, nlsChannel } from './appSupport/GlobalConstants';
import Globals from './appSupport/Globals';
import LanguageStore from './appSupport/LanguageStore';
import { useInitNlsStoreController, useInitNlsStoreLogin, useInitNlsStorePlayer, useInitNlsStoreSupport } from './appSupport/NlsHooks';
import { STORAGE_languageCode, STORAGE_overrideBetaLanguages, STORAGE_pseudoMode } from './appSupport/storageConstants';
import BoxCG from './components/box/BoxCG';
import GlobalMessageContainer from './components/GlobalMessageContainer';
import { useStateWithLocalStorage, useStateWithSessionStorage } from './db/StorageStore';
import SupportedLanguages from './i18n/SupportedLanguages';
import ConfirmationPrompt from './prompt/ConfirmationPrompt';
import SupportPrompt from './prompt/support/SupportPrompt';
import ErrorBoundary from './routing/ErrorBoundary';
import MasterRouter from './routing/MasterRouter';

const themeColorsArray = [
  { main: '#515252', contrastText: '#ffffff' },
  { main: '#E63B2E', contrastText: '#ffffff' },
  { main: '#d1b589', contrastText: '#000000' },
  { main: '#f5c396', contrastText: '#000000' },
  { main: '#cdd6d0', contrastText: '#ffffff' }
];

// const themeColorsArrayOriginalBlueAndRed = [
//   { main: '#3f51b5', contrastText: '#ffffff' },
//   { main: '#e91e63', contrastText: '#ffffff' },
//   { main: '#d1b589', contrastText: '#ffffff' },
//   { main: '#f5c396', contrastText: '#ffffff' },
//   { main: '#cdd6d0', contrastText: '#ffffff' }
// ];

export default function App() {
  const theme = createThemeFromColors(themeColorsArray);
  const [isConfirmationPromptOpen, setIsConfirmationPromptOpen] = React.useState(false);
  const [confirmationProps, setConfirmationProps] = React.useState({});
  const [overrideBetaLanguages, setOverrideBetaLanguages] = useStateWithSessionStorage(STORAGE_overrideBetaLanguages, []);
  const [pseudoMode, setPseudoMode] = useStateWithSessionStorage(STORAGE_pseudoMode, false);
  const [languageCode, setLanguageCode] = useStateWithLocalStorage(STORAGE_languageCode, 'en');

  React.useEffect(() => {
    const cb = (event) => {
      if (event.payload.overrideBetaLanguages) {
        setOverrideBetaLanguages(event.payload.overrideBetaLanguages);
      }
      setPseudoMode(!!event.payload.pseudoMode);
      if (event.payload.newLanguage) {
        setLanguageCode(event.payload.newLanguage);
      }
    }
    Hub.listen(nlsChannel, cb);
    return () => Hub.remove(nlsChannel, cb);
  }, [setOverrideBetaLanguages, setPseudoMode, setLanguageCode]);

  React.useEffect(() => {
    const cb = (event) => {
      if (!isConfirmationPromptOpen) {
        setIsConfirmationPromptOpen(true);
      }
      if (JSON.stringify(event.payload) !== JSON.stringify(confirmationProps)) {
        setConfirmationProps(event.payload);
      }
    }
    Hub.listen(confirmationChannel, cb);
    return () => Hub.remove(confirmationChannel, cb);
  }, [isConfirmationPromptOpen, confirmationProps]);

  React.useEffect(() => {
    AnalyticsStore.init();
    // see  https://www.npmjs.com/package/react-pdf#standard-browserify-and-others
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  function handleCloseConfirmation(...args) {
    setIsConfirmationPromptOpen(false);
    if (confirmationProps.onClose) confirmationProps.onClose(...args);
    setConfirmationProps({});
  }
  function handleSaveConfirmation(...args) {
    setIsConfirmationPromptOpen(false);
    if (confirmationProps.onSave) {
      confirmationProps.onSave(...args);
    } else if (confirmationProps.onClose) {
      confirmationProps.onClose(...args);
    }
    setConfirmationProps({});
  }

  SupportedLanguages.setOverrideBetaLanguages(overrideBetaLanguages);
  Globals.setLanguageCode(languageCode);
  LanguageStore.init(languageCode, pseudoMode);
  useInitNlsStoreController(languageCode, pseudoMode);
  useInitNlsStoreLogin(languageCode, pseudoMode);
  useInitNlsStorePlayer(languageCode, pseudoMode);
  useInitNlsStoreSupport(languageCode, pseudoMode);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BoxCG style={{ height: '100vh' }}>
          <CssBaseline />
          <SupportPrompt />
          <ConfirmationPrompt open={isConfirmationPromptOpen} onClose={handleCloseConfirmation} onSave={handleSaveConfirmation}
            title={confirmationProps.title} text={confirmationProps.text} continueButtonText={confirmationProps.continueButtonText}
            showCancelButton={confirmationProps.showCancelButton} />
          <GlobalMessageContainer>
            <ErrorBoundary>
              <MasterRouter />
            </ErrorBoundary>
          </GlobalMessageContainer>
        </BoxCG>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
