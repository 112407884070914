export default class ScoreButtons {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.point5 = input ? input.point5 : false;
    this.ones = input ? input.ones : true;
    this.fives = input ? input.fives : false;
    this.tens = input ? input.tens : true;
    this.hundreds = input ? input.hundreds : false;
    this.thousands = input ? input.thousands : false;
    this.custom = input ? input.custom : '';
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

