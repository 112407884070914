export default class SRTrace {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.playerName = input ? input.playerName : null;
    this.gameFinderId = input ? input.gameFinderId : null;
    this.logEntries = input ? input.logEntries : [];
    this.dialog = input ? input.dialog : [];
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

