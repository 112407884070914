import { Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import BoxR from '../../components/box/BoxR';
import { siteRoutesFull } from '../../routing/siteRoutes';
import Asset from './Asset';

const Root = styled('div')({
  fontSize: 22,
});

const StyledBoxR = styled(BoxR)({
  alignItems: 'center',
});


// React docs: How can I measure a dom node
// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export default function AppLogo(props) {
  const { open = true, ...rest } = props;
  const navigate = useNavigate();

  function handleClick() {
    navigate(siteRoutesFull.faq);
  }

  if (!open) return null;
  return (
    <Root {...rest}>
      <StyledBoxR onClick={handleClick}>
        <Hidden smDown>
          <Asset src={logo} onClick={handleClick} mobile={false} size={300} />
        </Hidden>
        <Hidden smUp>
          <Asset src={logo} onClick={handleClick} mobile={true} mobileSize={200} />
        </Hidden>
      </StyledBoxR>
    </Root>
  );
}
