import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function ValuesButtonHelp() {
  const { t } = useTranslation('help');
  return (
    <HelpTool helpText={t("players-will-have-a-button-for")} />
  );
}
