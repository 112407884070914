import { TableCell } from '@mui/material';
import React from 'react';
import DividerH from '../DividerH';

export default function RowCell(props) {
  const { classes, className, showDivider, children, ...rest } = props;
  return (
    <TableCell {...rest} align='center' padding='none' classes={classes} className={className} style={{ borderBottom: 'none' }}>
      {children}
      <DividerH open={showDivider} />
    </TableCell>
  );
}

RowCell.defaultProps = {
  classes: {}
}