import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import { createRg0PendingPlayer, deleteRg0PendingPlayer, updateRg0PendingPlayer } from "../graphql/mutations";
import { listRg0PendingPlayers } from "../graphql/queries";
import { onCreateRg0PendingPlayer, onUpdateRg0PendingPlayer } from "../graphql/subscriptions";
import TraceLog from "../appSupport/TraceLog";
import GameStore from "./GameStore";
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';

export default class PendingPlayerOps {
  // static async registerPlayer(playerName, gameId, dataOwner) {
  //   const defaults = { id: gameId, playerName, owner: dataOwner };
  //   try {
  //     TraceLog.addDbEvent('PendingPlayer:create', JSON.stringify(defaults));
  //     await API.graphql(graphqlOperation(createRg0PendingPlayer, { input: { ...defaults } }));
  //     return true;
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   return false;
  // }

  static async createPlayer(properties) {
    TraceLog.addDbEvent('PendingPlayer:create', JSON.stringify(properties));
    return API.graphql(graphqlOperation(createRg0PendingPlayer, { input: { ...properties } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static updatePlayer(keyFields, updates) {
    const defaults = { ...keyFields, ...updates };
    TraceLog.addDbEvent('PendingPlayer:update', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(updateRg0PendingPlayer, { input: { ...defaults } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static getPendingPlayers(gameIdIn) {
    return new Promise(async resolve => {
      const gameId = gameIdIn || PathUtil.getGameParam();
      const getResult = async (nextToken) => {
        const constraint = { id: gameId, nextToken };
        TraceLog.addDbEvent('PendingPlayer:list', JSON.stringify(constraint));
        const result = await API.graphql(graphqlOperation(listRg0PendingPlayers, constraint))
          .catch(err => {
            Globals.dispatchApiError(err);
            return null;
          });
        return result ? result.data.listRg0PendingPlayers : null;
      }
      let result = await getResult();
      let resultArray = result ? result.items : [];
      while (result && result.nextToken) {
        result = await getResult(result.nextToken);
        resultArray = result ? [...resultArray, ...result.items] : resultArray;
      }
      return resolve(resultArray);
    });
  }

  static deletePendingPlayer(gameId, playerName) {
    const input = { id: gameId, playerName };
    TraceLog.addDbEvent('PendingPlayer:delete', JSON.stringify(input));
    API.graphql(graphqlOperation(deleteRg0PendingPlayer, { input }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  // Subscriptions ----------------------------------------------------

  static onPendingPlayerCreate(onCreate, onError) {
    const handleCreate = (event) => {
      const newPlayer = event.value.data.onCreateRg0PendingPlayer;
      if (newPlayer.id === PathUtil.getGameParam()) {
        TraceLog.addSubscriptionEvent('SBS PendingPlayer:create', newPlayer);
        onCreate(newPlayer);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: Globals.getDataOwner() };
    TraceLog.addDbEvent('PendingPlayer:subscribeCreate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0PendingPlayer, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  static onPendingPlayerUpdate(onUpdate, onError) {
    const handleUpdate = (event) => {
      const player = event.value.data.onUpdateRg0PendingPlayer;
      TraceLog.addSubscriptionEvent('SBS PendingPlayer:update', player);
      onUpdate(player);
    }
    const variables = { id: PathUtil.getGameParam(), owner: GameStore.getGame().owner };
    TraceLog.addDbEvent('PendingPlayer:subscribeUpdate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0PendingPlayer, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

}