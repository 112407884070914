// NLS-NLS(support)
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import { STORAGE_overrideBetaLanguages } from '../../appSupport/storageConstants';
import UiUtil from '../../appSupport/UiUtil';
import UserAssistance from '../../appSupport/UserAssistance';
import { useStateWithSessionStorage } from '../../db/StorageStore';
import NewLanguagePrompt from './NewLanguagePrompt';

export default function LanguageRequestPrompt(props) {
  const { open, onClose } = props;

  const [overrideBetaLanguages] = useStateWithSessionStorage(STORAGE_overrideBetaLanguages);
  const [isNewLanguagePromptOpen, setIsNewLanguagePromptOpen] = React.useState(false);
  const { t } = useTranslation('support');

  const PROMPT_TITLE = t('language-request');
  const PROMPT_TEXT_1 = t('wish-a-different-translation-w');
  const PROMPT_TEXT_2 = t('see-problems-with-the-translat');

  // The following two strings are only displayed in english.  Do not translate.
  const ENGLISH_NOT_ALLOWED_TITLE = 'English not allowed';
  const ENGLISH_NOT_ALLOWED_TEXT = 'You must select a language other than English before you may enable the translation tool.';

  function handleClose() {
    onClose();
  }

  function handleRequestNewLanguage() {
    setIsNewLanguagePromptOpen(true);
  }

  function handleTurnOnTranslateTool() {
    const languageCode = Globals.getLanguageCode();
    if (languageCode === 'en') {
      Globals.showConfirmation({
        title: ENGLISH_NOT_ALLOWED_TITLE, text: ENGLISH_NOT_ALLOWED_TEXT, showCancelButton: false,
      });
    } else if (!overrideBetaLanguages.includes(languageCode)) {
      const newOverrides = [...overrideBetaLanguages, languageCode];
      Globals.dispatchNls({ overrideBetaLanguages: newOverrides });
      // Delay so the tool can be added first
      setTimeout(() => UserAssistance.displayTranslateToolTip(), 500);
    }
  }

  return (
    <React.Fragment>
      <NewLanguagePrompt open={isNewLanguagePromptOpen} onClose={f => setIsNewLanguagePromptOpen(false)} />
      <Dialog
        open={open}
        onClose={(event, reason) => UiUtil.onClose(event, reason, handleClose)}
      >
        <DialogTitle>{PROMPT_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText >{PROMPT_TEXT_1}</DialogContentText>
          <Button variant='contained' color='secondary'
            onClick={handleRequestNewLanguage}>
            {t('request-a-new-language')}
          </Button>
          <DialogContentText style={{ marginTop: 20 }}>{PROMPT_TEXT_2}</DialogContentText>
          <Button variant='contained' color='secondary'
            onClick={handleTurnOnTranslateTool}>
            {t('enable-translation-tool')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
