import { styled } from '@mui/material/styles';
import React from 'react';
import BoxR from '../components/box/BoxR';
import MrDie from '../components/MrDie';

const classes = {
  root: 'StatusBoxWithLogo-root'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    justifyContent: 'space-between',
  }
}));

export default function StatusBoxWithLogo(props) {
  const { small, medium, children, sx: sxIn } = props;

  const handleOnClickMrDie = undefined; // () => { ResultStore.debugIt() }
  return (
    <StyledBoxR className={classes.root} sx={sxIn}>
      <div>
        {children}
      </div>
      <MrDie small={small} medium={medium} onClick={handleOnClickMrDie} />
    </StyledBoxR>
  );
}
