import { Hub } from 'aws-amplify';
import FinderStore from '../db/FinderStore';
import GameStore from '../db/GameStore';
import PendingPlayerStore from '../db/PendingPlayerStore';
import ResultStore from '../db/ResultStore';
import AnswerAnnotations from '../fields/AnswerAnnotations';
import EntryRequests from '../fields/EntryRequests';
import PublishedTo from '../fields/PublishedTo';
import RoundData from '../fields/RoundData';
import ScoreOptions from '../fields/ScoreOptions';
import { apiErrorChannel, confirmationChannel, defaultUser, gameAbortChannel, globalUiChannel, navigateChannel, nlsChannel, productSupporters, storeResetChannel, supportChannel } from './GlobalConstants';
import PathUtil from './PathUtil';
import { STORAGE_recentGame } from './storageConstants';
import TraceLog from './TraceLog';
import UserStore from './UserStore';

export const clearRecentGame = () => {
  sessionStorage.setItem(STORAGE_recentGame, ''); // Clear the recent game as it is used in case a user accidentally hits back
}
export default class Globals {
  static isLanguageFeatureActive() {
    return false;
  }
  static isSiteFeatureActive() {
    return true;
  }
  static isAttachMode() {
    return false;
  }
  static isWindows() {
    const ua = navigator.userAgent.toLocaleLowerCase();
    return ua.includes('(windows');
  }
  static isIphoneOrIpad() {
    const ua = navigator.userAgent.toLocaleLowerCase();
    return ua.includes('iphone') || ua.includes('ipad');
  }
  static addDebugText(text) {
    if (!Globals.debugText) Globals.debugText = [];
    Globals.debugText.push(text)
  }
  static isDevMode() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  }
  // static isLoginEnhancement() {
  //   return Globals.isDevMode();
  // }
  static isDefaultUser() {
    const user = UserStore.getUser();
    return user ? user.username === defaultUser : true;
  }
  static isProductSupporter() {
    const user = UserStore.getUser();
    return user ? productSupporters.includes(user.username) : false;
  }

  static dispatchClearApiError() {
    Hub.dispatch(apiErrorChannel, { clearAll: true });
  }
  static dispatchApiError(err, additional) {
    let errors;
    if (err.error && err.error.errors) {
      errors = err.error.errors;
    } else if (err.errors) {
      errors = err.errors;
    } else {
      errors = [err.toString()];
    }
    const message = errors.reduce((pv, cv) => `${pv}  ${cv.message ? cv.message : cv.toString()}`, '');
    TraceLog.addError(`${message} ${additional ? additional : ''}`);
    Hub.dispatch(apiErrorChannel, { err, message });
    // throw err;  // This will be uncaught and show in console
  }
  static dispatchUserError(err) {
    const message = err.message ? err.message : err.toString();
    Hub.dispatch(apiErrorChannel, { err, message });
  }
  static dispatchAbortGame(debug) {
    clearRecentGame();
    TraceLog.addEvent(`Game aborted-${debug}`);
    Hub.dispatch(gameAbortChannel, { message: 'gameAbortedForUnknownReason' });
  }
  static dispatchCloseAppMenu() {
    Hub.dispatch(globalUiChannel, { closeAppMenu: true });
  }
  static dispatchStoreReset() {
    Hub.dispatch(storeResetChannel, {});
  }
  static dispatchNavigate(path, options) {
    Hub.dispatch(navigateChannel, { path, options });
  }
  static dispatchNls(payload = {}) {
    if (payload.newLanguage) {
      Globals.setLanguageCode(payload.newLanguage);
    }
    Hub.dispatch(nlsChannel, payload);
  }
  static dispatchSupport(payload = {}) {
    Hub.dispatch(supportChannel, payload);
  }
  static showConfirmation(confirmationProps) {
    Hub.dispatch(confirmationChannel, confirmationProps);
  }

  static getAutoReloadErrors() { return Globals.autoReloadErrors }
  static setAutoReloadErrors(value) { Globals.autoReloadErrors = value }

  static getLanguageCode() { return Globals.languageCode || 'en' }
  static setLanguageCode(value) { Globals.languageCode = value }

  static getDataOwner() {
    const finderOwner = FinderStore.getFinderOwner();
    return finderOwner || UserStore.getUserName();
  }

  static getGameStaleMinutes(game) {
    if (Globals.isAttachMode()) return 1; // ==> EARLY EXIT
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    if (scoreOptions.scoreOnly && Globals.isGameController()) return 1;  // ==> EARLY EXIT

    return ((new Date()).getTime() - (new Date(game.updatedAt)).getTime()) / 1000 / 60;
  }

  static getPlayerName(playerNumberIn) {
    if (playerNumberIn === undefined) {
      return PathUtil.getPlayerNameParam();
    }
    const playerNumber = parseInt(playerNumberIn);
    const player = PendingPlayerStore.getPlayers().find(f => f.playerNumber === playerNumber);
    return player ? player.playerName : null;
  }
  static getPlayerNumber(playerNameIn) {
    const playerName = playerNameIn ? playerNameIn : Globals.getPlayerName();
    const result = PendingPlayerStore.getPlayer(playerName);
    return result ? result.playerNumber : -1;
  }
  static getScoringHandOffset() {
    const game = GameStore.getGame();
    return game.scoringStartHandsArray[game.scoringStartHandsArray.length - 1] - 1;
  }
  static getRelativeCurrentHand() {
    const game = GameStore.getGame();
    return game.currentHand ? game.currentHand - Globals.getScoringHandOffset() : 0;
  }

  static isGameController(gameIn) {
    if (!gameIn && !GameStore.isReady()) return false; // ==> EARLY EXIT
    const game = gameIn ? gameIn : GameStore.getGame();
    if (game === null || (game && game.notLoaded)) return false; // Assume the Store is not fluffed, 
    const playerName = Globals.getPlayerName();
    return !!(game && game.controllerName === playerName && !Globals.isWaitingForEntry(game));
  }

  static isScoreKeeper(game) {
    const playerName = Globals.getPlayerName();
    return game && game.scoreKeeperName === playerName;
  }

  /* Which players have selected this answer */
  static getPlayerNamesSelecting(playerResult) {
    const playerNames = [];
    AnswerAnnotations.visitEachAnnotation(playerResult, (result, aa, name) => {
      if (aa.answerSelected) playerNames.push(name);
    });
    return playerNames;
  }
  static getPlayerSelections(playerResult) {
    const result = [];
    AnswerAnnotations.visitEachAnnotation(playerResult, (pResult, aa, name) => {
      if (aa.answerSelected) result.push({ name, selection: aa.answerSelected });
    });
    return result;
  }
  static isRoundOver(gameIn) {
    const game = gameIn || GameStore.getGame();
    return this.areAllResultsIn() || RoundData.fromGame(game).isRoundForcedEnd;
  }
  static areAllResultsIn() {
    return ResultStore.getResultCount() === PendingPlayerStore.getPlayerCount();
  }

  static getPublishedData(game) {
    const publishedTo = (game && game.publishedTo) ? new PublishedTo(game.publishedTo) : null;
    const isPublishedToMe = Boolean(publishedTo
      && (publishedTo.players.length === 0 || publishedTo.players.indexOf(Globals.getPlayerName()) > -1));
    const randomResultOrder = publishedTo ? publishedTo.randomResultOrder : null;
    const unmasked = publishedTo ? publishedTo.unmasked : [];
    return { isPublishedToMe, randomResultOrder, unmasked };
  }

  static getEntryRequests(game) {
    const er = EntryRequests.entryRequestsFor(game) || new EntryRequests();
    return er.requests;
  }
  static getEntryRequestEntryKeys(game) {
    return Globals.getEntryRequests(game).map(er => er.entryKey);
  }
  static getEntryRequestPlayerNames(game) {
    return Globals.getEntryRequests(game).map(er => er.playerName);
  }
  static isWaitingForEntry(game) {
    const entryRequestKeys = Globals.getEntryRequestEntryKeys(game);
    return entryRequestKeys.includes(PathUtil.getEntryKeyParam());
  }

  static getTeamNamesArray(valuesString) {
    return Globals.getValueButtonsArray(valuesString);
  }

  static getValueButtonsArray(valuesString) {
    const temp = (valuesString || '').split(',');
    const result = (temp[0] === '' && temp.length === 1) ? [] : temp;
    return result.filter(f => f.length).map(m => m.trim());
  }

  static hasPlayerPlayed(playerName) {
    const game = GameStore.getGame();
    const result = ResultStore.getResult(playerName);
    return result || RoundData.fromGame(game).isRoundForcedEnd;
  }

  static sorterCreatedAt(a, b) {
    return Globals.sorterAny(a.createdAt, b.createdAt);
  }
  static sorterCreatedAtDesc(a, b) {
    return Globals.sorterAny(b.createdAt, a.createdAt);
  }

  static sorterAny(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
  static sorterAnyStringCaseInsensitive(a, b) {
    const a1 = typeof a === 'string' ? a.toLocaleLowerCase() : a;
    const b1 = typeof b === 'string' ? b.toLocaleLowerCase() : b;
    return Globals.sorterAny(a1, b1);
  }

  static listToCommaSeparatedString(list) {
    if (!list) return '';
    if (list.length === 1) return list[0];
    let result = '';
    list.forEach((e, i) => i === list.length - 1 ? result += e : result += `${e}, `);
    return result;
  }

  static shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

}