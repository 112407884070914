import { styled } from '@mui/material/styles';
import React from 'react';
import BoxCG from './box/BoxCG';

const classes = {
  root: 'PageLayout-root'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(1),
    overflow: 'auto',
    paddingLeft: 24,
    paddingRight: 24,
  }
}));

export default function PageLayout(props) {
  return (
    <StyledBoxCG className={classes.root} {...props}>
      {props.children}
    </StyledBoxCG>
  );
}