// NLS-NLS(controller)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import GameStore from '../../db/GameStore';
import PerformAutoScoringSelect from '../../db/PerformAutoScoringSelect';
import { useFreshScores } from '../../db/ScoreStore';
import GameOptions from '../../fields/GameOptions';
import ScoreOptions from '../../fields/ScoreOptions';

const classes = {
  root: 'AutoScoreSelectButton-root'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: theme.palette.secondary.contrastText,
  }
}));


export default function AutoScoreSelectButton(props) {

  const { game: gameIn, sx: sxIn, ...rest } = props;
  const game = gameIn || GameStore.getGame();
  const isScoreKeeper = Globals.isScoreKeeper(game);
  const gameOptions = new GameOptions(game.gameOptions);
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const scores = Object.values(useFreshScores()).filter(f => f.hand === game.currentHand && f.handScore);
  const { t } = useTranslation('controller');


  const handleAutoScore = () => {
    PerformAutoScoringSelect.perform();
  }

  if (!isScoreKeeper || !gameOptions.keepScore || !scoreOptions.useAutoScoreSelect) return null; // ==> EARLY EXIT
  return (
    <StyledButton className={classes.root} sx={sxIn} variant="contained" onClick={handleAutoScore}
      disabled={!!scores.length} {...rest}>
      {t('auto-score-selections')}
    </StyledButton>
  );
}
