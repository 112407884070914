// NLS-NLS(support)
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from "react-i18next";
import Globals from '../../appSupport/Globals';
import UserStore from '../../appSupport/UserStore';
import BoxCG from '../../components/box/BoxCG';
import SupportRequestLocalStore from '../../db/SupportRequestLocalStore';
import ConfirmationPrompt from '../../prompt/ConfirmationPrompt';
import ProgressPrompt from '../../prompt/ProgressPrompt';
import LandingBox from './LandingBox';
import LandingButton from './LandingButton';

export default function LandingSupport() {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = React.useState(false);
  const [isConfirmPlayTransactionsOpen, setIsConfirmPlayTransactionsOpen] = React.useState(false);
  const [isProgressOpen, setIsProgressOpen] = React.useState(false);
  const { t } = useTranslation('support');
  const isDefaultUser = Globals.isDefaultUser();
  const localTransactionCount = SupportRequestLocalStore.numberOfPendingTransactions();

  React.useEffect(() => {
    setIsConfirmPlayTransactionsOpen(!isDefaultUser);
  }, [isDefaultUser]);

  function handleDeleteButton() {
    setIsConfirmDeleteOpen(true);
  }
  function handleDeleteCancel() {
    setIsConfirmDeleteOpen(false);
  }
  function handleDeleteSave() {
    SupportRequestLocalStore.clearPendingTransactions();
    setIsConfirmDeleteOpen(false);
  }

  function handleLoginButton() {
    UserStore.loginWithUI();
  }
  function handlePlayTransactionsCancel() {
    UserStore.signInWithGameId(null); // Sign in with default credentials
  }
  async function handlePlayTransactionsSave() {
    setIsProgressOpen(true);
    setIsConfirmPlayTransactionsOpen(false);
    SupportRequestLocalStore.playPendingTransactions().then(a => {
      setIsProgressOpen(false);
      Globals.dispatchSupport({ localTransactionsPlayed: true });
    });
  }


  function renderLoginButton() {
    return (
      <LandingButton onClick={handleLoginButton} >
        {t('Login')}
      </LandingButton>
    );
  }
  function renderDeleteTransactionsButton() {
    return (
      <LandingButton onClick={handleDeleteButton} small>
        {t('Delete {{localTransactionCount}} pending Support Requests', { localTransactionCount })}
      </LandingButton>
    );
  }

  return (
    <BoxCG>
      <ProgressPrompt open={isProgressOpen} statusText={t('Please wait')} title={t('Creating support requests')} />
      <ConfirmationPrompt open={isConfirmDeleteOpen} onSave={handleDeleteSave} onClose={handleDeleteCancel}
        title={t('Confirm Delete')} text={t('Are you sure you would like to delete {{localTransactionCount}} pending Support Requests', { localTransactionCount })}
        continueButtonText={t('Delete')} />
      <ConfirmationPrompt open={isConfirmPlayTransactionsOpen} onSave={handlePlayTransactionsSave} onClose={handlePlayTransactionsCancel}
        title={t('Confirm Submit')} text={t('Would you like to submit {{localTransactionCount}} pending Support Requests under the user {{username}}?', { localTransactionCount, username: UserStore.getUserName() })}
        continueButtonText={t('Submit')} />
      <LandingBox >
        <Typography>{t('Login to submit {{localTransactionCount}} pending Support Requests', { localTransactionCount })}</Typography>
        {renderLoginButton()}
        <Typography>{t('--OR--')}</Typography>
        {renderDeleteTransactionsButton()}
      </LandingBox>
    </BoxCG>
  );
}
