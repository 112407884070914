import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../appSupport/Globals';

const localOnly = ['indent'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledLink = styled(Link, { shouldForwardProp: localOnlyTest })
  (({ theme, indent }) => ({
    fontSize: 18,
    marginBottom: theme.spacing(2),
    marginLeft: indent ? theme.spacing(indent) : undefined,
  }));

export default function SiteNav(props) {
  const { open = true, target, indent, children } = props;

  function showUnderConstruction() {
    Globals.showConfirmation({
      title: 'Under Construction!',
      text: "Haven't got to this yet",
      showCancelButton: false,
    })
  }
  function handleLink() {
    if (!target) {
      showUnderConstruction();
    }
  }

  if (!open) return null; // ==> EARLY EXIT
  return (
    <StyledLink indent={indent} to={target} onClick={handleLink} >
      {children}
    </StyledLink>
  );
}
