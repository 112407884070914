import { Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import UserAssistance from '../appSupport/UserAssistance';
import UserAssistanceUtil from '../appSupport/UserAssistanceUtil';
import IconAnimation from './IconAnimation';
import PopoverContainer from './PopoverContainer';

export default class UserAssistancePopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverContent: {},
      popoverOpen: false,
      popoverMessage: null,
      animateIconNow: false,
    };
  }

  componentDidMount() {
    const { userAssistance } = this.props;
    UserAssistanceUtil.listen(userAssistance, this.handlePopoverRequest);
  }
  componentWillUnmount() {
    const { userAssistance } = this.props;
    UserAssistanceUtil.stopListening(userAssistance, this.handlePopoverRequest);
  }

  handleClosePopover = () => {
    this.setState({ animateIconNow: false, popoverOpen: false });
  }

  handlePopoverRequest = (capsule) => {
    const { userAssistance } = this.props;
    if (UserAssistanceUtil.isInteresting(userAssistance, capsule)) {
      const { popoverMessage: prevPopoverMessage } = this.state;
      const { payload } = capsule;
      const { id, popoverText, messageType } = payload;
      const popoverOpen = Boolean(popoverText);
      const newState = { popoverContent: { channel: capsule.channel, popoverId: id, popoverText, messageType } };
      newState.popoverOpen = popoverOpen;
      newState.popoverMessage = popoverOpen ? popoverText : prevPopoverMessage;
      if (payload.toolGuidance && popoverText) {
        newState.animateIconNow = true;
      }
      this.setState(newState);
    }
  }

  render() {
    const { children } = this.props;
    const { popoverContent, popoverOpen, popoverMessage, animateIconNow } = this.state;
    const { messageType } = popoverContent;

    return (<React.Fragment>
      <div ref={(r) => { this.popoverAnchor = r }} style={{ position: 'relative' }}>
        {children}
        <IconAnimation animateNow={animateIconNow} />
      </div>
      <Popover
        id='popover-id'
        open={popoverOpen}
        anchorEl={this.popoverAnchor}
        onClose={this.handleClosePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <PopoverContainer messageType={messageType}>
          <Typography>
            {popoverMessage}
          </Typography>
        </PopoverContainer>
      </Popover>
    </React.Fragment>);
  }
}
UserAssistancePopover.propTypes = {
  onClick: PropTypes.func,
  userAssistance: PropTypes.arrayOf(PropTypes.shape(UserAssistance.tipShape))
};

UserAssistancePopover.defaultProps = {
  edge: 'start',
  color: 'inherit',
}
