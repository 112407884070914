import { Hub } from "aws-amplify";

class UserAssistanceUtil {
  listen(popovers, onMatch) {
    // Register one listener per channel
    const already = {};
    popovers.forEach(({ channel }) => {
      if (!already[channel]) {
        already[channel] = 'X';
        Hub.listen(channel, onMatch);
      }
    })
  }
  stopListening(popovers, onMatch) {
    // Register one listener per channel
    const already = {};
    popovers.forEach(({ channel }) => {
      if (!already[channel]) {
        already[channel] = 'X';
        Hub.remove(channel, onMatch);
      }
    })
  }
  isInteresting(popovers, capsule) {
    const { channel } = capsule;
    const { id } = capsule.payload;
    const hit = popovers.find(p => p.channel === channel && p.popoverId === id);
    return Boolean(hit);
  }
  closeMessage = (channel, popoverId) => {
    this.closeTip(channel, popoverId);
  }
  closeTip = (channel, popoverId) => {
    Hub.dispatch(channel, { id: popoverId, popoverText: null });
  }
}
export default new UserAssistanceUtil();
