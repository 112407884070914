// NLS-NLS(support)
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../../appSupport/UiUtil';
import TranslateBetterWordsPrompt from './TranslateBetterWordsPrompt';
import TranslateBleedThroughPrompt from './TranslateBleedThroughPrompt';

export default function TranslateToolPrompt(props) {
  const { open, onClose } = props;

  const [isBleedThroughPromptOpen, setIsBleedThroughPromptOpen] = React.useState(false);
  const [isBetterWordsPromptOpen, setIsBetterWordsPromptOpen] = React.useState(false);
  const { t } = useTranslation('support');

  const PROMPT_TITLE = t('translation-improvement');
  const PROMPT_TEXT_1 = t('are-you-seeing-english-bleed-t');
  const PROMPT_TEXT_2 = t('are-you-seeing-a-poor-choice-o');

  function handleClose() {
    onClose();
  }

  function handleBleedThrough() {
    setIsBleedThroughPromptOpen(true);
  }
  function handleBetterWords() {
    setIsBetterWordsPromptOpen(true);
  }

  return (
    <React.Fragment>
      <TranslateBleedThroughPrompt open={isBleedThroughPromptOpen} onClose={f => setIsBleedThroughPromptOpen(false)} />
      <TranslateBetterWordsPrompt open={isBetterWordsPromptOpen} onClose={f => setIsBetterWordsPromptOpen(false)} />
      <Dialog
        open={open}
        onClose={(event, reason) => UiUtil.onClose(event, reason, handleClose)}
      >
        <DialogTitle>{PROMPT_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText >{PROMPT_TEXT_1}</DialogContentText>
          <Button variant='contained' color='secondary'
            onClick={handleBleedThrough}>
            {t('report-english-bleed-through')}
          </Button>
          <DialogContentText style={{ marginTop: 20 }}>{PROMPT_TEXT_2}</DialogContentText>
          <Button variant='contained' color='secondary'
            onClick={handleBetterWords}>
            {t('request-better-words')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
