export default class AutoScoreSelect {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.answerAuthor = input ? input.answerAuthor : 1;
    // this.answerSelector = input ? input.answerSelector : undefined;

    this.enableReadersValues = input ? input.enableReadersValues : false;
    this.readersAnswerAuthor = input ? input.readersAnswerAuthor : undefined;
    this.readersAnswerSelector = input ? input.readersAnswerSelector : undefined;
    this.readersAnswerNoOne = input ? input.readersAnswerNoOne : undefined;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

