import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import SequenceTitle from '../siteComponents/SequenceTitle';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import { siteRoutesFull } from '../../routing/siteRoutes';
import SiteNav from '../siteComponents/SiteNav';

const gameSettings = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameSettings.png';

export default function FlowConfigure(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <SiteSection>
      <SequenceTitle sequence='3' title={t('Configure the game')} />
      <div>
        <Asset src={gameSettings} mobile={mobile} />
        <StyleParagraph>{t("The game controller can configure BuenoFun to accomplish various game play needs.  Pressing the Settings button in the header next to the player's name gets you to these settings.")}</StyleParagraph>
        <SiteNav target={siteRoutesFull.setupTips}>We have a page full of setup tips</SiteNav>
      </div>
    </SiteSection>
  );
}
