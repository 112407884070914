import React from 'react';
import useMonitorGame from '../../appSupport/monitorGame';
import useMonitorGameState from '../../appSupport/monitorGameState';
import RecentGame from '../../fields/RecentGame';
import Loading from '../Loading';
import Results from './Results';

export default function ReadyResults() {
  const game = useMonitorGame();
  useMonitorGameState(game);

  if (!game) return <Loading /> // ==> EARLY EXIT

  RecentGame.setRecentGame();

  return <Results game={game} />
}
