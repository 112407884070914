import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import TimerValues, { ANSWER_TIMER } from '../fields/TimerValues';
import BoxR from './box/BoxR';

const classes = {
  root: 'PlayTimer-root',
  timer: 'PlayTimer-timer'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center'
  },
  [`& .${classes.timer}`]: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
}));

const TICKS = 200;

export default function PlayTimer(props) {
  const { game, open, onTimerExpired, whichTimer, sx: sxIn } = props;
  const [progress, setProgress] = React.useState(0);
  const [remainingSeconds, setRemainingSeconds] = React.useState(0);
  const [passedSeconds, setPassedSeconds] = React.useState(0);
  const timerHasExpired = React.useRef(false);
  const timer = React.useRef(null);

  const timerValues = TimerValues.newTimerValues(whichTimer, game);
  const isValidTimer = open && timerValues.hasTime();

  function clearTimer() {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  }
  React.useEffect(() => {
    function defaultPassedSeconds() { return timerValues.totalTime - timerValues.remainingTime }
    function passedFromCrossView() {
      // If start times match, adjustment counts

      const cva = PlayTimer.crossViewAdjustment;
      if (!cva) return defaultPassedSeconds();  // ==> EARLY EXIT

      if (timerValues.startTime === cva.timerValues.startTime) {
        const already = (new Date().getTime() - cva.localStartTime.getTime()) / 1000;
        return defaultPassedSeconds() + already;
      }
      return defaultPassedSeconds();
    }
    function progressFromCrossView() {
      // If start times match, adjustment counts
      const cva = PlayTimer.crossViewAdjustment;
      if (!cva) return 0;  // ==> EARLY EXIT

      if (timerValues.startTime === cva.timerValues.startTime) {
        const already = new Date().getTime() - cva.localStartTime.getTime();
        const result = already / (cva.timerValues.remainingTime * 1000) * 100;
        return result;
      }
      return 0;
    }
    clearTimer();
    const timerValues = new TimerValues(whichTimer === ANSWER_TIMER ? game.answerTimerValues : game.questionTimerValues);
    if (timerValues.isReady()) setPassedSeconds(0);
    if (timerValues.isExpired()) setPassedSeconds(timerValues.totalTime);
    if (timerValues.isPaused()) setPassedSeconds(defaultPassedSeconds());
    if (timerValues.isRunning()) setPassedSeconds(defaultPassedSeconds());
    if (timerValues.isReady()) setProgress(0);
    setRemainingSeconds(timerValues.remainingTime);
    if (!isValidTimer || timerValues.isExpired()) return undefined;  // ==> EARLY EXIT 
    if (!timerValues.isRunning()) return undefined; // ==> EARLY EXIT 
    const increment = 100 / (timerValues.remainingTime * 1000 / TICKS);

    setProgress(progressFromCrossView());
    setPassedSeconds(passedFromCrossView());

    timerHasExpired.current = false;
    if (!PlayTimer.crossViewAdjustment || (timerValues.startTime !== PlayTimer.crossViewAdjustment.timerValues.startTime)) {
      PlayTimer.crossViewAdjustment = { timerValues, localStartTime: new Date() }
    }
    timer.current = setInterval(() => {
      setPassedSeconds(oldPassedSeconds => {
        const newPassedSeconds = oldPassedSeconds + TICKS / 1000;
        const newRemaining = timerValues.totalTime - newPassedSeconds;
        setRemainingSeconds(newRemaining);
        return newPassedSeconds;
      });
      setProgress((oldProgress) => {
        return (oldProgress / 1000 >= 100) ? 999 : oldProgress + increment;
      });
    }, TICKS);

    return clearTimer;
  }, [game.questionTimerValues, game.answerTimerValues, isValidTimer, whichTimer]);


  if (progress > 100 || timerValues.isExpired()) {
    if (!timerHasExpired.current) {
      timerHasExpired.current = true;
      clearTimer()
      if (onTimerExpired) {
        onTimerExpired();
      }
    }
  }

  if (!isValidTimer) return null; // ==> EARLY EXIT

  return (
    <StyledBoxR className={classes.root} sx={sxIn}>
      <p>{Math.round(passedSeconds)}</p>
      <LinearProgress className={classes.timer} variant="determinate" value={progress} />
      <p>{Math.round(remainingSeconds)}</p>
    </StyledBoxR>
  );
}

PlayTimer.defaultProps = {
  open: true,
}
