import { styled } from '@mui/material/styles';
import { DialogContentText, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import SupportRequestOps, { STATUS_DEFER, STATUS_INVALID, STATUS_RESOLVED, STATUS_REVIEW, STATUS_WAIT_CLIENT, STATUS_WAIT_SUPPORT } from '../../db/SupportRequestOps';
import SupportRequestStore, { useFreshSupportRequests } from '../../db/SupportRequestStore';
import ProgressPrompt from '../ProgressPrompt';
import { useTranslation } from 'react-i18next';
import SRDialogEntry from '../../fields/support/SRDialogEntry';
import Globals from '../../appSupport/Globals';
import ConfirmationPrompt from '../ConfirmationPrompt';
import UiUtil from '../../appSupport/UiUtil';

const SecondarySpan = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const ID_NEW_STATUS = 'status';
const ID_COMMENT = 'comment';

const EMPTY_STAGED_VALUES = {};

export default function SupportRequestPrompt(props) {

  const { open, onClose, supportRequestIds } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [isProgressOpen, setIsProgressOpen] = React.useState(false);
  const { t } = useTranslation('support');
  const supportRequests = useFreshSupportRequests();
  const isProductSupporter = Globals.isProductSupporter();
  const isOnlyOnePromptOpen = !isProductSupporter && (supportRequestIds.length > 1);


  function requestForId(id) {
    const result = supportRequests.find(f => f.id === id);
    return result ? result : {};
  }
  const clearValidations = () => {
  }
  const validations = {
    performValidate: () => {
      clearValidations();
      return false;
    }
  }
  const handleCancel = () => {
    setStagedValues(EMPTY_STAGED_VALUES);
    clearValidations();
    onClose();
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = value;
    setStagedValues(newStaged);
  }
  const handleSave = () => {
    const newComment = stagedValues[ID_COMMENT];
    function buildNewPayload(forId) {
      const sr = requestForId(forId);
      const payloadObj = JSON.parse(sr.payload);
      const newDialog = [...payloadObj.dialog];
      newDialog.push(JSON.stringify(SRDialogEntry.newComment(newComment)));
      payloadObj.dialog = newDialog;
      return JSON.stringify(payloadObj);
    }
    if (!validations.performValidate()) {
      setIsProgressOpen(true);
      const all = supportRequestIds.map(async m => {
        const newStatus = isProductSupporter ? stagedValues[ID_NEW_STATUS] : STATUS_WAIT_SUPPORT;
        const newPayload = newComment ? buildNewPayload(m) : undefined;
        return await SupportRequestStore.updateStatus(m, newStatus, requestForId(m).status, newPayload);
      });
      Promise.all(all).then(() => {
        clearValidations();
        setStagedValues(EMPTY_STAGED_VALUES);
        setIsProgressOpen(false);
        onClose();
      });
    }
  }
  const handleRadioValueChange = (event) => {
    const { value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[ID_NEW_STATUS] = value;
    setStagedValues(newStaged);
  }

  if (!open) return null;
  const prevStatus = supportRequestIds.length === 1 ? requestForId(supportRequestIds[0]).prevStatus : null;

  const title = isProductSupporter ? t('update-support-request') : t('add-to-the-conversation');
  const x = supportRequestIds.length;
  return (
    <div>
      <ConfirmationPrompt open={isOnlyOnePromptOpen} onClose={handleCancel} showCancelButton={false}
        title={t('single-selection-required')}
        text={t('you-may-only-select-one-record')} />
      <ProgressPrompt open={isProgressOpen} statusText={t('updating-x-records', { x })} title={t('updating-support-requests')} />
      <Dialog open={open && !isOnlyOnePromptOpen} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(400, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ display: supportRequestIds.length === 1 ? 'none' : 'normal' }}>
            <SecondarySpan>{t('updating-x-records', { x })}</SecondarySpan>
          </DialogContentText>
          {prevStatus ? <Typography style={{ marginBottom: 10 }}>{`Previous status: ${prevStatus}`}</Typography> : null}
          <FormControl style={{ display: isProductSupporter ? 'normal' : 'none' }} component="fieldset">
            <FormLabel component="legend">{t('New Status')}</FormLabel>
            <RadioGroup aria-label="status" name="status" value={stagedValues[ID_NEW_STATUS]} onChange={handleRadioValueChange}>
              <FormControlLabel value={STATUS_WAIT_CLIENT} control={<Radio />} label={SupportRequestOps.getStatusText(STATUS_WAIT_CLIENT)} />
              <FormControlLabel value={STATUS_REVIEW} control={<Radio />} label={SupportRequestOps.getStatusText(STATUS_REVIEW)} />
              <FormControlLabel value={STATUS_RESOLVED} control={<Radio />} label={SupportRequestOps.getStatusText(STATUS_RESOLVED)} />
              <FormControlLabel value={STATUS_DEFER} control={<Radio />} label={SupportRequestOps.getStatusText(STATUS_DEFER)} />
              <FormControlLabel value={STATUS_INVALID} control={<Radio />} label={SupportRequestOps.getStatusText(STATUS_INVALID)} />
            </RadioGroup>
          </FormControl>
          <TextField fullWidth multiline rows={3} onChange={handleValueChange}
            id={ID_COMMENT} label={t("comment")} type="text" defaultValue={stagedValues[ID_COMMENT]} margin='normal' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" type="submit" autoFocus >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}