import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import React from 'react';
import Globals from '../appSupport/Globals';
import PendingPlayerStore from '../db/PendingPlayerStore';
import AnswerAnnotations from '../fields/AnswerAnnotations';
import BoxR from './box/BoxR';

const classes = {
  root: 'PlayerNotes-root',
  chip: 'PlayerNotes-chip',
  chipOwner: 'PlayerNotes-chipOwner'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexWrap: 'wrap',
  },
  [`& .${classes.chip}`]: {
    margin: 2,
    backgroundColor: theme.palette.notesChip.light,
    color: theme.palette.notesChip.contrastText,
    // backgroundColor: '#fcd9d9',
  },
  [`& .${classes.chipOwner}`]: {
    margin: 2,
    backgroundColor: theme.palette.notesChip.main,
    color: theme.palette.notesChip.contrastText,
    // backgroundColor: '#fcd9d9',
  }
}));


export default function PlayerNotes(props) {
  const { playerResult, publicNotes, sx: sxIn } = props;

  const notes = [];
  const playerName = Globals.getPlayerName();
  const playerCount = PendingPlayerStore.getPlayerCount();
  // Cycle through each set of annotations and collect names & notes
  for (let playerNumber = 1; playerNumber <= playerCount; playerNumber++) {
    const noteTakerName = Globals.getPlayerName(playerNumber);
    const aa = AnswerAnnotations.forPlayerNumber(playerResult, playerNumber);
    if (aa && aa.answerNoteText) {
      if (publicNotes || (playerName === noteTakerName)) {
        notes.push({ noteTakerName, text: aa.answerNoteText, isThisPlayersNote: (noteTakerName === playerName) });
      }
    }
  }
  const listPlayers = () => {
    return notes.map(m => {
      if (m.isThisPlayersNote) {
        const label = m.text;
        const className = publicNotes ? classes.chipOwner : classes.chip;
        return <Chip key={label} label={label} className={className} />
      } else {
        const label = `${m.noteTakerName}-${m.text}`;
        return <Chip key={label} label={label} className={classes.chip} />
      }
    });
  }

  return (
    <StyledBoxR className={classes.root} sx={sxIn}>
      {listPlayers()}
    </StyledBoxR>
  );
}