import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import React from 'react';
import SRDialogEntry, { AUTHOR_CLIENT } from '../../../fields/support/SRDialogEntry';
import BoxC from '../../box/BoxC';
import BoxR from '../../box/BoxR';
import LabelAndData from '../../LabelAndData';
import ClientIcon from '@mui/icons-material/Face';
import SupportIcon from '@mui/icons-material/AccountBox';
import { useTranslation } from 'react-i18next';

const classes = {
  root: 'SreDialog-root',
  clientIcon: 'SreDialog-clientIcon',
  supportIcon: 'SreDialog-supportIcon',
  commentText: 'SreDialog-commentText'
};

const StyledBoxC = styled(BoxC)(({ theme }) => ({
  [`& .${classes.clientIcon}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${classes.supportIcon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.commentText}`]: {
    marginLeft: theme.spacing(2),
    // fontSize: 22,
  }
}));

export default function SreDialog(props) {
  const { dialogArray, sx: sxIn, ...rest } = props;

  const { t } = useTranslation('support');

  function RenderClientIcon({ time }) {
    return (
      <ClientIcon key={time + 'icon'} className={classes.clientIcon} />
    );
  }
  function RenderSupportIcon({ time }) {
    return (
      <SupportIcon key={time + 'icon'} className={classes.supportIcon} />
    );
  }

  function RenderEntry({ entry: de }) {
    const result = [];
    result.push(<BoxR key={de.time + 'misc'}>
      {de.author === AUTHOR_CLIENT ? <RenderClientIcon time={de.time} /> : <RenderSupportIcon time={de.time} />}
      <LabelAndData key={de.time + 'realTime'} label={t('when-colon')} data={new Date(de.time).toLocaleString()} />
    </BoxR>);
    result.push(<Typography className={classes.commentText} key={de.time + 'comment'} >{de.comment}</Typography>);
    return result;
  }
  if (!dialogArray.length) return null; // ==> EARLY EXIT
  return (
    <StyledBoxC className={classes.root} sx={sxIn} {...rest}>
      {dialogArray.map((m, i) => {
        const entry = new SRDialogEntry(m);
        return (<RenderEntry key={entry.time + 'root'} entry={entry} />);
      })}
    </StyledBoxC>
  );
}
