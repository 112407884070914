// NLS-NLS(controller)
// Derived from Sorting & Selecting example: https://material-ui.com/components/tables/
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { lighten } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

const classes = {
  root: 'TableList-root',
  tableContainer: 'TableList-tableContainer',
  highlight: 'TableList-highlight',
  title: 'TableList-title'
};
const toolbarClasses = {
  root: 'TableList-toolbar-root',
  highlight: 'TableList-toolbar-highlight',
  title: 'TableList-toolbar-title'
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  [`& .Mui-selected`]: theme.palette.mode === 'light'
    ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    }
    : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  [`& .Mui-selected:hover`]: theme.palette.mode === 'light'
    ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    }
    : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  [`& .${classes.tableContainer}`]: {
  },

  [`& .${classes.title}`]: {
    flex: '1 1 100%',
  }
}));

export const formatCellValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? <CheckBoxIcon color='disabled' /> : <CheckBoxOutlineBlankIcon color='disabled' />;
  }
  return value;
}

const headerPropType = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.any,
  justify: PropTypes.string,
  disablePadding: PropTypes.bool,
  canSort: PropTypes.bool,
  label: PropTypes.string,
})).isRequired;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
    onRowSelection, rowCollapseRenderer, enableSelectAllHeaderTool } = props;
  const { headers: headCells } = props;
  const allowSelection = Boolean(onRowSelection);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const renderHeaderField1 = (allowSelection, enableSelectAllHeaderTool, rowCollapseRenderer, numSelected, rowCount, onSelectAllClick) => {
    const result = [];
    if (allowSelection) {
      const hiddenStyle = enableSelectAllHeaderTool ? {} : { display: 'none' };
      result.push(
        <TableCell padding="checkbox" key='headerCellCB'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            style={hiddenStyle}
          />
        </TableCell>
      );
    }
    if (rowCollapseRenderer) {
      result.push(<TableCell padding="checkbox" key='headerCellCR' />);
    }
    return result;
  }
  return (
    <TableHead>
      <TableRow>
        {renderHeaderField1(allowSelection, enableSelectAllHeaderTool, rowCollapseRenderer, numSelected, rowCount, onSelectAllClick)}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.justify}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canSort ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
              : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCollapseRenderer: PropTypes.func,
  onRequestSort: PropTypes.func.isRequired,
  onRowSelection: PropTypes.func,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  headers: headerPropType,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`&.${toolbarClasses.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  [`&.${toolbarClasses.highlight}`]: theme.palette.mode === 'light'
    ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    }
    : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  [`& .${toolbarClasses.title}`]: {
    flex: '1 1 100%',
  }
}));

const EnhancedTableToolbar = (props) => {
  const { numSelected, title, oneSelectedTools, moreSelectedTools, noneSelectedTools } = props;
  const { t } = useTranslation('controller');

  let activeTools;
  if (numSelected === 0) {
    activeTools = noneSelectedTools;
  } else {
    activeTools = (numSelected === 1 ? oneSelectedTools : moreSelectedTools);
  }
  return (
    <StyledToolbar
      className={clsx(toolbarClasses.root, { [toolbarClasses.highlight]: numSelected > 0, })} >
      {numSelected > 0 ? (
        <Typography className={toolbarClasses.title} color="inherit" variant="subtitle1" component="div">
          {t('x-selected', { x: numSelected })}
        </Typography>
      ) : (
        <Typography className={toolbarClasses.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}

      {activeTools}
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  moreSelectedTools: PropTypes.array,
  noneSelectedTools: PropTypes.array,
  oneSelectedTools: PropTypes.array
};

const NoDataView = (props) => {
  const { rowCount, noDataMessage, noDataRenderer, headers } = props;
  if (rowCount > 0) {
    return null;
  }
  const cellText = noDataMessage ? noDataMessage : 'No records exist';
  return (
    <TableRow
      key='noDataRow'
      hover
      tabIndex={-1}
    >
      <TableCell colSpan={headers.length + 1}>
        {noDataRenderer ? noDataRenderer() : cellText}
      </TableCell>
    </TableRow>
  );
}
NoDataView.propTypes = {
  rowCount: PropTypes.number.isRequired,
  noDataMessage: PropTypes.string,
  noDataRenderer: PropTypes.func,
};


export default function EnhancedTable(props) {
  const { dense, rows, paginate, paginatePageSize, headers, initialSortName, title, rowRenderer, rowCollapseRenderer,
    noDataMessage, noDataRenderer, oneSelectedTools, moreSelectedTools, noneSelectedTools, onRowSelection,
    selectedRows: selected, disableFlexSizing, enableSelectAllHeaderTool, hidePaper } = props;
  const allowSelection = Boolean(onRowSelection);
  const noDataProps = { rowCount: rows.length, noDataMessage, noDataRenderer, headers }

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(initialSortName);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(paginate ? paginatePageSize : 10000);
  const [maxHeightTable, setMaxHeightTable] = React.useState(undefined);
  const [windowSize, setWindowSize] = React.useState({ width: 0, height: 0 });
  const [resizingNow, setResizingNow] = React.useState(false);
  const [collapseOpenSet, setCollapseOpenSet] = React.useState({});

  // const [debugString, setDebugString] = React.useState('initialDebugString');
  // const [debugCount, setDebugCount] = React.useState(0);

  // This entire useEffect exist because I was unable to get the TableContainer to "flex". So this code
  // sets a maxHeightTable state variable that is used for the maxHeight on the TableContainer.  This 
  // process is complicated if the window is getting smaller, as the maxHeight prevents the natural 
  // sizing from occurring.  Thus we set the maxHeight to 0 if we are resizing which unfortunately results
  // in a blinky resize.
  const tableWrapperRef = React.createRef();
  const wrapperOverage = 20;
  useEffect(() => {
    let debounceTimerId = null;
    let previousWindowHeight = window.innerHeight;
    const tableWrapperElement = tableWrapperRef.current || {};
    function handleResize() {
      // setDebugString(previousWindowHeight.toString() + ' ' + window.innerHeight.toString());
      // console.log(previousWindowHeight, window.innerHeight);
      if (previousWindowHeight !== window.innerHeight) {
        clearTimeout(debounceTimerId);
        if (previousWindowHeight - window.innerHeight > wrapperOverage) { // If sizing smaller
          setResizingNow(true);
        }
        debounceTimerId = setTimeout(() => {
          setWindowSize({ width: window.innerWidth, height: window.innerHeight });
          setResizingNow(false);
        }, 300); // 300 is less blinky than 200
      }
    }

    if (!disableFlexSizing) {
      window.addEventListener('resize', handleResize);
      // -wrapperOverage A) defeats occasional outside scroller, B) Allows wrapper to shrink even
      // though table has a maxSize. Only set maxHeight to 0 if there is a big window size change
      const measuredHeight = tableWrapperElement.clientHeight - wrapperOverage;
      if (resizingNow) {
        setMaxHeightTable(0);
      } else {
        setMaxHeightTable(measuredHeight);
      }
    }
    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    }

  }, [resizingNow, windowSize, tableWrapperRef, disableFlexSizing]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    let newSelected = [];
    if (event.target.checked) {
      newSelected = rows.map((n) => n.id);
    }
    if (onRowSelection) {
      onRowSelection(newSelected);
    }
  };

  const handleClick = (event, id, onRowSelection) => {
    if (!event.defaultPrevented) {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      if (onRowSelection) {
        onRowSelection(newSelected);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderSelectionControlCell = (allowSelection, isItemSelected) => {
    return allowSelection ?
      (<TableCell padding="checkbox" key='checkBoxCell'>
        <Checkbox
          checked={isItemSelected}
        />
      </TableCell>)
      : null;
  }
  const renderCollapseControlCell = (row, rowCollapseRenderer) => {
    function toggleCollapse(event, row) {
      event.preventDefault();
      const newCollapseOpen = { ...collapseOpenSet };
      newCollapseOpen[row.id] = Boolean(!collapseOpenSet[row.id]);
      setCollapseOpenSet(newCollapseOpen);
    }
    const isCollapseOpen = Boolean(collapseOpenSet[row.id]);
    return rowCollapseRenderer ?
      (<TableCell key='collapseControlCell'>
        <IconButton aria-label="expand row" size="small" onClick={(event) => toggleCollapse(event, row)}>
          {isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>)
      : null;
  }
  const renderCollapseRow = (row, rows, rowCollapseRenderer, headers) => {
    const isCollapseOpen = collapseOpenSet[row.id];
    if (!isCollapseOpen || !rowCollapseRenderer) {
      return null;
    }
    const factor = 1 + (onRowSelection ? 1 : 0); // col for checkbox & col for collapse control
    return (
      <TableRow key={`${row.id}-collapse`}>
        <TableCell key='collapseControlCell' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headers.length + factor}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            {rowCollapseRenderer(row, rows)}
          </Collapse>
        </TableCell>
      </TableRow>);
  }
  const renderTableCells = (row, rows, headers, rowRenderer, rowCollapseRenderer, allowSelection) => {
    if (rowRenderer) {
      return rowRenderer(row, rows);
    }
    const result = headers.map((headerCell, index) => {
      const value = row[headerCell.id];
      const formattedValue = headerCell.formatter ? headerCell.formatter(value) : formatCellValue(value);
      return (
        <TableCell align={headerCell.justify} key={headerCell.id} padding={headerCell.disablePadding ? 'none' : 'normal'}>
          {formattedValue}
        </TableCell>
      );
    });
    return result;
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = paginate ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage) : 0;
  const hidePaperProps = hidePaper ? { elevation: 0, style: { backgroundColor: 'transparent' } } : undefined;
  return (
    <StyledPaper className={classes.root} {...hidePaperProps}>
      <div>
        {title || allowSelection ?
          <EnhancedTableToolbar numSelected={selected.length} title={title} oneSelectedTools={oneSelectedTools}
            moreSelectedTools={moreSelectedTools} noneSelectedTools={noneSelectedTools} />
          : null}
      </div>
      <div ref={tableWrapperRef} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} >
        <TableContainer className={classes.tableContainer} style={{ maxHeight: maxHeightTable }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            {headers.length ?
              <EnhancedTableHead
                classes={classes}
                allowSelection={allowSelection}
                enableSelectAllHeaderTool={enableSelectAllHeaderTool}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowCollapseRenderer={rowCollapseRenderer}
                onRowSelection={onRowSelection}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headers={headers}
              /> : null}
            <TableBody className={classes.tableBody}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage, rows.length)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <React.Fragment key={`Row-${row.id}`}>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id, onRowSelection)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {renderSelectionControlCell(allowSelection, isItemSelected)}
                        {renderCollapseControlCell(row, rowCollapseRenderer)}
                        {renderTableCells(row, rows, headers, rowRenderer, rowCollapseRenderer, allowSelection)}
                      </TableRow>
                      {renderCollapseRow(row, rows, rowCollapseRenderer, headers)}
                    </React.Fragment>
                  );
                })}
              <NoDataView {...noDataProps} />
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={headers.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {paginate && <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
    </StyledPaper>
  );
}

EnhancedTable.propTypes = {
  classes: PropTypes.object,
  headers: headerPropType,
  rowRenderer: PropTypes.func,
  rowCollapseRenderer: PropTypes.func,
  noDataMessage: PropTypes.string,
  noDataRenderer: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  dense: PropTypes.bool,
  paginate: PropTypes.bool,
  paginatePageSize: PropTypes.number,
  initialSortName: PropTypes.string,
  title: PropTypes.string,
  moreSelectedTools: PropTypes.array,
  noneSelectedTools: PropTypes.array,
  oneSelectedTools: PropTypes.array,
  onRowSelection: PropTypes.func,
  selectedRows: PropTypes.array,
  disableFlexSizing: PropTypes.bool,
  enableSelectAllHeaderTool: PropTypes.bool,
};

EnhancedTable.defaultProps = {
  classes: {},
  headers: [],
  dense: false,
  paginate: false,
  moreSelectedTools: [],
  noneSelectedTools: [],
  oneSelectedTools: [],
  selectedRows: [],
  disableFlexSizing: false,
};


