import { styled } from '@mui/material/styles';
import { Hidden } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Globals from '../../appSupport/Globals';
import BoxR from '../box/BoxR';
import PlayerName, { PLAY_WAIT_LIST } from '../PlayerName';
import TableList from '../TableList';
import RowCell from './RowCell';

const classes = {
  root: 'AddPlayerTableList-root',
  centerBox: 'AddPlayerTableList-centerBox'
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.centerBox}`]: {
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const loadingOrNoData = f => null;

export default function AddPlayerTableList(props) {
  const { rows, ...rest } = props;

  const playerName = Globals.getPlayerName();

  function rowRenderer(row, rows) {
    const isLastRow = row === rows[rows.length - 1];
    return (
      <RowCell showDivider={!isLastRow} align='center'>
        <BoxR className={classes.centerBox}>
          <PlayerName playerName={row.playerName} size={PLAY_WAIT_LIST} isMe={row.playerName === playerName} />
        </BoxR>
      </RowCell>
    );
  }

  const tools = [];
  const fullTableProps = {
    rows, rowRenderer,
    dense: true,
    hidePaper: true,
    selectedRows: [],
    disableFlexSizing: true,
    noDataRenderer: loadingOrNoData,
    oneSelectedTools: tools,
    moreSelectedTools: tools,
    noneSelectedTools: tools,
  };
  const mobileTableProps = { ...fullTableProps }

  return (
    <Root className={classes.root} {...rest} >
      <Hidden smDown>
        <TableList {...fullTableProps} />
      </Hidden>
      <Hidden smUp>
        <TableList {...mobileTableProps} />
      </Hidden>
    </Root>
  );
}

AddPlayerTableList.propTypes = {
  selectedRows: PropTypes.array,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string,
  onRowSelection: PropTypes.func,
  isLoading: PropTypes.bool,
};