import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loading from '../page/Loading';
import { gameRoutes } from './gameRoutes';

export default function LoadingRouter(props) {
  return (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Routes>
        <Route path={gameRoutes.loading} replace
          element={<Loading showHeader={false} />} />
        <Route path='*'
          element={<Navigate replace to={gameRoutes.loading} />} />
      </Routes>
    </div>
  );
}