import i18n from "i18next";
import Pseudo from 'i18next-pseudo';
import { initReactI18next } from "react-i18next";
import SupportedLanguages from './i18n/SupportedLanguages';

// "|'|`

// Pseudo... see https://github.com/MattBoatman/i18next-pseudo
const initI18n = (language = 'en', pseudoMode = false) => {
  const resources = { [language]: SupportedLanguages.allLanguages()[language].resources };
  if (language !== 'en') {
    resources.en = SupportedLanguages.allLanguages().en.resources;
  }
  i18n
    .use(new Pseudo({
      enabled: pseudoMode,
      languageToPseudo: 'en',
      letterMultiplier: 2,
    }))
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: 'en',
      ns: ['controller', 'player', 'help', 'support', 'login', 'site'],
      defaultNS: 'player',
      resources,
      lng: language,
      nsSeparator: false, // we do not use name space separators in key (allows : in fallback strings)
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      postProcess: ['pseudo']
    });
}

export default initI18n;