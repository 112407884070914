import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import nlsStore from '../appSupport/NlsStore';
import UiUtil from '../appSupport/UiUtil';
import UserStore from '../appSupport/UserStore';
import BoxCG from '../components/box/BoxCG';
import BoxR from '../components/box/BoxR';
import BoxRG from '../components/box/BoxRG';
import BusyLink from '../components/BusyLink';
import PasswordField from '../components/PasswordField';
import ProgressPrompt from '../prompt/ProgressPrompt';
import PromptMessage from '../prompt/PromptMessage';

const classes = {
  container: 'LoginPage-container',
  dialog: 'LoginPage-dialog',
  button: 'LoginPage-button',
  actions: 'LoginPage-actions',
  linkBox: 'LoginPage-linkBox',
  textRow: 'LoginPage-textRow'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.dialog}`]: {
    maxWidth: 400,
  },
  [`& .${classes.button}`]: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    color: theme.palette.secondary.contrastText,
  },
  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(1),
  },
  [`& .${classes.linkBox}`]: {
    marginTop: theme.spacing(3),
  },
  [`& .${classes.textRow}`]: {
    fontSize: 18,
    alignItems: 'center',
  }
}));

const ID_USER = 'userName';
const ID_PASSWORD = 'password';
const ID_VERIFY_CODE = 'verifyCode';

export default function LoginPage() {
  const { t } = useTranslation('login');
  const LOGIN_TITLE = t('login');
  const LOGIN_DESC = nlsStore.getValuePlayer('a-login-is-not-required-to-hos');
  const SIGNUP_TITLE = t('signup');
  const SIGNUP_DESC = t('your-account-will-be-used-to-s');
  const VERIFY_TITLE = t('verify-email');
  const VERIFY_DESC = t('a-code-has-been-emailed-enter-');
  const FORGOT_TITLE = t('forgot-password');
  const FORGOT_DESC = t('a-code-has-been-emailed-enterP');

  const [dialogTitle, setDialogTitle] = React.useState(LOGIN_TITLE);
  const [dialogDesc, setDialogDesc] = React.useState(LOGIN_DESC);
  const [progressTitle, setProgressTitle] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [verifyCode, setVerifyCode] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  function toForgotMode() {
    setDialogTitle(FORGOT_TITLE);
    setDialogDesc(FORGOT_DESC);
  }
  function toSignupMode() {
    setDialogTitle(SIGNUP_TITLE);
    setDialogDesc(SIGNUP_DESC);
  }
  function toLoginMode() {
    setDialogTitle(LOGIN_TITLE);
    setDialogDesc(LOGIN_DESC);
  }
  function toVerifyMode() {
    setDialogTitle(VERIFY_TITLE);
    setDialogDesc(VERIFY_DESC);
  }
  function handleCancel() {
    if (isSignupMode || isVerifyMode) {
      toLoginMode();
    } else {
      setProgressTitle(t('cancelling'));
      UserStore.signInWithGameId(null);
    }
  }
  async function handleLogin() {
    setErrorMessage('');
    if (!password) {
      setErrorMessage(t('password-cannot-be-empty'));
    } else {
      setProgressTitle(t('logging-in'));
      try {
        await Auth.signIn(username, password.trim());
      } catch (error) {
        setProgressTitle('');
        if (error.code === 'UserNotConfirmedException') {
          toVerifyMode();
        } else {
          setErrorMessage(error.message);
        }
      }
    }
  }
  async function handleCreateAccount() {
    setErrorMessage('');
    if (!password) {
      setErrorMessage(t('password-cannot-be-empty'));
    } else if (password.trim().length < 8) {
      setErrorMessage(t('password-must-be-8-characters-'));
    } else {
      setProgressTitle(t('creating-account'));
      try {
        await Auth.signUp({
          username,
          password: password.trim(),
          attributes: {
            email: username,
          }
        });
        setProgressTitle('');
        toVerifyMode();
      } catch (error) {
        setErrorMessage(error.message);
        setProgressTitle('');
      }
    }
  }
  async function handleForgotPassword() {
    if (!username) {
      setErrorMessage(t('enter-your-email'));
    } else {
      setErrorMessage('');
      setProgressTitle(t('forgot-password-ellipse'));
      try {
        await Auth.forgotPassword(username);
        toForgotMode();
        setProgressTitle('');
      } catch (error) {
        setErrorMessage(error.message);
        setProgressTitle('');
      }
    }
  }

  async function handleForgotReset() {
    if (!password) {
      setErrorMessage(t('password-cannot-be-empty'));
    } else if (password.trim().length < 8) {
      setErrorMessage(t('password-must-be-8-characters-'));
    } else {
      setErrorMessage('');
      setProgressTitle(t('forgot-password-reset'));
      try {
        await Auth.forgotPasswordSubmit(username, verifyCode, password)
        await Auth.signIn(username, password.trim());
      } catch (error) {
        setErrorMessage(error.message);
        setProgressTitle('');
      }
    }
  }

  function handleUserChange(event) {
    const { value } = event.target;
    setUsername(value.trim().toLowerCase());
  }
  function handlePasswordChange(event) {
    const { value } = event.target;
    setPassword(value);
  }
  function handleVerifyCodeChange(event) {
    const { value } = event.target;
    setVerifyCode(value);
  }
  function handleSignup() {
    setErrorMessage('');
    toSignupMode();
  }
  async function handleVerify() {
    setErrorMessage('');
    setProgressTitle(t('complete-setup'));
    try {
      await Auth.confirmSignUp(username, verifyCode);
      toLoginMode();
      await Auth.signIn(username, password.trim());
    } catch (error) {
      setErrorMessage(error.message);
      setProgressTitle('');
    }
  }
  async function handleResendCode() {
    setErrorMessage('');
    setProgressTitle(t('resending-code'));
    try {
      await Auth.resendSignUp(username);
      setProgressTitle('');
    } catch (error) {
      setErrorMessage(error.message);
      setProgressTitle('');
    }
  }
  function RenderCancelButton() {
    return (
      <Button className={classes.button} variant="contained" onClick={handleCancel} >
        {t('cancel')}
      </Button>
    );
  }
  function RenderCreateAccountButton() {
    if (!isSignupMode) return null; // ==> EARLY EXIT
    return (
      <Button className={classes.button} variant="contained" onClick={handleCreateAccount} >
        {t('create-account')}
      </Button>
    );
  }
  function RenderLoginButton() {
    if (!isLoginMode) return null; // ==> EARLY EXIT
    return (
      <Button className={classes.button} variant="contained" onClick={handleLogin} >
        {t('login')}
      </Button>
    );
  }
  function RenderVerifyButton() {
    if (!isVerifyMode) return null; // ==> EARLY EXIT
    return (
      <Button className={classes.button} variant="contained" onClick={handleVerify} >
        {t('finish-setup')}
      </Button>
    );
  }
  function RenderResendCodeButton() {
    if (!isVerifyMode) return null; // ==> EARLY EXIT
    return (
      <Button className={classes.button} variant="contained" onClick={handleResendCode} >
        {t('resend-code')}
      </Button>
    );
  }
  function RenderForgotButton() {
    if (!isForgotMode) return null; // ==> EARLY EXIT
    return (
      <Button className={classes.button} variant="contained" onClick={handleForgotReset} >
        {t('reset-password')}
      </Button>
    );
  }

  const isSignupMode = dialogTitle === SIGNUP_TITLE;
  const isLoginMode = dialogTitle === LOGIN_TITLE;
  const isVerifyMode = dialogTitle === VERIFY_TITLE;
  const isForgotMode = dialogTitle === FORGOT_TITLE;
  return (
    <StyledBoxCG className={classes.container}>
      <ProgressPrompt open={!!progressTitle} statusText={t('please-wait')} title={progressTitle} />

      <Dialog
        open={true}
        onClose={(event, reason) => UiUtil.onClose(event, reason)}
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText id="alert-dialog-description">{dialogDesc}</DialogContentText>

          {/* User ID and Password.  Used for Login and Signup */}
          {(isLoginMode || isSignupMode) ?
            <div>
              <TextField autoFocus fullWidth margin='normal' onChange={handleUserChange}
                id={ID_USER} label={t('enter-your-email')} type="text" value={username} />
              {isSignupMode ?
                <Typography>{t('an-email-will-be-sent-to-this-')}</Typography>
                : null}
              <PasswordField fullWidth onChange={handlePasswordChange}
                id={ID_PASSWORD} label={t('enter-your-password')} value={password} />
            </div>
            : null}

          {/* Verification code. */}
          {(isVerifyMode || isForgotMode) ?
            <TextField autoFocus fullWidth margin='normal' onChange={handleVerifyCodeChange} autoComplete='off'
              id={ID_VERIFY_CODE} label={t('verification-code')} type="text" value={verifyCode} />
            : null}
          {(isForgotMode) ?
            <PasswordField fullWidth onChange={handlePasswordChange}
              id={ID_PASSWORD} label={t('enter-your-new-password')} value={password} />
            : null}

          <PromptMessage sx={{ mt: 3 }} errorText={errorMessage} />
          {isLoginMode ?
            <div className={classes.linkBox}>
              <BoxR className={classes.textRow}>
                <Typography style={{ marginRight: 5 }}>{t('dont-have-an-account')}</Typography>
                <BusyLink onClick={handleSignup}>{t('signup')}</BusyLink>
              </BoxR>
              <BoxR className={classes.textRow}>
                <BusyLink onClick={handleForgotPassword}>{t('forgot-password-lc')}</BusyLink>
                <Typography>? </Typography>
              </BoxR>
            </div>
            : null}

        </DialogContent>
        <DialogActions className={classes.actions}>
          <RenderResendCodeButton />
          <BoxRG />
          <RenderCancelButton />
          <RenderCreateAccountButton />
          <RenderLoginButton />
          <RenderVerifyButton />
          <RenderForgotButton />
        </DialogActions>
      </Dialog>
    </StyledBoxCG>
  );
}
