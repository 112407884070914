import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const localOnly = ['indent'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledSpan = styled('span', { shouldForwardProp: localOnlyTest })
  (({ theme, indent }) => ({
    fontSize: 18,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    marginBottom: theme.spacing(2),
    marginLeft: indent ? theme.spacing(indent) : undefined,
  }));

export default function MoreInfo(props) {
  const { open = true, onClick, indent } = props;
  const { t } = useTranslation('site');

  return (
    <StyledSpan indent={indent} onClick={onClick} >
      {!open ? t('More...') : t('Less...')}
    </StyledSpan>
  );
}