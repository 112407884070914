import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import UserAssistance from '../../appSupport/UserAssistance';

const classes = {
  root: 'HelpTool-root'
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.root}`]: {
    edge: 'start',
    color: theme.palette.info.main
  }
}));


export default function HelpTool(props) {

  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const { helpText, visible = true, sx: sxIn, ...rest } = props;

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setDimensions({
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height
      });
    }
  }, []);

  function handleHelp() {
    UserAssistance.displayGlobalInfoMessage(helpText);
  }

  // The visible prop is a trick used to right justify a HelpTool where the main content is centered. 
  // visible=false allows for a non visible box the same height/width as the help would be.
  if (!visible && dimensions.width) {
    return <Box style={{ width: dimensions.width, height: dimensions.height }} />
  }
  return (
    <StyledIconButton ref={measuredRef} className={classes.root} onClick={handleHelp} sx={sxIn} {...rest} size="large">
      <HelpIcon />
    </StyledIconButton>
  );
}
