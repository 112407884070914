import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import UserStore from "../appSupport/UserStore";
import TraceLog from "../appSupport/TraceLog";
import { createRg0Profile, updateRg0Profile } from "../graphql/mutations";
import { listRg0Profiles } from "../graphql/queries";
import { onCreateRg0Profile, onUpdateRg0Profile } from "../graphql/subscriptions";
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';


export default class ProfileOps {
  static createProfile(properties) {
    TraceLog.addDbEvent('Profile:create', JSON.stringify(properties));
    return API.graphql(graphqlOperation(createRg0Profile, { input: { ...properties } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static updateProfile(keyFields, updates) {
    const defaults = { ...keyFields, ...updates };
    TraceLog.addDbEvent('Profile:update', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(updateRg0Profile, { input: { ...defaults } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static getProfiles() {
    return new Promise(async resolve => {
      const getResult = async (nextToken) => {
        const constraint = { nextToken };
        TraceLog.addDbEvent('Profile:list', JSON.stringify(constraint));
        const result = await API.graphql(graphqlOperation(listRg0Profiles, constraint))
          .catch(err => {
            Globals.dispatchApiError(err);
            return null;
          });
        return result ? result.data.listRg0Profiles : null;
      }
      let result = await getResult();
      let resultArray = result ? result.items : [];
      while (result && result.nextToken) {
        result = await getResult(result.nextToken);
        resultArray = result ? [...resultArray, ...result.items] : resultArray;
      }
      return resolve(resultArray);
    });
  }

  // Subscriptions ----------------------------------------------------

  static onProfileCreate(onCreate, onError) {
    const handleCreate = (event) => {
      const newProfile = event.value.data.onCreateRg0Profile;
      TraceLog.addSubscriptionEvent('SBS Profile:create', newProfile);
      onCreate(newProfile);
    }
    const { username: userName } = UserStore.getUser();
    const variables = { userName, owner: userName };
    TraceLog.addDbEvent('Profile:subscribeCreate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0Profile, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  static onProfileUpdate(onUpdate, onError) {
    const handleUpdate = (event) => {
      const profile = event.value.data.onUpdateRg0Profile;
      TraceLog.addSubscriptionEvent('SBS Profile:update', profile);
      onUpdate(profile);
    }
    const { username: userName } = UserStore.getUser();
    const variables = { userName, owner: userName };
    TraceLog.addDbEvent('Profile:subscribeUpdate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0Profile, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

}