// NLS-NLS(controller)
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PLATFORM_GAME, PLATFORM_WEB_SITE, REQUEST_TYPE_BUG_REPORT, REQUEST_TYPE_COMPATIBLE_GAME, REQUEST_TYPE_GENERAL_FEEDBACK, REQUEST_TYPE_NLS_MISSING, REQUEST_TYPE_NLS_NEW_LANGUAGE, REQUEST_TYPE_NLS_SUGGESTION, REQUEST_TYPE_OTHER, REQUEST_TYPE_PRODUCT_ENHANCEMENT, REQUEST_TYPE_TRACE, STATUS_DEFER, STATUS_INVALID, STATUS_RESOLVED, STATUS_REVIEW, STATUS_USER_DELETED, STATUS_WAIT_CLIENT, STATUS_WAIT_SUPPORT } from '../db/SupportRequestOps';
import nlsStore from './NlsStore';

export function useInitNlsStoreController(languageCode, pseudoMode) {
  const { t } = useTranslation('controller');

  React.useEffect(() => {
    nlsStore.setValueController('menu-exit-end-play-for-all', t('menu-exit-end-play-for-all'));
  }, [languageCode, pseudoMode, t]);
}

export function useInitNlsStoreLogin(languageCode, pseudoMode) {
  const { t } = useTranslation('login');

  React.useEffect(() => {
    nlsStore.setValueLogin('change-password', t('change-password'));
  }, [languageCode, pseudoMode, t]);
}

export function useInitNlsStorePlayer(languageCode, pseudoMode) {
  const { t } = useTranslation('player');

  React.useEffect(() => {
    nlsStore.setValuePlayer('add-to-home', t('add-to-home'));
    nlsStore.setValuePlayer('menu-exit', t('menu-exit'));
    nlsStore.setValuePlayer('menu-share-game', t('menu-share-game'));
    nlsStore.setValuePlayer('a-login-is-not-required-to-hos', t('a-login-is-not-required-to-hos'));
    nlsStore.setValuePlayer('you-should-never-have-to-but-i', t('you-should-never-have-to-but-i'));
    nlsStore.setValuePlayer('exit-to-site', t('exit-to-site'));
    nlsStore.setValuePlayer('show-instructions', t('show-instructions'));
    nlsStore.setValuePlayer('if-you-use-this-app-a-lot-you-', t('if-you-use-this-app-a-lot-you-'));
  }, [languageCode, pseudoMode, t]);
}

export function useInitNlsStoreSupport(languageCode, pseudoMode) {
  const { t } = useTranslation('support');

  React.useEffect(() => {
    nlsStore.setValueSupport(STATUS_WAIT_CLIENT, t('status-wait-client'));
    nlsStore.setValueSupport(STATUS_WAIT_SUPPORT, t('status-wait-support'));
    nlsStore.setValueSupport(STATUS_REVIEW, t('status-review'));
    nlsStore.setValueSupport(STATUS_DEFER, t('status-defer'));
    nlsStore.setValueSupport(STATUS_INVALID, t('status-invalid'));
    nlsStore.setValueSupport(STATUS_RESOLVED, t('status-resolved'));
    nlsStore.setValueSupport(STATUS_USER_DELETED, t('status-user-deleted'));

    nlsStore.setValueSupport(PLATFORM_WEB_SITE, t('platform-web'));
    nlsStore.setValueSupport(PLATFORM_GAME, t('platform-game'));

    nlsStore.setValueSupport(REQUEST_TYPE_NLS_MISSING, t('type-xlate-missing'));
    nlsStore.setValueSupport(REQUEST_TYPE_NLS_SUGGESTION, t('type-xlate-suggestion'));
    nlsStore.setValueSupport(REQUEST_TYPE_NLS_NEW_LANGUAGE, t('type-xlate-new'));
    nlsStore.setValueSupport(REQUEST_TYPE_BUG_REPORT, t('type-bug'));
    nlsStore.setValueSupport(REQUEST_TYPE_GENERAL_FEEDBACK, t('type-feedback'));
    nlsStore.setValueSupport(REQUEST_TYPE_PRODUCT_ENHANCEMENT, t('type-enhancement'));
    nlsStore.setValueSupport(REQUEST_TYPE_COMPATIBLE_GAME, t('type-compatible-games'));
    nlsStore.setValueSupport(REQUEST_TYPE_TRACE, t('type-trace'));
    nlsStore.setValueSupport(REQUEST_TYPE_OTHER, t('type-other'));

    nlsStore.setValueSupport('menu-language-request-or-defect', t('menu-language-request-or-defect'));
    nlsStore.setValueSupport('report-a-bug', t('report-a-bug'));
    nlsStore.setValueSupport('suggest-enhancement', t('suggest-enhancement'));
    nlsStore.setValueSupport('share-your-feedback', t('share-your-feedback'));
    nlsStore.setValueSupport('menu-send-trace', t('menu-send-trace'));
    nlsStore.setValueSupport('translate-tool-added-to-header', t('translate-tool-added-to-header'));
  }, [languageCode, pseudoMode, t]);
}