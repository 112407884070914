import React, { useState } from 'react';
import GameStore from '../db/GameStore';
import { usePreloadStores } from '../db/GlobalStore';

export default function useMonitorGame() {
  const [game, setGame] = useState(null);
  const areStoresReady = usePreloadStores('monitorGame');

  React.useEffect(() => {
    const handleUpdate = (event) => {
      const { game } = event.payload;
      setGame(game.notLoaded ? null : game);
    }
    if (!areStoresReady) return undefined; // ==> EARLY EXIT
    const newGame = GameStore.getGame();
    setGame(newGame.notLoaded ? null : newGame);
    GameStore.listen(handleUpdate);
    return () => {
      GameStore.stopListen(handleUpdate)
    }
  }, [areStoresReady]);

  return areStoresReady ? game : null;
}