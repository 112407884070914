import { styled } from '@mui/material/styles';
import React from 'react';

const StyledSpan = styled('span')(({ theme }) => ({
  margin: theme.spacing(2),
  fontWeight: 'bold',
  fontSize: 22,
}));

const ContainerDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'horizontal',
});

const Sequence = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 40,
  width: 40,
  minWidth: 40,
  fontWeight: 'bold',
  fontSize: 22,
  backgroundColor: theme.palette.info.main,
  borderRadius: 10000,
  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: 'grey',
}));

const Title = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export default function SequenceTitle(props) {

  const { sequence, title, children, ...rest } = props;

  return (
    <StyledSpan {...rest}>
      <ContainerDiv>
        <Sequence>{sequence}</Sequence>
        <Title>{title}</Title>
      </ContainerDiv>
    </StyledSpan>
  );
}
