import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

export default function GenericTool(props) {
  const { onClick, toolTip, children } = props;
  return (
    <Tooltip title={toolTip} >
      <IconButton onClick={onClick} size="large">
        {children}
      </IconButton>
    </Tooltip>
  );
}
