// NLS-NLS(player)
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import PendingPlayerStore from '../db/PendingPlayerStore';
import PublishedTo from '../fields/PublishedTo';
import RoundData from '../fields/RoundData';
import BoxC from './box/BoxC';
import BoxR from './box/BoxR';
import LabelAndData from './LabelAndData';
import PlayerName, { PLAYER_STATUS_LINE } from './PlayerName';


export const STATUS_WAITING_CONTROLLER = 1;
export const STATUS_ENTER_ANSWER = 2;
export const STATUS_SELECT_ANSWER = 3;
export const STATUS_WAITING_OTHER_PLAYERS = 4;

export default function PlayerStatusLine(props) {
  const { game, status, handToDisplay, includePublishedTo = true } = props;
  const bump = handToDisplay ? handToDisplay - game.currentHand : 0;
  const handOffset = game.scoringStartHandsArray[game.scoringStartHandsArray.length - 1];
  const publishedTo = (game.publishedTo) ? new PublishedTo(game.publishedTo) : null;
  const roundData = RoundData.fromGame(game, game.currentHand + bump);
  const theme = useTheme();
  const { t } = useTranslation('player');

  const WAIT_CONTROLLER_TEXT = t('waiting-for-game-controller');
  const SELECT_ANSWER_TEXT = t('select-your-answer');
  const ENTER_ANSWER_TEXT = ''; // 'Enter your answer';
  const WAIT_OTHER_PLAYERS_TEXT = t('waiting-for-other-players-to-s');


  function formattedPlayers() {
    if (!publishedTo) return t('nobody'); // ==> EARLY EXIT
    const players = publishedTo.players.length ? publishedTo.players : PendingPlayerStore.getPlayerNames();
    let result = '';
    players.forEach((playerName, i2) => {
      const isLast = i2 === players.length - 1;
      result += playerName;
      if (!isLast) result += ', ';
    });
    return result;
  }

  function RenderPublishedTo(props) {
    const { open } = props;
    if (!open) return null; // ==> EARLY EXIT
    return (
      <BoxR style={{ alignSelf: 'stretch', flexWrap: 'wrap', alignItems: 'center' }}>
        <LabelAndData label={t('published-to')} data={formattedPlayers()} />
      </BoxR>
    );
  }

  let title;
  switch (status) {
    case STATUS_WAITING_OTHER_PLAYERS:
      title = WAIT_OTHER_PLAYERS_TEXT;
      break;
    case STATUS_ENTER_ANSWER:
      title = ENTER_ANSWER_TEXT;
      break;
    case STATUS_SELECT_ANSWER:
      title = SELECT_ANSWER_TEXT;
      break;
    default:
      title = WAIT_CONTROLLER_TEXT;
  }

  const hasCurrentReader = !!roundData.currentReader;
  let currentReader;
  if (hasCurrentReader) {
    currentReader = <PlayerName playerName={roundData.currentReader} isReader={true} size={PLAYER_STATUS_LINE} color={theme.palette.dataText} />;
  }
  return (
    <BoxC>
      <BoxR style={{ alignSelf: 'stretch', flexWrap: 'wrap', alignItems: 'center' }}>
        <LabelAndData label={t('game-colon')} data={game.scoringStartHandsArray.length} />
        <LabelAndData label={t('round-colon')} labelStyle={{ marginLeft: 10 }} data={game.currentHand - handOffset + 1 + bump} />
        <LabelAndData label={t('reader-colon')} labelStyle={{ marginLeft: 10 }} data={currentReader} open={hasCurrentReader} />
      </BoxR>
      <RenderPublishedTo open={includePublishedTo} />
      {Globals.isGameController(game) ? null : <Typography>{title}</Typography>}
    </BoxC>
  );
}

PlayerStatusLine.propTypes = {
  game: PropTypes.any,
  status: PropTypes.number,
};

PlayerStatusLine.defaultProps = {
  status: STATUS_WAITING_CONTROLLER,
}