import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function EditCardHelp(props) {
  const { t } = useTranslation('help');

  return (
    <HelpTool helpText={t("add-cards-to-your-deck-n-nif-a")} {...props} />
  );
}
