import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from "react-i18next";
import Globals from '../../appSupport/Globals';
import UserStore from '../../appSupport/UserStore';
import BoxR from '../../components/box/BoxR';
import BoxRG from '../../components/box/BoxRG';
import BusyLink from '../../components/BusyLink';

const classes = {
  linkBox: 'LoginLinks-linkBox',
  root: 'LoginLinks-root'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexWrap: 'wrap',
  },
  [`& .${classes.linkBox}`]: {
    margin: theme.spacing(1),
    justifyContent: 'flex-end',
  }
}));


export default function LoginLinks() {
  const [logoutLinkSpinner, setLogoutLinkSpinner] = React.useState(false);
  const { t } = useTranslation('player');
  const isDefaultUser = Globals.isDefaultUser();

  function handleLogin() {
    UserStore.loginWithUI();
  }
  function handleLogout() {
    if (!logoutLinkSpinner) setLogoutLinkSpinner(true);
    UserStore.signInWithGameId(null); // Sign in with default credentials
  }
  function renderLoginLink() {
    if (!isDefaultUser) return null;
    if (logoutLinkSpinner) setLogoutLinkSpinner(false);
    return (
      <BoxR className={classes.linkBox}>
        <BusyLink onClick={handleLogin}>
          {t('login')}
        </BusyLink>
      </BoxR>
    );
  }
  function renderLogoutLink() {
    if (isDefaultUser) return null;
    return (
      <BoxR className={classes.linkBox}>
        <BusyLink open={!isDefaultUser} busy={logoutLinkSpinner} onClick={handleLogout} >
          {t('logout')}
        </BusyLink>
      </BoxR>
    );
  }

  return (
    <StyledBoxR className={classes.root}>
      <BoxRG />
      {renderLoginLink()}
      {renderLogoutLink()}
    </StyledBoxR>
  );
}
