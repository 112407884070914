import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import DeleteTool from './tools/DeleteTool';
import EditTool from './tools/EditTool';
import AddTool from './tools/AddTool';

export function loadingRenderer() {
  return () => textRenderer(20, 'Loading...');
}
export function noDataRenderer(pluralItemText) {
  return () => textRenderer(20, `No ${pluralItemText} have been added`);
}
function textRenderer(spanColumns, text) {
  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
    >
      <TableCell colSpan={spanColumns}>
        <Typography>{text}</Typography>
      </TableCell>
    </TableRow>
  );
}

export function noneSelectedTools(props) {
  const { onAdd } = props;
  const result = [];
  if (onAdd) result.push(<AddTool onAdd={onAdd} key='addTool' />);
  return result;
}

export function oneSelectedTools(props) {
  const { onAdd, onDelete, onEdit } = props;
  const result = [];
  if (onAdd) result.push(<AddTool onAdd={onAdd} key='addTool' />);
  if (onEdit) result.push(<EditTool onEdit={onEdit} key='editTool' />);
  if (onDelete) result.push(<DeleteTool onDelete={onDelete} key='deleteTool' />);
  return result;
}

export function moreSelectedTools(props) {
  const { onAdd, onDelete } = props;
  const result = [];
  if (onAdd) result.push(<AddTool onAdd={onAdd} key='addTool' />);
  if (onDelete) result.push(<DeleteTool onDelete={onDelete} key='deleteTool' />);
  return result;
}
