import React from 'react';

const fromStorage = (storage, key, defaultV, ClassName) => {
  const result = storage.getItem(key);
  if (!result) return defaultV;
  const resultParsed = JSON.parse(result).payload;
  return ClassName ? new ClassName(JSON.stringify(resultParsed)) : resultParsed;
}
const toStorage = (storage, key, value) => {
  const obj = { payload: value };
  storage.setItem(key, JSON.stringify(obj));
}
// https://www.robinwieruch.de/local-storage-react
const useStateWithStorage = (storage, storageKey, defaultInitialValue = '', ClassName) => {
  const [value, setValue] = React.useState(fromStorage(storage, storageKey, defaultInitialValue, ClassName));

  React.useEffect(() => {
    toStorage(storage, storageKey, value);
  }, [value, storage, storageKey]);

  return [value, setValue];
};

export function useStateWithLocalStorage(storageKey, defaultInitialValue = '', ClassName) {
  return useStateWithStorage(localStorage, storageKey, defaultInitialValue, ClassName);
};
export function useStateWithSessionStorage(storageKey, defaultInitialValue = '', ClassName) {
  return useStateWithStorage(sessionStorage, storageKey, defaultInitialValue, ClassName);
};

export default class StorageStore {

  static fromLocalStorage(key, defaultV = null) {
    return fromStorage(localStorage, key, defaultV);
  }
  static fromSessionStorage(key, defaultV = null) {
    return fromStorage(sessionStorage, key, defaultV);
  }

  static toLocalStorage(key, value) {
    toStorage(localStorage, key, value);
  }
  static toSessionStorage(key, value) {
    toStorage(sessionStorage, key, value);
  }
}

