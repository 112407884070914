import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupScoring(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Check the "Keep Score"\n    (there are many score options on the drill-in)')}>
        {t('Keep score')}
      </SetupTip>
    </p>
  );
}
