// NLS-NLS(player)
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import FinderOps from '../../db/FinderOps';
import GameOps, { HAND_STATUS_END } from '../../db/GameOps';
import GameStore from '../../db/GameStore';
import GlobalStore from '../../db/GlobalStore';
import PendingPlayerOps from '../../db/PendingPlayerOps';
import RecentGame from '../../fields/RecentGame';
import ConfirmationPrompt from '../../prompt/ConfirmationPrompt';
import { gameRoutesFull } from '../../routing/gameRoutes';
import { newEntryKey, newSearchPath } from './LandingContent';

export default function RejoinRecentGame(props) {
  const [confirmPromptOpen, setConfirmPromptOpen] = React.useState(false);
  const { t } = useTranslation('player');
  const RE_JOIN_GAME_TEXT = t('re-join-game');
  const RECENT_GAME_TITLE = t('exit-by-mistake');
  const RECENT_GAME_TEXT = t('would-you-like-to-re-join-the-');
  const recentGame = RecentGame.currentRecentGame;

  React.useEffect(() => {
    const game = GameStore.getGameUnsafe();
    if (recentGame && game && !game.notLoaded && (game.handStatus !== HAND_STATUS_END)) {
      if (!confirmPromptOpen) { // Already handled
        const deltaInMinutes = ((new Date()).getTime() - (new Date(recentGame.startedAt)).getTime()) / 1000 / 60;
        if (deltaInMinutes < 120) {
          setConfirmPromptOpen(true);
        }
      }
    }
    return () => { GlobalStore.releaseAllStores() }
  }, [recentGame, confirmPromptOpen]);


  async function handleRecentGameSave() {
    const finderRecord = await FinderOps.getFinderRecord(recentGame.finderId);
    const game = await GameOps.getGameWithId(finderRecord.gameId);
    setConfirmPromptOpen(false);
    const promptControllerForEntry = false;
    if (promptControllerForEntry) {
      const existingEntryKeys = await PendingPlayerOps.getPendingPlayers(game.id).map(m => m.entryKey);
      const entryKey = newEntryKey(existingEntryKeys);
      GameOps.addEntryRequest(game, recentGame.playerName, entryKey).then(() => {
        Globals.dispatchNavigate(gameRoutesFull.addPlayers + newSearchPath(finderRecord.gameId, recentGame.playerName, finderRecord.id, entryKey),
          { replace: false });
      });
    } else {
      const entryKey = recentGame.entryKey;   // Will re-enter with no controller prompt
      Globals.dispatchNavigate(gameRoutesFull.addPlayers + newSearchPath(finderRecord.gameId, recentGame.playerName, finderRecord.id, entryKey),
        { replace: false });
    }
  }
  function handleRecentGameCancel() {
    setConfirmPromptOpen(false);
    RecentGame.clearRecentGame();
  }

  return (
    <ConfirmationPrompt open={confirmPromptOpen} title={RECENT_GAME_TITLE} text={RECENT_GAME_TEXT}
      continueButtonText={RE_JOIN_GAME_TEXT} onClose={handleRecentGameCancel} onSave={handleRecentGameSave} />
  );
}
