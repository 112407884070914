import Globals from "../appSupport/Globals";
import TraceLog from "../appSupport/TraceLog";
import AutoScoreReader from "../fields/AutoScoreReader";
import GameOptions from "../fields/GameOptions";
import RoundData from "../fields/RoundData";
import ScoreOptions from "../fields/ScoreOptions";
import GameStore from "./GameStore";
import PendingPlayerStore from "./PendingPlayerStore";
import ResultStore from "./ResultStore";
import ScoreStore from "./ScoreStore";

export default class PerformAutoScoringReader {
  static perform() {
    const instance = new PerformAutoScoringReader();
    instance.perform();
  }
  constructor() {
    this.game = GameStore.getGame();
    this.gameOptions = new GameOptions(this.game.gameOptions);
    this.scoreOptions = new ScoreOptions(this.game.scoreOptions);
    this.autoScoreReader = new AutoScoreReader(this.scoreOptions.autoScoreReader);
  }
  initValues() {
    this.sortedResults = ResultStore.getRecords()
      .filter(f => f.hand === this.game.currentHand)
      .sort(Globals.sorterCreatedAt);
    this.currentReader = RoundData.fromGame(this.game).currentReader;
    this.correctResult = this.sortedResults.find(f => f.playerName === this.currentReader);
    this.scores = ScoreStore.getRecords()
      .filter(f => f.hand === this.game.currentHand);
  }
  perform() {
    if (this.scoreOptions.useAutoScoreReader && this.gameOptions.readersAnswerIsCorrect) {
      this.initValues();
      // Only do auto scoring if there are no scores present.
      if (!this.scores.length && this.correctResult) {
        TraceLog.addEvent('Performing auto score READER');
        this.performSubmittedAnswerScoring();
        this.performNoAnswerSubmittedScoring();
      }
    }
  }
  performSubmittedAnswerScoring() {
    // Pull the readers answer out of workingResults, as the reader can't be first or last.
    const workingResults = [...this.sortedResults.filter(f => f.playerName !== this.currentReader)];
    const readerResult = this.sortedResults.find(f => f.playerName === this.currentReader);

    // First player scoring
    if (workingResults.length && this.autoScoreReader.firstAnswerScoring) {
      this.addScoreForResult(workingResults.shift(), this.autoScoreReader.firstAnswerCorrect, this.autoScoreReader.firstAnswerIncorrect);
    }
    // Last player scoring
    // If some answers are forced (not submitted by user), then nobody is last.
    const someAnswersForced = this.sortedResults.length < ResultStore.getResultCount();
    if (!someAnswersForced && workingResults.length && this.autoScoreReader.lastAnswerScoring) {
      this.addScoreForResult(workingResults.pop(), this.autoScoreReader.lastAnswerCorrect, this.autoScoreReader.lastAnswerIncorrect);
    }

    // All other scoring
    workingResults.forEach(e => this.addScoreForResult(e, this.autoScoreReader.answerCorrect, this.autoScoreReader.answerIncorrect));

    // Reader scoring
    if (this.autoScoreReader.readerPoints) {
      this.addScoreForResult(readerResult, this.autoScoreReader.readerPoints, 0);
    }
  }
  performNoAnswerSubmittedScoring() {
    if (this.autoScoreReader.noAnswerSubmittedPoints) {
      const allPlayerNames = PendingPlayerStore.getRecords().map(m => m.playerName);
      const withAnswerNames = {};
      ResultStore.getRecords().map(m => m.playerName).forEach(e => withAnswerNames[e] = e);
      allPlayerNames.forEach(e => {
        if (!withAnswerNames[e]) {
          this.addScoreForName(e, this.autoScoreReader.noAnswerSubmittedPoints);
        }
      });
    }
  }
  addScoreForName(name, score) {
    ScoreStore.setScore(this.game.currentHand, name, score);
  }
  addScoreForResult(result, correctPoints, inCorrectPoints) {
    const newScore = result.answer.toString() === this.correctResult.answer.toString() ? correctPoints : inCorrectPoints;
    this.addScoreForName(result.playerName, newScore);
  }

}
