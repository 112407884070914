import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_WAIT_SUPPORT } from '../../db/SupportRequestOps';
import { useFreshSupportRequests } from '../../db/SupportRequestStore';
import LandingButton from './LandingButton';

const classes = {
  chipRoot: 'SupportRequestButton-chip-root'
};

const StyledChip = styled(Chip)(({ theme }) => ({
  [`&.${classes.chipRoot}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }
}));

export default function SupportRequestButton(props) {
  const { onClick, ...rest } = props;

  const { t } = useTranslation('player');
  const newlyCreatedSupportRequests = useFreshSupportRequests()
    .filter(f => f.status === STATUS_WAIT_SUPPORT);

  return (
    <LandingButton onClick={onClick} {...rest}>
      <StyledChip label={newlyCreatedSupportRequests.length} className={classes.chipRoot} />
      {t('support-requests')}
    </LandingButton>
  );
}
