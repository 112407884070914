
export const STORAGE_userName = 'RemoteGame.userName';
export const STORAGE_controllerName = 'RemoteGame.controllerName';
export const STORAGE_recentGame = 'RemoteGame.recentGame';
export const STORAGE_inControllerTab = 'RemoteGame.inControllerTab';
export const STORAGE_viewedCardIds = 'RemoteGame.viewedCardIds';
export const STORAGE_voidJoinGameId = 'RemoteGame.voidJoinGameId';
export const STORAGE_languageCode = 'RemoteGame.LanguageCode';
export const STORAGE_pseudoMode = 'RemoteGame.PseudoMode';
export const STORAGE_overrideBetaLanguages = 'RemoteGame.OverrideBetaLanguages';
export const STORAGE_refreshTipDisplayed = 'RemoteGame.RefreshTipDisplayed';
export const STORAGE_userToldAboutLoginForSupport = 'RemoteGame.UserToldAboutLoginForSupport';
export const STORAGE_pendingSupportRequestTransactions = 'RemoteGame.PendingSupportRequestTransactions';
