import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTipKeepScore from '../siteComponents/SetupTipKeepScore';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/punIntended.jpg';

export default function PunIntended({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset right src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Pun Intended')}</StyleTopic>
      <StyleParagraph>{t("Why did we make a game about puns? Because fun is our res-pun-sibility. Pun Intended is for pun-loving people everywhere! Teams have 1 minute to solve 6 puns that all start with the same letter and score points for each correct pun. The first team to 30 points wins! If you’re an astronaut and don’t like puns, please accept our Apollo-gees! The game is easy to learn and play in under 20-30 minutes.")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("A great BuenoFun variation for this game is to treat each question as a round and have each player enter their answer. You can give a bonus point for the first answer in of 1 or -1 if it/'s wrong.")}</StyleParagraph>
      <SetupTipKeepScore open={!compact} />
    </SiteSection>
  );
}
