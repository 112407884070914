// NLS-NLS(player)
import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SwapperIcon from '@mui/icons-material/SwapCalls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import AnswersInStatus from '../../components/AnswersInStatus';
import BoxC from '../../components/box/BoxC';
import DividerH from '../../components/DividerH';
import PlayerStatusLine, { STATUS_ENTER_ANSWER } from '../../components/PlayerStatusLine';
import PlayTimer from '../../components/PlayTimer';
import StatusBoxWithLogo from '../../components/StatusBoxWithLogo';
import GameStore from '../../db/GameStore';
import GameOptions from '../../fields/GameOptions';
import TimerValues, { QUESTION_TIMER } from '../../fields/TimerValues';

const classes = {
  root: 'PlayContent-root',
  publicBox: 'PlayContent-publicBox',
  button: 'PlayContent-button',
  container: 'PlayContent-container',
  multi: 'PlayContent-multi',
  single: 'PlayContent-single',
  swapper: 'PlayContent-swapper',
  littleBigger: 'PlayContent-littleBigger'
};

const StyledBoxC = styled(BoxC)(({ theme }) => ({
  [`& .${classes.publicBox}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    backgroundColor: theme.palette.playBackground.main,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
    overflowX: 'hidden', // Required for marginLeft/paddingRight below
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontSize: 20,
  },
  [`& .${classes.container}`]: {
    minWidth: '90%',
  },
  [`& .${classes.multi}`]: {
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [`& .${classes.single}`]: {
  },
  [`& .${classes.swapper}`]: {
    marginLeft: -theme.spacing(1),
    marginTop: -theme.spacing(10),
    color: theme.palette.secondary.primary,
  },
  [`& .${classes.littleBigger}`]: {
    fontSize: 25,
  }
}));

const ID_ENTRY_FIELD = 'entryField';
const ID_ENTRY_BUTTON = 'entryButton';


function PlayContent(props) {
  const { game, ...rest } = props;
  const [protectUi, setProtectUi] = React.useState(false);
  const [entryValues, setEntryValues] = React.useState([]);
  const hasExpiredTimerBeenReported = React.useRef(false);
  const { t } = useTranslation('player');

  const gameOptions = new GameOptions(game.gameOptions);
  const timerValues = TimerValues.newTimerValues(QUESTION_TIMER, game);
  const valueButtons = Globals.getValueButtonsArray(gameOptions.valueButtons);
  const isController = Globals.isGameController(game);
  const answerCount = gameOptions.numberOfAnswers;
  const isMultiAnswer = answerCount > 1;

  React.useEffect(() => {
    const newValues = [...entryValues];
    while (newValues.length > answerCount) newValues.pop();
    while (newValues.length < answerCount) newValues.push('');
    if (newValues.toString() !== entryValues.toString()) {
      setEntryValues(newValues);
    }
  }, [answerCount, entryValues]);

  function areAllEmpty(answer) {
    let result = true;
    answer.forEach(e => { if (e) result = false });
    return result;
  }
  function idFor(number) {
    return `${ID_ENTRY_FIELD},${number}`
  }
  function getEntryFieldFor(number) {
    return document.getElementById(idFor(number));
  }
  function getAnswerValue() {
    let current = 0;
    const result = [];
    while (current < answerCount) {
      const element = getEntryFieldFor(current++);
      result.push(element ? element.value : '');
    }
    return result;
  }
  const handleEmptyConfirm = () => {
    submitAnswer(getAnswerValue());
  }
  const handleValueButtonClick = (event) => {
    const answer = [event.currentTarget.textContent];
    submitAnswer(answer);
  }

  const handleSubmitButtonClick = (event) => {
    const answer = getAnswerValue();
    if (areAllEmpty(answer)) {
      Globals.showConfirmation({
        title: t('are-you-sure'),
        text: t('are-you-sure-you-want-to-submi'),
        continueButtonText: t('submit-blank'),
        onSave: handleEmptyConfirm
      });
    } else {
      submitAnswer(answer);
    }
  }

  function handleTimerExpired() {
    const answer = valueButtons.length ? [''] : getAnswerValue();
    submitAnswer(answer, true);
  }

  const submitAnswer = (answer, timerExpired) => {
    if (!timerExpired) setProtectUi(true);
    GameStore.submitAnswer(answer, !!timerExpired);
  }

  if (timerValues.isExpired() && !hasExpiredTimerBeenReported.current) {
    hasExpiredTimerBeenReported.current = true;
    handleTimerExpired();
  }

  const buttonContent = () => {
    if (!valueButtons.length) return null; // ==> EARLY EXIT
    return (
      <BoxC key='buttonList' className={classes.container}>
        {valueButtons.map(m => {
          return (
            <Button className={classes.button} variant="contained"
              onClick={handleValueButtonClick} color="secondary" disabled={protectUi}
              id={m} key={m}>
              {m}
            </Button>
          )
        })}
        <AnswersInStatus />
      </BoxC>
    );
  }

  function renderEntryFields() {
    const result = [];
    let id = 0;
    while (id < answerCount) {
      result.push(renderEntryField(id));
      if (id > 0) result.push(<RenderSwapper id1={id - 1} id2={id} key={`swapper${id}`} />);
      id++;
    };
    return result;
  }
  function renderEntryField(idIn) {
    const handleChange = (event) => {
      const { id, value } = event.target;
      const valueIndex = parseInt(id.split(',')[1]);
      const newValues = [...entryValues];
      newValues[valueIndex] = value;
      setEntryValues(newValues);
    }
    const autoFocus = idIn === 0;
    const label = isMultiAnswer ? t('x-enter-your-answer', { x: idIn + 1 }) : t('enter-your-answer');

    return (
      <TextField margin='normal' className={isMultiAnswer ? classes.multi : classes.single} autoFocus={autoFocus} fullWidth multiline autoComplete='off'
        onChange={handleChange} value={entryValues[idIn]}
        InputProps={{ className: classes.littleBigger }}
        InputLabelProps={{ className: classes.littleBigger }}
        id={idFor(idIn)} key={idFor(idIn)} label={label} type="text" />
    );
  }
  function RenderSwapper({ id1, id2 }) {
    function handleSwap() {
      const newValues = [...entryValues];
      newValues[id1] = entryValues[id2];
      newValues[id2] = entryValues[id1];
      setEntryValues(newValues);
    }
    return (
      <div>
        <div className={classes.swapper}>
          <IconButton onClick={handleSwap} size="large">
            <SwapperIcon />
          </IconButton>
        </div>
      </div>
    );
  }

  const entryContent = () => {
    if (valueButtons.length) return null; // ==> EARLY EXIT
    return (
      <BoxC key='entryList' className={classes.container}>
        {renderEntryFields()}
        <BoxC style={{ minHeight: 60 }} >
          <AnswersInStatus />
        </BoxC>
        <Button className={classes.button} variant="contained"
          onClick={handleSubmitButtonClick} color="secondary" disabled={protectUi}
          id={ID_ENTRY_BUTTON} >
          {t('submit')}
        </Button>
      </BoxC>
    );
  }

  return (
    <StyledBoxC className={classes.root} {...rest} >
      <StatusBoxWithLogo small>
        <PlayerStatusLine game={game} status={STATUS_ENTER_ANSWER} includePublishedTo={false} />
      </StatusBoxWithLogo>
      <PlayTimer game={game} onTimerExpired={handleTimerExpired} whichTimer={QUESTION_TIMER} />
      <DividerH open={isController} />
      <div className={classes.publicBox} >
        {buttonContent()}
        {entryContent()}
      </div>
    </StyledBoxC>
  );
}

export default PlayContent;
