import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import BoxCG from '../../components/box/BoxCG';
import BoxR from '../../components/box/BoxR';
import SupportRequestHelp from '../../components/help/SupportRequestHelp';
import SupportRequestTableList from '../../components/list/SupportRequestTableList';
import { STATUS_USER_DELETED } from '../../db/SupportRequestOps';
import SupportRequestStore from '../../db/SupportRequestStore';
import ProgressPrompt from '../../prompt/ProgressPrompt';
import SupportRequestPrompt from '../../prompt/support/SupportRequestPrompt';

const classes = {
  root: 'SupportRequestsContent-root',
  containerBox: 'SupportRequestsContent-containerBox',
  listBox: 'SupportRequestsContent-listBox',
  headerBox: 'SupportRequestsContent-headerBox'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`& .${classes.containerBox}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.listBox}`]: {
    margin: theme.spacing(2),
  },
  [`& .${classes.headerBox}`]: {
    justifyContent: 'space-between',
  }
}));

export default function SupportRequestsContent(props) {
  const { onFinished, supportRequests, isLoading, ...rest } = props;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isSrPromptOpen, setIsSrPromptOpen] = React.useState(false);
  const [deleteProgressPrompt, setDeleteProgressPrompt] = React.useState({ open: false });

  const { t } = useTranslation('support');

  async function deleteSelected() {
    setDeleteProgressPrompt({ open: true, statusText: t('deleting-x-support-requests', { x: selectedRows.length }) });
    const all = selectedRows.map(async e => {
      return await SupportRequestStore.deleteSupportRequest(e);
    });
    Promise.all(all).then(() => {
      setDeleteProgressPrompt({ open: false })
      setSelectedRows([]);
    });
  }
  async function updateSelected() {
    setDeleteProgressPrompt({ open: true, statusText: t('deleting-x-support-requests', { x: selectedRows.length }) });
    const all = selectedRows.map(async m => {
      return await SupportRequestStore.updateStatus(m, STATUS_USER_DELETED, m.status);
    });
    Promise.all(all).then(() => {
      setDeleteProgressPrompt({ open: false })
      setSelectedRows([]);
    });
  }
  function handleFinished() {
    onFinished();
  }
  function handleRowSelection(selected) {
    setSelectedRows(selected);
  }
  function handleOnDeleteTool() {
    Globals.showConfirmation({
      title: t('delete-support-request'),
      text: t('are-you-sure-you-want-to-delsr', { x: selectedRows.length }),
      continueButtonText: t('delete'),
      onSave: handleDeleteConfirmed
    });
  }
  function handleOnEditTool() {
    setIsSrPromptOpen(true);
  }

  function handleDeleteConfirmed() {
    if (Globals.isProductSupporter()) {
      deleteSelected();
    } else {
      updateSelected();
    }
  }
  function handleSrPromptClose() {
    setIsSrPromptOpen(false);
  }

  return (
    <StyledBoxCG className={classes.root} {...rest}>
      <SupportRequestPrompt open={isSrPromptOpen} onClose={handleSrPromptClose} supportRequestIds={selectedRows} />
      <ProgressPrompt open={deleteProgressPrompt.open} statusText={deleteProgressPrompt.statusText} title={t('delete-support-request-s')} />
      <BoxCG className={classes.containerBox}>
        <BoxR className={classes.headerBox}>
          <SupportRequestHelp visible={false} />
          <Button style={{ alignSelf: 'center' }} onClick={handleFinished} variant="contained" color="primary" >
            {t('finished')}
          </Button>
          <SupportRequestHelp />
        </BoxR>
        <BoxCG className={classes.listBox}>
          <SupportRequestTableList rows={supportRequests} isLoading={isLoading} selectedRows={selectedRows}
            onRowSelection={handleRowSelection} onDelete={handleOnDeleteTool} onEdit={handleOnEditTool} />
        </BoxCG>
      </BoxCG>
    </StyledBoxCG>
  );
}
