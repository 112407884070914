export default class SRBetterWords {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.languageKey = input ? input.languageKey : null;
    this.languageNamespace = input ? input.languageNamespace : null;
    this.badWords = input ? input.badWords : null;
    this.betterWords = input ? input.betterWords : null;
    this.dialog = input ? input.dialog : [];
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

