import { Divider } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import BoxR from '../box/BoxR';

export default function SimpleMenu(props) {
  // actions are deprecated
  const { anchorElement, onClose, actions, menuActionItems } = props;

  const handleClose = () => {
    onClose();
  };

  const renderActions = () => {
    const result = [];
    actions.forEach(e => {
      if (e.separatorBefore) result.push(<Divider key={`${e.text}-dividerB`} />);
      result.push(
        <MenuItem key={e.text} onClick={() => { e.onClick(); onClose() }}>
          <BoxR>
            {e.IconClass ? <e.IconClass color='primary' style={{ marginRight: 5 }} /> : null}
            {e.text}
          </BoxR>
        </MenuItem>);
      if (e.separatorAfter) result.push(<Divider key={`${e.text}-dividerA`} />);
    });
    return result;
  }

  if (!anchorElement) return null;
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorElement}
      keepMounted
      open={Boolean(anchorElement)}
      onClose={handleClose}
    >
      {actions && renderActions()}
      {menuActionItems}
    </Menu>
  );
}