import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import TraceLog from "../appSupport/TraceLog";
import { createRg0PlayerResult, updateRg0PlayerResult } from "../graphql/mutations";
import { getRg0PlayerResult, listRg0PlayerResults } from "../graphql/queries";
import { onCreateRg0PlayerResult, onUpdateRg0PlayerResult } from "../graphql/subscriptions";
import GameStore from "./GameStore";
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';

const handToString = h => (h || 0).toString().padStart(10, '0');

export default class PlayerResultOps {

  static getPlayerResult(keyFields) {
    TraceLog.addDbEvent('ResultOPs:getPlayerResult', JSON.stringify(keyFields));
    return API.graphql(graphqlOperation(getRg0PlayerResult, keyFields))
      .then(result => {
        if (result && result.data.getRg0PlayerResult) {
          return result.data.getRg0PlayerResult;
        } else {
          throw Error(`ResultOPs:getPlayerResult not found: ${JSON.stringify(keyFields)}`);
        }
      });
  }

  static getPlayerResults(hand) {
    return new Promise(async resolve => {
      const gameId = PathUtil.getGameParam();
      const getResult = async (nextToken) => {
        const constraint = { id: gameId, nextToken, filter: { hand: { eq: hand } } };
        TraceLog.addDbEvent('Result:list', JSON.stringify(constraint));
        const result = await API.graphql(graphqlOperation(listRg0PlayerResults, constraint))
          .catch(err => {
            Globals.dispatchApiError(err);
            return null;
          });
        return result ? result.data.listRg0PlayerResults : null;
      }
      let result = await getResult();
      let resultArray = result ? result.items : [];
      while (result && result.nextToken) {
        result = await getResult(result.nextToken);
        resultArray = result ? [...resultArray, ...result.items] : resultArray;
      }
      return resolve(resultArray);
    });
  }

  static createPlayerResult(gameId, hand, playerName, answer) {
    const handString = handToString(hand);
    const defaults = { id: gameId, hand, handString, playerName, answer, owner: GameStore.getGame().owner };
    TraceLog.addDbEvent('Result:create', JSON.stringify(defaults));
    API.graphql(graphqlOperation(createRg0PlayerResult, { input: defaults }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static performUpdate(keyFieldsIn, updates, conflictResolver, debug) {
    const handString = handToString(keyFieldsIn.hand);
    const keyFields = { ...keyFieldsIn, handString }; // Should remove hand?
    const defaults = { ...keyFields, ...updates };
    TraceLog.addDbEvent('Result:update', JSON.stringify(defaults));
    // console.log(`performUpdate, expectedVersion: ${updates.expectedVersion}  txnKey: ${debug}`)

    return API.graphql(graphqlOperation(updateRg0PlayerResult, { input: defaults }))
      .catch(err => {
        // console.log('Error on update');
        if (err.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
          TraceLog.addTrace('ResultOps.performUpdate (update CONFLICT). Will re-fetch record and re-update.');
          // console.log('performUpdate (update CONFLICT). Will re-fetch record and re-update.');
          return this.getPlayerResult(keyFields)
            .then(result => {
              // console.log(`version found: ${result.version}`);
              const newUpdates = conflictResolver(result);
              return this.performUpdate(keyFieldsIn, newUpdates, conflictResolver, 'from-catch');
            })
            .catch(err => Globals.dispatchApiError(err))
        } else {
          Globals.dispatchApiError(err);
        }
      });
  }

  // Subscriptions ----------------------------------------------------
  static onPlayerResultCreate(onCreate, onError) {
    const handleCreate = (event) => {
      const newResult = event.value.data.onCreateRg0PlayerResult;
      if (newResult.id === PathUtil.getGameParam()) {
        TraceLog.addSubscriptionEvent('SBS Result:create', newResult);
        onCreate(newResult);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: GameStore.getGame().owner };
    TraceLog.addDbEvent('Result:subscribe:create', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0PlayerResult, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  static onPlayerResultUpdate(onUpdate, onError) {
    const handleUpdate = (event) => {
      const playerResult = event.value.data.onUpdateRg0PlayerResult;
      if (playerResult.id === PathUtil.getGameParam()) {
        TraceLog.addSubscriptionEvent('SBS Result:update', playerResult);
        onUpdate(playerResult);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: GameStore.getGame().owner };
    TraceLog.addDbEvent('Result:subscribe:update', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0PlayerResult, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }
}
