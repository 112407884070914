import { gameRoutesFull } from "../routing/gameRoutes";

const appendParam = (path, param) => {
  const sep = path.indexOf('?') > -1 ? '&' : '?';
  return path + sep + param;
}
export default class PathUtil {
  static currentPathWithSearch() {
    return `${window.location.pathname}${PathUtil.currentPathSearch()}`;
  };
  static currentPathWithSearchAndError() {
    return `${window.location.pathname}${window.location.search}`
  };
  static currentPathSearch() {
    // If there is an errorReload or wakeReload param, remove it.
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('errorReload');
    urlParams.delete('wakeReload');
    const asString = urlParams.toString();
    return asString ? `?${asString}` : '';
  };

  static reloadPage() {
    PathUtil.navigateExternal(PathUtil.currentPathWithSearch(), { reload: true });
  }

  static reloadPageForError() {
    PathUtil.navigateExternal(appendParam(PathUtil.currentPathWithSearch(), 'errorReload=' + new Date().toISOString()));
  }

  static reloadPageForWake(pathToLoad) {
    const target = pathToLoad || PathUtil.currentPathWithSearch();
    PathUtil.navigateExternal(appendParam(target, 'wakeReload=true'));
  }

  // static replaceUrl(url) {
  //   // alert(`PathUtil.navigateTo: ${url}`)
  //   // Globals.addDebugText(`PathUtil.navigateTo: ${url}`);
  //   window.location.replace(url);
  // }
  static navigateExternal(url, options = { replace: true }) {
    if (options.reload) {
      console.log(`Reload of current page: ${PathUtil.currentPathWithSearch()}`);
      window.location.reload();
    } else if (options.replace) {
      console.log(`Replace of current page: ${url}`);
      window.location.replace(url);
    } else {
      console.log(`Assign of current page: ${url}`);
      window.location.assign(url);
    }
  }

  static isWwwSubDomain() {
    return (window.location.hostname.split('.')[0] === 'www');
  }
  static isLandingPage() {
    return window.location.pathname === gameRoutesFull.landing;
  }
  static isLocalHost() {
    const { hostname } = window.location;
    return hostname === 'localhost' || hostname.startsWith('192.168');
  }

  static getEntryKeyParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('entryKey');
  }
  static getFinderIdParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('finderId');
  }
  static getGameParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('game');
  }
  static getPlayerNameParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('name');
  }
  static getJoinGameParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('joinGame');
  }
  static getErrorReloadParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('errorReload');
  }
  static getWakeReloadParam(searchPath) {
    const urlParams = new URLSearchParams(searchPath || window.location.search);
    return urlParams.get('wakeReload');
  }
}