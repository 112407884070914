// NLS-NLS(support)
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../../appSupport/Globals';
import SRBetterWords from '../../../fields/support/SRBetterWords';
import BoxC from '../../box/BoxC';
import LabelAndData from '../../LabelAndData';
import SreDialog from './SreDialog';


export default function SreBetterWords(props) {
  const { row, ...rest } = props;
  const payload = new SRBetterWords(row.payload);
  const isProductSupporter = Globals.isProductSupporter();
  const { t } = useTranslation('support');

  return (
    <BoxC {...rest}>
      { isProductSupporter ? <LabelAndData key='languageKey' label='Key:' data={payload.languageKey} /> : null}
      { isProductSupporter ? <LabelAndData key='languageNamespace' label='Namespace:' data={payload.languageNamespace} /> : null}
      <LabelAndData key='badWords' label={t('poor-wording')} data={payload.badWords} />
      <LabelAndData key='betterWords' label={t('better-wording')} data={payload.betterWords} />
      <SreDialog key='dialog' dialogArray={payload.dialog} />
    </BoxC>
  );
}
