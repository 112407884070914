import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import nlsStore from "../appSupport/NlsStore";
import TraceLog from "../appSupport/TraceLog";
import UserStore from "../appSupport/UserStore";
import { createRg0SupportRequest, deleteRg0SupportRequest, updateRg0SupportRequest } from "../graphql/mutations";
import { listRg0SupportRequests } from "../graphql/queries";
import { onCreateRg0SupportRequest, onCreateRg0SupportRequestUser, onDeleteRg0SupportRequest, onDeleteRg0SupportRequestUser, onUpdateRg0SupportRequest, onUpdateRg0SupportRequestUser } from "../graphql/subscriptions";
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';

export const COMMON_OWNER = 'COMMON_OWNER';

// see initNlsStoreSupport if maintaining status
export const STATUS_WAIT_CLIENT = 'WAIT_CLIENT';
export const STATUS_WAIT_SUPPORT = 'WAIT_SUPPORT';
export const STATUS_REVIEW = 'REVIEW';
export const STATUS_DEFER = 'DEFER';
export const STATUS_INVALID = 'INVALID';
export const STATUS_RESOLVED = 'RESOLVED';
export const STATUS_USER_DELETED = 'USER_DELETED';

export const getNewStatus = () => Globals.isProductSupporter() ? STATUS_WAIT_CLIENT : STATUS_WAIT_SUPPORT;

// see initNlsStoreSupport if maintaining platform
export const PLATFORM_WEB_SITE = 'WEB_SITE';
export const PLATFORM_GAME = 'GAME';

// see initNlsStoreSupport if maintaining type
export const REQUEST_TYPE_NLS_MISSING = 'NLS_MISSING';
export const REQUEST_TYPE_NLS_SUGGESTION = 'NLS_SUGGESTION';
export const REQUEST_TYPE_NLS_NEW_LANGUAGE = 'NLS_NEW_LANGUAGE';
export const REQUEST_TYPE_BUG_REPORT = 'BUG_REPORT';
export const REQUEST_TYPE_GENERAL_FEEDBACK = 'GENERAL_FEEDBACK';
export const REQUEST_TYPE_PRODUCT_ENHANCEMENT = 'PRODUCT_ENHANCEMENT';
export const REQUEST_TYPE_COMPATIBLE_GAME = 'COMPATIBLE_GAME';
export const REQUEST_TYPE_TRACE = 'TRACE';
export const REQUEST_TYPE_OTHER = 'OTHER';

export default class SupportRequestOps {

  static getPlatformText(source) { return nlsStore.getValueSupport(source) }
  static getStatusText(source) { return nlsStore.getValueSupport(source) }
  static getTypeText(source) { return nlsStore.getValueSupport(source) }

  static async createSupportRequest(id, properties) {
    const defaults = { id, ...properties };
    TraceLog.addDbEvent('SupportRequest:create', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(createRg0SupportRequest, { input: { ...defaults } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static async deleteSupportRequest(id) {
    const defaults = { id };
    TraceLog.addDbEvent('SupportRequest:delete', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(deleteRg0SupportRequest, { input: { ...defaults } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static updateSupportRequest(id, updates) {
    const defaults = { id, ...updates };
    TraceLog.addDbEvent('SupportRequest:update', JSON.stringify(defaults));
    return API.graphql(graphqlOperation(updateRg0SupportRequest, { input: { ...defaults } }))
      .catch(err => {
        Globals.dispatchApiError(err);
      });
  }

  static async getSupportRequests(constraintsIn = {}) {
    return new Promise(async resolve => {
      const getResult = async (nextToken) => {
        const constraint = { ...constraintsIn, nextToken };
        TraceLog.addDbEvent('SupportRequests:list', JSON.stringify(constraint));
        const result = await API.graphql(graphqlOperation(listRg0SupportRequests, constraint))
          .catch(err => {
            Globals.dispatchApiError(err);
            return null;
          });
        return result ? result.data.listRg0SupportRequests : null;
      }
      let result = await getResult();
      let resultArray = result ? result.items : [];
      while (result && result.nextToken) {
        result = await getResult(result.nextToken);
        resultArray = result ? [...resultArray, ...result.items] : resultArray;
      }
      return resolve(resultArray);
    });
  }

  // Subscriptions ----------------------------------------------------

  // User ---------------------------------------------------------------------
  static onSupportRequestCreateUser(onCreate, onError) {
    const handleCreate = (event) => {
      const newSR = event.value.data.onCreateRg0SupportRequestUser;
      TraceLog.addSubscriptionEvent('SBS SupportRequest:createU', newSR);
      onCreate(newSR);
    }
    const variables = { originatingUsername: UserStore.getUserName() };
    TraceLog.addDbEvent('SupportRequest:subscribeCreateU', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0SupportRequestUser, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }
  static onSupportRequestDeleteUser(onDelete, onError) {
    const handleDelete = (event) => {
      const sr = event.value.data.onDeleteRg0SupportRequestUser;
      TraceLog.addSubscriptionEvent('SBS SupportRequest:delete', sr);
      onDelete(sr);
    }
    const variables = { originatingUsername: UserStore.getUserName() };
    TraceLog.addDbEvent('SupportRequest:subscribeDelete', JSON.stringify(variables));
    const subscription = API.graphql({ query: onDeleteRg0SupportRequestUser, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleDelete(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }
  static onSupportRequestUpdateUser(onUpdate, onError) {
    const handleUpdate = (event) => {
      const sr = event.value.data.onUpdateRg0SupportRequestUser;
      TraceLog.addSubscriptionEvent('SBS SupportRequest:update', sr);
      onUpdate(sr);
    }
    const variables = { originatingUsername: UserStore.getUserName() };
    TraceLog.addDbEvent('SupportRequest:subscribeUpdate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0SupportRequestUser, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  // Normal -------------------------------------------------------------------
  static onSupportRequestCreate(onCreate, onError) {
    const handleCreate = (event) => {
      const newSR = event.value.data.onCreateRg0SupportRequest;
      TraceLog.addSubscriptionEvent('SBS SupportRequest:create', newSR);
      onCreate(newSR);
    }
    const variables = { commonOwner: COMMON_OWNER };
    TraceLog.addDbEvent('SupportRequest:subscribeCreate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0SupportRequest, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  static onSupportRequestDelete(onDelete, onError) {
    const handleDelete = (event) => {
      const sr = event.value.data.onDeleteRg0SupportRequest;
      TraceLog.addSubscriptionEvent('SBS SupportRequest:delete', sr);
      onDelete(sr);
    }
    const variables = { commonOwner: COMMON_OWNER };
    TraceLog.addDbEvent('SupportRequest:subscribeDelete', JSON.stringify(variables));
    const subscription = API.graphql({ query: onDeleteRg0SupportRequest, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleDelete(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

  static onSupportRequestUpdate(onUpdate, onError) {
    const handleUpdate = (event) => {
      const sr = event.value.data.onUpdateRg0SupportRequest;
      TraceLog.addSubscriptionEvent('SBS SupportRequest:update', sr);
      onUpdate(sr);
    }
    const variables = { commonOwner: COMMON_OWNER };
    TraceLog.addDbEvent('SupportRequest:subscribeUpdate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onUpdateRg0SupportRequest, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleUpdate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }

}