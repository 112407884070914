import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import TraceLog from "../appSupport/TraceLog";
import { createRg0GameFinder, updateRg0GameFinder } from "../graphql/mutations";
import { getRg0GameFinder } from "../graphql/queries";

const SPECIAL_FINDER_KEY = 'Z';
const MIN_FINDER = parseInt('0xAA');
const MAX_FINDER = parseInt('0xFFFF');

export default class FinderOps {
  static incrementId = (idHexString) => {
    let id = parseInt(idHexString, 16) + 1;
    if (id > MAX_FINDER) {
      id = MIN_FINDER;
    }
    return id.toString(16);
  }

  static async getFinderRecord(finderId) {
    return new Promise(async resolve => {
      const variables = { id: finderId };
      TraceLog.addDbEvent('GameFinder:get', JSON.stringify(variables));
      const result = await API.graphql(graphqlOperation(getRg0GameFinder, variables))
        .catch(err => {
          if (finderId !== SPECIAL_FINDER_KEY) {
            Globals.dispatchApiError(err);
          }
          return null;
        });
      resolve(result ? result.data.getRg0GameFinder : null);
    });
  }

  static async getSpecialFinderRecord() {
    // Special record SPECIAL_FINDER_KEY is used to hold the last used finder id.
    return FinderOps.getFinderRecord(SPECIAL_FINDER_KEY);
  }

  static async createFinderRecord(finderId, gameId) {
    const defaults = { id: finderId, gameId };
    TraceLog.addDbEvent('GameFinder:create', JSON.stringify(defaults));
    const result = await API.graphql(graphqlOperation(createRg0GameFinder, { input: defaults }))
      .catch(err => {
        Globals.dispatchApiError(err);
        return null;
      });
    return result ? result.data.createRg0GameFinder : null;
  }

  static async nextFindId() {
    // Special record 'Z' is used to hold the last used finder id.  Create it if it does not exist.
    let finder = await FinderOps.getSpecialFinderRecord();
    // Create it if it doesn't exist.
    if (!finder) {
      try {
        const idForCreate = FinderOps.incrementId(MIN_FINDER.toString(16));
        const finder = await FinderOps.createFinderRecord(SPECIAL_FINDER_KEY, idForCreate);
        return finder ? idForCreate : -1;
      } catch (err) { }
      // Assume the create failed because someone beat us to it (highly unlikely for this first record).
      finder = await FinderOps.getSpecialFinderRecord();
    }
    let nextId = FinderOps.incrementId(finder.gameId);
    let keepTrying = true;
    while (keepTrying) {
      try {
        const defaults = { id: SPECIAL_FINDER_KEY, gameId: nextId, expectedVersion: finder.version };
        TraceLog.addDbEvent('GameFinder:update', JSON.stringify(defaults));
        await API.graphql(graphqlOperation(updateRg0GameFinder, { input: defaults }));
        keepTrying = false;
      } catch (err) {
        if (err.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException') {
          finder = await FinderOps.getSpecialFinderRecord();
          nextId = FinderOps.incrementId(finder.gameId);
        } else {
          nextId = -1;
          keepTrying = false;  // Unknown state
          Globals.dispatchApiError(err);
        }
      }
    }
    return nextId;
  }

  // Subscriptions ----------------------------------------------------
  // static onFinderUpdate(onUpdate, properties) {
  //   const handleError = (err) => {
  //     Globals.dispatchApiError(err);
  //   }
  //   const handleUpdate = (event) => {
  //     const finder = event.value.data.onUpdateRg0GameFinder;
  //     if (!finder) {
  //       console.log('null finder for pub/sub event.');
  //     } else {
  //       TraceLog.addGameFinderUpdate(finder);
  //       onUpdate(finder);
  //     }
  //   }
  //   if (!properties.finderId) return null; // Can't subscribe without a finderId
  //   const variables = { id: properties.finderId };
  //   const subscription = API.graphql({ query: onUpdateRg0GameFinder, variables })
  //     .subscribe({
  //       next: handleUpdate,
  //       error: handleError,
  //     });
  //   return subscription;
  // }

}
