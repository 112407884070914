import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/factOrCrap.jpg';
const gameUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/factOrCrapGame.png';

export default function FactOrCrap({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Fact Or Crap')}</StyleTopic>
      <StyleParagraph>{t("Bunk? Bull? Bologna? Malarkey? No matter what you call it, in Fact or Crap, you need to sift it out from the real facts. This fast-paced trivia game will challenge you to take the bull by the horns and determine what's truth and what's hogwash. The armadillo is the only animal to suffer from leprosy... fact or crap? Your call!")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("It can be fun to award a bonus/penalty point for the first answer in.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Set the "Buttons" text to "Fact,Crap"\n...Select "Keep Score"\n...Select "Reader is assigned for each round"\n...Select "Reader\'s answer is correct"\nScore Settings\n...Select "Auto scoring on Reader\'s Answer"')} />
      <Asset right open={!compact} src={gameUrl} mobile={mobile} />
    </SiteSection>
  );
}
