// NLS-NLS(controller)
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import GameStore from '../db/GameStore';
import GameOptions from '../fields/GameOptions';
import SelectOptions from '../fields/SelectOptions';
import PromptUtil from './PromptUtil';

const ID_NUMBER_OF_SELECTIONS_ALLOWED = 'numberOfSelectionsAllowed';
const ID_CAN_SELECT_OWN_ANSWER_CB = 'canSelectOwnAnswer';

const checkboxControls = [ID_CAN_SELECT_OWN_ANSWER_CB];
const allIds = [ID_NUMBER_OF_SELECTIONS_ALLOWED, ID_CAN_SELECT_OWN_ANSWER_CB];
const numericIds = [ID_NUMBER_OF_SELECTIONS_ALLOWED];

export default function SelectOptionsPrompt(props) {
  const { open, onClose, game, selectOptionsJSON } = props;
  const [stagedValues, setStagedValues] = React.useState({});
  const gameOptions = new GameOptions(game.gameOptions);
  const { t } = useTranslation('controller');


  React.useEffect(() => {
    setStagedValues(PromptUtil.mergeProps(new SelectOptions(selectOptionsJSON), {}, allIds));
  }, [selectOptionsJSON]);

  function clearValidations() {
  }
  const validations = {

    performValidate: () => {
      clearValidations();
      return false;
    }
  }

  function handleCancel() {
    clearValidations();
    onClose();
  }
  function handleSave() {
    if (!validations.performValidate()) {
      const selectOptions = new SelectOptions();
      PromptUtil.mergeProps(stagedValues, selectOptions, allIds, numericIds);
      gameOptions.selectOptions = selectOptions.asJSON();
      GameStore.updateFields({ gameOptions });
      clearValidations();
      onClose();
    }
  }

  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  if (!open) return null;
  return (
    <React.Fragment>
      <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
        <DialogTitle style={{ minWidth: Math.min(400, window.innerWidth * .8) }} id="form-dialog-title">{t('select-answer-options')}</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <TextField fullWidth style={{ minWidth: 180, marginRight: 20 }} onChange={handleValueChange} margin='normal'
              id={ID_NUMBER_OF_SELECTIONS_ALLOWED} label={t('number-of-answers-user-may-sel')} type="number" defaultValue={stagedValues[ID_NUMBER_OF_SELECTIONS_ALLOWED]}
              InputProps={{
                inputProps: {
                  max: 20, min: 1
                }
              }} />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={stagedValues[ID_CAN_SELECT_OWN_ANSWER_CB]} onChange={handleValueChange} id={ID_CAN_SELECT_OWN_ANSWER_CB} />}
              label={t('can-user-select-own-answer')}
            />
          </FormGroup>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}