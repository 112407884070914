import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BoxC from '../../components/box/BoxC';
import { gameRoutesFull } from "../../routing/gameRoutes";

const localOnly = ['isMobile'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledBoxC = styled(BoxC, { shouldForwardProp: localOnlyTest })
  (({ isMobile }) => ({
    maxWidth: 250,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
    padding: 10,
    margin: 10,
    backgroundColor: 'yellow',
    alignSelf: (isMobile ? 'center' : undefined)
  }));

export default function GotoGameBox({ mobile: isMobile = false }) {
  const { t } = useTranslation('site');
  const navigate = useNavigate();

  function handleGotoGame() {
    navigate(gameRoutesFull.landing);
  }

  return (
    <StyledBoxC isMobile={isMobile}>
      <Typography>Proceed to the game</Typography>
      <Button color="secondary" variant="contained" component="span" onClick={handleGotoGame}>
        {t('Go to Game')}
      </Button>
    </StyledBoxC>
  );
}
