import { Hub } from "aws-amplify";
import PathUtil from "../appSupport/PathUtil";
import UserStore from "../appSupport/UserStore";
import AbstractStore from "./AbstractStore";
import FinderOps from './FinderOps';

export const FINDER_CHANNEL = 'FINDER_CHANNEL';

let INSTANCE;
export default class FinderStore {
  static instance() {
    const finderIdFromUrl = PathUtil.getFinderIdParam();
    if (!INSTANCE) {
      INSTANCE = new FinderStoreInst(finderIdFromUrl);
    } else {
      // See if this is a stale instance
      const finder = INSTANCE.getFinderUnsafe();
      if (finder && finderIdFromUrl && (finder.id !== finderIdFromUrl)) {
        INSTANCE = new FinderStoreInst(finderIdFromUrl);
      }
    }
    return INSTANCE;
  }
  static isReady() {
    const finder = INSTANCE ? INSTANCE.getFinderUnsafe() : null;
    return finder && (finder.id === PathUtil.getFinderIdParam());
  }
  static initialQueryCompletePromise() {
    return FinderStore.instance().initialQueryCompletePromise();
  }
static loadedPromise() {
    return FinderStore.instance().loadedPromise();
  }
  static listen(callBack) {
    FinderStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getFinderOwner() {
    return FinderStore.isReady() ? FinderStore.getFinder().owner : null;
  }
  static getFinder() {
    return FinderStore.instance().getFinder();
  }
  static getFinderUnsafe() {
    return FinderStore.instance().getFinderUnsafe();
  }

  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }
}

class FinderStoreInst extends AbstractStore {
  constructor(finderId) {
    super('FinderStore', FINDER_CHANNEL);
    this.finderId = finderId;
    this.loadStore()
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    return `User: ${UserStore.getUserName()}  finderId: ${PathUtil.getFinderIdParam() || this.finderId}`; 
  }
  performCreate(properties) {
    return FinderOps.createFinderRecord(properties.id, properties.gameId);
  }
  queryRecords() {
    return FinderOps.getFinderRecord(this.finderId)
    .then(record=>record ? [record] : []);
  }
  performUpdate(keyFields, updates) {
    return FinderOps.performUpdate(keyFields, updates);
  }
  recKey(record) {
    return record.id;
  }

  dispatchActivity() {
    Hub.dispatch(this.channel, { finder: this.getFinder() });
  }

  getFinder() {
    return this.getRecords().length ? this.getRecords()[0] : null;
  }
  getFinderUnsafe() {
    const recs = this.getRecords(null, true);
    return recs.length ? recs[0] : null;
  }

}

