import { styled } from '@mui/material/styles';
import React from 'react';
import artwork from '../assets/MrDie200.png';
import BoxR from './box/BoxR';

const classes = {
  root: 'MrDie-root',
  image: 'MrDie-image'
};

const localOnly = ['fullWidth', 'imageSize', 'handleOnClick'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledBoxR = styled(BoxR, { shouldForwardProp: localOnlyTest })
  (({ fullWidth, imageSize, handleOnClick }) => ({
    [`&.${classes.root}`]: {
      width: fullWidth ? '100%' : undefined,
      maxHeight: imageSize,
      justifyContent: 'center',
      cursor: handleOnClick ? 'pointer' : undefined,
    },
    [`& .${classes.image}`]: {
      maxWidth: imageSize,
      maxHeight: imageSize,
      objectFit: 'contain',
    }
  }));

export default function MrDie(props) {
  const { fullWidth, small, medium, onClick: handleOnClick } = props;
  let imageSize = small ? 40 : 80;
  if (medium) imageSize = 60;

  return (
    <StyledBoxR className={classes.root} onClick={handleOnClick}
      fullWidth={fullWidth} imageSize={imageSize} handleOnClick={handleOnClick}>
      <img className={classes.image} src={artwork} alt={''} />
    </StyledBoxR>
  );
}
