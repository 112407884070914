import React from 'react';
import Globals from "../appSupport/Globals";
import { specialUserAll, specialUserDealer } from "../appSupport/GlobalConstants";
import PathUtil from "../appSupport/PathUtil";
import UserStore from "../appSupport/UserStore";
import AbstractStore from "./AbstractStore";
import CardStoreStorage from "./CardStoreStorage";
import DeckOps, { getDataOwnerLocal } from "./DeckOps";
import PendingPlayerStore from './PendingPlayerStore';

const DECK_CHANNEL = 'DECK_CHANNEL';

let INSTANCE;

export function useFreshDecks(filter) {
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    var hasEnded = false;
    var hasListener = false;
    const getRecords = () => DeckStore.getRecords(filter);
    const handleChange = (event) => {
      // const { records } = event.payload;
      if (!hasEnded) setRecords(getRecords(filter));
    }
    DeckStore.initialQueryCompletePromise()
      .then(r => {
        if (!hasEnded) {
          hasListener = true;
          DeckStore.listen(handleChange);
          setRecords(getRecords(filter));
        }
      });
    return () => {
      hasEnded = true;
      if (hasListener) {
        hasListener = false;
        DeckStore.stopListen(handleChange);
      }
    }
  }, [filter]);

  return records;
}

export default class DeckStore {

  static instance() {
    if (!INSTANCE) {
      INSTANCE = new DeckStoreInst();
    }
    return INSTANCE;
  }
  static initialQueryCompletePromise() {
    return DeckStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return DeckStore.instance().loadedPromise();
  }
  static listen(callBack) {
    DeckStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getRecords(filtered) {
    return DeckStore.instance().getRecords(filtered);
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

  static createDeck(id, name, sortSequence) {
    DeckStore.instance().createDeck(id, name, sortSequence);
  }
  static deleteDeck(id) {
    return DeckStore.instance().deleteDeck(id);
  }
  static updateDeck(id, properties) {
    DeckStore.instance().updateDeck(id, properties);
  }
  static addCard(id, cardName) {
    DeckStore.instance().addCard(id, cardName);
  }
  static deckFor(id) {
    return DeckStore.getRecords().find(f => f.id === id);
  }
  static nextCardKey(deck) {
    return deck.cardKeys[deck.nextCardIndex];
  }
  static playCard(deck) {
    return DeckStore.instance().playCard(deck);
  }
  static playersToDealTo(roundData) {
    let dealToNames = [];
    const selectedDealToNames = roundData.dealTo;
    if (selectedDealToNames.length === 1 && (selectedDealToNames[0] === specialUserAll)) {
      dealToNames = PendingPlayerStore.getPlayerNames();
    } else {
      const temp = {};
      selectedDealToNames.forEach(e => {
        if (e === specialUserDealer) {
          if (roundData.currentReader) {
            temp[roundData.currentReader] = roundData.currentReader
          }
        } else {
          temp[e] = e;
        }
      });
      dealToNames = Object.keys(temp);
    }
    return dealToNames;
  }
}

class DeckStoreInst extends AbstractStore {
  constructor() {
    const isController = PathUtil.getGameParam() ? Globals.isGameController() : true;
    super('DeckStore', DECK_CHANNEL);
    this.loadStore(isController ? DeckOps.onDeckCreate : undefined,
      isController ? DeckOps.onDeckUpdate : undefined,
      isController ? DeckOps.onDeckDelete : undefined);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    return `User: ${UserStore.getUserName()}  subscriptionOwner: ${getDataOwnerLocal()}`;
  }
  queryRecords() {
    return DeckOps.getDecks();
  }
  performCreate(properties) {
    return DeckOps.createDeck(properties.id, properties);
  }
  performDelete(keyFields) {
    return DeckOps.deleteDeck(keyFields.id);
  }
  performUpdate(keyFields, updates) {
    return DeckOps.updateDeck(keyFields.id, updates);
  }

  getSortFn() {
    return (a, b) => Globals.sorterAny(`${a.sortSequence}${a.name}`, `${b.sortSequence}${b.name}`);
  }
  recKey(record) {
    return record.id;
  }

  addCard(id, cardKey) {
    this.assertReady('addCard');
    const deck = this.getRecords().find(f => f.id === id);
    if (deck) {
      const keyFields = { id };
      const cardKeys = [...deck.cardKeys];
      cardKeys.push(cardKey);
      this.put(keyFields, { cardKeys });
    }
  }
  createDeck(id, name, sortSequence) {
    this.assertReady('createDeck');
    const keyFields = { id };
    this.put(keyFields, { name, sortSequence, nextCardIndex: 0, activeGames: [], cardKeys: [] });
  }
  playCard(deck) {
    this.assertReady('playCard');
    const keyFields = { id: deck.id };
    const properties = { nextCardIndex: deck.nextCardIndex + 1 }
    this.put(keyFields, properties);
  }
  updateDeck(id, properties) {
    this.assertReady('updateDeck');
    const keyFields = { id };
    this.put(keyFields, properties);
    this.records = this.assimilateList(this.getRecords()); // Should be done in super or is it a rare case (sorting for sortSequence field)?
  }
  deleteDeck(id) {
    this.assertReady('deleteDeck');
    return CardStoreStorage.list(null, id)
      .then(cards => {
        const all = cards.map(e => CardStoreStorage.remove(e.key));
        Promise.all(all).then(() => {
          const keyFields = { id };
          this.deleteRec(keyFields);
        });
      });
  }
}

