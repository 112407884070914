// NLS-NLS(player)
import React from 'react';
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from 'uuid';
import AnalyticsStore from '../../appSupport/AnalyticsStore';
import Globals from '../../appSupport/Globals';
import PathUtil from '../../appSupport/PathUtil';
import { STORAGE_userName, STORAGE_voidJoinGameId } from '../../appSupport/storageConstants';
import UserStore from '../../appSupport/UserStore';
import BoxCG from '../../components/box/BoxCG';
import MrDie from '../../components/MrDie';
import FinderOps from '../../db/FinderOps';
import GameOps, { HAND_STATUS_END, HAND_STATUS_PLAY } from '../../db/GameOps';
import PendingPlayerOps from '../../db/PendingPlayerOps';
import { useStateWithLocalStorage, useStateWithSessionStorage } from '../../db/StorageStore';
import ConfirmationPrompt from '../../prompt/ConfirmationPrompt';
import DebugPrompt from '../../prompt/DebugPrompt';
import GamePrompt from '../../prompt/GamePrompt';
import { gameRoutesFull } from '../../routing/gameRoutes';
import LandingBox from './LandingBox';
import LandingButton from './LandingButton';
import LandingFooter from './LandingFooter';
import LanguageLinks from './LanguageLinks';
import LoginLinks from './LoginLinks';
import RejoinRecentGame from './RejoinRecentGame';
import SupportRequestButton from './SupportRequestButton';
import SupportRequestButtonUser from './SupportRequestButtonUser';
import { useNavigate } from 'react-router-dom';

const JOIN_CLOSED = { open: false };

export const newSearchPath = (gameId, playerName, finderId, entryKey) => {
  const entryKeyPortion = entryKey ? `&entryKey=${entryKey}` : '';
  return `?game=${gameId}&name=${playerName}&finderId=${finderId}${entryKeyPortion}`;
}

export const newEntryKey = (existingEntryKeys) => {
  return GameOps.defineEntryKeys(1, existingEntryKeys)[0];
}

function LandingContent() {
  const [isNewOpen, setIsNewOpen] = React.useState(false);
  const [isJoinOpen, setIsJoinOpen] = React.useState(false);
  const [joinExisting, setJoinExisting] = React.useState(JOIN_CLOSED);
  const [finderId, setFinderId] = React.useState('');
  const [playerName, setPlayerName] = useStateWithLocalStorage(STORAGE_userName);
  const [voidJoinGameId, setVoidJoinGameId] = useStateWithSessionStorage(STORAGE_voidJoinGameId);
  const [gameIdErrorText, setGameIdErrorText] = React.useState('');
  const [playerErrorText, setPlayerErrorText] = React.useState('');
  const [protectUi, setProtectUi] = React.useState(false);
  const [logPromptOpen, setLogPromptOpen] = React.useState(false);
  const [isLoginRequiredOpen, setIsLoginRequiredOpen] = React.useState(false);
  const { t } = useTranslation('player');
  const isDefaultUser = Globals.isDefaultUser();
  const joinGameId = PathUtil.getJoinGameParam();
  const navigate = useNavigate();

  const JOIN_GAME_TITLE = t('join-game');
  const NEW_GAME_TITLE = t('new-game');

  const JOIN_EXISTING_TITLE = t('join-existing-game');
  const JOIN_EXISTING_TEXT = t('request-the-controller-to-admi');
  const REJOIN_EXISTING_TEXT = t('request-the-controller-to-re-a');

  const LOGIN_REQUIRED_TITLE = t('login-required');
  const LOGIN_REQUIRED_TEXT = t('you-must-login-to-manage-decks');

  async function createGame(playerName) {
    setProtectUi(true);
    const gameId = uuidV4();
    const [game, finderId] = await GameOps.createGame(gameId, playerName);
    if (game) { // No game means error handled by CognitoRouter
      setFinderId(finderId);
      // Must use PendingPlayerOps instead of PendingPlayerStore because no game exists.
      await PendingPlayerOps.createPlayer({ id: game.id, playerName });
      navigate(gameRoutesFull.addPlayers + newSearchPath(game.id, playerName, finderId));
      AnalyticsStore.recordCreateGame(playerName);
    }
  }
  async function registerPlayer(playerNameIn, gameFinderId) {
    setProtectUi(true);
    const finderRecord = await FinderOps.getFinderRecord(gameFinderId);
    if (!finderRecord) {
      setGameIdErrorText(t('game-id-not-found', { gameFinderId }));
      setIsJoinOpen(true);
    } else {
      const finderId = finderRecord.id;
      if (finderRecord.owner !== UserStore.getUser().username) {
        await UserStore.signInWithGameId(finderRecord.gameId);
      }
      const game = await GameOps.getGameWithId(finderRecord.gameId);
      const pendingPlayers = await PendingPlayerOps.getPendingPlayers(game.id);
      const playerNames = pendingPlayers.map(m => m.playerName);
      const gameHandStatus = Globals.isAttachMode() ? HAND_STATUS_PLAY : game.handStatus;
      if (!game) {
        // Do nothing and let the error show itself.
      } else if (gameHandStatus === HAND_STATUS_END) {
        setGameIdErrorText(t('that-game-has-ended'));
        setIsJoinOpen(true);
      } else if (gameHandStatus === HAND_STATUS_PLAY) {
        if (Globals.getGameStaleMinutes(game) >= 15) {
          // Stale game chosen
          setGameIdErrorText(t('that-game-has-been-idle-for-to'));
          setIsJoinOpen(true);
        } else {
          // New player or player needs to be re-admitted.  
          const existingPlayerName = playerNames.find(f => f.toLowerCase() === playerNameIn.toLowerCase())
          if (existingPlayerName && (existingPlayerName !== playerNameIn)) setPlayerName(existingPlayerName);
          const playerName = existingPlayerName || playerNameIn;
          AnalyticsStore.recordRegisterPlayer(playerName);
          if (existingPlayerName) {
            // Re-admit scenario
            if (game.controllerName === playerName) {
              // If this is a controller request, silently add an entry request with a mangled name.  The 
              // addPlayer view will automatically allow entry if there is not an attached controller.
              const existingEntryKeys = pendingPlayers.map(m => m.entryKey);
              const entryKey = newEntryKey(existingEntryKeys);
              GameOps.addEntryRequest(game, playerName, entryKey).then(() => {
                navigate(gameRoutesFull.addPlayers + newSearchPath(finderRecord.gameId, playerName, finderRecord.id, entryKey));
              });
            } else {
              const joinExistingObj = { open: true, title: JOIN_EXISTING_TITLE, text: REJOIN_EXISTING_TEXT, playerName, finderId };
              setJoinExisting(joinExistingObj);
            }
          } else {
            // New player scenario
            const joinExistingObj = { open: true, title: JOIN_EXISTING_TITLE, text: JOIN_EXISTING_TEXT, playerName, finderId };
            setJoinExisting(joinExistingObj);
          }
        }
      } else if (playerNames.map(m => m.toLowerCase()).find(f => f.playerName === playerNameIn.toLowerCase())) {
        setPlayerErrorText(t('player-name-already-registered', { playerNameIn }));
        setIsJoinOpen(true);
      } else {
        await PendingPlayerOps.createPlayer({ id: finderRecord.gameId, playerName: playerNameIn, owner: finderRecord.owner });
        navigate(gameRoutesFull.addPlayers + newSearchPath(finderRecord.gameId, playerNameIn, finderRecord.id));
      }
    }
  }
  function clearStaged() {
    setFinderId(null);
    // setPlayerName(null);
    setGameIdErrorText('');
    setPlayerErrorText('');
  }
  function handleCardDecksButton() {
    if (isDefaultUser) {
      setIsLoginRequiredOpen(true);
    } else {
      navigate(gameRoutesFull.decks);
    }
  }
  function handleSupportRequestsButton() {
    navigate(gameRoutesFull.supportRequests);
  }
  function handleDebugPromptCancel() {
    setLogPromptOpen(false);
  }
  function handleGamePromptCancel() {
    clearStaged();
    if (joinGameId) setVoidJoinGameId(joinGameId); // We only try it once
    setIsJoinOpen(false);
    setIsNewOpen(false);
    setProtectUi(false);
  }
  function handleGamePromptSave(playerName, finderId) {
    setFinderId(finderId);
    setPlayerName(playerName);
    if (joinGameId) setVoidJoinGameId(joinGameId); // We only try it once
    setGameIdErrorText('');
    setPlayerErrorText('');
    if (isJoinOpen) {
      registerPlayer(playerName, finderId);
      setIsJoinOpen(false);
    } else {
      createGame(playerName);
      setIsNewOpen(false);
    }
  }
  function handleJoinExistingCancel() {
    setJoinExisting(JOIN_CLOSED);
    setProtectUi(false);
    clearStaged();
  }
  async function handleJoinExistingSave() {
    setJoinExisting(JOIN_CLOSED);
    const finderRecord = await FinderOps.getFinderRecord(joinExisting.finderId);
    const game = await GameOps.getGameWithId(finderRecord.gameId);
    const pendingPlayers = await PendingPlayerOps.getPendingPlayers(game.id);
    const existingEntryKeys = pendingPlayers.map(m => m.entryKey);
    const entryKey = newEntryKey(existingEntryKeys);
    GameOps.addEntryRequest(game, playerName, entryKey).then(() => {
      navigate(gameRoutesFull.addPlayers + newSearchPath(finderRecord.gameId, playerName, finderRecord.id, entryKey));
    });
  }
  function handleNewGame() {
    setIsNewOpen(true);
  }
  function handleJoinGame() {
    setIsJoinOpen(true);
  }
  function renderCreateGameButton() {
    return (
      <LandingButton onClick={handleNewGame} disabled={protectUi}>
        {t('new-game')}
      </LandingButton>
    );
  }
  function renderJoinGameButton() {
    return (
      <LandingButton onClick={handleJoinGame} disabled={protectUi}>
        {t('join-game')}
      </LandingButton>
    );
  }
  function renderCardDecksButton() {
    return (
      <LandingButton onClick={handleCardDecksButton} disabled={protectUi}>
        {t('card-decks')}
      </LandingButton>
    );
  }
  function renderSupportRequestsButton() {
    if (Globals.isProductSupporter()) {
      // IMPORTANT not to render this if not a product supporter... subscriptions
      return <SupportRequestButton onClick={handleSupportRequestsButton} disabled={protectUi} />
    } else if (!isDefaultUser) {
      return <SupportRequestButtonUser onClick={handleSupportRequestsButton} disabled={protectUi} />
    }
    return null;
  }
  const promptTitle = isJoinOpen ? JOIN_GAME_TITLE : NEW_GAME_TITLE;
  if (joinGameId && (joinGameId !== voidJoinGameId) && !isJoinOpen) {
    setIsJoinOpen(true);
    setFinderId(joinGameId);
  }

  return (
    <BoxCG>
      <GamePrompt open={isJoinOpen || isNewOpen} title={promptTitle} playerName={playerName} finderId={finderId}
        protectFinderId={joinGameId && (joinGameId !== voidJoinGameId)}
        idErrorText={gameIdErrorText} playerErrorText={playerErrorText}
        onSave={handleGamePromptSave} onCancel={handleGamePromptCancel} promptForId={isJoinOpen} />
      <ConfirmationPrompt open={joinExisting.open} onSave={handleJoinExistingSave} onClose={handleJoinExistingCancel}
        title={joinExisting.title} text={joinExisting.text} continueButtonText={JOIN_GAME_TITLE} />
      <ConfirmationPrompt open={isLoginRequiredOpen} onClose={f => setIsLoginRequiredOpen(false)} showCancelButton={false}
        title={LOGIN_REQUIRED_TITLE} text={LOGIN_REQUIRED_TEXT} />
      <RejoinRecentGame />
      <DebugPrompt open={logPromptOpen} onCancel={handleDebugPromptCancel} />
      <BoxCG >
        <LoginLinks />
        <LanguageLinks />
        <LandingBox >
          {renderCreateGameButton()}
          {renderJoinGameButton()}
          {renderCardDecksButton()}
          {renderSupportRequestsButton()}
        </LandingBox>
        <MrDie fullWidth />
        <BoxCG />
        <LandingFooter />
      </BoxCG>
    </BoxCG>
  );
}

export default LandingContent;
