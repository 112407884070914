import Globals from "../appSupport/Globals";
import UserStore from "../appSupport/UserStore";
import AbstractStore from "./AbstractStore";
import ProfileOps from "./ProfileOps";
import StorageStore from "./StorageStore";

const PROFILE_CHANNEL = 'PROFILE_CHANNEL';
const DEFAULT_PROFILE = { lastTeams: [], playerNames: [] };

const KEY_LAST_TEAMS = 'lastTeams2';  // Number necessary as format has changed from string to JSON
const KEY_PLAYER_NAMES = 'playerNames2';
const LAST_TEAMS_LIST_LENGTH = 8;
const DEFAULT_TEAM_NAMES = ['Boys, Girls', 'Steve, Layla, Anita, Suzie Q', 'Red Team, Blue Team'];

let INSTANCE;

export default class ProfileStore {

  static instance() {
    const userName = UserStore.getUser().username;
    const storeUserName = INSTANCE ? INSTANCE.getUserName() : '';
    if (!INSTANCE || (userName !== storeUserName)) {
      ProfileStore.release();
      INSTANCE = new ProfileStoreInst(userName);
    }
    return INSTANCE;
  }
  static initialQueryCompletePromise() {
    return ProfileStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return ProfileStore.instance().loadedPromise();
  }
  static listen(callBack) {
    ProfileStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getProfile() {
    return ProfileStore.instance().getProfile();
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }
  static formatTeamNames(teamNames) {
    let result = '';
    const namesArray = typeof teamNames === 'string' ? teamNames.split(',') : teamNames;
    namesArray.forEach(n => {
      if (result.length) result += ', ';
      result += n.trim();
    });
    return result;
  }
  static addLastTeams(teamNames) {
    const formattedTeamNames = ProfileStore.formatTeamNames(teamNames);
    const newNames = ProfileStore.getLastTeams().filter(f => f !== formattedTeamNames);
    newNames.unshift(formattedTeamNames);
    while (newNames.length > LAST_TEAMS_LIST_LENGTH) newNames.pop();
    ProfileStore.setLastTeams(newNames);
    ProfileStore.addPlayerNames(formattedTeamNames.split(','));
  }
  static getLastTeams() {
    if (Globals.isDefaultUser()) return StorageStore.fromLocalStorage(KEY_LAST_TEAMS, DEFAULT_TEAM_NAMES);
    const result = ProfileStore.getProfile().lastTeams || [];
    return result.length ? result : DEFAULT_TEAM_NAMES;
  }
  static setLastTeams(teams) {
    return Globals.isDefaultUser()
      ? StorageStore.toLocalStorage(KEY_LAST_TEAMS, teams)
      : ProfileStore.instance().setLastTeams(teams);
  }
  static addPlayerNames(playerNamesIn) {
    const playerNames = playerNamesIn.map(m => m.trim());
    const playerNamesLC = playerNames.map(m=>m.toLowerCase());
    const newNames = ProfileStore.getPlayerNames().filter(f => !playerNamesLC.includes(f.toLowerCase()));
    playerNames.forEach(e => newNames.push(e));
    ProfileStore.setPlayerNames(newNames);
  }
  static removePlayerNames(playerNamesIn) {
    const playerNames = playerNamesIn.map(m => m.trim());
    const newNames = ProfileStore.getPlayerNames().filter(f => !playerNames.includes(f));
    ProfileStore.setPlayerNames(newNames);
  }
  static getPlayerNames() {
    if (Globals.isDefaultUser()) return StorageStore.fromLocalStorage(KEY_PLAYER_NAMES, []);
    return ProfileStore.getProfile().playerNames || [];
  }
  static setPlayerNames(names) {
    return Globals.isDefaultUser()
      ? StorageStore.toLocalStorage(KEY_PLAYER_NAMES, names)
      : ProfileStore.instance().setPlayerNames(names);
  }
}

class ProfileStoreInst extends AbstractStore {
  constructor(userName) {
    super('ProfileStore', PROFILE_CHANNEL);
    this.defaultProfile = { userName, ...DEFAULT_PROFILE };
    this.userName = userName;
    this.loadStore(ProfileOps.onProfileCreate, ProfileOps.onProfileUpdate);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    return `User: ${UserStore.getUserName()}`;
  }
  queryRecords() {
    return ProfileOps.getProfiles();
  }
  recKey(record) {
    return `${record.userName}`;
  }
  performCreate(properties) {
    return ProfileOps.createProfile(properties);
  }
  performUpdate(keyFields, updates) {
    return ProfileOps.updateProfile(keyFields, updates);
  }

  getProfile() {
    return this.getRecords().length ? this.getRecords()[0] : this.defaultProfile;
  }
  getUserName() {
    return this.userName;
  }
  setLastTeams(teams) {
    this.put({ userName: this.getUserName() }, { lastTeams: teams });
  }
  setPlayerNames(names) {
    this.put({ userName: this.getUserName() }, { playerNames: names });
  }

}

