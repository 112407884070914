import { ErrorRounded, InfoRounded, WarningRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import BoxC from '../components/box/BoxC';
import BoxR from '../components/box/BoxR';


const classes = {
  centeredRow: 'PromptMessage-centeredRow',
  errorIcon: 'PromptMessage-errorIcon',
  warningIcon: 'PromptMessage-warningIcon',
  infoIcon: 'PromptMessage-infoIcon'
};

const Root = styled(BoxC)(({ theme }) => ({
  [`& .${classes.centeredRow}`]: {
    alignItems: 'center',
  },
  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  [`& .${classes.warningIcon}`]: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
  },
  [`& .${classes.infoIcon}`]: {
    color: theme.palette.info.main,
    marginRight: theme.spacing(1),
  }
}));

export default function PromptMessage(props) {
  const { errorText, warningText, infoText, plainText = false, ...rest } = props;

  if (isEmpty(errorText) && isEmpty(warningText) && isEmpty(infoText)) return null;  // ==> EARLY EXIT

  function isEmpty(textOrArray) {
    return Array.isArray(textOrArray) ? Array.isEmpty(textOrArray) : !!textOrArray;
  }

  function RenderError({ text }) {
    return (
      <BoxR className={classes.centeredRow}>
        <ErrorRounded className={classes.errorIcon} />
        {!plainText && <Typography>{text}</Typography>}
        {plainText && text}
      </BoxR>
    );
  }
  function RenderWarning({ text }) {
    return (
      <BoxR className={classes.centeredRow}>
        <WarningRounded className={classes.warningIcon} />
        {!plainText && <Typography>{text}</Typography>}
        {plainText && text}
      </BoxR>
    );
  }
  function RenderInfo({ text }) {
    return (
      <BoxR className={classes.centeredRow}>
        <InfoRounded className={classes.infoIcon} />
        {!plainText && <Typography>{text}</Typography>}
        {plainText && text}
      </BoxR>
    );
  }

  function RenderErrors() {
    if (!errorText) return null;
    return Array.isArray(errorText)
      ? errorText.map(m => <RenderError text={m} />)
      : <RenderError text={errorText} />
  }
  function RenderWarnings() {
    if (!warningText) return null;
    return Array.isArray(warningText)
      ? warningText.map(m => <RenderWarning text={m} />)
      : <RenderWarning text={warningText} />
  }
  function RenderInfos() {
    if (!infoText) return null;
    return Array.isArray(infoText)
      ? infoText.map(m => <RenderInfo text={m} />)
      : <RenderInfo text={infoText} />
  }

  return (
    <Root {...rest}>
      <RenderErrors />
      <RenderWarnings />
      <RenderInfos />
    </Root>
  );
}
