import { styled } from '@mui/material/styles';
import React from 'react';

const localOnly = ['indent'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledP = styled('p', { shouldForwardProp: localOnlyTest })(({ theme, indent }) => ({
  marginTop: theme.spacing(1),
  fontSize: 16,
  marginLeft: indent ? theme.spacing(indent) : undefined,
}));

export default function StyleParagraph(props) {
  const { children, open = true, indent, ...rest } = props;

  if (!open) return null; // ==> EARLY EXIT
  return (
    <StyledP indent={indent} {...rest}>
      {children}
    </StyledP>
  );
}
