import Globals from "../appSupport/Globals";

export default class AnswerAnnotations {
  static forPlayerNumber(playerResult, playerNumber) {
    if (playerResult && playerResult.annotations && playerResult.annotations.length >= playerNumber) {
      const raw = playerResult.annotations[playerNumber - 1];
      return raw ? new AnswerAnnotations(raw) : null;
    }
    return null;
  }

  static visitEachAnnotation(result, cb) {
    // Cycle through each user's selection fields and perform callback.
    if (result && result.annotations) {
      result.annotations.forEach((e, i) => {
        const aa = e ? new AnswerAnnotations(e) : null;
        const playerNumber = i + 1;
        if (aa) cb(result, aa, Globals.getPlayerName(playerNumber), playerNumber);
      })
    }
  }

  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.answerSelected = input ? input.answerSelected : false;
    this.answerNoteText = input ? input.answerNoteText : '';
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

