import { styled } from '@mui/material/styles';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import BoxC from '../../components/box/BoxC';
import BoxR from '../../components/box/BoxR';
import NewGameButton from '../../components/button/NewGameButton';
import NewRoundButton from '../../components/button/NewRoundButton';
import DividerH from '../../components/DividerH';
import ScoreTableList from '../../components/list/ScoreTableList';
import PlayerStatusLine from '../../components/PlayerStatusLine';
import StatusBoxWithLogo from '../../components/StatusBoxWithLogo';
import GameStore from '../../db/GameStore';
import PendingPlayerStore from '../../db/PendingPlayerStore';
import ScoreStore, { useFreshScores } from '../../db/ScoreStore';
import NavigationCode from '../../fields/NavigationCode';
import ScoreOptions from '../../fields/ScoreOptions';

const classes = {
  root: 'ScoreContent-root',
  publicBox: 'ScoreContent-publicBox',
  centeredCol: 'ScoreContent-centeredCol',
  centeredRow: 'ScoreContent-centeredRow',
  controllerButton: 'ScoreContent-controllerButton',
  nameList: 'ScoreContent-nameList',
  nameListEntry: 'ScoreContent-nameListEntry'
};

const StyledBoxC = styled(BoxC)(({ theme }) => ({
  [`& .${classes.publicBox}`]: {
    minWidth: 300,
    margin: theme.spacing(1),
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
    backgroundColor: theme.palette.scoreBackground.main,
    // overflow: 'auto',
  },
  [`& .${classes.centeredCol}`]: {
    alignItems: 'center',
  },
  [`& .${classes.centeredRow}`]: {
    marginTop: theme.spacing(1),
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.controllerButton}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.nameList}`]: {
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  [`& .${classes.nameListEntry}`]: {
    marginLeft: theme.spacing(1),
  }
}));

export default function ScoreContent(props) {
  const game = GameStore.getGame();
  const { onFinished, ...rest } = props;

  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const navigationCode = new NavigationCode(game.navigationCode);
  const [sortResultsNow, setSortResultsNow] = React.useState(true);
  const [showScoringButtons, setShowScoringButtons] = React.useState(navigationCode.isEditScore());
  const [showBidButtons, setShowBidButtons] = React.useState(false);
  const [lastSortOrder, setLastSortOrder] = React.useState([]);
  const [handToDisplay, setHandToDisplay] = React.useState(undefined);
  const [currentHand, setCurrentHand] = React.useState(undefined);
  const scoresByName = useFreshScores(undefined, handToDisplay);
  const isGameController = Globals.isGameController(game);
  const isScoreKeeper = Globals.isScoreKeeper(game);
  const temp = ScoreStore.sortedScores(game, scoresByName); // Fluffed with empty scores
  const rows = sortResultsNow ? temp : lastSortOrder.map(m => scoresByName[m]);
  const handOffset = Globals.getScoringHandOffset();
  const relativeCurrentHand = Globals.getRelativeCurrentHand();
  const relativeHandToDisplay = handToDisplay ? handToDisplay - handOffset : relativeCurrentHand;
  const { t } = useTranslation('controller');

  if (currentHand !== game.currentHand) {
    setCurrentHand(game.currentHand);
    setHandToDisplay(undefined);
  }
  if (sortResultsNow) {
    const newLastSortOrder = rows.map(m => m.name);
    if (newLastSortOrder.toString() !== lastSortOrder.toString()) {
      setLastSortOrder(newLastSortOrder);
    }
  }
  function handleChangeBidButtons(event) {
    const { checked } = event.target;
    if (checked) setShowScoringButtons(false);
    setShowBidButtons(checked);
  }
  function handleChangeScoringButtons(event) {
    const { checked } = event.target;
    if (checked) setShowBidButtons(false);
    setShowScoringButtons(checked);
  }
  function handleFinished() {
    onFinished();
  }
  function handleRoundNext() {
    const nextRound = handToDisplay + 1;
    setHandToDisplay(nextRound === currentHand ? undefined : nextRound);
  }
  function handleRoundPrevious() {
    const nextRound = (handToDisplay ? handToDisplay : currentHand) - 1;
    setHandToDisplay(nextRound);
  }
  function handleScoreChange(scoreRow, score) {
    if (showScoringButtons) {
      ScoreStore.setScore(game.currentHand, (scoreRow.name || scoreRow.playerName), score);
      setSortResultsNow(false);
    } else if (showBidButtons) {
      ScoreStore.setBid(game.currentHand, scoreRow, score);
    }
  }
  function handleSortNow(event) {
    const { checked } = event.target;
    setSortResultsNow(checked);
  }

  function RenderPrimaryButton(props) {
    const newProps = { color: 'primary', ...props }
    return (
      <RenderButton {...newProps} />
    )
  }
  function RenderSecondaryButton(props) {
    const newProps = { color: 'secondary', ...props }
    return (
      <RenderButton {...newProps} />
    )
  }
  function RenderButton(props) {
    const { open, color: colorIn, children, ...rest } = props;
    if (open !== undefined && !open) return null; // ==> EARLY EXIT
    const color = colorIn || 'primary';
    return (
      <Button className={classes.controllerButton} {...rest} variant="contained" color={color} >
        {children}
      </Button>
    );
  }
  function RenderPlayerNamesAndBids() {
    function addBidChild(childArray) {
      if (scoreOptions.enableBids) {
        const totalBids = rows.reduce((total, row) => total + (row.bid ? row.bid : 0), 0);
        childArray.push(<Typography sx={{ fontWeight: 'bold' }} key='key-bid-total'>{t('Total Bids: {{totalBids}}', { totalBids })}</Typography>);
      }
    }
    function addSeparator(childArray) {
      if (scoreOptions.enableBids && scoreOptions.teamNames) {
        childArray.push(<Typography sx={{ 'ml': 1 }} key='key-separator'>{'|'}</Typography>);
      }
    }
    function addNames(childArray) {
      if (scoreOptions.teamNames) {
        names.forEach((m, i) => {
          const isLast = i === names.length - 1;
          childArray.push(<Typography key={`nameList-${m}`} className={classes.nameListEntry}>{m}</Typography>);
          childArray.push(<Typography key={`nameListComma-${m}`} >{isLast ? '' : ','}</Typography>);
        });
      }
    }

    const names = PendingPlayerStore.getPlayerNames();
    const children = [];
    addBidChild(children);
    if (names.length > 1) {
      addSeparator(children);
      addNames(children);
    }

    if (!children.length) return null;  // ==> EARLY EXIT
    return (
      <BoxR className={classes.nameList}>
        {children}
      </BoxR>
    );
  }
  function RenderRoundButtons() {
    const previousDisabled = relativeHandToDisplay <= 1;
    const nextDisabled = handToDisplay === undefined;
    const roundNo = handToDisplay ? handToDisplay - handOffset : currentHand - handOffset;
    return (
      <BoxR className={classes.centeredRow}>
        <RenderSecondaryButton open={currentHand > 1} onClick={handleRoundPrevious} disabled={previousDisabled}>
          <BackIcon />
        </RenderSecondaryButton>
        <Typography style={{ marginRight: 7 }}>{t('round-roundno', { roundNo })}</Typography>
        <RenderSecondaryButton open={currentHand > 1} onClick={handleRoundNext} disabled={nextDisabled}>
          <ForwardIcon />
        </RenderSecondaryButton>
      </BoxR>
    )
  }
  function RenderControllerRow(props) {
    const { open } = props;
    if (!open) return null; // ==> EARLY EXIT
    const isScoreOnly = scoreOptions.scoreOnly;
    return (
      <BoxR className={classes.centeredRow} >
        <NewGameButton />
        <RenderPrimaryButton open={!isScoreOnly} onClick={handleFinished}>
          {t('finished')}
        </RenderPrimaryButton>
        <NewRoundButton open={isScoreOnly} />
        <FormControlLabel
          control={<Checkbox checked={sortResultsNow} onChange={handleSortNow} />}
          label={t('sort-results')}
        />
        {scoreOptions.enableBids ?
          <FormControlLabel style={{}}
            control={<Checkbox checked={showBidButtons} onChange={handleChangeBidButtons} disabled={isShowingPrevRounds} />}
            label={t('bids')}
          />
          : null}
        {scoreOptions.useScoreButtons ?
          <FormControlLabel style={{}}
            control={<Checkbox checked={showScoringButtons} onChange={handleChangeScoringButtons} disabled={isShowingPrevRounds || !relativeCurrentHand} />}
            label={t('scores')}
          />
          : null}
      </BoxR>
    );
  }

  const isControllerOrScoreKeeper = isGameController || isScoreKeeper;
  const isShowingPrevRounds = !!handToDisplay;
  return (
    <StyledBoxC className={classes.root} {...rest} >
      <StatusBoxWithLogo medium>
        <PlayerStatusLine game={game} includePublishedTo={false} />
      </StatusBoxWithLogo>
      <BoxC className={classes.centeredCol}>
        <RenderControllerRow open={isControllerOrScoreKeeper} />
        <DividerH open={isControllerOrScoreKeeper} style={{ marginTop: 5 }} />
        <RenderRoundButtons />
        <RenderPlayerNamesAndBids />
        <div className={classes.publicBox}>
          <ScoreTableList game={game} rows={rows} onScoreChange={handleScoreChange}
            showScoringButtons={showScoringButtons && !isShowingPrevRounds}
            showBidButtons={showBidButtons && !isShowingPrevRounds}
            handToDisplay={handToDisplay} />
        </div>
      </BoxC>
    </StyledBoxC>
  );
}
