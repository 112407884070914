// NLS-NLS(player)
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PendingPlayerStore from '../db/PendingPlayerStore';
import ResultStore from '../db/ResultStore';

export default function AnswersInStatus() {
  const { t } = useTranslation('player');
  const answersIn = ResultStore.getResultCount();
  const totalPlayerCount = PendingPlayerStore.getPlayerCount();

  if (!answersIn) return null; // ==> EARLY EXIT
  return <Typography variant='h5' style={{ color: '#a09f9f' }}>{t('answers-in-x-of-y', { x: answersIn, y: totalPlayerCount })}</Typography>
}
