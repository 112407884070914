// NLS-NLS(controller)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const classes = {
  root: 'StartPlayButton-root'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: theme.palette.secondary.contrastText,
  }
}));

export default function StartPlayButton(props) {

  const { onClick, sx: sxIn } = props;
  const { t } = useTranslation('controller');

  return (
    <StyledButton className={classes.root} sx={sxIn} variant="contained" onClick={onClick} >
      {t('start-play')}
    </StyledButton>
  );
}
