// NLS-NLS(controller)
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BoxR from '../components/box/BoxR';
import GameStore from '../db/GameStore';
import PerformAutoScoringSelect from '../db/PerformAutoScoringSelect';
import { useFreshScores } from '../db/ScoreStore';
import GameOptions from '../fields/GameOptions';
import GamePlay from '../fields/GamePlay';
import ScoreOptions from '../fields/ScoreOptions';
import ConfirmationPrompt from '../prompt/ConfirmationPrompt';

const ID_ANONYMOUS_CB = 'id_anonymous_cb';
const ID_ANSWER_TIMER_CB = 'id_answer_timer_cb';
const ID_ANSWER_SELECTION_CB = 'id_answer_selection_cb';
const ID_ANSWER_SELECTION_PUBLISH_CB = 'id_answer_selection_publish_cb';
const ID_ANSWER_NOTES_CB = 'id_answer_notes_cb';
const ID_ANSWER_NOTES_PUBLISH_CB = 'id_answer_notes_publish_cb';


export default function PlayOptionControls(props) {
  const { game: gameIn, gamePlay: gamePlayIn, onSave, open = true } = props;
  const [isConfirmAutoScoreOpen, setIsConfirmAutoScoreOpen] = React.useState(false);
  const game = gameIn || GameStore.getGame();
  const gameOptions = new GameOptions(game.gameOptions);
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const gamePlay = gamePlayIn ? gamePlayIn : GamePlay.fromGame(game);
  const scores = Object.values(useFreshScores()).filter(f => f.hand === game.currentHand && f.handScore);

  const { t } = useTranslation('controller');


  const handleAnswerSelection = (event) => {
    const { checked } = event.target;
    gamePlay.areAnswerSelectionsActive = checked;
    updateGamePlay();
    // If being turned off and selection scoring is active, see if user wants to auto score.
    if (!checked && scoreOptions.useAutoScoreSelect && !scores.length) {
      setIsConfirmAutoScoreOpen(true);
    }
  }

  const handleAnswerTimer = (event) => {
    const { checked } = event.target;
    gamePlay.isAnswerTimerActive = checked;
    updateGamePlay();
  }

  const handleAnswerSelectionPublish = (event) => {
    const { checked } = event.target;
    gamePlay.areAnswerSelectionsPublic = checked;
    updateGamePlay();
  }

  const handleAnswerNotes = (event) => {
    const { checked } = event.target;
    gamePlay.areAnswerNotesActive = checked;
    updateGamePlay();
  }

  const handleAnswerNotesPublish = (event) => {
    const { checked } = event.target;
    gamePlay.areAnswerNotesPublic = checked;
    updateGamePlay();
  }

  const handleAnonymous = (event) => {
    const { checked } = event.target;
    gamePlay.anonymousResult = checked;
    updateGamePlay();
  }

  function handleCloseConfirmAutoScore() {
    setIsConfirmAutoScoreOpen(false);
  }
  function handleSaveConfirmAutoScore() {
    setIsConfirmAutoScoreOpen(false);
    PerformAutoScoringSelect.perform();
  }

  const updateGamePlay = () => {
    onSave(gamePlay);
  }

  if (!open) return null;

  return (
    <BoxR style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      <ConfirmationPrompt open={isConfirmAutoScoreOpen} onClose={handleCloseConfirmAutoScore}
        onSave={handleSaveConfirmAutoScore} continueButtonText={t('Auto Score')}
        title={t('Auto Scoring')} text={t("Would you like to perform auto scoring now based on player's selections?")} />
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={gamePlay.anonymousResult} onChange={handleAnonymous} id={ID_ANONYMOUS_CB} />}
          label={t('anonymous')}
        />
        <FormControlLabel
          control={<Checkbox checked={gamePlay.isAnswerTimerActive} onChange={handleAnswerTimer} id={ID_ANSWER_TIMER_CB} />}
          label={t('answer-timer')}
        />
        {gameOptions.isAnswerSelectable ?
          <FormControlLabel
            control={<Checkbox checked={gamePlay.areAnswerSelectionsActive} onChange={handleAnswerSelection} id={ID_ANSWER_SELECTION_CB} />}
            label={t('selections')}
          />
          : null}
        {gameOptions.isAnswerSelectable ?
          <FormControlLabel
            control={<Checkbox checked={gamePlay.areAnswerSelectionsPublic} onChange={handleAnswerSelectionPublish} id={ID_ANSWER_SELECTION_PUBLISH_CB} />}
            label={t('selections-public')}
          />
          : null}
        {gameOptions.isAnswerNotes ?
          <FormControlLabel
            control={<Checkbox checked={gamePlay.areAnswerNotesActive} onChange={handleAnswerNotes} id={ID_ANSWER_NOTES_CB} />}
            label={t('notes')}
          />
          : null}
        {gameOptions.isAnswerNotes ?
          <FormControlLabel
            control={<Checkbox checked={gamePlay.areAnswerNotesPublic} onChange={handleAnswerNotesPublish} id={ID_ANSWER_NOTES_PUBLISH_CB} />}
            label={t('notes-public')}
          />
          : null}
      </FormGroup>
    </BoxR>
  );
}
