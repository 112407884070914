import ScoreIcon from '@mui/icons-material/DragIndicator';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import BoxC from '../components/box/BoxC';
import BoxR from '../components/box/BoxR';
import ScoreButtons from '../fields/ScoreButtons';
import ScoreOptions from '../fields/ScoreOptions';
import DividerV from './DividerV';

export const classes = {
  root: 'ScoreableEntry-root',
  buttonBox: 'ScoreableEntry-buttonBox',
  centeredRow: 'ScoreableEntry-centeredRow',
  rowWrap: 'ScoreableEntry-rowWrap',
  scoreBox: 'ScoreableEntry-scoreBox',
  textField: 'ScoreableEntry-textField'
};

const StyledBoxC = styled(BoxC)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    alignSelf: 'stretch'
  },
  [`& .${classes.buttonBox}`]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.centeredRow}`]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.rowWrap}`]: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  [`& .${classes.scoreBox}`]: {
    minWidth: 350,
    padding: theme.spacing(1),
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
    backgroundColor: theme.palette.secondary.light,
  },
  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    maxWidth: 100,
  }
}));

export default function ScoreableEntry(props) {
  const { isOpen, onOpenClose, children, onContentClick, contentContainerStyle, isScoreKeeper,
    scoreOptionsString, showScoreButtons, sx: sxIn } = props;
  const { onScoreChange, scoreToEdit } = props;
  const scoreOptions = new ScoreOptions(scoreOptionsString);
  const scoreButtons = new ScoreButtons(scoreOptions.scoreButtons);

  const handleHandScoreEntryChange = (event) => {
    const newRaw = event.target.value;
    if (!newRaw.length) {
      onScoreChange(newRaw);
    } else {
      const newValue = parseFloat(newRaw);
      if (isNaN(newValue)) {
        onScoreChange(scoreToEdit); // Old value, as the new is NaN
      } else {
        onScoreChange(newValue);
      }
    }
  }

  const handleOpenScoreBox = () => {
    onOpenClose(!isOpen);
  }

  const newScoreButton = (delta) => {
    const handleButton = () => {
      onScoreChange((isNaN(scoreToEdit) ? 0 : scoreToEdit) + delta);
    }
    return (
      <Button onClick={handleButton} >
        {delta > 0 ? `+${delta}` : delta}
      </Button>
    );
  }
  function RenderScoreButtons({ open }) {
    if (!open) return null; // ==> EARLY EXIT
    return (
      <BoxR className={classes.buttonBox}>
        <BoxR className={classes.rowWrap}>
          {scoreButtons.thousands ? newScoreButton(-1000) : null}
          {scoreButtons.hundreds ? newScoreButton(-100) : null}
          {scoreButtons.tens ? newScoreButton(-10) : null}
          {scoreButtons.fives ? newScoreButton(-5) : null}
          {scoreButtons.ones ? newScoreButton(-1) : null}
          {scoreButtons.point5 ? newScoreButton(-0.5) : null}
        </BoxR>
        <DividerV style={{ height: '80%' }} />
        <BoxR className={classes.rowWrap}>
          {scoreButtons.point5 ? newScoreButton(0.5) : null}
          {scoreButtons.ones ? newScoreButton(1) : null}
          {scoreButtons.fives ? newScoreButton(5) : null}
          {scoreButtons.tens ? newScoreButton(10) : null}
          {scoreButtons.hundreds ? newScoreButton(100) : null}
          {scoreButtons.thousands ? newScoreButton(1000) : null}
        </BoxR>
      </BoxR>
    );
  }

  function RenderScoreBoxOpenButton({ open }) {
    if (!open) return null; // ==> EARLY EXIT
    return (
      <IconButton onClick={handleOpenScoreBox} size="large">
        <ScoreIcon />
      </IconButton>
    );
  }

  function RenderScoreEntryBox({ open }) {
    if (!open) return null; // ==> EARLY EXIT
    return (
      <BoxC className={classes.scoreBox}>
        <BoxR className={classes.centeredRow}>
          <Typography>Enter score change</Typography>
          <TextField autoFocus autoComplete='off' margin='normal' className={classes.textField}
            inputProps={{ style: { fontSize: 20, fontWeight: 'bold' } }}
            defaultValue={scoreToEdit}
            id={'ID_ENTRY_FIELD'} type="number" onChange={handleHandScoreEntryChange} />
        </BoxR>
      </BoxC>
    );
  }

  const useScoreBox = isScoreKeeper && !scoreOptions.useScoreButtons;
  const useScoreButtons = isScoreKeeper && scoreOptions.useScoreButtons;
  return (
    <StyledBoxC className={classes.root} sx={sxIn}
      style={{ ...contentContainerStyle }}>
      <BoxR className={classes.centeredRow} >
        <RenderScoreBoxOpenButton open={useScoreBox} />
        <BoxC style={{ alignItems: 'center' }} onClick={onContentClick}>
          {children}
        </BoxC>
      </BoxR>
      <RenderScoreEntryBox open={isOpen} />
      <RenderScoreButtons open={useScoreButtons && showScoreButtons} />
    </StyledBoxC >
  )
}

ScoreableEntry.propTypes = {
  isOpen: PropTypes.bool,
  onOpenClose: PropTypes.func,
  children: PropTypes.any,
  onScoreChange: PropTypes.func,
  onContentClick: PropTypes.func,
  contentContainerStyle: PropTypes.object,
  scoreOptionsString: PropTypes.string,
  showScoreButtons: PropTypes.bool,
}

ScoreableEntry.defaultProps = {
  onOpenClose: () => { },
  contentContainerStyle: {},
  showScoreButtons: true,
  classes: {},
}