import { styled } from '@mui/material/styles';
import { Checkbox, Hidden, IconButton } from '@mui/material';
import NotesIcon from '@mui/icons-material/Comment';
import React from 'react';
import Globals from '../../appSupport/Globals';
import Answer from '../../components/Answer';
import BoxR from '../../components/box/BoxR';
import PlayerName, { PLAYER_NAME_SMALL } from '../../components/PlayerName';
import PlayerNotes from '../../components/PlayerNotes';
import ScoreableEntry, { classes as ScoreableEntryClasses } from '../../components/ScoreableEntry';
import GameOptions from '../../fields/GameOptions';
import GamePlay from '../../fields/GamePlay';
import RoundData from '../../fields/RoundData';
import ScoreOptions from '../../fields/ScoreOptions';
import SelectOptions from '../../fields/SelectOptions';
import Selections from '../Selections';
import TableList from '../TableList';
import RowCell from './RowCell';
import { useTheme } from '@mui/styles';

const classes = {
  button: 'ResultTableList-button',
  notesTool: 'ResultTableList-notesTool'
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.notesTool}`]: {
    edge: 'start',
    color: theme.palette.notesTool,
  }
}));

const loadingOrNoData = f => null;

export default function ResultsTableList(props) {

  const { rows, game, areResultsAnonymous, correctAnswer, refScoresByName, scoreOpenMap,
    onAnswerSelected, onAnswerClick, onAnswerNotesClick, onOpenScoreToggle, onScoreChange,
    showScoreButtons } = props;

  const { randomResultOrder } = Globals.getPublishedData(game);
  const gameOptions = new GameOptions(game.gameOptions);
  const selectOptions = new SelectOptions(gameOptions.selectOptions);
  const { isAnswerSelectable, isAnswerNotes, keepScore } = gameOptions;
  const gamePlay = GamePlay.fromGame(game);
  const roundData = RoundData.fromGame(game);
  const { areAnswerSelectionsActive, areAnswerSelectionsPublic,
    areAnswerNotesActive, areAnswerNotesPublic } = gamePlay;
  const { currentReader } = roundData;
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const playerName = Globals.getPlayerName();
  const theme = useTheme();

  function selectionsForRow(row) {
    let selections = [];
    if (isAnswerSelectable) {
      if (areAnswerSelectionsPublic) {
        selections = row.selections
      } else {
        if (selectOptions.numberOfSelectionsAllowed > 1) {
          // Names have a format of '1 Leah' if multiple selections are allowed
          selections = row.selections.filter(f => f.endsWith(playerName));
        }
      }
    }
    return selections;
  }

  function rowRenderer(row) {
    const enableScoring = keepScore && !scoreOptions.teamNames;
    const { handScore: handScoreRef } = refScoresByName[row.playerName] || {};
    const handScoreForPlayer = enableScoring ? handScoreRef : undefined;
    const isMe = row.playerName === playerName;
    const excludeSelection = isMe && !selectOptions.canSelectOwnAnswer;

    const answerElement = <Root>
      <BoxR>
        {(isAnswerSelectable && !excludeSelection) ? <Checkbox id={String(row.playerNumber)} disabled={!areAnswerSelectionsActive}
          checked={!!row.myAnnotations.answerSelected} onClick={onAnswerSelected} /> : null}
        {isAnswerNotes ?
          <IconButton
            className={classes.notesTool}
            disabled={!areAnswerNotesActive}
            onClick={(event) => onAnswerNotesClick(event, row.playerName)}
            size="large">
            <NotesIcon />
          </IconButton> : null}
        <Answer answer={row.answer} playerName={row.playerName} playerIndex={row.playerIndex}
          isRandomResult={!!randomResultOrder} correctAnswer={correctAnswer} multiRow={gamePlay.showStackedAnswers} />
      </BoxR>
      <BoxR style={{ alignItems: 'center', flexWrap: 'wrap' }}>
        <PlayerName playerName={row.playerName} size={PLAYER_NAME_SMALL} timeDelta={row.timeDelta}
          isReader={row.playerName === currentReader} handScore={handScoreForPlayer}
          isMe={isMe} anonymousMode={areResultsAnonymous} />
        <Selections names={selectionsForRow(row)} contrastNames={[playerName]} />
        {isAnswerNotes ? <PlayerNotes playerResult={row} publicNotes={areAnswerNotesPublic} /> : null}
      </BoxR>
    </Root>

    const isLastRow = row === rows[rows.length - 1];
    const sx = {
      [`& .${ScoreableEntryClasses.buttonBox}`]: {
        backgroundColor: theme.palette.scoreBackground.scoreEntry,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'grey',
        borderStyle: 'solid',
      }
    }
    return (
      <RowCell showDivider={!isLastRow}>
        <ScoreableEntry onContentClick={() => onAnswerClick(row.playerName)}
          isOpen={!!scoreOpenMap[row.playerName]} onOpenClose={(isOpen) => onOpenScoreToggle(row.playerName, isOpen)}
          isScoreKeeper={enableScoring && Globals.isScoreKeeper(game)} scoreOptionsString={game.scoreOptions}
          prevTotalScore={row.prevTotalScore} scoreToEdit={row.handScore} totalScore={row.totalScore}
          onScoreChange={(newScore) => onScoreChange(row, newScore)} showScoreButtons={showScoreButtons}
          sx={sx}>
          {answerElement}
        </ScoreableEntry>
      </RowCell>
    )
  }

  const tools = [];
  const fullTableProps = {
    rows, rowRenderer,
    dense: true,
    hidePaper: true,
    selectedRows: [],
    disableFlexSizing: true,
    noDataRenderer: loadingOrNoData,
    oneSelectedTools: tools,
    moreSelectedTools: tools,
    noneSelectedTools: tools,
  };
  const mobileTableProps = { ...fullTableProps }

  return (
    <React.Fragment>
      <Hidden smDown>
        <TableList {...fullTableProps} />
      </Hidden>
      <Hidden smUp>
        <TableList {...mobileTableProps} />
      </Hidden>
    </React.Fragment>
  );

}

