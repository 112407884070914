// NLS-NLS(player)
import React from 'react';
import TabbedView from '../TabbedView';
import GameView from '../GameView';
import PlayContent from './PlayContent';
import PlayController from './PlayController';
import { useTranslation } from 'react-i18next';
import UiUtil from '../../appSupport/UiUtil';


export default function Play(props) {
  const { game } = props;
  const { t } = useTranslation('player');
  const TITLE_PLAYER = t('play');

  return (
    <GameView game={game} playerTitle={TITLE_PLAYER} key={UiUtil.gameViewKey(game)}>
      <TabbedView mainLabel={t('answer')} buttonTabs
        mainView={<PlayContent game={game} />}
        controllerView={<PlayController game={game} />} />
    </GameView>
  );
}