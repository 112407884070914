import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownIcon from '@mui/icons-material/ArrowDownward';
import UpIcon from '@mui/icons-material/ArrowUpward';
import ReaderIcon from '@mui/icons-material/Casino';
import IsMeIcon from '@mui/icons-material/Star';
import PropTypes from 'prop-types';
import React from 'react';
import BoxR from './box/BoxR';

const classes = {
  root: 'PlayerName-root',
  arrowIcon: 'PlayerNameArrowIcon',
  isMeIcon: 'PlayerNameIsMeIcon',
  readerIcon: 'PlayerNameReaderIcon'
};

const localOnly = ['anonymousMode', 'isMe', 'isReader'];
const localOnlyTest = prop => !localOnly.includes(prop);
const StyledBoxR = styled(BoxR, { shouldForwardProp: localOnlyTest })(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center'
  },
  [`& .${classes.arrowIcon}`]: {
    color: theme.palette.primary.main
  },
  [`& .${classes.isMeIcon}`]: ({ anonymousMode, isMe }) => {
    return {
      color: theme.palette.isMeIcon,
      display: (anonymousMode || !isMe) ? 'none' : undefined,
    }
  },
  [`& .${classes.readerIcon}`]: ({ anonymousMode, isReader }) => {
    return {
      color: theme.palette.readerIcon,
      display: (anonymousMode || !isReader) ? 'none' : undefined,
    }
  }
}));

const StyledIsMeIcon = styled(IsMeIcon, { shouldForwardProp: localOnlyTest })(({ theme, anonymousMode, isMe }) => ({
  [`&.${classes.isMeIcon}`]: {
    color: theme.palette.isMeIcon,
    display: (anonymousMode || !isMe) ? 'none' : undefined,
  }
}));
const StyledReaderIcon = styled(ReaderIcon, { shouldForwardProp: localOnlyTest })(({ theme, anonymousMode, isReader }) => ({
  [`&.${classes.readerIcon}`]: {
    color: theme.palette.readerIcon,
    display: (anonymousMode || !isReader) ? 'none' : undefined,
  }
}));


export const PLAYER_STATUS_LINE = 'PLAYER_STATUS_LINE';
export const SCORE_TABLE = 'SCORE_TABLE';
export const PLAY_WAIT_LIST = 'large';
export const PLAYER_NAME_SMALL = 'small';

const getNameSize = (size) => {
  if (!size) return undefined;
  switch (size) {
    case PLAYER_STATUS_LINE:
      return 17;
    case PLAYER_NAME_SMALL:
      return 15;
    case SCORE_TABLE:
      return 20;
    default:
      return 30;;
  }
}
const getTimeSize = (size) => {
  if (!size) return undefined;
  switch (size) {
    case PLAYER_NAME_SMALL:
      return 15;
    case SCORE_TABLE:
      return 18;
    default:
      return 25;;
  }
}

export default function PlayerName(props) {
  const { playerName, size, timeDelta, handScore, color, isReader, isMe, anonymousMode, sx: sxIn } = props;
  const deltaText = timeDelta ? timeDelta.getDeltaText() : '';
  const nameSize = getNameSize(size);
  const timeSize = getTimeSize(size);


  function RenderHandScore() {
    if (!handScore) return null; // ==> EARLY EXIT
    return (
      <BoxR>
        <Typography color='primary'><b>(</b></Typography>
        {handScore > 0 ? <UpIcon className={classes.arrowIcon} /> : <DownIcon className={classes.arrowIcon} />}
        <Typography style={{ marginRight: 5 }} color='primary'><b>{Math.abs(handScore)})</b></Typography>
      </BoxR>
    );
  }
  const anon = anonymousMode ? { display: 'none' } : {};
  return (
    <StyledBoxR className={classes.root} sx={sxIn} key={`${playerName}-name`} >
      <StyledIsMeIcon className={classes.isMeIcon} anonymousMode={anonymousMode} isMe={isMe} />
      <StyledReaderIcon className={classes.readerIcon} anonymousMode={anonymousMode} isReader={isReader} />
      <RenderHandScore />
      <div style={{ color, fontSize: nameSize, ...anon }}>{playerName}</div>
      <div style={{ width: 5, ...anon }} />
      <div style={{ color, fontSize: timeSize, ...anon }}>{deltaText}</div>
    </StyledBoxR>
  );
}

PlayerName.propTypes = {
  playerName: PropTypes.string,
  size: PropTypes.string,
  myTime: PropTypes.number,
  otherTime: PropTypes.number,
  color: PropTypes.string,
  isCurrentReader: PropTypes.bool,
  handScore: PropTypes.number,
};

PlayerName.defaultProps = {
  color: undefined,
  variant: 'h5',
}