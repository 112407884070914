import React from 'react';
import { useTranslation } from 'react-i18next';
import SequenceTitle from '../siteComponents/SequenceTitle';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import Asset from '../siteComponents/Asset';
import Clear from '../siteComponents/Clear';
import TipScanCard from '../siteComponents/TipScanCard';

const gameAnswerMultiple = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameAnswerMultiple.png';
const gameAnswerSingle = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameAnswerSingle.png';
const gameAnswerButtons = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameAnswerButtons.png';
const cardThings = 'https://remote-game-image-assets.s3.amazonaws.com/site/cardThings.jpg';
const gameCardDeals = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameCardDeals.png';

export default function FlowPlay(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <SiteSection>
      <SequenceTitle sequence='4' title={t('Play')} />
      <div>
        <StyleParagraph>{t('Play typically consists of somebody reading a game card. Then other players enter a response on their device.')}</StyleParagraph>
        <Clear />
        <Asset right src={gameAnswerMultiple} mobile={mobile} />
        <Asset right src={gameAnswerSingle} mobile={mobile} />
        <StyleParagraph>{t('Players will either type a response, type multiple responses...')}</StyleParagraph>
        <Clear />
        <Asset left src={gameAnswerButtons} mobile={mobile} />
        <StyleParagraph>{t('or press a button on their device.')}</StyleParagraph>
        <Clear />
        <Asset src={cardThings} mobile={mobile} size={100} mobileSize={75} />
        <StyleParagraph>{t("Some games, like 'Things', can play well with one person having all of the cards.")}</StyleParagraph>
        <Asset src={gameCardDeals} mobile={mobile} />
        <StyleParagraph>{t("Other games, like 'Pun Intended',  work better if all players have access to the cards. For these games the game controller can scan game cards into the system some point before the game is played.  Then cards can be electronically dealt at play time.")}</StyleParagraph>
        <TipScanCard />
      </div>
    </SiteSection>
  );
}
