// NLS-NLS(support)
import React from 'react';
import BoxC from '../../box/BoxC';
import SRLanguageRequest from '../../../fields/support/SRLanguageRequest';
import LabelAndData from '../../LabelAndData';
import SreDialog from './SreDialog';
import { useTranslation } from 'react-i18next';


export default function SreNewLanguage(props) {
  const { row, ...rest } = props;
  const payload = new SRLanguageRequest(row.payload);
  const { t } = useTranslation('support');

  return (
    <BoxC {...rest}>
      <LabelAndData key='request' label={t('requested-language')} data={payload.requestedLanguage} />
      <SreDialog key='dialog' dialogArray={payload.dialog} />
    </BoxC>
  );
}
