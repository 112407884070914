// NLS-NLS(controller)
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import GameStore from '../db/GameStore';
import ScoreButtons from '../fields/ScoreButtons';
import ScoreOptions from '../fields/ScoreOptions';

const ID_SCORE_BUTTON_05_CB = 'point5';
const ID_SCORE_BUTTON_1_CB = 'ones';
const ID_SCORE_BUTTON_5_CB = 'fives';
const ID_SCORE_BUTTON_10_CB = 'tens';
const ID_SCORE_BUTTON_100_CB = 'hundreds';
const ID_SCORE_BUTTON_1000_CB = 'thousands';
const EMPTY_STAGED_VALUES = {};
const checkboxControls = [ID_SCORE_BUTTON_05_CB, ID_SCORE_BUTTON_1_CB, ID_SCORE_BUTTON_5_CB,
  ID_SCORE_BUTTON_10_CB, ID_SCORE_BUTTON_100_CB, ID_SCORE_BUTTON_1000_CB];

// const useStyles = makeStyles((theme) => ({
//   settings: {
//     edge: 'start',
//     color: theme.palette.secondary.main
//   },
// }));
export default function ScoreButtonsPrompt(props) {
  const { open, onCancel, onSave, scoreButtonsJSON } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const { t } = useTranslation('controller');

  React.useEffect(() => {
    const scoreButtons = new ScoreButtons(scoreButtonsJSON);
    setStagedValues({
      [ID_SCORE_BUTTON_05_CB]: scoreButtons.point5,
      [ID_SCORE_BUTTON_1_CB]: scoreButtons.ones,
      [ID_SCORE_BUTTON_5_CB]: scoreButtons.fives,
      [ID_SCORE_BUTTON_10_CB]: scoreButtons.tens,
      [ID_SCORE_BUTTON_100_CB]: scoreButtons.hundreds,
      [ID_SCORE_BUTTON_1000_CB]: scoreButtons.thousands,
    });
  }, [scoreButtonsJSON]);

  const clearValidations = () => {
  }
  const validations = {
    // validateDecks: (newStaged) => {
    //   if (!newStaged[ID_DECKS_USED].length) {
    //     setDecksUsedErrorText('You must select at least one deck');
    //     return true;
    //   }
    //   return false;
    // },

    performValidate: () => {
      clearValidations();
      return false; // No errors
    }
  }

  const handleCancel = () => {
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      const scoreButtons = new ScoreButtons();
      scoreButtons[ID_SCORE_BUTTON_05_CB] = stagedValues[ID_SCORE_BUTTON_05_CB];
      scoreButtons[ID_SCORE_BUTTON_1_CB] = stagedValues[ID_SCORE_BUTTON_1_CB];
      scoreButtons[ID_SCORE_BUTTON_5_CB] = stagedValues[ID_SCORE_BUTTON_5_CB];
      scoreButtons[ID_SCORE_BUTTON_10_CB] = stagedValues[ID_SCORE_BUTTON_10_CB];
      scoreButtons[ID_SCORE_BUTTON_100_CB] = stagedValues[ID_SCORE_BUTTON_100_CB];
      scoreButtons[ID_SCORE_BUTTON_1000_CB] = stagedValues[ID_SCORE_BUTTON_1000_CB];
      onSave(scoreButtons.asJSON());
      const newScoreOptions = new ScoreOptions(GameStore.getGame().scoreOptions);
      newScoreOptions.scoreButtons = scoreButtons.asJSON();
      GameStore.updateScoreOptions(newScoreOptions);
      clearValidations();
      onCancel();
    }
  }

  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  const title = t('select-buttons-for-scoring');
  if (!open) return null;
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_SCORE_BUTTON_05_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTON_05_CB} />}
            label=".5"
          />
        </FormGroup>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_SCORE_BUTTON_1_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTON_1_CB} />}
            label="1"
          />
        </FormGroup>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_SCORE_BUTTON_5_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTON_5_CB} />}
            label="5"
          />
        </FormGroup>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_SCORE_BUTTON_10_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTON_10_CB} />}
            label="10"
          />
        </FormGroup>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_SCORE_BUTTON_100_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTON_100_CB} />}
            label="100"
          />
        </FormGroup>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_SCORE_BUTTON_1000_CB]} onChange={handleValueChange} id={ID_SCORE_BUTTON_1000_CB} />}
            label="1000"
          />
        </FormGroup>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}