// NLS-NLS(player)
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import PathUtil from '../../appSupport/PathUtil';
import BoxCG from '../../components/box/BoxCG';
import BoxR from '../../components/box/BoxR';
import StartPlayButton from '../../components/button/StartPlayButton';
import AddPlayerTableList from '../../components/list/AddPlayerTableList';
import MrDie from '../../components/MrDie';
import GameStore from '../../db/GameStore';
import PendingPlayerStore, { useFreshPendingPlayers } from '../../db/PendingPlayerStore';
import { gameRoutesFull } from '../../routing/gameRoutes';
import { newSearchPath } from '../landing/LandingContent';
import { useNavigate } from 'react-router-dom';

const classes = {
  root: 'AddPlayersContent-root',
  publicBox: 'AddPlayersContent-publicBox'
};

const StyledBoxCG = styled(BoxCG)(({ theme }) => ({
  [`& .${classes.publicBox}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: 'center',
    backgroundColor: theme.palette.playerWaitingBackground.main,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
  }
}));

let controllerReentryTimer;

export default function AddPlayerContent(props) {
  const { game, ...rest } = props;
  const [playersWaitingEntry, setPlayersWaitingEntry] = React.useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation('player');
  const pendingPlayers = useFreshPendingPlayers();

  // If we are waiting for entry for a game that has already started,
  // use the players waiting for entry as the list.
  if (Globals.isWaitingForEntry(game)) {
    const waitingPlayers = Globals.getEntryRequestPlayerNames(game).map(m => { return { id: m, playerName: m } });
    if (!playersWaitingEntry || playersWaitingEntry.length !== waitingPlayers.length) {
      setPlayersWaitingEntry(waitingPlayers);
    }
    if (!controllerReentryTimer && Globals.getPlayerName() === game.controllerName) {
      // Start a timer for 4 seconds.  If the active controller doesn't boot us out by then, assume the controller role.
      const timer = setTimeout(() => {
        // If we are still waiting, grab the reins and become the new controller.
        controllerReentryTimer = null;
        if (!PathUtil.getGameParam()) return null; // No longer on this path
        const game = GameStore.getGame();
        if (Globals.isWaitingForEntry(game)) {
          // Assume the role of controller.  As controller, the request for controller data (isWaitingForEntry) will be cleaned up
          const entryKey = PendingPlayerStore.getClaimEntryKey(game.controllerName);
          navigate(gameRoutesFull.addPlayers + newSearchPath(PathUtil.getGameParam(), game.controllerName, PathUtil.getFinderIdParam(), entryKey),
            { replace: true });
        }
      }, 4000);
      controllerReentryTimer = timer;
    }
  }

  const handleStartPlay = () => {
    GameStore.startPlay();
  }

  const playersForList = () => {
    return playersWaitingEntry || pendingPlayers.map(m => { return { id: m.playerName, playerName: m.playerName } });
  }

  const isController = Globals.isGameController(game);
  const finderId = PathUtil.getFinderIdParam();

  return (
    <StyledBoxCG className={classes.root} {...rest} >
      <Typography color='primary'>{t('welcome-playername', { playerName: Globals.getPlayerName() })}</Typography>
      <Typography>{t('other-players-can-join-the-gam', { finderId })}</Typography>
      {
        isController ?
          (<BoxR style={{ justifyContent: 'center' }}>
            <StartPlayButton onClick={handleStartPlay} />
          </BoxR>
          ) : null
      }
      <BoxR style={{ justifyContent: 'center' }}>
        <MrDie small />
        <Typography style={{ marginLeft: 10, fontSize: 22 }}>{playersForList().length}</Typography>
      </BoxR>
      <div className={classes.publicBox} >
        <AddPlayerTableList rows={playersForList()} />
      </div>
    </StyledBoxCG >
  );
}