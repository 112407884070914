// NLS-NLS(controller)
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function EditTool(props) {
  const { onEdit } = props;
  const { t } = useTranslation('controller');

  return (
    <Tooltip title={t('edit')} key='edit'>
      <IconButton onClick={onEdit} size="large">
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}
