import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/iShouldHaveKnownThat_.jpg';

export default function IShouldHaveKnownThat({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset right src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('I Should Have Known That')}</StyleTopic>
      <StyleParagraph>{t("Is starboard on the left or right side of a boat? How do you say “Japan” in Japanese? What is the character limit for Tweets on Twitter? How long did Sleeping Beauty actually sleep? ...I should have known that!TM is an addictively entertaining trivia game with over 400 questions about things that you should know. In contrast to traditional trivia formats, you don’t receive points for answering questions correctly. Instead, points are subtracted for every incorrect answer! One thing is for sure: it will only be a matter of time before you hear yourself say… Doh!... I should have known that!")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("Trivia games like this can be more fun with teams. If the teams collaborate, designate one player per team for the answers. Or everybody submits an answer and if anyone on the team has the correct answer, the team scores.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Select "Keep Score"\n...Drill in to score settings\n...Enter the comma separated team names"')} />
    </SiteSection>
  );
}
