import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import SequenceTitle from '../siteComponents/SequenceTitle';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';

const gameShare = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameShare.png';

export default function FlowJoinGame(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <SiteSection>
      <SequenceTitle sequence='2' title={t('Players join the game')} />
      <div>
        <Asset right src={gameShare} mobile={mobile} />
        <StyleParagraph>{t('This can happen in one of two ways')}</StyleParagraph>
        <StyleParagraph indent={1}>{t('A) The game controller can Share a link to the game with a text or an email')}</StyleParagraph>
        <StyleParagraph indent={1}>{t('B) Other players go to the game launch site and press the Join Game button. They will be prompted for the game code provided to the game controller when the game was created.')}</StyleParagraph>
      </div>
    </SiteSection>
  );
}
