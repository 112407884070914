
let INSTANCE;

export default class StoreFailure {

  static instance() {
    if (!INSTANCE) {
      INSTANCE = new StoreFailureInst();
    }
    return INSTANCE;
  }
  static listen(callBack) {
    StoreFailure.instance().listen(callBack);
    StoreFailure.instance().notifyListeners();
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static addFailedStore(storeName) {
    StoreFailure.instance().addFailedStore(storeName);
  }
  static removeFailedStore(storeName) {
    StoreFailure.instance().removeFailedStore(storeName);
  }
  static replaceDebugNames(names) {
    StoreFailure.instance().replaceDebugNames(names);
  }
  static getFailedStores() {
    return StoreFailure.instance().getMessages();
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

}

class StoreFailureInst {
  constructor() {
    this.init();
  }
  init() {
    this.failedStores = [];
    this.listeners = [];
  }
  getFailedStores() {
    return this.failedStores;
  }
  listen(callBack) {
    if (!this.listeners.includes(callBack)) {
      this.listeners.push(callBack);
    }
  }
  stopListen(callBack) {
    if (this.listeners.includes(callBack)) {
      this.listeners = this.listeners.filter(f => f !== callBack);
      this.notifyListeners();
    }
  }
  addFailedStore(storeName) {
    if (!this.failedStores.includes(storeName)) {
      this.failedStores.push(storeName);
      this.failedStores = [...this.failedStores];
      this.notifyListeners();
    }
  }
  removeFailedStore(storeName) {
    if (this.failedStores.includes(storeName)) {
      this.failedStores = this.failedStores.filter(f => f !== storeName);
      this.notifyListeners();
    }
  }
  replaceDebugNames(names) {
    this.failedStores = names;
    this.notifyListeners();
  }

  release() {
    this.init();
  }

  notifyListeners() {
    this.listeners.forEach(e => e(this.getFailedStores()));
  }
}

