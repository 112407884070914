import React from 'react';
import Globals from '../appSupport/Globals';
import BoxR from './box/BoxR';
import AutoScoreSelectButton from './button/AutoScoreSelectButton';
import EditScoreButton from './button/EditScoreButton';
import NewRoundButton from './button/NewRoundButton';

export default function GameControls(props) {
  const { game } = props;
  const isController = Globals.isGameController(game);
  const isRoundOver = Globals.isRoundOver(game);

  if (!isController || !isRoundOver) return null; // ==> EARLY EXIT

  return (
    <React.Fragment>
      <BoxR style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
        <AutoScoreSelectButton />
        <EditScoreButton />
        <NewRoundButton />
      </BoxR>
    </React.Fragment >
  );
}
