import { API, graphqlOperation } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import { createRg0Card } from "../graphql/mutations";
import { listRg0Cards } from "../graphql/queries";
import { onCreateRg0Card } from "../graphql/subscriptions";
import TraceLog from "../appSupport/TraceLog";
import { DEFAULT_QUEUE_WAIT } from './AbstractOps';

const handToString = h => (h || 0).toString().padStart(10, '0');

export default class CardOps {
  static async createCard(properties) {
    const handString = handToString(properties.hand);
    const defaults = { ...properties, handString, owner: Globals.getDataOwner() };
    try {
      TraceLog.addDbEvent('Card:create', JSON.stringify(defaults));
      await API.graphql(graphqlOperation(createRg0Card, { input: { ...defaults } }));
      return true;
    } catch (err) {
      Globals.dispatchApiError(err);
    }
    return false;
  }

  static async getCards() {
    return new Promise(async resolve => {
      const gameId = PathUtil.getGameParam();
      const getResult = async (nextToken) => {
        const constraint = { id: gameId, nextToken };
        TraceLog.addDbEvent('Card:list', JSON.stringify(constraint));
        const result = await API.graphql(graphqlOperation(listRg0Cards, constraint))
          .catch(err => {
            Globals.dispatchApiError(err);
            return null;
          });
        return result ? result.data.listRg0Cards : null;
      }
      let result = await getResult();
      let resultArray = result ? result.items : [];
      while (result && result.nextToken) {
        result = await getResult(result.nextToken);
        resultArray = result ? [...resultArray, ...result.items] : resultArray;
      }
      return resolve(resultArray);
    });
  }

  // Subscriptions ----------------------------------------------------

  static onCardCreate(onCreate, onError) {
    const handleCreate = (event) => {
      const newCard = event.value.data.onCreateRg0Card;
      if (newCard.id === PathUtil.getGameParam()) {
        TraceLog.addSubscriptionEvent('SBS Card:create', newCard);
        onCreate(newCard);
      }
    }
    const variables = { id: PathUtil.getGameParam(), owner: Globals.getDataOwner() };
    TraceLog.addDbEvent('Card:subscribeCreate', JSON.stringify(variables));
    const subscription = API.graphql({ query: onCreateRg0Card, variables })
      .subscribe({
        next: (event) => setTimeout(() => handleCreate(event), DEFAULT_QUEUE_WAIT), // Queue it
        error: onError,
      });
    return subscription;
  }
}