// NLS-NLS(player)
import { IconButton, Tooltip } from '@mui/material';
import IconImage from '@mui/icons-material/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SettingsTool(props) {
  const { onClick } = props;
  const { t } = useTranslation('player');

  return (
    <Tooltip title={t('settings')}>
      <IconButton
        style={{ edge: 'start', color: 'inherit' }}
        onClick={onClick}
        size="large">
        <IconImage />
      </IconButton>
    </Tooltip>
  );
}
