import React from 'react';
import ErrorView from '../page/ErrorView';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorText: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    let text = error && error.message ? error.message : 'No details available';
    // text += '\n';
    // text += error && error.stack ? error.stack : '';   // Stack is worthless after webpack
    return { errorText: 'ERROR BOUNDARY CATCH: ' + text };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log(error);
    // console.log(errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { errorText } = this.state;
    if (this.state.errorText) {
      return <ErrorView errorText={errorText} />
    }
    return this.props.children; 
  }
}