export default class SRBleedThrough {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.englishText = input ? input.englishText : null;
    this.translation = input ? input.translation : null;
    this.dialog = input ? input.dialog : [];
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

