import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { Document, Page } from 'react-pdf';
import CardStoreStorage from '../db/CardStoreStorage';
import DeckStore from '../db/DeckStore';
import BoxCG from './box/BoxCG';
import BoxR from './box/BoxR';
import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';

const classes = {
  root: 'PdfCard-root',
  sample: 'PdfCard-sample',
  scrollBox: 'PdfCard-scrollBox',
  pageButton: 'PdfCard-pageButton',
  controlsRow: 'PdfCard-controlsRow',
  pageOfText: 'PdfCard-pageOfText',
  slider: 'PdfCard-slider'
};

const StyledBoxCG = styled(BoxCG)(({ theme, numPages }) => ({
  [`&.${classes.root}`]: {
    overflow: 'hidden'
  },
  [`& .${classes.sample}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.scrollBox}`]: {
    // overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.pageButton}`]: {
    margin: theme.spacing(1),
    display: numPages && (numPages > 1) ? undefined : 'none',
  },
  [`& .${classes.controlsRow}`]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.pageOfText}`]: {
    // minWidth: 150,     
    whiteSpace: 'nowrap',
    display: numPages && (numPages > 1) ? undefined : 'none',
  },
  [`& .${classes.slider}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  }
}));

const PDF_THRESHOLD = 60 * 1000;


export default function PdfCard(props) {
  const { userName, deckId, fileName, inView, sx: sxIn } = props;
  const [numPages, setNumPages] = React.useState(1);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfPath, setPdfPath] = React.useState(null);
  const [pdfPathTime, setPdfPathTime] = React.useState(null);
  const [outerDimensions, setOuterDimensions] = React.useState({ width: 0, height: 0 });
  const [pageDimensions, setPageDimensions] = React.useState({ width: 0, height: 0 });
  const [everInView, setEverInView] = React.useState(inView);

  const { t } = useTranslation('player');
  if (inView && !everInView) setEverInView(true);

  React.useEffect(() => {
    DeckStore.initialQueryCompletePromise().then(() =>
      retrievePdfPath(userName, deckId, fileName)
    );
  }, [userName, deckId, fileName]);

  const measuredOuterRef = React.useCallback(node => {
    if (node !== null) {
      setOuterDimensions({
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height
      });
    }
  }, []);
  const measuredPageRef = React.useCallback(node => {
    if (node !== null) {
      setPageDimensions({
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height
      });
    }
  }, []);

  function retrievePdfPath(userName, deckId, fileName) {
    CardStoreStorage.get(userName, deckId, fileName)
      .then(pdfPath => {
        setPdfPath(pdfPath);
        setPdfPathTime(new Date().getTime());
      });
  }
  function handleDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function handleLoadError(error) {
    // If the error of display is close to the resolve of the path, then we don't know
    // what to do. But if it has been displayed previously, then try it again.
    if (new Date().getTime() - pdfPathTime > PDF_THRESHOLD) {
      // retrievePdfPath(userName, deckId, fileName);
    }
    console.log(error);
  }
  function handlePageBack() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }
  function handlePageForward() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  if (!everInView) return null;
  return (
    <StyledBoxCG className={classes.root} sx={sxIn} numPages={numPages}>
      <BoxR className={classes.controlsRow}>
        <Button className={classes.pageButton} variant='contained' color='secondary'
          disabled={pageNumber === 1} onClick={handlePageBack}><BackIcon /></Button>
        <Typography className={classes.pageOfText}>{t('page-x-of-y', { x: pageNumber, y: numPages })}</Typography>
        <Button className={classes.pageButton} variant='contained' color='secondary'
          disabled={pageNumber === numPages} onClick={handlePageForward}><ForwardIcon /></Button>
      </BoxR>
      <BoxCG className={classes.scrollBox} ref={measuredOuterRef}>
        <div ref={measuredPageRef} >
          <Document
            file={pdfPath}
            onLoadSuccess={handleDocumentLoadSuccess}
            onLoadError={handleLoadError}
            error={<Typography variant="h6">Error displaying card</Typography>}
            loading={<Typography variant="h6">Loading card...</Typography>}
            noData={<Typography variant="h6">Finding card...</Typography>}
          >
            <Page pageNumber={pageNumber} width={Math.min(outerDimensions.width, 500)} />
          </Document>
        </div>
        { /* Add a filler div at the bottom so the card will be at the top (instead of centered). Better for zooming */}
        <div style={{ height: outerDimensions.height - pageDimensions.height }} />
      </BoxCG>
    </StyledBoxCG>
  );
}
