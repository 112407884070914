import React from 'react';
import StyleTopic from '../siteComponents/StyleTopic';
import StyleParagraph from '../siteComponents/StyleParagraph';
import SiteNav from '../siteComponents/SiteNav';
import { useTranslation } from 'react-i18next';
import { siteRoutesFull } from '../../routing/siteRoutes';
import Asset from '../siteComponents/Asset';

const gameLanding = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameLanding.png';

export default function DescFaqHowUse({ mobile = false }) {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <Asset src={gameLanding} mobile={mobile} right />
      <StyleTopic>{t('How do I use it?')}</StyleTopic>
      <StyleParagraph>{t("One person, the game controller, starts a new game and gets a game id.  Other players join that game using the game id.  Then it's like being around the same kitchen table. Coordinate your own play and let the software help with timing, answers and scoring.")}</StyleParagraph>
      <SiteNav target={siteRoutesFull.instructions}>{t('Here are some more instructions')}</SiteNav>
      <br />
      <br />
    </React.Fragment>
  );
}
