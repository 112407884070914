import { Typography } from '@mui/material';
import React from 'react';
import BoxC from './box/BoxC';
import BoxR from './box/BoxR';
import BoxRG from './box/BoxRG';
import DividerH from './DividerH';

export default function LogEntry(props) {
  const { entry, last } = props;
  return (
    <BoxC >
      <BoxR >
        <Typography style={{ whiteSpace: 'nowrap' }}>{entry.type}</Typography>
        <BoxRG />
        <Typography style={{ marginLeft: 10, wordBreak: 'break-all' }}>{entry.date}</Typography>
      </BoxR>
      <Typography style={{ marginLeft: 10, wordBreak: 'break-all' }}>{entry.data}</Typography>
      {!last ? <DividerH /> : null}
    </BoxC>
  );
}
