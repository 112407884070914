// NLS-NLS(player)
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import UiUtil from '../../appSupport/UiUtil';
import GameView from '../GameView';
import TabbedView, { SELECT_CONTROLLER_TAB } from '../TabbedView';
import PlayWaitContent from './PlayWaitContent';
import PlayWaitController from './PlayWaitController';

export default function PlayWait(props) {
  const { game } = props;
  const { t } = useTranslation('player');

  const TITLE_PLAYER = t('answers-in');

  const selectTab = Globals.isRoundOver(game) ? SELECT_CONTROLLER_TAB : undefined;

  return (
    <GameView game={game} playerTitle={TITLE_PLAYER} key={UiUtil.gameViewKey(game)}>
      <TabbedView mainLabel={t('players')} buttonTabs
        mainView={<PlayWaitContent game={game} />}
        controllerView={<PlayWaitController game={game} />}
        selectTab={selectTab} />
    </GameView>
  );
}
