import React from 'react';
import DescFaqDoINeedAccount from './DescFaqDoINeedAccount';
import DescFaqHowUse from './DescFaqHowUse';
import DescFaqIsItHard from './DescFaqIsItHard';
import DescFaqWhatDoesItCost from './DescFaqIsWhatDoesItCost';
import DescFaqWhatCanItDo from './DescFaqWhatCanItDo';
import DescFaqWhatGames from './DescFaqWhatGames';
import DescFaqWhatIs from './DescFaqWhatIs';
import DescFaqWhyAccount from './DescFaqWhyAccount';

export default function FaqContent(props) {
  const { mobile = false } = props;

  return (
    <React.Fragment>
      <DescFaqWhatIs mobile={mobile} />
      <DescFaqWhatGames mobile={mobile} />
      <DescFaqWhatCanItDo mobile={mobile} />
      <DescFaqHowUse mobile={mobile} />
      <DescFaqIsItHard mobile={mobile} />
      <DescFaqWhatDoesItCost mobile={mobile} />
      <DescFaqDoINeedAccount mobile={mobile} />
      <DescFaqWhyAccount mobile={mobile} />
    </React.Fragment>
  );
}
