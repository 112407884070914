export default class SRGeneralFeedback {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.feedback = input ? input.feedback : null;
    this.dialog = input ? input.dialog : [];
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

