// import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Globals from '../appSupport/Globals';
import { apiErrorChannel, gameAbortChannel, userChannel } from '../appSupport/GlobalConstants';
import PathUtil from '../appSupport/PathUtil';
import TraceLog from '../appSupport/TraceLog';
import UserStore from '../appSupport/UserStore';
import ErrorView from '../page/ErrorView';
import LoginPage from '../page/LoginPage';
import GameRouter from './GameRouter';
import LoadingRouter from './LoadingRouter';
import { gameRoutesFull } from './gameRoutes';
import { useNavigate } from 'react-router-dom';

export default function CognitoRouter() {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isWithLoginPrompt, setIsWithLoginPrompt] = useState(false);
  const [errorText, setErrorTextPrivate] = useState([]);
  const [reloadNow, setReloadNow] = useState(false);
  const [originalPathname, setOriginalPathname] = useState(null);
  const userName = React.useRef(user ? user.username : null);
  const alreadyHandled = React.useRef(false);
  const errorReload = PathUtil.getErrorReloadParam();
  const wakeReload = PathUtil.getWakeReloadParam();
  const navigate = useNavigate();

  useEffect(() => {
    if (errorReload) TraceLog.addEvent('Error reload (via URL)');
    if (wakeReload) TraceLog.addEvent('Wake reload (via URL)');
  }, [errorReload, wakeReload]);

  useEffect(() => {
    const setErrorText = (errorTextIn) => {
      if (!errorText.includes(errorTextIn)) {
        const newErrorText = [...errorText];
        newErrorText.push(errorTextIn);
        setErrorTextPrivate(newErrorText);
      }
    }
    const handleGlobalError = (data) => {
      const { message, clearAll } = data.payload;
      const errorReload = PathUtil.getErrorReloadParam();
      const errorReloadDate = errorReload ? new Date(errorReload) : null;
      if (message.includes('Timeout disconnectNetwork')) {
        Globals.dispatchAbortGame('CognitoRouter, Timeout disconnectNetwork');
      } else if (message.includes('Connection closed')) {
        // May get many of these, but all within a fraction of a second. 
        if (!alreadyHandled.current) {
          alreadyHandled.current = true;
          const ONE_MINUTE = 1000 * 60;
          if (!errorReload || ((Date.now() - errorReloadDate.getTime()) > ONE_MINUTE)) {
            setReloadNow(true); // Will force reload on ErrorView
          } else {
            setErrorText(message); // Reloaded too recently, just show Connection closed    
          }
        }
      } else if (clearAll) {
        setErrorText([]);
      } else if (!errorReload && !PathUtil.isLandingPage() && Globals.getAutoReloadErrors()) {
        PathUtil.reloadPageForError();
      } else {
        setErrorText(message);
      }
    }
    const handleGameAbort = () => {
      PathUtil.navigateExternal(gameRoutesFull.landing); // External refresh to tidy up
    }
    function handleShowLoginPrompt(event) {
      if (UserStore.isLoginWithUiEvent(event)) {
        setIsWithLoginPrompt(true);
        Auth.signOut(); // Will force login prompt
      }
    }
    const getUserLocal = async () => {
      if (!isWithLoginPrompt) {
        const user = await UserStore.getUserAsync();
        setUser(user);
        setLoading(false);
      }
    }
    const handleAuthEvent = ({ user: userFromEvent, errorText }) => {
      if (errorText !== undefined) {
        setErrorText(errorText);
      }
      if (userFromEvent !== undefined) { // If user was passed (!== undefined), it is either a user or null
        if (userFromEvent) setIsWithLoginPrompt(false); // May be null
        setUser(userFromEvent);
        // If user is null (and stays null), we should end up at a logon screen.
        if (userFromEvent) {
          if (userName.current && (userFromEvent.username !== userName.current)) {
            TraceLog.addTrace(`Stores NOT released(1)... new user sign in: ${userFromEvent.username}, previous user: ${userName.current}`);
            // GlobalStore.releaseAllStores();
          } else {
            TraceLog.addTrace(`Stores NOT released(2)... new user sign in: ${userFromEvent.username}, previous user: ${userName.current}`);
          }
        }
        userName.current = userFromEvent ? userFromEvent.username : null;
      }
    }
    UserStore.listen(handleAuthEvent);
    const unregisterAuthEventsFn = () => UserStore.stopListen(handleAuthEvent);
    Hub.listen(apiErrorChannel, handleGlobalError);
    Hub.listen(gameAbortChannel, handleGameAbort);
    Hub.listen(userChannel, handleShowLoginPrompt);
    getUserLocal();
    return () => {
      unregisterAuthEventsFn();
      Hub.remove(apiErrorChannel, handleGlobalError);
      Hub.remove(gameAbortChannel, handleGameAbort);
      Hub.remove(userChannel, handleShowLoginPrompt);
    }
  }, [userName, errorText, isWithLoginPrompt, navigate, alreadyHandled]);

  if (!originalPathname) {
    setOriginalPathname(PathUtil.currentPathWithSearchAndError());
  }
  if (errorText.length || reloadNow) {
    return <ErrorView errorText={errorText} reloadNow={reloadNow} />
  }
  if (user) {
    // Auth.signOut(); // Use this to test LoggedOutRouter
    return (
      <GameRouter user={user} originalPathname={originalPathname} />
    );
  }
  if (loading) {
    return <LoadingRouter /> // Should be a loading view and not a Router
  }

  // Logon screen
  return <LoginPage />
}
