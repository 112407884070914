// NLS-NLS(controller)
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import PlayerName, { PLAYER_NAME_SMALL } from '../components/PlayerName';
import PlayerSelect from '../components/PlayerSelect';

const ID_SELECTED_PLAYER_NAMES = 'selectedPlayerNames';
const EMPTY_STAGED_VALUES = { selectedPlayerNames: [] };

export default function PlayerPrompt(props) {
  const { open, onCancel, onSave, allPlayerNames, selectedPlayerNames,
    nameButtonsAdditive, title, allowMultiSelect, currentReader } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const { t } = useTranslation('controller');


  React.useEffect(() => {
    setStagedValues({ selectedPlayerNames });
  }, [selectedPlayerNames]);

  const clearValidations = () => {
  }
  const validations = {
    performValidate: () => {
      clearValidations();
      return false;
    }
  }

  const handleCancel = () => {
    setStagedValues(EMPTY_STAGED_VALUES);
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      onSave(stagedValues[ID_SELECTED_PLAYER_NAMES]);
      clearValidations();
      setStagedValues(EMPTY_STAGED_VALUES);
    }
  }
  const handleSelectedPlayersChange = (newPlayers) => {
    const newStaged = { ...stagedValues };
    newStaged[ID_SELECTED_PLAYER_NAMES] = newPlayers;
    setStagedValues(newStaged);
  }

  const handlePublishButton = (event) => {
    const player = event.target.textContent;
    let players = [];
    if (nameButtonsAdditive) {
      if (selectedPlayerNames.includes(player)) {
        players = [...selectedPlayerNames];
      } else {
        players = [...selectedPlayerNames, player];
      }
    } else {
      players = [player];
    }
    onSave(players);
    clearValidations();
    setStagedValues(EMPTY_STAGED_VALUES);
  }

  const renderPlayerButtons = () => {
    return allPlayerNames.map(m =>
      <Button id={m} key={m} onClick={handlePublishButton}>
        <PlayerName playerName={m} size={PLAYER_NAME_SMALL} isReader={m === currentReader} />
      </Button>
    );
  }

  if (!open) return null;
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <BoxR style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          {renderPlayerButtons()}
        </BoxR>
        {allowMultiSelect ?
          <React.Fragment>
            <PlayerSelect onChange={handleSelectedPlayersChange} playerNames={allPlayerNames}
              id={ID_SELECTED_PLAYER_NAMES} label={t('or-select-multiple-players')} selectedPlayers={stagedValues[ID_SELECTED_PLAYER_NAMES]} />
          </React.Fragment>
          : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}