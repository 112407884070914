import Globals from "../appSupport/Globals";
import { STORAGE_pendingSupportRequestTransactions } from "../appSupport/storageConstants";
import StorageStore from "./StorageStore";
import SupportRequestStore from "./SupportRequestStore";

const saveTransactions = (transactions) => {
  StorageStore.toLocalStorage(STORAGE_pendingSupportRequestTransactions, transactions);
}
class SupportRequestLocalStore {
  constructor() {
    this.pendingTransactions = StorageStore.fromLocalStorage(STORAGE_pendingSupportRequestTransactions, []);
  }
  clearPendingTransactions() {
    this.pendingTransactions = [];
    saveTransactions(this.pendingTransactions);
    Globals.dispatchSupport({ localTransactionsCleared: true });
  }
  numberOfPendingTransactions() {
    return this.pendingTransactions.length;
  }
  addTransaction(paramObj) {
    this.pendingTransactions.push(paramObj);
    saveTransactions(this.pendingTransactions);
    Globals.dispatchSupport({ localTransactionCreated: true });
  }
  playPendingTransactions() {
    // Play and clear the pending transactions
    let sequence = Promise.resolve();
    while (this.pendingTransactions.length) {
      const paramObj = this.pendingTransactions.shift();
      saveTransactions(this.pendingTransactions);

      sequence = sequence.then(f => SupportRequestStore.playLocalTransaction(paramObj));
    }
    return sequence;
  }
}

export default new SupportRequestLocalStore();