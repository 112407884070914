import React from 'react';
import { useTranslation } from 'react-i18next';
import SetupTip from '../siteComponents/SetupTip';

export default function SetupReader(props) {
  const { t } = useTranslation('site');

  return (
    <p>
      <SetupTip tip={t('In Settings\n...Check the "Reader is assigned for each round"\n...(Optional) Select the "Readers answer is correct" checkbox if you are using buttons for answers and scoring')}>
        {t('Assign a reader per round')}
      </SetupTip>
    </p>
  );
}
