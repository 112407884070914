import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';
import BoxR from '../components/box/BoxR';
import { useFreshDecks } from '../db/DeckStore';
import DeckOptions from '../fields/DeckOptions';
import PromptMessage from './PromptMessage';

const classes = {
  selectedButton: 'DeckOptionsPrompt-selectedButton',
  unselectedButton: 'DeckOptionsPrompt-unselectedButton'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.selectedButton}`]: {
    margin: 2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    textTransform: 'none',
  },
  [`&.${classes.unselectedButton}`]: {
    margin: 2,
    textTransform: 'none',
  }
}));

const ID_DECKS_USED = 'decksUsed';
const ID_CARD_EXPIRES_CB = 'cardsExpirePerRound';
const ID_DEAL_SAME_CARDS_CB = 'dealSameCardToMultiplePlayers';
const EMPTY_STAGED_VALUES = { [ID_DECKS_USED]: [], [ID_CARD_EXPIRES_CB]: true };
const checkboxControls = [ID_CARD_EXPIRES_CB, ID_DEAL_SAME_CARDS_CB];

export default function DeckSelectionPrompt(props) {

  const { open, onCancel, onSave, game } = props;
  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [prevOpenState, setPrevOpenState] = React.useState(false);
  const [decksUsedErrorText, setDecksUsedErrorText] = React.useState('');

  const deckOptions = new DeckOptions(game.deckOptions);
  const allDecks = useFreshDecks();
  const { t } = useTranslation('controller');


  if (open && !prevOpenState) {
    setStagedValues({
      [ID_DECKS_USED]: DeckOptions.asDecks(deckOptions[ID_DECKS_USED]),
      [ID_CARD_EXPIRES_CB]: deckOptions[ID_CARD_EXPIRES_CB],
      [ID_DEAL_SAME_CARDS_CB]: deckOptions[ID_DEAL_SAME_CARDS_CB],
    });
    setPrevOpenState(true);
  }

  const clearValidations = () => {
    setDecksUsedErrorText('');
  }
  const validations = {
    validateDecks: (newStaged) => {
      if (!newStaged[ID_DECKS_USED].length) {
        setDecksUsedErrorText(t('you-must-select-at-least-one-d'));
        return true;
      }
      return false;
    },

    performValidate: () => {
      clearValidations();
      return validations.validateDecks(stagedValues);
    }
  }

  const handleCancel = () => {
    setPrevOpenState(false);
    clearValidations();
    onCancel();
  }
  const handleSave = () => {
    if (!validations.performValidate()) {
      deckOptions[ID_DECKS_USED] = stagedValues[ID_DECKS_USED].map(m => m.id);
      deckOptions[ID_CARD_EXPIRES_CB] = stagedValues[ID_CARD_EXPIRES_CB];
      deckOptions[ID_DEAL_SAME_CARDS_CB] = stagedValues[ID_DEAL_SAME_CARDS_CB];
      onSave(deckOptions);
      clearValidations();
      setPrevOpenState(false);
    }
  }

  const handleButton = (deck) => {
    const selectedDecks = stagedValues[ID_DECKS_USED]
    const selectedDeckIds = selectedDecks.map(m => m.id);
    const newStagedValues = { ...stagedValues };
    if (selectedDeckIds.includes(deck.id)) {
      newStagedValues[ID_DECKS_USED] = selectedDecks.filter(f => f.id !== deck.id);
    } else {
      newStagedValues[ID_DECKS_USED] = [...selectedDecks, deck];
    }
    setStagedValues(newStagedValues);
  }

  const handleValueChange = (event) => {
    const { id, value: valueTarget, checked: checkedTarget } = event.target;
    // event.stopPropagation();
    const newStaged = { ...stagedValues };
    const value = checkboxControls.includes(id) ? checkedTarget : valueTarget;
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  const renderButtons = () => {
    const selectedDeckIds = stagedValues[ID_DECKS_USED].map(m => m.id);
    return allDecks.map(m => {
      const className = selectedDeckIds.includes(m.id) ? classes.selectedButton : classes.unselectedButton;
      return (
        <StyledButton className={className} id={m.id} key={m.id} onClick={() => handleButton(m)}>
          <Typography>{m.name}</Typography>
        </StyledButton>
      );
    }
    );
  }

  const title = t('select-decks');
  if (!open) return null;
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle style={{ minWidth: Math.min(500, window.innerWidth * .8) }} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <BoxR style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          {renderButtons()}
        </BoxR>
        <PromptMessage errorText={decksUsedErrorText} />
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_CARD_EXPIRES_CB]} onChange={handleValueChange} id={ID_CARD_EXPIRES_CB} />}
            label={t('card-expires-per-round')}
          />
        </FormGroup>
        <FormGroup row >
          <FormControlLabel
            control={<Checkbox checked={stagedValues[ID_DEAL_SAME_CARDS_CB]} onChange={handleValueChange} id={ID_DEAL_SAME_CARDS_CB} />}
            label={t('deal-the-same-card-to-multiple')}
          />
        </FormGroup>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" type="submit" autoFocus disabled={!stagedValues}>
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}