// NLS-NLS(support)
import { Hub } from '@aws-amplify/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { supportChannel } from '../../appSupport/GlobalConstants';
import Globals from '../../appSupport/Globals';
import { STORAGE_userToldAboutLoginForSupport } from '../../appSupport/storageConstants';
import { useStateWithSessionStorage } from '../../db/StorageStore';
import { useFreshSupportRequests } from '../../db/SupportRequestStore';
import ConfirmationPrompt from '../ConfirmationPrompt';
import GeneralFeedbackPrompt from './GeneralFeedbackPrompt';
import LanguageRequestPrompt from './LanguageRequestPrompt';
import ReportBugPrompt from './ReportBugPrompt';
import SendTracePrompt from './SendTracePrompt';
import SuggestedEnhancementPrompt from './SuggestEnhancementPrompt';
import TranslateToolPrompt from './TranslateToolPrompt';


export default function SupportPrompt() {
  const [supportOpenMap, setSupportOpenMap] = React.useState({});
  const [isConfirmLoggedInOpen, setIsConfirmLoggedInOpen] = React.useState(false);
  const isDefaultUser = Globals.isDefaultUser();
  const anyOpen = !!Object.keys(supportOpenMap).length;
  const supportRequests = useFreshSupportRequests();
  const noSupportRequestsExist = !supportRequests.length;
  const { t } = useTranslation('support');
  const [userToldAboutLogin, setUserToldAboutLogin] = useStateWithSessionStorage(STORAGE_userToldAboutLoginForSupport, false);
  const SR_TITLE = t('support-request');
  const SR_TEXT = t("a-new-launcher-button-has-been");

  React.useEffect(() => {
    const cb = (event) => {
      if (event.payload.openLanguageRequestPrompt) {
        setSupportOpenMap({ languageRequestPrompt: true });
      } else if (event.payload.openReportBugPrompt) {
        setSupportOpenMap({ reportBugPrompt: true });
      } else if (event.payload.openSuggestEnhancementPrompt) {
        setSupportOpenMap({ suggestEnhancementPrompt: true });
      } else if (event.payload.openGeneralFeedbackPrompt) {
        setSupportOpenMap({ generalFeedbackPrompt: true });
      } else if (event.payload.openTranslateToolPrompt) {
        setSupportOpenMap({ translateToolPrompt: true });
      } else if (event.payload.openSendTracePrompt) {
        setSupportOpenMap({ sendTracePrompt: true });
      } else if (event.payload.requestCreated) {
        if (noSupportRequestsExist && !Globals.isDefaultUser()) {
          Globals.showConfirmation({
            title: SR_TITLE,
            text: SR_TEXT,
          });
        }
      }
    }
    Hub.listen(supportChannel, cb);
    return () => Hub.remove(supportChannel, cb);
  }, [noSupportRequestsExist, SR_TEXT, SR_TITLE]);

  const handleClose = () => {
    setSupportOpenMap({});
  }

  if (!anyOpen) return null;
  if (isDefaultUser && !userToldAboutLogin && !supportOpenMap.sendTracePrompt) {
    setUserToldAboutLogin(true);
    setIsConfirmLoggedInOpen(true);
    return null; // Will cycle back around with isConfirmLoggedInOpen
  }

  const openMapAlt = isConfirmLoggedInOpen ? {} : supportOpenMap;
  return (
    <React.Fragment>
      <ConfirmationPrompt open={isConfirmLoggedInOpen} onClose={f => setIsConfirmLoggedInOpen(false)}
        title={t('login-required')} text={t('you-must-be-logged-in-to-creat')} showCancelButton={false} />
      <LanguageRequestPrompt open={!!openMapAlt.languageRequestPrompt} onClose={handleClose} />
      <ReportBugPrompt open={!!openMapAlt.reportBugPrompt} onClose={handleClose} />
      <SuggestedEnhancementPrompt open={!!openMapAlt.suggestEnhancementPrompt} onClose={handleClose} />
      <GeneralFeedbackPrompt open={!!openMapAlt.generalFeedbackPrompt} onClose={handleClose} />
      <TranslateToolPrompt open={!!openMapAlt.translateToolPrompt} onClose={handleClose} />
      <SendTracePrompt open={!!openMapAlt.sendTracePrompt} onClose={handleClose} />
    </React.Fragment>
  );
}