// NLS-NLS(player)
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UiUtil from '../appSupport/UiUtil';


export default function ConfirmationPrompt(props) {
  const { open, onClose, onSave, title, text, continueButtonText, showCancelButton = true } = props;
  const { t } = useTranslation('player');

  function renderButton({ autoFocus = false, text }) {
    return (
      <Button onClick={onSave || onClose} color="primary" autoFocus={autoFocus}>
        {text}
      </Button>
    );
  }

  function renderContinueButtons() {
    if (Array.isArray(continueButtonText)) {
      return continueButtonText.map((m, i) =>
        <Button onClick={() => onSave({ index: i }) || onClose({ index: i })} color="primary" key={m}>
          {m}
        </Button>
      );
    } else {
      const props = { autoFocus: true, text: (continueButtonText || t('continue')) };
      return renderButton(props);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => UiUtil.onClose(event, reason, onClose)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCancelButton ?
          <Button onClick={onClose} color="primary">
            {t('cancel')}
          </Button>
          : null}
        {renderContinueButtons()}
      </DialogActions>
    </Dialog>
  );
}