import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import React from 'react';
import BoxR from './box/BoxR';

const classes = {
  root: 'Selections-root',
  chip: 'Selections-chip',
  contrastChip: 'Selections-contrastChip'
};

const StyledBoxR = styled(BoxR)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexWrap: 'wrap',
  },
  [`& .${classes.chip}`]: {
    margin: 2,
    backgroundColor: theme.palette.selectionsChip.light,
    color: theme.palette.selectionsChip.contrastText,
    // backgroundColor: '#fcd9d9',
  },
  [`& .${classes.contrastChip}`]: {
    margin: 2,
    backgroundColor: theme.palette.selectionsChip.main,
    color: theme.palette.selectionsChip.contrastText,
    // backgroundColor: '#fcd9d9',
  }
}));


export default function Selections(props) {
  const { names, contrastNames, sx: sxIn } = props;


  const listNames = () => {
    return names.map(m =>
      <Chip key={m} label={m} className={contrastNames.includes(m) ? classes.contrastChip : classes.chip} />
    )
  }

  return (
    <StyledBoxR className={classes.root} sx={sxIn}>
      {listNames()}
    </StyledBoxR>
  );
}

Selections.defaultProps = {
  contrastNames: [],
}