import { Divider, Popover } from '@mui/material';
import React from 'react';
import UserAssistance from '../appSupport/UserAssistance';
import UserAssistanceUtil from '../appSupport/UserAssistanceUtil';
import PageContainer from './PageContainer';
import PopoverContainer from './PopoverContainer';

export default class GlobalMessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverContent: {},
      popoverOpen: false,
      popoverMessage: null,
    };
    this.popoverAnchor = null;
  }

  componentDidMount() {
    UserAssistanceUtil.listen(this.messages(), this.handlePopoverRequest);
  }
  componentWillUnmount() {
    UserAssistanceUtil.stopListening(this.messages(), this.handlePopoverRequest);
  }

  messages() {
    return [UserAssistance.globalErrorMessage(), UserAssistance.loggingMessage()];
  }

  formatArray = (inputArray) => {
    return (
      <React.Fragment>
        <Divider />
        {inputArray.map((m, i) => (<React.Fragment><p key={'key' + i}>{m}</p><Divider /></React.Fragment>))}
      </React.Fragment>
    );
  }

  handleClosePopover = () => {
    this.setState({ popoverOpen: false });
  }

  handlePopoverRequest = (capsule) => {
    if (UserAssistanceUtil.isInteresting(this.messages(), capsule)) {
      const { payload } = capsule;
      const { popoverMessage: prevPopoverMessage } = this.state;
      const { id, popoverText, messageType } = payload;
      const popoverOpen = Boolean(popoverText);
      const popoverMessage = Array.isArray(popoverText) ? this.formatArray(popoverText) : popoverText;
      this.setState({
        popoverContent:
          { channel: capsule.channel, popoverId: id, popoverText: popoverText, messageType },
        popoverOpen,
        popoverMessage: (popoverOpen ? popoverMessage : prevPopoverMessage), // retain previous message for fade out 
      });
    }
  }

  render() {
    const { popoverContent, popoverOpen, popoverMessage } = this.state;
    const { messageType } = popoverContent;
    return (
      <React.Fragment>
        <PageContainer ref={r => this.popoverAnchor = r}>
          {this.props.children}
        </PageContainer>
        <Popover
          id='popover-router'
          anchorEl={this.popoverAnchor}
          open={popoverOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClick={this.handleClosePopover}
          onClose={this.handleClosePopover}
        >
          <PopoverContainer messageType={messageType}>
            {popoverMessage}
          </PopoverContainer>
        </Popover>
      </React.Fragment >
    );
  }
}
