import React from 'react';
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import UserStore from "../appSupport/UserStore";
import AbstractStore from "./AbstractStore";
import GameOps from './GameOps';
import GameStore from "./GameStore";
import PendingPlayerOps from "./PendingPlayerOps";

const PENDING_PLAYER_CHANNEL = 'PENDING_PLAYER_CHANNEL';

let INSTANCE;

export function useFreshPendingPlayers(filter) {
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    var hasEnded = false;
    var hasListener = false;
    const getRecords = () => PendingPlayerStore.getRecords(filter);
    const handleChange = (event) => {
      // const { records } = event.payload;
      if (!hasEnded) setRecords(getRecords(filter));
    }
    PendingPlayerStore.initialQueryCompletePromise()
      .then(r => {
        if (!hasEnded) {
          hasListener = true;
          PendingPlayerStore.listen(handleChange);
          setRecords(getRecords(filter));
        }
      });
    return () => {
      hasEnded = true;
      if (hasListener) {
        hasListener = false;
        PendingPlayerStore.stopListen(handleChange);
      }
    }
  }, [filter]);

  return records;
}

export default class PendingPlayerStore {
  static instance() {
    const id = PathUtil.getGameParam();
    const game = INSTANCE ? INSTANCE.getGame() : { id };
    if (!INSTANCE || (game.id !== id)) {
      PendingPlayerStore.release();
      INSTANCE = new PendingPlayerStoreInst();
    }
    return INSTANCE;
  }
  static initForStart() {
    return PendingPlayerStore.instance().initForStart();
  }
  static initialQueryCompletePromise() {
    return PendingPlayerStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return PendingPlayerStore.instance().loadedPromise();
  }
  static listen(callBack) {
    PendingPlayerStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static createPlayer(properties) {
    return PendingPlayerStore.instance().performCreate(properties);
  }
  static updatePlayer(keyFields, updates) {
    return PendingPlayerStore.instance().performUpdate(keyFields, updates);
  }
  static getClaimEntryKey(playerNameIn) {
    const playerName = playerNameIn || PathUtil.getPlayerNameParam();
    const player = PendingPlayerStore.getPlayer(playerName);
    return player ? player.entryKey : null;
  }

  static getPlayers() {
    return this.getRecords();
  }
  static getPlayerNames() {
    return this.getPlayers().map(m => m.playerName);
  }
  static getPlayer(playerNameIn) {
    const playerName = playerNameIn || PathUtil.getPlayerNameParam();
    return this.getRecords().find(f => f.playerName === playerName);
  }
  static getPlayerCount() {
    return this.getRecords().length;
  }
  static getRecords(filtered) {
    // Originally lazy loaded, but now specifically loaded.
    // PendingPlayers is accessed via Globals for PlayerNumber, so short circuit out if not loaded.
    if (!INSTANCE) return [];
    return PendingPlayerStore.instance().getRecords(filtered);
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }
}

class PendingPlayerStoreInst extends AbstractStore {
  constructor() {
    super('PendingPlayerStore', PENDING_PLAYER_CHANNEL);
    this.game = GameStore.getGame();
    this.boundGameUpdate = this.handleGameUpdate.bind(this);
    GameStore.listen(this.boundGameUpdate);
    this.loadStore(PendingPlayerOps.onPendingPlayerCreate, PendingPlayerOps.onPendingPlayerUpdate, null);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    const gameId = PathUtil.getGameParam();
    if (!gameId) return this.getValuesForInit();  // If we have navigated back from game, don't fail async ops
    return `User: ${UserStore.getUserName()}  Id: ${gameId}  subscriptionOwner: ${Globals.getDataOwner()}`;
  }
  performCreate(properties) {
    return PendingPlayerOps.createPlayer(properties);
  }
  performUpdate(keyFields, updates) {
    return PendingPlayerOps.updatePlayer(keyFields, updates);
  }
  queryRecords() {
    return PendingPlayerOps.getPendingPlayers();
  }
  recKey(record) {
    return record.playerName;
  }
  getSortFn() {
    return Globals.sorterCreatedAt;
  }


  release() {
    super.release();
    GameStore.stopListen(this.boundGameUpdate);
  }
  getGame() {
    return this.game;
  }
  handleGameUpdate(event) {
    // Don't update the game if the id has changed.  This allows for the Store to perform release() for new game.
    const { game: newGame } = event.payload;
    if (this.game.id === newGame.id) {
      this.game = newGame;
    }
  }
  initForStart() {
    const { id } = this.getGame();
    const players = this.getRecords();
    const entryKeys = GameOps.defineEntryKeys(players.length);
    players.forEach((player, i) => {
      const updates = { playerNumber: i + 1, entryKey: entryKeys[i] };
      this.put({ id, playerName: player.playerName }, updates);
    });
  }

}

