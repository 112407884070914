import { styled } from '@mui/material/styles';
import { Hidden, Typography } from '@mui/material';
import DownIcon from '@mui/icons-material/ArrowDownward';
import UpIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';
import Globals from '../../appSupport/Globals';
import GameOptions from '../../fields/GameOptions';
import RoundData from '../../fields/RoundData';
import ScoreOptions from '../../fields/ScoreOptions';
import BoxC from '../box/BoxC';
import BoxR from '../box/BoxR';
import PlayerName, { SCORE_TABLE } from '../PlayerName';
import ScoreableEntry from '../ScoreableEntry';
import TableList from '../TableList';
import RowCell from './RowCell';
import { classes as scoreableEntryClasses } from '../../components/ScoreableEntry';
import { useTheme } from '@mui/styles';


const classes = {
  innerVerticalBox: 'ScoreTableList-innerVerticalBox',
  scoreRow: 'ScoreTableList-scoreRow',
  bidCell: 'ScoreTableList-bidCell',
  handScoreCell: 'ScoreTableList-handScoreCell',
  totalScoreCell: 'ScoreTableList-totalScoreCell'
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.innerVerticalBox}`]: {
    width: 280,
    // backgroundColor: 'pink',
  },
  [`& .${classes.scoreRow}`]: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [`& .${classes.bidCell}`]: {
    width: 80,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(1),
    // backgroundColor: 'green',
  },
  [`& .${classes.handScoreCell}`]: {
    width: 110,
    alignItems: 'center',
    justifyContent: 'flex-end',
    // backgroundColor: 'red',
  },
  [`& .${classes.totalScoreCell}`]: {
    width: 100,
    justifyContent: 'flex-end',
    // backgroundColor: 'pink',
  }
}));


const loadingOrNoData = f => null;

export default function ScoreTableList(props) {
  const { rows, game, onScoreChange, showScoringButtons, showBidButtons, handToDisplay } = props;
  const [openMap, setOpenMap] = React.useState({});

  const theme = useTheme();
  const roundData = RoundData.fromGame(game, handToDisplay);
  const gameOptions = new GameOptions(game.gameOptions);
  const scoreOptions = new ScoreOptions(game.scoreOptions);
  const enableScoring = Globals.isScoreKeeper(game) && gameOptions.keepScore;
  const myName = Globals.getPlayerName();

  const playerScoreMe = {
    pl: 1,
    pr: 1,
    backgroundColor: theme.palette.scoreBackground.me,
  };
  const playerScoreOther = {
  };

  const buttonBoxBase = {
    borderWidth: 1,
    borderRadius: 1,
    borderColor: 'grey',
    borderStyle: 'solid',
  };
  const buttonBoxBid = { ...buttonBoxBase, ...{ backgroundColor: theme.palette.scoreBackground.bidEntry } };
  const buttonBoxScore = { ...buttonBoxBase, ...{ backgroundColor: theme.palette.scoreBackground.scoreEntry } };

  function handleSetScore(scoreRow, score) {
    onScoreChange(scoreRow, score);
  }

  function handleOpenScoreToggle(name, isOpen) {
    setOpenMap({ [name]: isOpen });
  }

  function rowRenderer(row, rows) {
    const notLastRow = row !== rows[rows.length - 1];
    const isMe = row.name === myName;
    const isReader = (row.name === roundData.currentReader);
    const scoreToEdit = showBidButtons ? row.bid : row.handScore;
    const playerSx = isMe ? playerScoreMe : playerScoreOther;
    const buttonSx = showBidButtons ? buttonBoxBid : buttonBoxScore;
    const sx = {
      [`&.${scoreableEntryClasses.root}`]: playerSx,
      [`& .${scoreableEntryClasses.buttonBox}`]: buttonSx
    };
    return (
      <RowCell showDivider={notLastRow}>
        <ScoreableEntry key={row.name} id={row.name} isOpen={!!openMap[row.name]} onOpenClose={(isOpen) => handleOpenScoreToggle(row.name, isOpen)}
          isScoreKeeper={enableScoring} scoreOptionsString={game.scoreOptions}
          prevTotalScore={row.prevTotalScore} scoreToEdit={scoreToEdit} totalScore={row.totalScore}
          onScoreChange={(newScore) => handleSetScore(row, newScore)} showScoreButtons={showScoringButtons || showBidButtons}
          sx={sx}>
          <BoxC className={classes.innerVerticalBox}>
            <PlayerName playerName={row.name} size={SCORE_TABLE} isMe={isMe} isReader={isReader} />
            <BoxR className={classes.scoreRow}>
              {scoreOptions.enableBids ?
                <BoxR className={classes.bidCell}>
                  <Typography style={{ fontSize: 18 }}>(</Typography>
                  <Typography style={{ fontSize: 18 }}>{row.bid}</Typography>
                  <Typography style={{ fontSize: 18 }}>)</Typography>
                </BoxR>
                : null}
              {row.handScore ?
                <BoxR className={classes.handScoreCell}>
                  <Typography style={{ fontSize: 25 }}>{row.handScore.toLocaleString()}</Typography>
                  {row.handScore > 0 ? <UpIcon /> : <DownIcon />}
                </BoxR>
                : <BoxR className={classes.handScoreCell} />}
              <BoxR className={classes.totalScoreCell}>
                <Typography style={{ fontSize: 25, fontWeight: 'bold' }}>{row.totalScore.toLocaleString()}</Typography>
              </BoxR>
            </BoxR>
          </BoxC>
        </ScoreableEntry>
      </RowCell>
    );
  }

  const tools = [];
  const fullTableProps = {
    rows, rowRenderer,
    dense: true,
    hidePaper: true,
    selectedRows: [],
    disableFlexSizing: true,
    noDataRenderer: loadingOrNoData,
    oneSelectedTools: tools,
    moreSelectedTools: tools,
    noneSelectedTools: tools,
  };
  const mobileTableProps = { ...fullTableProps }

  return (
    <Root>
      <Hidden smDown>
        <TableList {...fullTableProps} />
      </Hidden>
      <Hidden smUp>
        <TableList {...mobileTableProps} />
      </Hidden>
    </Root>
  );
}
