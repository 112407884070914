import { Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import nlsStore from './NlsStore';

export const MessageTypes = {
  error: 'ERROR',
  info: 'INFO',
  diagnostic: 'DIAGNOSTIC',
}
const channelUserAssistance = 'UserAssistance';
const channelGlobalReporting = 'GlobalReporting';
// const channelUserAssistance = 'UserAssistance';
const SOMETHING_WENT_WRONG_TEXT = 'Something went wrong...';
class UserAssistance {
  tipShape = {
    channel: PropTypes.string,
    popoverId: PropTypes.string,
  }

  loggingMessage() {
    return { channel: channelGlobalReporting, popoverId: 'systemLog' };
  }
  displayLoggingMessage(message) {
    const tip = this.globalErrorMessage();
    const messageType = MessageTypes.diagnostic;
    Hub.dispatch(tip.channel, { id: tip.popoverId, popoverText: message, messageType });
  }

  globalErrorMessage() {
    return { channel: channelGlobalReporting, popoverId: 'errorMessage' };
  }
  displayGlobalErrorMessage(message) {
    const tip = this.globalErrorMessage();
    const messageType = MessageTypes.error;
    Hub.dispatch(tip.channel, { id: tip.popoverId, popoverText: message, messageType });
  }

  displayGlobalInfoMessage(message) {
    const tip = this.globalErrorMessage();
    const messageType = MessageTypes.info;
    Hub.dispatch(tip.channel, { id: tip.popoverId, popoverText: message, messageType });
  }

  displaySomethingWentWrong = () => {
    this.displayGlobalErrorMessage(SOMETHING_WENT_WRONG_TEXT);
  }

  translateToolTip() {
    return { channel: channelUserAssistance, popoverId: 'translateToolTip' };
  }
  displayTranslateToolTip = () => {
    const tip = this.translateToolTip();
    const messageType = MessageTypes.info;
    Hub.dispatch(tip.channel, { id: tip.popoverId, popoverText: nlsStore.getValueSupport('translate-tool-added-to-header'), toolGuidance: true, messageType });
  }
  refreshToolTip() {
    return { channel: channelUserAssistance, popoverId: 'refreshToolTip' };
  }
  displayRefreshToolTip = () => {
    const tip = this.refreshToolTip();
    const messageType = MessageTypes.info;
    Hub.dispatch(tip.channel, { id: tip.popoverId, popoverText: nlsStore.getValuePlayer('you-should-never-have-to-but-i'), toolGuidance: true, messageType });
  }

}
export default new UserAssistance();
