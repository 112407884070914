import React from 'react';
import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')(({ onClick }) => ({
  fontWeight: 'bold',
  fontSize: 22,
  cursor: (onClick ? 'pointer' : undefined),
}));

export default function StyleTopic(props) {
  const { onClick, children, ...rest } = props;

  return (
    <StyledSpan onClick={onClick} {...rest}>
      {children}
    </StyledSpan>
  );
}
