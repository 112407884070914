import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsStore from '../../appSupport/AnalyticsStore';
import Globals from '../../appSupport/Globals';
import FaqContent from '../faq/FaqContent';
import MenuGames from '../menu/MenuGames';
import MenuGotoGame from '../menu/MenuGotoGame';
import MenuInstructions from '../menu/MenuInstructions';
import MenuScanningTips from '../menu/MenuScanningTips';
import MenuSetupTips from '../menu/MenuSetupTips';
import SitePage from '../siteComponents/SitePage';
import UnderConstructionText from '../siteComponents/UnderConstructionText';

export default function Faq(props) {
  const { t } = useTranslation('site');

  React.useEffect(() => {
    AnalyticsStore.recordSiteLandingVisit();
  }, []);

  const isSiteActive = Globals.isSiteFeatureActive();
  const title = t("Bueno Fun - FAQ");
  const tabTitle = t("Bueno Fun - FAQ");
  const component = isSiteActive ? <FaqContent /> : <UnderConstructionText />;
  const mobileComponent = isSiteActive ? <FaqContent mobile /> : <UnderConstructionText />

  const menuActionItems = [
    <MenuGotoGame key='gotoGame' />,
    <MenuGames key='games' />,
    <MenuInstructions key='instructions' />,
    <MenuScanningTips key='scanningTips' />,
    <MenuSetupTips key='setupTips' />
  ];

  return (
    <SitePage title={title} tabTitle={tabTitle} component={component} mobileComponent={mobileComponent} menuActionItems={menuActionItems} />
  );
}
