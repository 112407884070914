import React from 'react';
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import TraceLog from '../appSupport/TraceLog';
import UserStore from "../appSupport/UserStore";
import ResultOps from "../db/ResultOps";
import AnswerAnnotations from "../fields/AnswerAnnotations";
import GameOptions from '../fields/GameOptions';
import SelectOptions from '../fields/SelectOptions';
import AbstractStore from "./AbstractStore";
import GameStore from "./GameStore";
import PendingPlayerStore from './PendingPlayerStore';

const RESULT_CHANNEL = 'RESULT_CHANNEL';

let INSTANCE;

export function useFreshResults(filter, preGame = false) {
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    var hasEnded = false;
    var hasListener = false;
    if (!preGame && PathUtil.getGameParam()) {
      const getRecords = () => ResultStore.getRecords(filter);
      const handleChange = (event) => {
        // const { records } = event.payload;
        if (!hasEnded) setRecords(getRecords(filter));
      }
      ResultStore.initialQueryCompletePromise()
        .then(r => {
          if (!hasEnded) {
            hasListener = true;
            ResultStore.listen(handleChange);
            setRecords(getRecords(filter));
          }
        });
      return () => {
        hasEnded = true;
        if (hasListener) {
          hasListener = false;
          ResultStore.stopListen(handleChange);
        }
      }
    }
  }, [filter, preGame]);

  return records;
}

export default class ResultStore {

  static instance() {
    const id = PathUtil.getGameParam();
    const game = INSTANCE ? INSTANCE.getGame() : { id };
    if (!INSTANCE || (game.id !== id)) {
      if (INSTANCE) INSTANCE.release();
      INSTANCE = new ResultStoreInst();
    }
    return INSTANCE;
  }
  static initialQueryCompletePromise() {
    return ResultStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return ResultStore.instance().loadedPromise();
  }
  static listen(callBack) {
    ResultStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getResultCount() {
    return this.getRecords().length;
  }
  static getResult(playerNameIn) {
    const playerName = playerNameIn || PathUtil.getPlayerNameParam();
    return this.getRecords().find(f => f.playerName === playerName);
  }

  static getRecords(filtered) {
    return ResultStore.instance().getRecords(filtered);
  }
  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }
  static saveAnswerNote(playerResult, text) {
    ResultStore.instance().saveAnswerNote(playerResult, text);
  }
  static resultSelection(playerResults, playerResult, selectedBoolean, playerName) {
    ResultStore.instance().resultSelection(playerResults, playerResult, selectedBoolean, playerName);
  }
}

class ResultStoreInst extends AbstractStore {
  constructor() {
    super('ResultStore', RESULT_CHANNEL);
    this.game = GameStore.getGame();
    this.boundGameUpdate = this.handleGameUpdate.bind(this);
    GameStore.listen(this.boundGameUpdate);
    this.currentHand = this.game.currentHand;
    this.loadStore(ResultOps.onPlayerResultCreate, ResultOps.onPlayerResultUpdate, null);
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    const gameId = PathUtil.getGameParam();
    if (!gameId) return this.getValuesForInit();  // If we have navigated back from game, don't fail async ops
    return `User: ${UserStore.getUserName()}  Id: ${gameId}  subscriptionOwner: ${Globals.getDataOwner()}`;
  }
  queryRecords() {
    return ResultOps.getPlayerResults(this.currentHand);
  }
  performUpdate(keyFields, updates, conflictResolver, debug) {
    return ResultOps.performUpdate(keyFields, updates, conflictResolver, debug);
  }
  recKey(record) {
    return record.playerName;
  }
  getSortFn() {
    return Globals.sorterCreatedAt;
  }

  release() {
    super.release();
    GameStore.stopListen(this.boundGameUpdate);
  }
  getGame() {
    return this.game;
  }
  async handleGameUpdate(event) {
    // If this is a change that effects the results, handle it.
    const { game: newGame } = event.payload;
    if (this.game.id === newGame.id) {
      if (this.game.currentHand !== newGame.currentHand) {
        this.currentHand = newGame.currentHand;
        this.reFilterRecords(f => f.hand === this.currentHand);
        TraceLog.addTrace(`Result records filtered.  currentHand: ${newGame.currentHand} resulting size: ${this.getRecords().length}`);
      }
      this.game = newGame;
    }
  }

  // A selection has been made.  This may require unselecting other answers
  resultSelection(playerResults, playerResult, selectedBoolean, playerName) {
    const gameOptions = new GameOptions(this.game.gameOptions);
    const selectOptions = new SelectOptions(gameOptions.selectOptions);
    const playerNumber = Globals.getPlayerNumber(playerName);
    // Find the selections and order them 
    const existingSelections = [];
    playerResults.forEach(e => {
      const aa = AnswerAnnotations.forPlayerNumber(e, playerNumber);
      if (aa && aa.answerSelected) {
        if (selectedBoolean || (this.recKey(e) !== this.recKey(playerResult))) {
          existingSelections.push({ selectionNumber: aa.answerSelected, result: e });
        }
      }
    });
    const sortedResults = existingSelections.sort((a, b) => Globals.sorterAny(a.selectionNumber, b.selectionNumber));
    if (selectedBoolean) {
      // Make room for the new selection
      while (sortedResults.length >= selectOptions.numberOfSelectionsAllowed) {
        const popped = sortedResults.pop();
        this.selectAnswer(popped.result, 0, playerName);
      }
      this.selectAnswer(playerResult, sortedResults.length + 1, playerName);
    } else {
      this.selectAnswer(playerResult, 0, playerName);
      // Re-select others as order may have changed
      let seq = 1;
      sortedResults.forEach(e => this.selectAnswer(e.result, seq++, playerName));
    }
  }

  getAnnotations(playerResult) {
    const count = PendingPlayerStore.getPlayerCount();
    const annotations = playerResult.annotations ? [...playerResult.annotations] : [];
    let i = 0;
    while (i < count) {
      if (annotations[i] === undefined) {
        annotations[i] = null; // Not sure how it will asJSON without this.
      }
      i++;
    }
    return annotations;
  }

  saveAnswerNote(playerResult, text) {
    const playerNumber = Globals.getPlayerNumber();
    const aa = AnswerAnnotations.forPlayerNumber(playerResult, playerNumber) || new AnswerAnnotations();
    // The conflictResolver is called in the event of a conflict so we can update only our array element.
    // Also use it for the initial update.
    const conflictResolver = (playerResult) => {
      const annotations = this.getAnnotations(playerResult);
      aa.answerNoteText = text;
      annotations[playerNumber - 1] = aa.asJSON();
      return { annotations, expectedVersion: playerResult.version };
    }
    const properties = conflictResolver(playerResult);
    const keyFields = {
      id: playerResult.id, hand: playerResult.hand,
      playerName: playerResult.playerName, createdAt: playerResult.createdAt
    };
    this.put(keyFields, properties);
  }
  selectAnswer(playerResult, selection, forPlayer = null) {
    const playerNumber = Globals.getPlayerNumber(forPlayer);
    const aa = AnswerAnnotations.forPlayerNumber(playerResult, playerNumber) || new AnswerAnnotations();
    // The conflictResolver is called in the event of a conflict so we can update only our array element.
    // Also use it for the initial update.
    const conflictResolver = (playerResult) => {
      const annotations = this.getAnnotations(playerResult);
      aa.answerSelected = selection;
      annotations[playerNumber - 1] = aa.asJSON();
      return { annotations, expectedVersion: playerResult.version };
    }
    if (aa.answerSelected !== selection) {
      aa.answerSelected = selection;
      const properties = conflictResolver(playerResult);
      const keyFields = {
        id: playerResult.id, hand: playerResult.hand,
        playerName: playerResult.playerName, createdAt: playerResult.createdAt
      };
      this.put(keyFields, properties, conflictResolver);
    }
  }

}

