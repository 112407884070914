import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function TeamNamesHelp() {
  const { t } = useTranslation('help');
  return (
    <HelpTool helpText={t("if-blank-each-player-is-on-the")} />
  );
}
