import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/fiveSecondRule.jpg';
const gameUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/fiveSecondRuleGame.png';

export default function FiveSecondRule({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset right src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('5 Second Rule')}</StyleTopic>
      <StyleParagraph>{t("There’s 5 seconds on the timer for you to name the 3 things listed on the card. Can you name 3 breeds of dogs? Can you name 3 finger foods? That’s easy! Well, not quite! 5 Second Rule is the seemingly simple game that gets players tongue-tied as the timer moves and other players are waiting for your answers. Say what comes to mind and some ridiculous answers will pop out. 5 Second Rule will have everyone laughing!")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("Great example of a game that DOES NOT work well in a video call. BUT, if you tweak the rules it works GREAT! Try playing where each player names 3 items per question. Score one point per answer, and one point per unique answer. ")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Set the "Number of answer fields"  to "3"\n...Select "Keep Score"')} />
      <Asset left open={!compact} src={gameUrl} mobile={mobile} />
    </SiteSection>
  );
}
