import AutoScoreReader from "./AutoScoreReader";
import AutoScoreSelect from "./AutoScoreSelect";
import ScoreButtons from "./ScoreButtons";

export default class ScoreOptions {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.showScoreBeforeNewRound = input ? input.showScoreBeforeNewRound : true;
    this.reverseScoring = input ? input.reverseScoring : false;
    this.enableBids = input ? input.enableBids : false;
    this.scoreOnly = input ? input.scoreOnly : false;
    this.useTeamNames = input ? input.useTeamNames : false;
    this.teamNames = input ? input.teamNames : '';
    this.useScoreButtons = input ? input.useScoreButtons : true;
    this.scoreButtons = input ? input.scoreButtons : (new ScoreButtons()).asJSON();
    this.useAutoScoreSelect = input ? input.useAutoScoreSelect : false;
    this.autoScoreSelect = input ? input.autoScoreSelect : (new AutoScoreSelect()).asJSON();
    this.useAutoScoreReader = input ? input.useAutoScoreReader : false;
    this.autoScoreReader = input ? input.autoScoreReader : (new AutoScoreReader()).asJSON();
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

