import { Hub } from "aws-amplify";

const uiEventChannel = 'UI_EVENT_CHANNEL';
const validateContentType = (contentType, contentTypes, fileName) => {
  const isSupportedContentType = contentTypes.includes(contentType);
  if (!contentType) {
    return `File selected for card import is of an unknown type: ${fileName}`;
  } else if (!isSupportedContentType) {
    return `File selected for card import is not a supported type: ${contentType}`;
  }
  return undefined;
}

export const EVENT_TIMER_EXPIRED = 'EVENT_TIMER_EXPIRED';
export default class UiUtil {
  static validatePdfContentType(contentType, fileName) {
    return validateContentType(contentType, ['application/pdf'], fileName);
  }
  static validateImageContentType(contentType, fileName) {
    return validateContentType(contentType, ['image/png', 'image/jpeg'], fileName);
  }
  static newTimerExpiredEvent() {
    return { id: EVENT_TIMER_EXPIRED }
  }
  static reportEvent(data) {
    Hub.dispatch(uiEventChannel, data);
  }
  static gameViewKey = (game) => {
    // Hoping to help the Garbage Collector.
    return `GameViewKey-${game ? game.currentHand : 'noGameYet'}`;
  }
  static onClose = (event, reason, onClose) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      return false;
    } else if (typeof onClose === "function") {
      onClose(event);
    }
  }
}