import React from 'react';
import { navigateChannel } from '../appSupport/GlobalConstants';
import { useNavigate } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';

export default function NavigationSupport() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const handleNavigate = (data) => {
      const { path, options = { replace: true } } = data.payload;
      navigate(path, options);
    }
    Hub.listen(navigateChannel, handleNavigate);
    return () => {
      Hub.remove(navigateChannel, handleNavigate);
    }
  }, [navigate]);

  return null;
}