import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/smartAss.jpg';
const gameUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/smartAssBuzzer.png';

export default function SmartAss({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset right src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t('Smart Ass')}</StyleTopic>
      <StyleParagraph>{t("Who's the Smart A** among your friends and family? Find out with this outrageous, fast-paced party game that will have the whole gang in hysterics within minutes. This is the game for players who have been bursting to scream out the answer even when it's not their turn. Each Who, What or Where question has eight clues - the first player to yell out the correct answer wins the round and takes a step closer to being the ultimate 'Smart A**'.")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("In Smart Ass, when a player is ready to answer they blurt it out. On a video call this does not work well as it's hard to know who answered first. To counter this, setup BuenoFun to have a single 'Buzzer' button. When a player wants to blurt out, they also press the button. You then know who was first and let them answer.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Set the "Buttons" text to "Buzzer"')} />
      <Asset open={!compact} src={gameUrl} left mobile={mobile} />
      <StyleParagraph open={!compact}>{t("Another fun alternative is to let everybody answer when they know. If they are wrong, they get 0 points. The last correct answer gets one point. The next to last correct answer gets two points, etc.")}</StyleParagraph>
    </SiteSection>
  );
}
