import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';


export default function AnswerId(props) {
  const { playerIndex, isRandomResult, color: colorIn, variant } = props;
  const theme = useTheme();
  const color = colorIn || theme.palette.primary.main;
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z']
  const answerId = `${isRandomResult ? letters[playerIndex] : (playerIndex + 1).toString()})`;
  return (
    <Typography variant={variant} style={{ color }}>{answerId}</Typography>
  );
}

AnswerId.propTypes = {
  playerIndex: PropTypes.number,
  isRandomResult: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
};

AnswerId.defaultProps = {
  variant: 'h5',
}