import React from 'react';
import useMonitorGame from '../../appSupport/monitorGame';
import useMonitorGameState from '../../appSupport/monitorGameState';
import { useFreshResults } from '../../db/ResultStore';
import RecentGame from '../../fields/RecentGame';
import Loading from '../Loading';
import Play from './Play';

export default function ReadyPlay() {
  const game = useMonitorGame();
  const results = useFreshResults(undefined, !game);
  useMonitorGameState(game, results);

  if (!game) return <Loading /> // ==> EARLY EXIT

  RecentGame.setRecentGame();

  return <Play game={game} />
}