export default class TimeDelta {

  constructor() {
    this.showDelta = true;
  }

  getDeltaText() {
    if (!this.showDelta) return '';
    const delta = (this.currentTime && this.prevTime) ? (this.currentTime - this.prevTime) / 1000 : -1;
    const deltaText = (delta === -1) ? '' : `+(${delta})`;
    return deltaText;
  }

  setShowDelta(show) {
    this.showDelta = Boolean(show);
  }

  newTime(createdAt) {
    const result = new TimeDelta();
    result.showDelta = this.showDelta;
    result.prevTime = this.currentTime;
    result.currentTime = new Date(createdAt).getTime();
    return result;
  }
}

