// NLS-NLS(player)
import { Hub } from 'aws-amplify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PathUtil from '../appSupport/PathUtil';
import TraceLog from '../appSupport/TraceLog';
import Loading from "../page/Loading";
import CognitoRouter from "./CognitoRouter";
export const WAKE_CHANNEL = 'wake_channel';

export default function OnlineRouter() {
  const [isOnline, setIsOnline] = React.useState(true);
  const [offlineTitle, setOfflineTitle] = React.useState(false);
  const { t } = useTranslation('player');
  const OFFLINE_WAITING = t('offline-waiting');

  React.useEffect(() => {
    const ua = navigator.userAgent;
    TraceLog.addTrace(`User Agent: ${ua}`);
  }, []);

  React.useEffect(() => {
    // see https://medium.com/backticks-tildes/a-guide-to-handling-internet-disconnection-in-react-applications-5fd02eccb2bb
    function handleConnectionChange() {
      if (navigator.onLine) {
        if (!isOnline) {
          TraceLog.addEvent('App online', '-Network');
          PathUtil.reloadPageForWake();
          // setIsOnline(true);  // This causes a blink before the reload
        }
      } else {
        TraceLog.addEvent('App offline', '-Network');
        setOfflineTitle(OFFLINE_WAITING);
        setIsOnline(false);
      }
    }
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    }
  }, [isOnline, OFFLINE_WAITING]);

  React.useEffect(() => {
    function handleForceWake() {
      TraceLog.addEvent('Wake up store release - FORCED');
      PathUtil.reloadPageForWake();
    }
    Hub.listen(WAKE_CHANNEL, handleForceWake);
    // window.addEventListener('blur', handleBlur);
    // window.addEventListener('focus', handleFocus);
    return f => {
      // window.removeEventListener('blur', handleBlur);
      // window.removeEventListener('focus', handleFocus);
      Hub.remove(WAKE_CHANNEL, handleForceWake);
    }
  }, []);

  return (
    <React.Fragment>
      {!isOnline ? <Loading title={offlineTitle} /> : null}
      {isOnline ? <CognitoRouter /> : null}
    </React.Fragment>
  );
}
