// NLS-NLS(player)
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AssignmentTurnedInRounded, Share } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { gamePrefix } from '../routing/gameRoutes';
import PathUtil from '../appSupport/PathUtil';
import ShareUtil from '../appSupport/ShareUtil';
import BoxC from '../components/box/BoxC';
import BoxR from '../components/box/BoxR';
import UiUtil from '../appSupport/UiUtil';

const EQUAL = '%3d';
// const CR = '%0d';
const LF = '%0a';

export default function SharePrompt(props) {
  const { open, onCancel } = props;
  const { t } = useTranslation('player');

  if (!open) return null;
  const gameId = PathUtil.getFinderIdParam();

  function getGameLink(encode = true) {
    const url = new URL(window.location.href);
    const { hostname } = window.location;
    const portInfo = url.port ? `:${url.port}` : '';
    return `${url.protocol}://${hostname}${portInfo}${gamePrefix}/landing/?joinGame${encode ? EQUAL : '='}${gameId}`;
  }
  const handleCancel = () => {
    onCancel();
  }
  const handleShareEmail = () => {
    const subject = t('join-my-game-gameid', { gameId });
    const gameLink = `${LF}${LF}${getGameLink()}`;
    const body = t('click-this-link-to-join-the-ga', { gameLink });
    ShareUtil.sendEmail('', subject, body);
    onCancel();
  }
  const handleShareSms = () => {
    const gameLink = `${LF}${LF}${getGameLink()}`;
    const body = t('click-this-link-to-join-the-ga', { gameLink });
    ShareUtil.sendSms('', body);
    onCancel();
  }
  const handleCopyToClipboard = () => {
    ShareUtil.copyTextToClipboard(getGameLink(false));
    onCancel();
  }
  return (
    <Dialog open={open} onClose={(event, reason) => UiUtil.onClose(event, reason, handleCancel)} >
      <DialogTitle id="form-dialog-title">{t('share-game-gameid', { gameId })}</DialogTitle>
      <DialogContent >
        <BoxC>
          <Button onClick={handleCopyToClipboard} color="primary" variant="contained">
            <BoxR style={{ alignItems: 'center' }}>
              <AssignmentTurnedInRounded color='secondary' style={{ marginRight: 5 }} />
              <p>{t('copy-to-clipboard')}</p>
            </BoxR>
          </Button>
          <Button onClick={handleShareSms} color="primary" variant="contained" style={{ marginTop: 10 }}>
            <BoxR style={{ alignItems: 'center' }}>
              <Share color='secondary' style={{ marginRight: 5 }} />
              <p>{t('text-sms')}</p>
            </BoxR>
          </Button>
          <Button onClick={handleShareEmail} color="primary" variant="contained" style={{ marginTop: 10 }}>
            <BoxR style={{ alignItems: 'center' }}>
              <Share color='secondary' style={{ marginRight: 5 }} />
              <p>{t('email')}</p>
            </BoxR>
          </Button>
        </BoxC>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}