// NLS-NLS(support)
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UiUtil from '../../appSupport/UiUtil';
import SupportRequestStore from '../../db/SupportRequestStore';
import PromptMessage from '../PromptMessage';

const ID_ENGLISH_TEXT = 'englishText';
const ID_NEW_TRANSLATION = 'newTranslation';
const ID_COMMENT = 'comment';

export default function TranslateBleedThroughPrompt(props) {
  const EMPTY_STAGED_VALUES = {};
  const { open, onClose } = props;

  const [stagedValues, setStagedValues] = React.useState(EMPTY_STAGED_VALUES);
  const [errorText, setErrorText] = React.useState('');
  const { t } = useTranslation('support');

  const PROMPT_TITLE = t('report-english-bleed-through');
  const PROMPT_TEXT_1 = t("identify-text-that-is-not-tran");

  const clearValidations = () => {
    setErrorText('');
  }
  const validations = {
    englishTextEntered: () => {
      if (!stagedValues[ID_ENGLISH_TEXT]) {
        setErrorText(t('value-may-not-be-blank'));
        return true;
      }
      return false;
    },
    newTranslationEntered: () => {
      if (!stagedValues[ID_NEW_TRANSLATION]) {
        setErrorText(t('value-may-not-be-blank'));
        return true;
      }
      return false;
    },
    performValidate: () => {
      clearValidations();
      return validations.englishTextEntered() || validations.newTranslationEntered();
    }
  }

  function handleClose() {
    clearValidations();
    setStagedValues(EMPTY_STAGED_VALUES);
    onClose();
  }

  function handleSave() {
    if (!validations.performValidate()) {
      SupportRequestStore.createReportBleedThrough(stagedValues[ID_ENGLISH_TEXT], stagedValues[ID_NEW_TRANSLATION],
        stagedValues[ID_COMMENT]);
      setStagedValues(EMPTY_STAGED_VALUES);
      onClose();
    }
  }
  const handleValueChange = (event) => {
    const { id, value } = event.target;
    const newStaged = { ...stagedValues };
    newStaged[id] = value;
    setStagedValues(newStaged);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event, reason) => UiUtil.onClose(event, reason, handleClose)}
      >
        <DialogTitle>{PROMPT_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText >{PROMPT_TEXT_1}</DialogContentText>

          <TextField fullWidth multiline onChange={handleValueChange} error={!!errorText && !stagedValues[ID_ENGLISH_TEXT]}
            id={ID_ENGLISH_TEXT} label={t("english-text")} type="text" defaultValue={stagedValues[ID_ENGLISH_TEXT]} margin='normal' />
          <PromptMessage errorText={errorText} />

          <TextField fullWidth multiline onChange={handleValueChange} error={!!errorText && !stagedValues[ID_NEW_TRANSLATION]}
            id={ID_NEW_TRANSLATION} label={t("translation")} type="text" defaultValue={stagedValues[ID_NEW_TRANSLATION]} margin='normal' />
          <PromptMessage errorText={errorText} />

          <TextField fullWidth multiline rows={3} onChange={handleValueChange}
            id={ID_COMMENT} label={t("comment")} type="text" defaultValue={stagedValues[ID_COMMENT]} margin='normal' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            {t('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
