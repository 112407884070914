import jsPDF from "jspdf";

export default class PdfImageBuilder {
  static buildWith(images) {
    const addImage = (pdf, pageSize, dataUrl, width, height) => {
      pdf.addImage(dataUrl, 'PNG', (pageSize - width) / 2, (pageSize - height) / 2, width, height);
    }
    const { dataUrl, width, height } = images.shift();
    const pageSize = Math.max(width, height);
    const pdf = new jsPDF({ orientation: 'p', format: [pageSize, pageSize], unit: 'px', hotfixes: ['px_scaling'] });
    addImage(pdf, pageSize, dataUrl, width, height);
    images.forEach(e => {
      const { dataUrl, width, height } = e;
      pdf.addPage([pageSize, pageSize]);
      addImage(pdf, pageSize, dataUrl, width, height);
    });
    return pdf;
  }
  static buildWithDynamicPageSize(images) {
    const addImage = (pdf, dataUrl, width, height) => {
      pdf.addImage(dataUrl, 'PNG', 0, 0, width, height);
    }
    const { dataUrl, width, height } = images.shift();
    const portraitOrLandscape = width > height ? 'l' : 'p';
    const pdf = new jsPDF({ orientation: portraitOrLandscape, format: [width, height], unit: 'px', hotfixes: ['px_scaling'] });
    addImage(pdf, dataUrl, width, height);
    images.forEach(e => {
      const { dataUrl, width, height } = e;
      pdf.addPage([width, height]);
      addImage(pdf, dataUrl, width, height);
    });
    return pdf;
  }


}