import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import MoreInfo from '../siteComponents/MoreInfo';
import SetupTip from '../siteComponents/SetupTip';
import SiteSection from '../siteComponents/SiteSection';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const imageUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/iWishIDidntKnow.jpg';
const gameUrl = 'https://remote-game-image-assets.s3.amazonaws.com/games/iWishIDidntKnowGame.png';

export default function IWishIDidntKnow({ mobile = false }) {
  const [compact, setCompact] = React.useState(true);
  const { t } = useTranslation('site');

  function handleClick() {
    setCompact(!compact);
  }

  return (
    <SiteSection>
      <Asset src={imageUrl} onClick={handleClick} mobile={mobile || compact} />
      <StyleTopic onClick={handleClick}>{t("I Wish I Didn't Know")}</StyleTopic>
      <StyleParagraph>{t("Compete with your friends and family by guessing which gross fact is actually true. Just read off the answer choices and hear your audience groan! By the end, you’ll all be experts on all things you wish you didn’t know.")}</StyleParagraph>
      <MoreInfo open={!compact} onClick={handleClick} />
      <StyleParagraph open={!compact}>{t("Optionally award a bonus/penalty point for the first answer in.")}</StyleParagraph>
      <SetupTip open={!compact} tip={t('In Settings\n...Set the "Buttons" text to "A,B,C,D"\n...Select "Keep Score"\n...Select "Reader is assigned for each round"\n...Select "Reader\'s answer is correct"\nScore Settings\n...Select "Auto scoring on Reader\'s Answer"')} />
      <Asset right open={!compact} src={gameUrl} mobile={mobile} />
    </SiteSection>
  );
}
