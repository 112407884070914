// NLS-NLS(controller)
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ScoreIcon from '@mui/icons-material/DragIndicator';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../appSupport/Globals';
import ScoreOptions from '../fields/ScoreOptions';
import BoxR from './box/BoxR';


export default function ScoreTapInstructions(props) {
  const { game, style, onScoringButtonsChange, showScoringButtonsValue, open = true } = props;
  const { t } = useTranslation('controller');

  if (!Globals.isGameController(game)) return null;


  const scoreOptions = new ScoreOptions(game.scoreOptions);

  function handleScoringButtonClick(event) {
    const { checked } = event.target;
    onScoringButtonsChange(checked);
  }
  function renderCheckBoxForScoring() {
    return (
      <BoxR style={{ alignItems: 'center', ...style }}>
        <FormControlLabel
          control={<Checkbox checked={showScoringButtonsValue} onChange={handleScoringButtonClick} />}
          label={t('scoring-buttons')}
        />
      </BoxR>
    );
  }
  function renderTapForScoring() {
    return (
      <BoxR style={{ alignItems: 'center', ...style }}>
        <Typography>{t('tap-score-tool-for-scoring')}</Typography>
        <ScoreIcon />
      </BoxR>
    )
  }
  if (!open) return null; // ==> EARLY EXIT
  return scoreOptions.useScoreButtons ? renderCheckBoxForScoring() : renderTapForScoring();
}

ScoreTapInstructions.propTypes = {
  game: PropTypes.object,
  style: PropTypes.any,
};

ScoreTapInstructions.defaultProps = {
}