import React from 'react';
import { useTranslation } from 'react-i18next';
import { siteRoutesFull } from '../../routing/siteRoutes';
import SiteNav from './SiteNav';

export default function TipScanCard(props) {
  const { ...rest } = props;
  const { t } = useTranslation('site');

  return (
    <SiteNav target={siteRoutesFull.scanTips} {...rest}>
      {t('Here are some tips for scanning cards')}
    </SiteNav>
  );
}
