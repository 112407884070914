// NLS-NLS(controller)
import { Hidden, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableList from '../TableList';
import { moreSelectedTools, noneSelectedTools, oneSelectedTools } from './util';


export default function DeckTableList(props) {
  const { rows: rowsIn, isDecksLoading, selectedRows, onRowSelection, onAdd, onDelete, onEdit } = props;
  const { t } = useTranslation('controller');

  const headers = [
    { id: 'name', justify: 'left', disablePadding: false, canSort: true, label: t('deck-name') },
    { id: 'cardUsage', justify: 'center', disablePadding: false, canSort: false, label: t('unused-total') },
    { id: 'sortSequence', justify: 'left', disablePadding: false, canSort: true, label: t('sort-sequence') },
  ];

  const rows = rowsIn.map(m => {
    const total = m.cardKeys.length;
    const unused = total - m.nextCardIndex;
    const cardUsage = `${unused}/${total}`;
    return { ...m, cardUsage };
  })

  function loadingOrNoData() {
    return isDecksLoading ? renderLoading() : renderNoData();
  }

  function renderLoading() {
    return (
      <Typography key='loadingKey' align='center' >{t('loading-ellipse')}</Typography>
    );
  }
  function renderNoData() {
    return (
      <Typography key='noDataKey' align='center' >{t('no-decks-have-been-added')}</Typography>
    );
  }

  const handlers = { onAdd, onDelete, onEdit };
  const fullTableProps = {
    rows, headers, selectedRows, onRowSelection,
    title: t('card-decks'),
    // dense: true,
    // hidePaper: true,
    // disableFlexSizing: true,
    noDataRenderer: loadingOrNoData,
    oneSelectedTools: oneSelectedTools(handlers),
    moreSelectedTools: moreSelectedTools(handlers),
    noneSelectedTools: noneSelectedTools(handlers),
  };
  const mobileTableProps = { ...fullTableProps }

  return (
    <React.Fragment>
      <Hidden smDown>
        <TableList {...fullTableProps} />
      </Hidden>
      <Hidden smUp>
        <TableList {...mobileTableProps} />
      </Hidden>
    </React.Fragment>
  );
}
