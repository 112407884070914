import { Amplify } from "aws-amplify";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import awsExports from "./aws-exports";
import './index.css';
import * as serviceWorker from './serviceWorker';

Amplify.configure(awsExports);
// Amplify.Logger.LOG_LEVEL = "DEBUG";
// if (!CognitoRouter.first) {
//   CognitoRouter.first = true;
//   alert(`index.js.first: ${PathUtil.currentPathWithSearchAndError()}`);
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
