import { Hub } from "aws-amplify";
import Globals from "../appSupport/Globals";
import PathUtil from "../appSupport/PathUtil";
import TraceLog from "../appSupport/TraceLog";
import UserStore from "../appSupport/UserStore";
import EntryRequests from "../fields/EntryRequests";
import GameOptions from "../fields/GameOptions";
import GamePlay from "../fields/GamePlay";
import NavigationCode from "../fields/NavigationCode";
import RoundData from "../fields/RoundData";
import ScoreOptions from "../fields/ScoreOptions";
import TimerValues, { ANSWER_TIMER } from "../fields/TimerValues";
import AbstractStore from "./AbstractStore";
import GameOps, { HAND_STATUS_END, HAND_STATUS_PLAY } from "./GameOps";
import PendingPlayerStore from "./PendingPlayerStore";
import ResultOps from "./ResultOps";
import ScoreStore from "./ScoreStore";

export const GAME_CHANNEL = 'GAME_CHANNEL';

let INSTANCE;
export default class GameStore {
  static instance() {
    const id = PathUtil.getGameParam();
    const game = (INSTANCE && INSTANCE.getGameUnsafe()) ? INSTANCE.getGameUnsafe() : null;
    if ((!INSTANCE || !game || (game.id !== id)) && id) {
      if (INSTANCE) INSTANCE.release();
      INSTANCE = new GameStoreInst();
    }
    return INSTANCE;
  }
  static hasInstance() {
    return !!INSTANCE;
  }
  static isReady() {
    const game = INSTANCE ? INSTANCE.getGameUnsafe() : null;
    return INSTANCE ? (INSTANCE.isReady && game && (game.id === PathUtil.getGameParam())) : false;
  }
  static initialQueryCompletePromise() {
    return GameStore.instance().initialQueryCompletePromise();
  }
  static loadedPromise() {
    return GameStore.instance().loadedPromise();
  }
  static listen(callBack) {
    GameStore.instance().listen(callBack);
  }
  static stopListen(callBack) {
    if (INSTANCE) {
      INSTANCE.stopListen(callBack);
    }
  }
  static getGame() {
    const instance = GameStore.instance();
    return instance ? instance.getGame() : null;
  }
  static getGameUnsafe() {
    return INSTANCE ? INSTANCE.getGameUnsafe() : null;
  }

  static release() {
    if (INSTANCE) INSTANCE.release();
    INSTANCE = null;
  }

  static acceptEntryRequest(playerName) {
    return GameStore.instance().acceptEntryRequest(playerName);
  }
  static denyEntryRequest(playerName) {
    return GameStore.instance().denyEntryRequest(playerName);
  }
  static endPlay() {
    return GameStore.instance().endPlay();
  }
  static forceRoundEnd() {
    return GameStore.instance().forceRoundEnd();
  }
  static performBogusGameUpdate() {
    return GameStore.instance().performBogusGameUpdate();
  }
  static clearNavigationOverride(forController, forPlayer) {
    return GameStore.instance().clearNavigationOverride(forController, forPlayer);
  }
  static editScoreNow() {
    return GameStore.instance().editScoreNow();
  }
  static navigateNewRound() {
    return GameStore.instance().navigateNewRound();
  }
  static resetScoringForThisRound() {
    return GameStore.instance().resetScoringForThisRound();
  }
  static resetScoringForNextRound() {
    return GameStore.instance().resetScoringForNextRound();
  }
  static resumeFromScoreOnly() {
    return GameStore.instance().resumeFromScoreOnly();
  }
  static showScoreNow() {
    return GameStore.instance().showScoreNow();
  }
  static showScoreThenNewRound() {
    return GameStore.instance().showScoreThenNewRound();
  }
  static startPlay(pendingPlayers) {
    return GameStore.instance().startPlay(pendingPlayers);
  }
  static startNewRound() {
    return GameStore.instance().startNewRound();
  }
  static submitAnswer(answer, timerExpired) {
    return GameStore.instance().submitAnswer(answer, timerExpired);
  }
  static timerAddSeconds(whichTimer, seconds) {
    return GameStore.instance().timerAddSeconds(whichTimer, seconds);
  }
  static timerExpire(whichTimer) {
    return GameStore.instance().timerExpire(whichTimer);
  }
  static timerKill(whichTimer) {
    return GameStore.instance().timerKill(whichTimer);
  }
  static timerReady(whichTimer, seconds) {
    return GameStore.instance().timerReady(whichTimer, seconds);
  }
  static timerStart(whichTimer) {
    return GameStore.instance().timerStart(whichTimer);
  }
  static timerPause(whichTimer) {
    return GameStore.instance().timerPause(whichTimer);
  }
  static updateDeckOptions(deckOptions) {
    return GameStore.instance().updateDeckOptions(deckOptions);
  }
  static updateFields(fieldsObject) {
    return GameStore.instance().updateFields(fieldsObject);
  }
  static updateGamePlay(game, gamePlay) {
    return GameStore.instance().updateGamePlay(game, gamePlay);
  }
  static updateGamePlayDefaults(game, gamePlay) {
    return GameStore.instance().updateGamePlayDefaults(game, gamePlay);
  }
  static updatePublishedTo(publishedTo) {
    return GameStore.instance().updatePublishedTo(publishedTo);
  }
  static updateRoundDataDefaults(game, roundData) {
    return GameStore.instance().updateRoundDataDefaults(game, roundData);
  }
  static updateScoreOptions(scoreOptions) {
    return GameStore.instance().updateScoreOptions(scoreOptions);
  }
}

class GameStoreInst extends AbstractStore {
  constructor() {
    super('GameStore', GAME_CHANNEL);
    this.isReady = false;
    this.gameId = PathUtil.getGameParam();
    this.defaultGame = { id: this.gameId, notLoaded: true };
    this.loadStore(null, GameOps.onGameUpdate);
    this.initialQueryCompletePromise()
      // The game creator (controller) by default sees these errors (and in dev mode).
      .then(r => {
        this.isReady = true;
        Globals.setAutoReloadErrors(!Globals.isGameController() && !Globals.isDevMode())
      });
  }
  buildValuesForInit() {
    // Subclasses should override this with values significant in the initialization process.
    return `User: ${UserStore.getUserName()}  Id: ${PathUtil.getGameParam()}  subscriptionOwner: ${Globals.getDataOwner()}`;
  }
  queryRecords() {
    const onRecord = record => record ? [record] : [];
    const isUnauthorized = (gameId, packet) => {
      if (gameId && packet && packet.errors && packet.errors[0]) {
        const error = packet.errors[0];
        return (error.errorType === 'Unauthorized');
      }
      return false;
    }
    // onFirstFail will check to see if this is an authority problem.  If so it will try once
    // to login with the correct meta data and try request again.
    const onFirstFail = packet => {
      const gameId = PathUtil.getGameParam();
      if (isUnauthorized(gameId, packet)) {
        // Sometimes we loose the meta data in the token and need to re-login.
        TraceLog.addTrace(`User NOT AUTHORIZED to query game, sign in with game id: ${gameId}`);
        const reportError = true; // GameOps will report any unexpected error
        return UserStore.signInWithGameId(gameId)
          .then(() => GameOps.getGameWithId(this.gameId, reportError))
          .then(onRecord)
          .catch(() => onRecord(null));
      }
      return [];
    };
    const reportError = false; // We can expect a security error that we will handle
    return GameOps.getGameWithId(this.gameId, reportError)
      .then(onRecord, onFirstFail);
  }
  performUpdate(keyFields, updates) {
    return GameOps.performUpdate(keyFields, updates);
  }
  recKey(record) {
    return record.id;
  }
  dispatchActivity() {
    Hub.dispatch(this.channel, { game: this.getGame() });
  }

  getGame() {
    return this.getRecords().length ? this.getRecords()[0] : this.defaultGame;
  }
  getGameUnsafe() {
    const recs = this.getRecords(null, true);
    return recs.length ? recs[0] : this.defaultGame;
  }

  acceptEntryRequest(playerName) {
    const game = this.getGame();
    const er = EntryRequests.entryRequestsFor(game) || new EntryRequests();
    const theRequest = er.requests.find(f => f.playerName === playerName);

    // Update/add the player claim.
    const player = PendingPlayerStore.getPlayer(playerName);
    const playerNumber = player ? player.playerNumber : PendingPlayerStore.getPlayerCount() + 1;
    const updates = { entryKey: theRequest.entryKey };
    const keyFields = { id: game.id, playerName }
    const isRoundOverLocal = Globals.isRoundOver(); // Before PendingPlayer update
    if (player) {
      PendingPlayerStore.updatePlayer(keyFields, updates);
    } else {
      updates.playerName = playerName;
      updates.playerNumber = playerNumber;
      PendingPlayerStore.createPlayer({ ...keyFields, ...updates });
    }

    // Remove the request from EntryRequests...
    const newRequests = er.requests.filter(f => f.playerName !== playerName);
    er.requests = newRequests;

    const delayed = f => {
      this.updateJSONField({}, 'entryRequests', er, true);
      // Force the round over if adding a new player after the round has ended.
      if (isRoundOverLocal && !RoundData.fromGame(game).isRoundForcedEnd) {
        this.forceRoundEnd();
      }
    }
    // Delay this as the update subscription must come in AFTER the PendingPlayer, and the order
    // is not reliable.
    setTimeout(delayed, 2000);
  }

  denyEntryRequest(playerName) {
    const game = this.getGame();
    const er = EntryRequests.entryRequestsFor(game) || new EntryRequests();
    const newRequests = er.requests.filter(f => f.playerName !== playerName);
    er.requests = newRequests;
    this.updateJSONField({}, 'entryRequests', er, true);
  }

  endPlay() {
    const game = this.getGame();
    const { id } = game;
    const updates = { handStatus: HAND_STATUS_END };
    this.put({ id }, updates);
  }

  resetScoringForThisRound() {
    this.resetScoring(0);
    // Need to reset the totals as they are currently wrong
    // Also, because the dispatch for the game update happens async, we need to reset the totals async also
    // or the reset will not recognize the game update.
    setTimeout(f => {
      const game = this.getGame();
      ScoreStore.resetTotals(game.currentHand);
    });
  }
  resetScoringForNextRound() {
    this.resetScoring(1);
  }
  resetScoring(delta) {
    const game = this.getGame();
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    const { id } = game;
    const hands = [...game.scoringStartHandsArray];
    hands.push(game.currentHand + delta);
    const updates = { scoringStartHandsArray: hands };
    this.put({ id }, updates);
    if (scoreOptions.scoreOnly) {
      this.startNewRound();
    }
  }

  performBogusGameUpdate() { // This is a get out of jail card when a control needs a deep refresh
    const game = this.getGame();   // It should be eliminated.
    const { id } = game;
    const updates = {};
    this.put({ id }, updates);
  }
  clearNavigationOverride(forController = false, forPlayer = false) {
    const game = this.getGame();
    const { id } = game;
    const navigationCode = new NavigationCode(game.navigationCode);
    if (forController) navigationCode.clearControllerOverride();
    if (forPlayer) navigationCode.clearPlayerOverride();
    const updates = { navigationCode: navigationCode.asJSON() };
    this.put({ id }, updates);
  }
  editScoreNow() {
    const game = this.getGame();
    const { id } = game;
    const navigationCode = new NavigationCode(game.navigationCode).editScore();
    const updates = { navigationCode: navigationCode.asJSON() };
    this.put({ id }, updates);
  }
  forceRoundEnd() {
    const newRoundData = RoundData.fromGame(this.getGame());
    newRoundData.isRoundForcedEnd = true;
    this.updateRoundData(this.getGame(), newRoundData);
  }
  resumeFromScoreOnly() {
    const game = this.getGame();
    const { id } = game;
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    scoreOptions.scoreOnly = false;
    const updates = {
      scoreOptions: scoreOptions.asJSON()
    };
    this.put({ id }, updates);
    this.navigateNewRound();
  }
  showScoreNow() {
    const game = this.getGame();
    const { id } = game;
    const navigationCode = new NavigationCode(game.navigationCode).showScore();
    const updates = { navigationCode: navigationCode.asJSON() };
    this.put({ id }, updates);
  }
  showScoreThenNewRound() {
    const game = this.getGame();
    const { id } = game;
    const navigationCode = new NavigationCode(game.navigationCode).showScoreThenNewRound();
    const updates = { navigationCode: navigationCode.asJSON() };
    this.put({ id }, updates);
  }

  startPlay() {
    // Assign each player a spot in the game.
    const game = this.getGame();
    PendingPlayerStore.initForStart();
    const { id } = game;
    const updates = { handStatus: HAND_STATUS_PLAY, navigationCode: new NavigationCode(game.navigationCode).newRound().asJSON() };
    this.put({ id }, updates);

    const scoreOptions = new ScoreOptions(game.scoreOptions);
    if (scoreOptions.scoreOnly) {
      this.startNewRound();
    }
  }

  navigateNewRound() {
    const game = this.getGame();
    const { id } = game;
    const navigationCode = new NavigationCode(game.navigationCode).newRound();
    const updates = { navigationCode: navigationCode.asJSON() };
    this.put({ id }, updates);
  }

  startNewRound() {
    const newQuestionTimerValues = () => {
      const startTimerNow = gameOptions.timeLimit && !gameOptions.manualTimerStart;
      const timerValues = new TimerValues();
      timerValues.readyWith(parseInt(gameOptions.timeLimit));
      if (startTimerNow && !scoreOptions.scoreOnly) {
        timerValues.setToRunning();
      }
      return timerValues;
    }
    const game = this.getGame();
    const { id } = game;
    const gameOptions = new GameOptions(game.gameOptions);
    const gamePlayDefaults = GamePlay.fromString(game.gamePlayDefaults);
    const roundDataDefaults = RoundData.fromString(game.roundDataDefaults);
    const scoreOptions = new ScoreOptions(game.scoreOptions);
    const questionTimerValues = newQuestionTimerValues();
    let navigationCode = new NavigationCode().playRound();
    if (scoreOptions.scoreOnly) {
      navigationCode = new NavigationCode().showScore();
    }
    const answerTimerValues = new TimerValues();
    answerTimerValues.readyWith(15);
    const properties = {
      currentHand: game.currentHand + 1, publishedTo: null,
      navigationCode: navigationCode.asJSON(),
      questionTimerValues: questionTimerValues.asJSON(),
      answerTimerValues: answerTimerValues.asJSON(),
      gamePlay: gamePlayDefaults.asJSON(),
    };
    const newRoundData = game.roundData ? game.roundData : [];
    if (newRoundData.length < properties.currentHand) {
      newRoundData.push(roundDataDefaults.asJSON());
    }
    properties.roundData = newRoundData;

    this.put({ id }, properties);
  }

  submitAnswer(answer, timerExpired) {
    const game = this.getGame();
    const { id } = game;
    // Write the answer and update the game to reflect this player has played
    ResultOps.createPlayerResult(game.id, game.currentHand, Globals.getPlayerName(), answer);
    if (timerExpired && Globals.isGameController()) {
      const timerValues = new TimerValues(game.questionTimerValues);
      timerValues.setToExpired();
      const updates = { questionTimerValues: timerValues.asJSON() };
      this.put({ id }, updates);
    }
  }

  timerValuesFieldName(whichTimer) {
    return whichTimer === ANSWER_TIMER ? 'answerTimerValues' : 'questionTimerValues';
  }
  timerExpire(whichTimer) {
    const game = this.getGame();
    const { id } = game;
    const timerValues = TimerValues.newTimerValues(whichTimer, game);
    timerValues.setToExpired();
    const updates = { [this.timerValuesFieldName(whichTimer)]: timerValues.asJSON() };
    this.put({ id }, updates);
  }
  timerKill(whichTimer) {
    const game = this.getGame();
    const oldTimerValues = TimerValues.newTimerValues(whichTimer, game);
    if (!oldTimerValues.isDefaultValues()) {
      const { id } = game;
      const timerValues = new TimerValues();
      const updates = { [this.timerValuesFieldName(whichTimer)]: timerValues.asJSON() };
      this.put({ id }, updates);
    }
  }
  timerReady(whichTimer, seconds) {
    const timerValues = new TimerValues();
    timerValues.readyWith(seconds);
    this.updateFields({ [this.timerValuesFieldName(whichTimer)]: timerValues });
  }
  timerStart(whichTimer) {
    const game = this.getGame();
    const timerValues = TimerValues.newTimerValues(whichTimer, game);
    timerValues.startTime = new Date().toISOString();
    timerValues.setToRunning();
    this.updateFields({ [this.timerValuesFieldName(whichTimer)]: timerValues });
  }
  timerAddSeconds(whichTimer, seconds) {
    const game = this.getGame();
    const timerValues = TimerValues.newTimerValues(whichTimer, game);
    timerValues.remainingTime = timerValues.getRemainingSeconds() + seconds;
    timerValues.totalTime += seconds;
    timerValues.startTime = new Date().toISOString();
    this.updateFields({ [this.timerValuesFieldName(whichTimer)]: timerValues });
  }
  timerPause(whichTimer) {
    const game = this.getGame();
    const timerValues = TimerValues.newTimerValues(whichTimer, game);
    timerValues.remainingTime = timerValues.getRemainingSeconds();
    timerValues.setToPaused();
    this.updateFields({ [this.timerValuesFieldName(whichTimer)]: timerValues });
  }

  updateDeckOptions(deckOptions) {
    this.updateJSONField({}, 'deckOptions', deckOptions, true);
  }
  updateFields({ gameOptions, gamePlay, publishedTo, scoreOptions, deckOptions,
    roundDataDefaults, gamePlayDefaults, answerTimerValues, questionTimerValues }) {
    const updates = {};
    this.updateJSONField(updates, 'gameOptions', gameOptions);
    this.updateJSONField(updates, 'gamePlay', gamePlay);
    this.updateJSONField(updates, 'publishedTo', publishedTo);
    this.updateJSONField(updates, 'scoreOptions', scoreOptions);
    this.updateJSONField(updates, 'deckOptions', deckOptions);
    this.updateJSONField(updates, 'answerTimerValues', answerTimerValues);
    this.updateJSONField(updates, 'questionTimerValues', questionTimerValues);
    this.updateJSONField(updates, 'roundDataDefaults', roundDataDefaults);
    this.updateJSONField(updates, 'gamePlayDefaults', gamePlayDefaults, true);
  }
  updateGameOptions(gameOptions) {
    this.updateJSONField({}, 'gameOptions', gameOptions, true);
  }
  buildRoundDataArray(game, roundData) {
    const roundDataArray = game.roundData ? [...game.roundData] : [];
    const workingHand = game.currentHand;
    if (roundDataArray.length < workingHand) {
      roundDataArray.push(roundData.asJSON());
    } else {
      roundDataArray[workingHand - 1] = roundData.asJSON();
    }
    return roundDataArray;
  }
  updateGamePlay(game, gamePlay) {
    this.put({ id: game.id }, { gamePlay: gamePlay.asJSON() });
  }
  updateGamePlayDefaults(game, gamePlay) {
    const gamePlayDefaults = gamePlay.asJSON();
    this.put({ id: game.id }, { gamePlayDefaults });
  }
  updateRoundData(game, roundData) {
    this.put({ id: game.id }, { roundData: this.buildRoundDataArray(game, roundData) });
  }
  updateRoundDataDefaults(game, roundData) {
    const roundDataDefaults = roundData.asJSON();
    this.put({ id: game.id }, { roundDataDefaults });
  }
  updatePublishedTo(publishedTo) {
    this.updateJSONField({}, 'publishedTo', publishedTo, true);
  }
  updateScoreOptions(scoreOptions) {
    this.updateJSONField({}, 'scoreOptions', scoreOptions, true);
  }
  updateJSONField(updatesIn, name, field, performNow = false) {
    const updates = updatesIn;
    if (Array.isArray(field)) {
      updates[name] = field.map(m => m.asJSON());
    } else if (field) {
      updates[name] = field.asJSON();
    }
    if (performNow) {
      const game = this.getGame();
      const { id } = game;
      this.put({ id }, updates);
    }
  }
}

