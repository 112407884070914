import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpTool from './HelpTool';

export default function CardDecksHelp(props) {
  const { t } = useTranslation('help');

  return (
    <HelpTool helpText={t("the-card-decks-view-allows-you")} {...props} />
  );
}
