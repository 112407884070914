import { Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import BoxC from '../../components/box/BoxC';
import PlainFooter from '../../components/header/PlainFooter';
import PlainHeader from '../../components/header/PlainHeader';
import PageContainer from '../../components/PageContainer';
import PageLayout from '../../components/PageLayout';
import TabbedPage from '../../page/TabbedPage';
import SiteHeader from './SiteHeader';

const StyledBoxC = styled(BoxC)({
  alignItems: 'center',
});

export default function SitePage(props) {
  const { title, tabTitle: tabTitleIn, menuActionItems, component, mobileComponent } = props;
  const tabTitle = tabTitleIn || title;

  return (
    <TabbedPage tabTitle={tabTitle}>
      <StyledBoxC>
        <PageContainer sx={{ maxWidth: 1000 }}>
          <PlainHeader title={title} menuActionItems={menuActionItems} />
          <PageLayout>
            <SiteHeader />

            <Hidden smDown>
              <div>
                {component}
              </div>
            </Hidden>

            <Hidden smUp>
              <div>
                {mobileComponent}
              </div>
            </Hidden>

          </PageLayout>
          <PlainFooter />
        </PageContainer>
      </StyledBoxC>
    </TabbedPage>
  );
}
