import React from 'react';
import BoxCG from '../../components/box/BoxCG';
import ControllerStatusLine from '../../components/ControllerStatusLine';
import DividerH from '../../components/DividerH';
import GameControls from '../../components/GameControls';
import PlayControls from '../../components/PlayControls';
import PlayerStatusLine, { STATUS_SELECT_ANSWER, STATUS_WAITING_CONTROLLER } from '../../components/PlayerStatusLine';
import PlayTimer from '../../components/PlayTimer';
import PublishControls from '../../components/PublishControls';
import GameStore from '../../db/GameStore';
import { useFreshResults } from '../../db/ResultStore';
import GamePlay from '../../fields/GamePlay';
import TimerValues, { ANSWER_TIMER } from '../../fields/TimerValues';

export default function ResultsController(props) {
  const { game } = props;
  const lastAnswerTimerActive = React.useRef(null);
  const playerResults = useFreshResults();
  const gamePlay = GamePlay.fromGame(game);
  const answerTimerValues = TimerValues.newTimerValues(ANSWER_TIMER, game);
  const answerTimerHasTime = (answerTimerValues).hasTime();
  const answerTimerReady = answerTimerValues.isReady();

  React.useEffect(() => { // Either ready or remove the timer
    if (lastAnswerTimerActive.current !== gamePlay.isAnswerTimerActive) {
      lastAnswerTimerActive.current = gamePlay.isAnswerTimerActive;
      if (gamePlay.isAnswerTimerActive) {
        if (!answerTimerReady || !answerTimerHasTime) GameStore.timerReady(ANSWER_TIMER, 15); // Ready for a 15 second start
      } else {
        GameStore.timerKill(ANSWER_TIMER); // Remove timer from UI
      }
    }
  }, [gamePlay.isAnswerTimerActive, answerTimerHasTime, answerTimerReady]);

  function handleTimerExpired() {
    GameStore.timerReady(ANSWER_TIMER, 15); // Ready for a 15 second start
  }
  const status = gamePlay.areAnswerSelectionsActive ? STATUS_SELECT_ANSWER : STATUS_WAITING_CONTROLLER;
  return (
    <BoxCG >
      <PlayerStatusLine game={game} status={status} />
      <ControllerStatusLine />
      <DividerH />
      <PlayTimer game={game} onTimerExpired={handleTimerExpired} whichTimer={ANSWER_TIMER} />
      <PlayControls whichTimer={ANSWER_TIMER} />
      <DividerH />
      <PublishControls playerResults={playerResults} />
      <GameControls game={game} />
    </BoxCG>
  );
}
