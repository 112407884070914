import React from 'react';
import { useTranslation } from 'react-i18next';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';
import TipScanCard from '../siteComponents/TipScanCard';

export default function DescFaqWhyAccount() {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <StyleTopic>{t('Why would I create an account?')}</StyleTopic>
      <StyleParagraph><b>{t('Scan your game cards')}</b></StyleParagraph>
      <StyleParagraph indent={2}>{t('You need an account if you want to invest the effort to scan your game cards.')}</StyleParagraph>
      <StyleParagraph indent={2}>{t('Many games can be played where one person, the game controller, has all of the cards. But some games require each player to have the card. BuenoFun will allow you to scan your game cards. Then you can electronically deal a card to a reader at play time.')}</StyleParagraph>
      <StyleParagraph indent={2}>{t('Furthermore, once game cards are scanned, if you have your phone with you, you have the game with you. You can be more spontaneous in your game play.')}</StyleParagraph>
      <TipScanCard indent={2} />
      <StyleParagraph><b>{t('Support')}</b></StyleParagraph>
      <StyleParagraph indent={2}>{t('If you want to make a support request you need an account. Accounts are free.')}</StyleParagraph>
    </React.Fragment>
  );
}
