// NLS-NLS(support)

class NlsStore {
  constructor() {
    this.loginValues = {};
    this.controllerValues = {};
    this.playerValues = {};
    this.supportValues = {};
  }
  getValueController(key) {
    return this.controllerValues[key];
  }
  setValueController(key, value) {
    this.controllerValues[key] = value;
  }
  getValueLogin(key) {
    return this.loginValues[key];
  }
  setValueLogin(key, value) {
    this.loginValues[key] = value;
  }
  getValuePlayer(key) {
    return this.playerValues[key];
  }
  setValuePlayer(key, value) {
    this.playerValues[key] = value;
  }
  getValueSupport(key) {
    return this.supportValues[key];
  }
  setValueSupport(key, value) {
    this.supportValues[key] = value;
  }
}
export default new NlsStore();