import React from 'react';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';
import StyleParagraph from '../siteComponents/StyleParagraph';
import StyleTopic from '../siteComponents/StyleTopic';

const gameLanding = 'https://remote-game-image-assets.s3.amazonaws.com/site/gameLanding.png';
const scanTip = 'https://remote-game-image-assets.s3.amazonaws.com/site/scanTip.jpg';

export default function ScanTipsContent(props) {
  const { t } = useTranslation('site');
  const { mobile = false } = props;

  return (
    <div>
      <StyleTopic>{t('Use an App to scan')}</StyleTopic>
      <StyleParagraph>{t("There are many free apps that you can use to scan your game cards. They automatically crop and straighten your card and yield a very professional result.")}</StyleParagraph>
      <StyleParagraph>{t("Search your preferred store with the term 'Document Scanner'.")}</StyleParagraph>
      <StyleParagraph>
        <a href='https://play.google.com/store/apps/details?id=com.indymobileapp.document.scanner'>ClearScanner</a>
        {t(" is an Android app that works really well. It will let you scan all of your cards and then save them all at once.")}
      </StyleParagraph>
      <StyleTopic>{t('Scan to PDF')}</StyleTopic>
      <StyleParagraph>{t("BuenoFun works much better with PDF files than image files.")}</StyleParagraph>
      <StyleTopic>{t('Setup your scan area')}</StyleTopic>
      <StyleParagraph>{t("You will be scanning a lot of cards so get things setup to minimize mistakes and stream line the process.")}</StyleParagraph>
      <Asset left src={scanTip} mobile={mobile} />
      <StyleParagraph>{t("Place a solid colored towel on the table draped over a box. You can then lean cards against the box. The towel is a non-slip surface and yields great contrast for the scanning app to find the edges of the card.")}</StyleParagraph>
      <StyleParagraph>{t("Secure your phone or tablet such that you are not holding it. You will then be able to place the cards in the exact same position for each scan.")}</StyleParagraph>
      <StyleParagraph>{t("Don't get hung up on perfection. If your app is not finding the edge of the card, but it has all the information... that's good enough. A different app might find the edges better.")}</StyleParagraph>
      <StyleTopic>{t('Scan your cards')}</StyleTopic>
      <StyleParagraph>{t("If cards have content on the front and the back, you will need to create 2 page PDF documents with each side of the card. The app will make this easy.")}</StyleParagraph>
      <StyleParagraph>{t("Even if there is only one side to the card, create a PDF. BuenoFun will let you multi-select PDF files when you are importing the cards.")}</StyleParagraph>
      <StyleTopic>{t('Import your cards')}</StyleTopic>
      <Asset right src={gameLanding} mobile={mobile} />
      <StyleParagraph>{t("Back on the game landing page use the 'Card Decks' button to manage your card decks. You will have to login in order manage decks because we need to save the cards under your ID. Creating an account to login is free.")}</StyleParagraph>
      <StyleParagraph>{t("Use the '+' button in the top right of the header to create a new deck. Name your deck and then add your scanned cards.")}</StyleParagraph>
    </div>
  );
}
