/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRg0GameFinder = /* GraphQL */ `
  query GetRg0GameFinder($id: ID!) {
    getRg0GameFinder(id: $id) {
      id
      gameId
      createdAt
      owner
      updatedAt
      version
    }
  }
`;
export const listRg0GameFinders = /* GraphQL */ `
  query ListRg0GameFinders(
    $filter: ModelRg0GameFinderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRg0GameFinders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gameId
        createdAt
        owner
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getRg0Game = /* GraphQL */ `
  query GetRg0Game($id: ID!) {
    getRg0Game(id: $id) {
      id
      entryRequests
      currentHand
      handStatus
      navigationCode
      scoringStartHandsArray
      controllerName
      scoreKeeperName
      gameOptions
      scoreOptions
      deckOptions
      gamePlay
      gamePlayDefaults
      roundData
      roundDataDefaults
      questionTimerValues
      answerTimerValues
      publishedTo
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listRg0Games = /* GraphQL */ `
  query ListRg0Games(
    $filter: ModelRg0GameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRg0Games(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entryRequests
        currentHand
        handStatus
        navigationCode
        scoringStartHandsArray
        controllerName
        scoreKeeperName
        gameOptions
        scoreOptions
        deckOptions
        gamePlay
        gamePlayDefaults
        roundData
        roundDataDefaults
        questionTimerValues
        answerTimerValues
        publishedTo
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRg0PlayerResult = /* GraphQL */ `
  query GetRg0PlayerResult(
    $id: ID!
    $handString: String!
    $createdAt: AWSDateTime!
    $playerName: String!
  ) {
    getRg0PlayerResult(
      id: $id
      handString: $handString
      createdAt: $createdAt
      playerName: $playerName
    ) {
      id
      hand
      handString
      playerName
      createdAt
      answer
      annotations
      owner
      updatedAt
      version
    }
  }
`;
export const listRg0PlayerResults = /* GraphQL */ `
  query ListRg0PlayerResults(
    $id: ID
    $handStringCreatedAtPlayerName: ModelRg0PlayerResultPrimaryCompositeKeyConditionInput
    $filter: ModelRg0PlayerResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0PlayerResults(
      id: $id
      handStringCreatedAtPlayerName: $handStringCreatedAtPlayerName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hand
        handString
        playerName
        createdAt
        answer
        annotations
        owner
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getRg0PendingPlayer = /* GraphQL */ `
  query GetRg0PendingPlayer($id: ID!, $playerName: String!) {
    getRg0PendingPlayer(id: $id, playerName: $playerName) {
      id
      playerName
      entryKey
      playerNumber
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listRg0PendingPlayers = /* GraphQL */ `
  query ListRg0PendingPlayers(
    $id: ID
    $playerName: ModelStringKeyConditionInput
    $filter: ModelRg0PendingPlayerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0PendingPlayers(
      id: $id
      playerName: $playerName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        playerName
        entryKey
        playerNumber
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRg0Score = /* GraphQL */ `
  query GetRg0Score($id: ID!, $handString: String!, $playerName: String!) {
    getRg0Score(id: $id, handString: $handString, playerName: $playerName) {
      id
      hand
      handString
      playerName
      bid
      handScore
      totalScore
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listRg0Scores = /* GraphQL */ `
  query ListRg0Scores(
    $id: ID
    $handStringPlayerName: ModelRg0ScorePrimaryCompositeKeyConditionInput
    $filter: ModelRg0ScoreFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0Scores(
      id: $id
      handStringPlayerName: $handStringPlayerName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hand
        handString
        playerName
        bid
        handScore
        totalScore
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRg0Deck = /* GraphQL */ `
  query GetRg0Deck($id: ID!) {
    getRg0Deck(id: $id) {
      id
      sortSequence
      name
      activeGames
      nextCardIndex
      cardKeys
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRg0Decks = /* GraphQL */ `
  query ListRg0Decks(
    $id: ID
    $filter: ModelRg0DeckFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0Decks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sortSequence
        name
        activeGames
        nextCardIndex
        cardKeys
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRg0Card = /* GraphQL */ `
  query GetRg0Card(
    $id: ID!
    $handString: String!
    $playerName: String!
    $sequence: Int!
  ) {
    getRg0Card(
      id: $id
      handString: $handString
      playerName: $playerName
      sequence: $sequence
    ) {
      id
      hand
      handString
      playerName
      sequence
      deckId
      cardKey
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listRg0Cards = /* GraphQL */ `
  query ListRg0Cards(
    $id: ID
    $handStringPlayerNameSequence: ModelRg0CardPrimaryCompositeKeyConditionInput
    $filter: ModelRg0CardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0Cards(
      id: $id
      handStringPlayerNameSequence: $handStringPlayerNameSequence
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hand
        handString
        playerName
        sequence
        deckId
        cardKey
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRg0Profile = /* GraphQL */ `
  query GetRg0Profile($userName: String!) {
    getRg0Profile(userName: $userName) {
      userName
      lastTeams
      playerNames
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRg0Profiles = /* GraphQL */ `
  query ListRg0Profiles(
    $userName: String
    $filter: ModelRg0ProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0Profiles(
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userName
        lastTeams
        playerNames
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRg0SupportRequest = /* GraphQL */ `
  query GetRg0SupportRequest($id: ID!) {
    getRg0SupportRequest(id: $id) {
      id
      createdAt
      commonOwner
      originatingUsername
      requestedByEmail
      languageCode
      platform
      requestType
      status
      prevStatus
      payload
      updatedAt
    }
  }
`;
export const listRg0SupportRequests = /* GraphQL */ `
  query ListRg0SupportRequests(
    $id: ID
    $filter: ModelRg0SupportRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRg0SupportRequests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        commonOwner
        originatingUsername
        requestedByEmail
        languageCode
        platform
        requestType
        status
        prevStatus
        payload
        updatedAt
      }
      nextToken
    }
  }
`;
