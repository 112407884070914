import { styled } from '@mui/material/styles';
import React from 'react';

const Root = styled('div')({
  clear: 'both',
});

export default function Clear(props) {
  const { children, ...rest } = props;
  return (
    <Root {...rest}>
      {children}
    </Root>
  );
}
