// NLS-NLS(controller)
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Globals from '../../appSupport/Globals';
import GameStore from '../../db/GameStore';
import GameOptions from '../../fields/GameOptions';

const classes = {
  root: 'EditScoreButton-root'
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: theme.palette.secondary.contrastText,
  }
}));


export default function EditScoreButton(props) {

  const { game: gameIn, sx: sxIn } = props;
  const game = gameIn || GameStore.getGame();
  const isScoreKeeper = Globals.isScoreKeeper(game);
  const gameOptions = new GameOptions(game.gameOptions);
  const { t } = useTranslation('controller');

  const handleEditScores = () => {
    GameStore.editScoreNow();
  }

  if (!isScoreKeeper || !gameOptions.keepScore) return null; // ==> EARLY EXIT
  return (
    <StyledButton className={classes.root} sx={sxIn} variant="contained" onClick={handleEditScores} disabled={!game.currentHand} >
      {t('edit-scores')}
    </StyledButton>
  );
}
