import Globals from "./Globals";

export default class ShareUtil {
  static sendEmail(to, subject, body) {
    window.open(`mailto:${to}?subject=${subject}&body=${body}`);
  }
  static sendSms(to, body) {
    const separator = Globals.isIphoneOrIpad() ? '&' : '?';  // ios 6 and below should use ';'
    window.open(`sms:${to}${separator}body=${body}`);
  }

  static fallbackCopyTextToClipboard(text, successFn, failFn) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      if (successful) {
        if (successFn) successFn(text);
      } else {
        if (failFn) failFn({}, text);
      }
    } catch (err) {
      if (failFn) failFn(err, text);
    }
  
    document.body.removeChild(textArea);
  }
  static copyTextToClipboard(text, successFn, failFn) {
    if (!navigator.clipboard) {
      ShareUtil.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      if (successFn) successFn(text);
    }, function(err) {
      if (failFn) failFn(err, text);
    });
  }
}