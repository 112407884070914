export default class SREnhancementRequest {
  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.enhancementDescription = input ? input.enhancementDescription : null;
    this.dialog = input ? input.dialog : [];
  }
  asJSON() {
    return JSON.stringify(this);
  }
}

