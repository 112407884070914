import { Hub } from 'aws-amplify';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import ChangePasswordPrompt from '../prompt/ChangePasswordPrompt';
import DebugPrompt from '../prompt/DebugPrompt';
import Faq from '../site/page/Faq';
import Flow from '../site/page/Flow';
import Games from '../site/page/Games';
import ScanTips from '../site/page/ScanTips';
import SetupTips from '../site/page/SetupTips';
import { siteRoutes } from './siteRoutes';

export const LOG_CHANNEL = 'log_channel';
export const PASSWORD_CHANNEL = 'password_channel';

export default function SiteRouter() {
  const [logPromptOpen, setLogPromptOpen] = React.useState(false);
  const [changePasswordPromptOpen, setChangePasswordPromptOpen] = React.useState(false);

  React.useEffect(() => {
    const cb1 = () => setLogPromptOpen(true);
    Hub.listen(LOG_CHANNEL, cb1);
    const cb3 = () => setChangePasswordPromptOpen(true);
    Hub.listen(PASSWORD_CHANNEL, cb3);
    return () => {
      Hub.remove(LOG_CHANNEL, cb1);
      Hub.remove(PASSWORD_CHANNEL, cb3);
    }
  }, []);

  function handleDebugPromptClose() {
    setLogPromptOpen(false);
  }
  function handleChangePasswordPromptClose() {
    setChangePasswordPromptOpen(false);
  }

  return (
    <PageContainer>
      <DebugPrompt open={logPromptOpen} onCancel={handleDebugPromptClose} />
      <ChangePasswordPrompt open={changePasswordPromptOpen} onClose={handleChangePasswordPromptClose} />
      <Routes>
        <Route path={siteRoutes.faq} element={<Faq />} />
        <Route path={siteRoutes.games} element={<Games />} />
        <Route path={siteRoutes.instructions} element={<Flow />} />
        <Route path={siteRoutes.scanTips} element={<ScanTips />} />
        <Route path={siteRoutes.setupTips} element={<SetupTips />} />
        <Route path={'*'} element={<Faq />} />
      </Routes>
    </PageContainer>
  );
}
