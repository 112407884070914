import React from 'react';
import StyleTopic from '../siteComponents/StyleTopic';
import StyleParagraph from '../siteComponents/StyleParagraph';
import { useTranslation } from 'react-i18next';
import Asset from '../siteComponents/Asset';

const freeImage = 'https://remote-game-image-assets.s3.amazonaws.com/site/free.png';
export default function DescFaqWhatDoesItCost({ mobile = false }) {
  const { t } = useTranslation('site');

  return (
    <React.Fragment>
      <Asset src={freeImage} mobile={mobile} />
      <StyleTopic>{t('What does it cost?')}</StyleTopic>
      <StyleParagraph>{t("It's Free with a capital F. This software was developed during the 2020 Covid Pandemic to get some quality time with family during a life shutdown. Our family has met up to play a game every week for over a year. We use this software most of the time. We are making the software generally available in hopes that others can enjoy it also.")}</StyleParagraph>
    </React.Fragment>
  );
}
