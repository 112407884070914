export const supportEmail = 'buenoFunGames@gmail.com';
export const defaultUser = 'gamenight@thejordans.org';
export const defaultPassword = 'jskakcnrnJRkfdKD';
export const productSupporters = ['tom@thejordans.org'];

export const apiErrorChannel = 'remoteGameErrorChannel';
export const gameAbortChannel = 'remoteGameAbortChannel';
export const storeResetChannel = 'storeResetChannel';
export const userChannel = 'userChannel';
export const nlsChannel = 'nlsChannel';
export const supportChannel = 'supportChannel';
export const confirmationChannel = 'confirmationChannel';
export const navigateChannel = 'navigateChannel';
export const globalUiChannel = 'globalUiChannel';

export const specialUserAll = '*ALL';
export const specialUserNone = '*NONE';
export const specialUserDealer = '*READER';

export const youTubeOverview = 'https://youtu.be/DUgLPo1Os7w';
export const youTubeFeatures = 'https://youtu.be/22H8WhZHY3U';
