// NLS-NLS(support)
import React from 'react';
import { useTranslation } from 'react-i18next';
import SRBugReport from '../../../fields/support/SRBugReport';
import BoxC from '../../box/BoxC';
import LabelAndData from '../../LabelAndData';
import SreDialog from './SreDialog';


export default function SreBugReport(props) {
  const { row, ...rest } = props;
  const payload = new SRBugReport(row.payload);
  const { t } = useTranslation('support');

  return (
    <BoxC {...rest}>
      <LabelAndData key='description' label={t('problem-description')} data={payload.problemDescription} />
      <LabelAndData key='stepsToReproduce' label={t('steps-to-reproduce-colon')} data={payload.stepsToReproduce}
        open={!!payload.stepsToReproduce} />
      <SreDialog dialogArray={payload.dialog} />
    </BoxC>
  );
}
