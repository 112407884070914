import GameStore from "../db/GameStore";

export default class ViewedCardIds {

  constructor(jsonInput) {
    const input = jsonInput ? JSON.parse(jsonInput) : null;
    this.viewedIds = input ? input.viewedIds : [];
    this.gameId = input ? input.gameId : GameStore.getGame().id;
  }

  asJSON() {
    return JSON.stringify(this);
  }
}

